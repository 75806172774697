import { Css, MenuItem, useModal } from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import {
  ConfirmDiscardDraftVersionModal_RpavFragment,
  useConfirmDiscardDraftVersionModal_ConfirmActionMutation,
  useConfirmDiscardDraftVersionModal_DataQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";

type RedirectToFn = (readyPlanLikeId: string, versionId: string) => string;
type ConfirmDiscardDraftVersionModalProps = {
  rpav: ConfirmDiscardDraftVersionModal_RpavFragment;
  redirectTo?: RedirectToFn;
};

/**
 * Designed to provide a unified confirm discard modal for PP/DP/POF.
 */
export function ConfirmDiscardDraftVersionModal({ rpav, redirectTo }: ConfirmDiscardDraftVersionModalProps) {
  const [discardDraft] = useConfirmDiscardDraftVersionModal_ConfirmActionMutation();
  const { closeModal } = useModal();
  const history = useHistory();

  const onConfirm = useCallback(async () => {
    const { data } = await discardDraft({ variables: { rpavId: rpav.id } });
    closeModal();
    if (redirectTo && data && data.deleteReadyPlanAggregateVersion.latestReadyPlanAggregateVersion) {
      history.replace(
        redirectTo(rpav.readyPlan.id, data.deleteReadyPlanAggregateVersion.latestReadyPlanAggregateVersion.id),
      );
    }
  }, [discardDraft, redirectTo, history, rpav, closeModal]);

  return (
    <ConfirmationModal
      title="Discard Draft"
      label="Yes, Discard"
      onConfirmAction={onConfirm}
      confirmationMessage={
        <>
          Are you sure you want to discard progress on <span css={Css.fw6.$}>{rpav.displayVersion}</span> of{" "}
          <span css={Css.fw6.$}>{rpav.readyPlan.name}</span>?
        </>
      }
    />
  );
}

export function useConfirmDiscardDraftVersionModalButtonMenuItem(rpavId: string, redirectTo?: RedirectToFn): MenuItem {
  const { data } = useConfirmDiscardDraftVersionModal_DataQuery({ variables: { rpavId } });
  const { openModal } = useModal();
  const { readyPlanAggregateVersion: rpav } = data ?? {};
  const menuItem = useMemo(
    () => ({
      label: "Discard Draft",
      ...(rpav
        ? {
            onClick: () =>
              openModal({
                content: <ConfirmDiscardDraftVersionModal rpav={rpav} redirectTo={redirectTo} />,
              }),
            disabled: disableBasedOnPotentialOperation(rpav.canDelete),
          }
        : {
            onClick: () => {},
            disabled: "Loading...",
          }),
    }),
    [rpav, openModal, redirectTo],
  );

  return menuItem;
}
