import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  Tag,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  CostType,
  Maybe,
  SaveChangeEventLineItemInput,
  UnitsOfMeasureDataFragment,
  useChangeEventLaborModal_EffectiveLocationsQuery,
  useChangeEventLaborModal_GlobalPlanTasksLazyQuery,
  useCreateChangeEventLineItemsTabMutation,
} from "src/generated/graphql-types";

type ChangeEventLaborModalProps = {
  projectId: string;
  changeEventId: string;
};

export function ChangeEventLaborModal(props: ChangeEventLaborModalProps) {
  const { changeEventId, projectId } = props;
  const [loadTasks, { data: tasksData }] = useChangeEventLaborModal_GlobalPlanTasksLazyQuery();
  const { data: locationsData } = useChangeEventLaborModal_EffectiveLocationsQuery({
    variables: { filter: { project: [projectId], version: [2] } },
  });
  const [createCeli] = useCreateChangeEventLineItemsTabMutation();
  const { closeModal } = useModal();

  const formState = useFormState({ config: formConfig });

  const onSave = useCallback(async () => {
    const { unitOfMeasure, ...input } = formState.value;
    await createCeli({
      variables: {
        input: {
          changeEventLineItems: [{ changeEventId, costType: CostType.Labor, ...input }],
        },
      },
    });
    closeModal();
  }, [changeEventId, createCeli, closeModal, formState]);

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>Add Labor</span>
          </ModalHeader>
          <ModalBody>
            <div css={Css.df.fdc.gap2.pb3.$}>
              <BoundSelectField
                label={`Task Allocation*`}
                field={formState.proposedTaskId}
                nothingSelectedText="Please select a task"
                options={{
                  current: undefined,
                  load: () => loadTasks({ variables: { filter: {} } }),
                  options: tasksData?.globalPlanTasks.entities,
                }}
                required
              />
              <BoundSelectField
                label="Location*"
                field={formState.locationId}
                options={locationsData?.locations ?? []}
                getOptionMenuLabel={({ name, displayLocationPath, type }) => (
                  <span>
                    <Tag xss={Css.m0.$} type="info" text={type.name} /> {name}{" "}
                    {name !== displayLocationPath && <span css={Css.gray500.$}>{displayLocationPath}</span>}
                  </span>
                )}
                required
              />
            </div>
            <div css={Css.df.jcsb.gap1.$}>
              <UnitsOfMeasureBoundSelectField
                label="UoM*"
                field={formState.unitOfMeasureId}
                onSelect={(uomId, uom) => {
                  formState.unitOfMeasureId.set(uomId);
                  formState.unitOfMeasure.set(uom);
                }}
              />
              <BoundNumberField
                label="Qty"
                field={formState.quantity}
                disabled={
                  formState.unitOfMeasure.value &&
                  !formState.unitOfMeasure.value?.useQuantity &&
                  `${formState.unitOfMeasure.value!.name} does not uses Quantity`
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
            <SubmitButton label="Save" form={formState} onClick={onSave} size="lg" />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type FormInput = Pick<
  SaveChangeEventLineItemInput,
  "proposedTaskId" | "locationId" | "unitOfMeasureId" | "quantity"
> & {
  // store the more detailed objects for easier access
  unitOfMeasure: Maybe<UnitsOfMeasureDataFragment>;
};

const formConfig: ObjectConfig<FormInput> = {
  locationId: { type: "value", rules: [required] },
  proposedTaskId: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value", rules: [required] },
  unitOfMeasure: { type: "value" },
  quantity: { type: "value" },
};
