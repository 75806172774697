import {
  BoundNumberField,
  BoundSelectField,
  column,
  Css,
  emptyCell,
  getTableStyles,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  numericColumn,
  RowStyles,
  selectColumn,
  simpleHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import {
  ChangeEventStatus,
  ExpensesTable_CommitmentLineItemFragment,
  ExpensesTable_ExpenseFragment,
  LotType,
  Maybe,
} from "src/generated/graphql-types";
import { sumCostData } from "src/routes/projects/budget/budgetCalc";
import { BudgetSuperDrawer } from "src/routes/projects/budget/components/BudgetSuperDrawer";
import { ExpensesTableInput } from "src/routes/projects/expenses/components/ExpensesTable";

type ExpenseAllocationTableProps = {
  expense: ExpensesTable_ExpenseFragment;
  formState: ObjectState<ExpensesTableInput>;
  tableApi: GridTableApi<ExpenseAllocationRow>;
  totalAmountInCents: number;
};

/** The list of project items to allocate the expense to. */
export function ExpenseAllocationTable(props: ExpenseAllocationTableProps) {
  const { expense, formState, tableApi, totalAmountInCents } = props;
  const { openInDrawer } = useSuperDrawer();
  const { potentialProjectItems, costCode } = expense;
  const tableStyle = getTableStyles({});

  if (potentialProjectItems.nonEmpty) {
    const rows = createRows(expense, formState, totalAmountInCents);
    return (
      <div css={Css.bgWhite.br8.m1.mb2.ba.bcGray300.oh.$}>
        <div css={Css.df.aic.mt1.mb1.ml2.mr2.$}>
          <div css={Css.baseMd.$}>Line items associated within this cost code</div>
        </div>
        <GridTable
          api={tableApi}
          id={`expenseAllocationTable-${expense.id}`}
          columns={createColumns(openInDrawer, expense.project.id, expense.project.lotType.code)}
          rows={rows}
          style={{ ...tableStyle, ...{ rootCss: { ...tableStyle.rootCss, ...Css.bt.bcGray200.$ } } }}
          rowStyles={createRowStyles()}
        />
      </div>
    );
  }

  return (
    <div css={Css.df.jcc.aic.mt2.$}>
      {costCode
        ? `No project line items were found for cost code ${costCode.number}.`
        : `Please contact accounting to add a cost code to this expense in Intacct`}
    </div>
  );
}

type CostData = {
  revisedInternalBudget?: Maybe<number>;
  committed?: Maybe<number>;
  uncommitted?: Maybe<number>;
  tradeBilled?: Maybe<number>;
};

type HeaderRow = {
  kind: "header";
};
type CostCodeRow = {
  kind: "costCode";
  data: CostData & {
    costCodeNumber: string;
    totalAmountInCents: number;
  };
};
type ProjectItemRow = {
  kind: "projectItem";
  data: CostData & {
    isBrex: boolean;
    amountInCentsField?: FieldState<Maybe<number>>;
    commitmentLineItemId?: FieldState<Maybe<string>>;
    projectItemId: string;
    name: string;
    createdFromChangeEvent: boolean;
    commitmentLineItems: ExpensesTable_CommitmentLineItemFragment[];
    expenseIntacctVendorId: string;
  };
};

export type ExpenseAllocationRow = HeaderRow | CostCodeRow | ProjectItemRow;

function createColumns(
  openInDrawer: ReturnType<typeof useSuperDrawer>["openInDrawer"],
  projectId: string,
  lotType: LotType,
): GridColumn<ExpenseAllocationRow>[] {
  const columns = [
    selectColumn<ExpenseAllocationRow>({ w: "32px" }),
    column<ExpenseAllocationRow>({
      header: "Cost Code",
      costCode: ({ costCodeNumber }) => costCodeNumber,
      projectItem: (data) => (
        <Link
          to={"#"}
          onClick={() =>
            openInDrawer({
              content: (
                <BudgetSuperDrawer
                  projectItemIds={[data.projectItemId]}
                  title={data.name}
                  projectId={projectId}
                  isPiRow
                />
              ),
            })
          }
        >
          {data.name}
        </Link>
      ),
      w: "120px",
    }),
    column<ExpenseAllocationRow>({
      header: "Revised Internal Budget",
      costCode: ({ revisedInternalBudget }) => <Price valueInCents={revisedInternalBudget} />,
      projectItem: ({ revisedInternalBudget }) => <Price valueInCents={revisedInternalBudget} />,
    }),
    column<ExpenseAllocationRow>({
      header: "Committed",
      costCode: ({ committed }) => <Price valueInCents={committed} />,
      projectItem: ({ committed }) => <Price valueInCents={committed} />,
    }),
    column<ExpenseAllocationRow>({
      header: "Uncommitted",
      costCode: ({ uncommitted }) => <Price valueInCents={uncommitted} />,
      projectItem: ({ uncommitted }) => <Price valueInCents={uncommitted} />,
    }),
    numericColumn<ExpenseAllocationRow>({
      header: "Trade Billed",
      costCode: ({ tradeBilled }) => <Price valueInCents={tradeBilled} />,
      projectItem: ({ tradeBilled }) => <Price valueInCents={tradeBilled} />,
    }),
    numericColumn<ExpenseAllocationRow>({
      header: "Allocate",
      costCode: ({ totalAmountInCents }) => <Price valueInCents={totalAmountInCents} />,
      projectItem: ({ amountInCentsField }, { row: { id }, api }) => {
        const selected = api.getSelectedRowIds("projectItem").includes(id);
        return (
          amountInCentsField && (
            <BoundNumberField field={amountInCentsField} labelStyle="hidden" type="cents" disabled={!selected} />
          )
        );
      },
    }),
  ];

  if (lotType === LotType.Hbl) {
    columns.push(
      column<ExpenseAllocationRow>({
        header: "Commitment",
        costCode: emptyCell,
        projectItem: ({ commitmentLineItems, commitmentLineItemId, expenseIntacctVendorId, isBrex }) => {
          const disabledOptions = !isBrex
            ? commitmentLineItems
                .map((cli) =>
                  cli.owner.tradePartner?.intacctId !== expenseIntacctVendorId
                    ? {
                        value: cli.id,
                        reason: "This Commitment's Trade Partner does not match the Expense's Intacct vendor",
                      }
                    : undefined,
                )
                .compact()
            : [];

          return (
            commitmentLineItemId && (
              <BoundSelectField
                field={commitmentLineItemId}
                getOptionLabel={(cli) => `#${cli.owner.accountingNumber} ${cli.owner.displayName}`}
                getOptionValue={(cli) => cli.id}
                options={commitmentLineItems}
                disabledOptions={disabledOptions}
              />
            )
          );
        },
        w: 2.0,
      }),
    );
  }

  return columns;
}

function createRows(
  expense: ExpensesTable_ExpenseFragment,
  formState: ObjectState<ExpensesTableInput>,
  totalAmountInCents: number,
): GridDataRow<ExpenseAllocationRow>[] {
  const createdProjectItems = expense.changeEvents
    .filter(({ status }) => status === ChangeEventStatus.Draft)
    .flatMap((ce) =>
      ce.lineItems.map((celi) => ({ id: celi.projectItem.id, changeEventCostInCents: ce.totalCostInCents })),
    );

  return [
    simpleHeader,
    {
      kind: "costCode" as const,
      id: expense.costCode!.id,
      data: {
        ...expense.potentialProjectItems.map((ppi) => ppi.budgetFinancials).reduce(sumCostData, {}),
        costCodeNumber: expense.costCode!.number,
        totalAmountInCents,
      },
      children: expense.potentialProjectItems.map((ppi) => {
        const existingAllocationState = formState.expenseAllocations.rows.find(
          (ea) => ea.projectItemId.value === ppi.id,
        );

        return {
          kind: "projectItem" as const,
          // The row id is the potential PI id, b/c the allocation may not be created yet
          id: ppi.id,
          initSelected: !!existingAllocationState?.id.value,
          data: {
            ...ppi.budgetFinancials,
            expenseIntacctVendorId: expense.intacctVendorId,
            isBrex: expense.isBrex,
            projectItemId: ppi.id,
            name: ppi.displayName,
            commitmentLineItemId: existingAllocationState?.commitmentLineItemId,
            commitmentLineItems: ppi.signedCommitmentLineItems,
            amountInCentsField: existingAllocationState?.amountInCents,
            createdFromChangeEvent: createdProjectItems.some((projectItem) => projectItem.id === ppi.id),
          },
        };
      }),
    },
  ];
}

function createRowStyles(): RowStyles<ExpenseAllocationRow> {
  return {
    projectItem: {
      cellCss: ({ data }) => (data.createdFromChangeEvent ? Css.bgYellow50.$ : {}),
    },
  };
}
