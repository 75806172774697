import { ButtonMenu } from "@homebound/beam";
import { createDesignPackageUrl, createDesignPackageVersionHistoryUrl } from "src/RouteUrls";
import { DesignPackageConfiguratorFragment } from "src/generated/graphql-types";
import { useConfirmDiscardDraftVersionModalButtonMenuItem } from "src/routes/libraries/plan-package/details/components/ConfirmDiscardDraftVersionModal";

type DesignPackageButtonMenuProps = {
  designPackage: DesignPackageConfiguratorFragment;
};

export function DesignPackageButtonMenu({ designPackage }: DesignPackageButtonMenuProps) {
  const discardDraftMenuItem = useConfirmDiscardDraftVersionModalButtonMenuItem(
    designPackage.version.id,
    createDesignPackageUrl,
  );

  return (
    <ButtonMenu
      trigger={{ icon: "verticalDots" }}
      items={[
        { label: "Version History", onClick: createDesignPackageVersionHistoryUrl(designPackage.id) },
        discardDraftMenuItem,
      ]}
    />
  );
}
