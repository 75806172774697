import { Accordion, BoundSelectField, Css, useComputed } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useEffect, useMemo } from "react";
import {
  ContactsTabTradePartnerContactFragment,
  ContactsTabTradePartnerMarketFragment,
  ContactsTabTradePartnerMarketRolesFragment,
  TradePartnerContactsDocument,
  TradePartnerMarketRole,
  useSaveTradePartnerMarketContactMutation,
  useTradePartnerContactsRolesPanelMetadataQuery,
} from "src/generated/graphql-types";
import { groupBy } from "src/utils";

export type TradePartnerContactsRolesProps = {
  tradePartnerId: string;
  roles: ContactsTabTradePartnerMarketRolesFragment[];
  contacts: ContactsTabTradePartnerContactFragment[];
  markets: ContactsTabTradePartnerMarketFragment[];
};

export function TradePartnerContactsRolesPanel({
  tradePartnerId,
  roles,
  contacts,
  markets,
}: TradePartnerContactsRolesProps) {
  const { data } = useTradePartnerContactsRolesPanelMetadataQuery();

  const { tradePartnerMarketRoles } = data ?? {};
  // Manually filtering out the scheduling roles per product/design
  const filteredRoles = tradePartnerMarketRoles?.filter(
    (r) => ![TradePartnerMarketRole.Scheduling, TradePartnerMarketRole.SchedulingRecipient_2].includes(r.code),
  );

  const [saveTradePartnerMarketRole] = useSaveTradePartnerMarketContactMutation({
    /**
     * `Disable TPC` flow has a canDeactivate PotentialOp that specifically calls out reassigning
     * users to enable. When users do that, but canDeactivate is still disabled because they haven't
     * refreshed, it's a confusing UX. So refetch here.
     */
    refetchQueries: [TradePartnerContactsDocument],
  });

  const rolesByMarket: FormValue = useMemo(
    () => ({
      rolesByMarket: filteredRoles
        ? markets
            .map((market) => {
              const marketInfo = { tradePartnerId, marketId: market.id, marketName: market.name };
              const getContactByRole = (role: TradePartnerMarketRole) =>
                roles.find((r) => r.market.id === market.id && r.role.code === role);
              // Get the primary, signatoy and bill approver contacts for this market
              return filteredRoles?.map((tpmr) => {
                const contact = getContactByRole(tpmr.code);
                return {
                  ...marketInfo,
                  tpContactRole: tpmr.code,
                  tpContactRoleName: tpmr.name,
                  tpContactId: contact?.contact.id,
                  tpmcId: contact?.id,
                };
              });
            })
            .flat()
        : [],
    }),
    [markets, roles, tradePartnerId, tradePartnerMarketRoles],
  );

  const formState = useFormState({
    config: formConfig,
    init: { input: rolesByMarket, map: mapToForm },
  });

  const groupedByMarket = useComputed(
    () => groupBy(formState.rolesByMarket.rows, ({ marketName }) => marketName.value),
    [formState],
  );

  useEffect(() => {
    formState.set(rolesByMarket);
  }, [formState, rolesByMarket]);

  return (
    <section css={Css.wPx(392).br4.p2.pb3.$}>
      <div css={Css.lgBd.pb2.$}>Role by Market</div>
      {Object.entries(groupedByMarket).map(([marketName, roles], index, arr) => (
        <Accordion key={marketName} title={marketName} bottomBorder={index === arr.length - 1}>
          {roles.map((r) => (
            <div key={`${r.marketId.value}-${r.tpContactRole.value}`} css={Css.pb2.$}>
              <BoundSelectField
                field={r.tpContactId}
                label={r.tpContactRoleName.value}
                placeholder={`Select a ${r.tpContactRoleName.value} contact`}
                options={[...contacts]}
                onSelect={async (tpContactId) => {
                  // We intentionally dont want to include the None option here
                  // so that downstream processes like bill/po pdf generations & notifications have the correct signatory
                  // ie Require a tpc to be replaced if a tpc needs to be removed or changed
                  // Save the selected trade partner contact
                  if (tpContactId) {
                    await saveTradePartnerMarketRole({
                      variables: {
                        input: {
                          contactId: tpContactId,
                          role: r.tpContactRole.value,
                          marketId: r.marketId.value,
                          tradePartnerId,
                        },
                      },
                    });
                  }
                }}
              />
            </div>
          ))}
        </Accordion>
      ))}
    </section>
  );
}

type RoleByMarketType = {
  tpContactId: string | undefined | null;
  tpContactRole: TradePartnerMarketRole;
  tpContactRoleName: string;
  marketId: string;
  marketName: string;
  tpmcId: string | undefined;
};

type FormValue = {
  rolesByMarket: RoleByMarketType[];
};

const formConfig: ObjectConfig<FormValue> = {
  rolesByMarket: {
    type: "list",
    config: {
      tpContactId: { type: "value" },
      tpContactRole: { type: "value" },
      tpContactRoleName: { type: "value" },
      marketId: { type: "value" },
      marketName: { type: "value" },
      tpmcId: { type: "value" },
    },
  },
};

function mapToForm({ rolesByMarket }: FormValue) {
  return {
    rolesByMarket: rolesByMarket.map((r) => ({
      tpContactId: r.tpContactId,
      tpContactRole: r.tpContactRole,
      tpContactRoleName: r.tpContactRoleName,
      marketId: r.marketId,
      marketName: r.marketName,
      tpmcId: r.tpmcId,
    })),
  };
}
