import { Css, Icon, Palette, Tooltip, useTestIds } from "@homebound/beam";
import { formatDate } from "src/components";
import {
  DraftTaskConstraintAndAllowance_PlanTaskFragment,
  Maybe,
  Named,
  PlanTask,
  PlanTaskSimulation_SimulationProbabilityFragment,
  PotentialDateBasisPoints,
} from "src/generated/graphql-types";
import { basisPointsToPercentage } from "src/utils/basisPoints";

type DebugTooltipProps = {
  debugMode?: boolean;
  task: Pick<PlanTask, "slackInDays" | "isCriticalPath" | "isManuallyScheduled" | "earliestStartDate"> & {
    simulationProbability: PlanTaskSimulation_SimulationProbabilityFragment;
  } & DraftTaskConstraintAndAllowance_PlanTaskFragment;
};

export function DebugTooltip({ debugMode, task }: DebugTooltipProps) {
  const tids = useTestIds({}, "debugToolTip");

  if (!debugMode) return null;

  return (
    <div {...tids}>
      <Tooltip title={<TooltipContent task={task} />}>
        <Icon icon="infoCircle" color={Palette.Gray600} />
      </Tooltip>
    </div>
  );
}

function TooltipContent({ task }: DebugTooltipProps) {
  const {
    simulationProbability: { potentialStartDateBasisPoints, potentialEndDateBasisPoints },
    constraintItems,
    allowanceItems,
    slackInDays,
    isCriticalPath,
    isManuallyScheduled,
    earliestStartDate,
  } = task;

  return (
    <div css={Css.df.fdc.gap1.p1.$}>
      <div>Fixed Start Date: {isManuallyScheduled && earliestStartDate ? formatDate(earliestStartDate) : "null"}</div>
      <div>Critical Path: {isCriticalPath.toString()}</div>
      <div>Slack in Days: {slackInDays}</div>
      <DateProbabilityDisplay dateProbability={potentialStartDateBasisPoints} label="Start Date Probability" />
      <DateProbabilityDisplay dateProbability={potentialEndDateBasisPoints} label="End Date Probability" />
      <ConstraintItemDisplay label="Constraints" constraintItems={constraintItems} />
      <ConstraintItemDisplay label="Allowances" constraintItems={allowanceItems} />
    </div>
  );
}

function DateProbabilityDisplay({
  dateProbability,
  label,
}: {
  dateProbability: Maybe<PotentialDateBasisPoints[]>;
  label: string;
}) {
  if (!dateProbability) return null;

  return (
    <div>
      {label}:
      <ul>
        {dateProbability.map((dateProbability) => {
          const { date, basisPoints } = dateProbability;

          return (
            <li key={`${label}-${date.toDateString()}`}>
              {formatDate(date)}: {basisPointsToPercentage(basisPoints)}%
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ConstraintItemDisplay({ constraintItems, label }: { constraintItems: Named[]; label: string }) {
  return (
    <div>
      {label}:
      <ul>
        {constraintItems.map(({ name }) => {
          return <li key={`${label}-${name}`}>{name}</li>;
        })}
      </ul>
    </div>
  );
}
