import {
  BoundSelectField,
  Button,
  column,
  Css,
  dateColumn,
  FullBleed,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  IconButton,
  LoadingSkeleton,
  selectColumn,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { dateCell, emptyCellDash } from "src/components";
import {
  FilteredProjectsTableFragment,
  FilteredProjectsTableQuery,
  Maybe,
  ProjectReadyPlanConfigVersion,
  ProjectStatus,
  useFilteredProjectsTableQuery,
  useProductOfferingApplyToProjectMutation,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { ProductOfferingParams } from "src/routes/routesDef";
import { createDevelopmentProductOfferingUrl, createProjectDashboardUrl } from "src/RouteUrls";
import { pluralize } from "src/utils";
import { openNewTab } from "src/utils/window";
import { Header } from "../templates/review-and-publish/components/Header";

export function ProductOfferingApplyToProjectsPage() {
  const { developmentId, productOfferingId, productOfferingVersionId } = useParams<ProductOfferingParams>();
  const tid = useTestIds({});
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const [applyToProjects] = useProductOfferingApplyToProjectMutation();
  const query = useFilteredProjectsTableQuery({
    variables: { filter: { readyPlans: [productOfferingId], status: [ProjectStatus.Active] } },
  });
  const { data, loading } = query;
  const formState = useFormState({
    config: formConfig,
    init: {
      query: query,
      map: ({ projectsPage }) => ({
        projects: projectsPage.entities.map((project) => ({ id: project.id, onlyChangedInVersion: true })),
      }),
    },
  });

  const tableApi = useGridTableApi<Row>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("project") ?? [], [tableApi]);
  const columns = useMemo(() => createColumns(), []);
  const formRows = useComputed(() => formState.projects.rows, [formState]);
  const rows = useMemo(() => createRows(data, formRows), [data, formRows]);

  async function applyProductOfferingVersion() {
    const toUpdate: ProjectReadyPlanConfigVersion[] = selectedRows.map(
      ({ data: { readyPlanConfig, objectState } }) => ({
        itemTemplateId: productOfferingVersionId,
        prpcId: readyPlanConfig!.id,
        onlyChangedInVersion: objectState?.onlyChangedInVersion.value ?? true,
      }),
    );
    const { data } = await applyToProjects({ variables: { input: { prpcAtVersions: toUpdate } } });
    const updatedProjects = data?.applyProjectReadyPlanConfigs.projectReadyPlanConfigs.length ?? 0;
    triggerNotice({
      message: `Product Offering version successfully applied to ${updatedProjects} ${pluralize(updatedProjects, "project")}`,
      icon: "success",
    });
    history.push(createDevelopmentProductOfferingUrl(developmentId, productOfferingId, productOfferingVersionId));
  }

  return (
    <div>
      <Header
        preHeader="Apply Product Offering Version"
        header={
          <div css={Css.df.cg1.$}>
            <div css={Css.mya.$}>
              <IconButton
                icon="arrowBack"
                onClick={createDevelopmentProductOfferingUrl(
                  developmentId,
                  productOfferingId,
                  productOfferingVersionId,
                )}
              />
            </div>
            <div>Apply to Projects</div>
          </div>
        }
        postHeader="Select the projects below you wish to apply the new product offering version to."
      />
      <FullBleed>
        <div css={Css.df.mh("61vh").p3.$}>
          <div css={Css.fg1.bgWhite.br8.$} {...tid.apply}>
            <div css={Css.px3.py2.$}>
              <TableActions>
                <div css={Css.gray900.smMd.$} {...tid.selectedRows}>
                  {selectedRows.length} selected
                </div>
              </TableActions>
              {loading ? (
                <>
                  <LoadingSkeleton rows={1} columns={1} />
                  <LoadingSkeleton rows={5} columns={3} />
                </>
              ) : (
                <GridTable
                  api={tableApi}
                  columns={columns}
                  rows={rows}
                  stickyHeader
                  style={{ vAlign: "top", rowHeight: "flexible" }}
                  fallbackMessage="All projects are up to date."
                />
              )}
            </div>
          </div>
        </div>
      </FullBleed>
      <div css={Css.tar.$}>
        <Button
          size="lg"
          variant="primary"
          label="Apply Changes"
          disabled={!selectedRows.length}
          onClick={applyProductOfferingVersion}
          tooltip={selectedRows.isEmpty ? "To apply choose one or more project." : undefined}
        />
      </div>
    </div>
  );
}

type HeaderRow = { kind: "header"; id: string };
type ProjectRow = {
  kind: "project";
  data: FilteredProjectsTableFragment & { objectState?: ObjectState<ProjectFields> };
};
type Row = HeaderRow | ProjectRow;

function createColumns(): GridColumn<Row>[] {
  return [
    selectColumn<Row>({}),
    column<Row>({
      header: "Project",
      project: ({ id, name }) => ({
        content: name,
        onClick: () => openNewTab(createProjectDashboardUrl(id)),
      }),
    }),
    dateColumn<Row>({
      header: "Construction Start",
      project: ({ readyPlanConfig }) =>
        readyPlanConfig ? dateCell(readyPlanConfig?.constructionStartDate, { xss: Css.gray900.$ }) : emptyCellDash,
    }),
    dateColumn<Row>({
      header: "Latest Stage",
      project: ({ latestActiveStage }) => latestActiveStage?.stage.name,
    }),
    dateColumn<Row>({
      header: "Market",
      project: ({ market }) => market.name,
    }),
    column<Row>({
      header: {
        content: () => (
          <div css={Css.df.cg1.$}>
            <span css={Css.mya.$}>Apply changes from</span>
            <Icon
              icon={"infoCircle"}
              tooltip="Choose to apply line item changes from the latest major version or cumulative changes from ALL in-between versions."
            />
          </div>
        ),
      },
      project: ({ objectState }) => ({
        content: objectState ? (
          <BoundSelectField
            field={objectState.onlyChangedInVersion}
            options={[
              { name: "Changes Only", value: true },
              { name: "All Lines", value: false },
            ]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            label="Apply Changes From"
            labelStyle="hidden"
            data-testid="applyChangesFrom"
          />
        ) : (
          emptyCellDash
        ),
      }),
      w: "180px",
    }),
  ];
}

function createRows(
  data: FilteredProjectsTableQuery | undefined,
  formRows: readonly ObjectState<ProjectFields>[],
): GridDataRow<Row>[] {
  const stateById = formRows.keyBy((row) => row.id.value);
  const rows: GridDataRow<Row>[] =
    data?.projectsPage.entities.map((project) => ({
      id: project.id,
      kind: "project",
      data: { ...project, objectState: stateById[project.id] },
    })) ?? [];
  return [simpleHeader, ...rows];
}

type ProjectFields = {
  id: string;
  onlyChangedInVersion: Maybe<boolean>;
};

// A small form just to hold the `onlyChangedInVersion` toggle.
const formConfig: ObjectConfig<{ projects: ProjectFields[] }> = {
  projects: {
    type: "list",
    config: {
      id: { type: "value" },
      onlyChangedInVersion: { type: "value" },
    },
  },
};
