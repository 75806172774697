import {
  actionColumn,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  column,
  Css,
  emptyCell,
  FormHeading,
  FormLines,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalProps,
  numericColumn,
  ScrollableContent,
  simpleHeader,
  StaticField,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { computed, IComputedValue } from "mobx";
import { Observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  CommentFeed,
  DeleteLineItemButton,
  Divider,
  FileList,
  FormActions,
  FormMode,
  HistoryFeed,
  Price,
  priceCell,
  priceTotal,
} from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { InvoiceNumber } from "src/components/formFields/InvoiceNumber";
import { PricePercentage } from "src/components/PricePercentage";
import {
  CostClassificationType,
  InvoiceEditorInvoiceFragment,
  InvoiceStatus,
  ProjectStageDetailFragment,
  SaveInvoiceDrawLineItemInput,
  SaveInvoiceInput,
} from "src/generated/graphql-types";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { PageHeaderActions } from "src/routes/layout/PageHeader";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { AddContractLineItemButton } from "src/routes/projects/invoices/AddContractLineItemButton";
import { AddDrawLineItemButton } from "src/routes/projects/invoices/AddDrawLineItemButton";
import { createHomeownerContractChangeOrderDrawsUrl, createHomeownerContractDrawsUrl } from "src/RouteUrls";
import { empty, isDefined, sum, unique } from "src/utils";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { isContractualStage } from "src/utils/projects";
import { SubmitApprovalModal } from "../approvals/SubmitApprovalModal";
import { BillsModal } from "./BillsModal";
import {
  buildLineItemsForProjectItem,
  calculateAmountByPercentage,
  calculatePercentageCompleteByAmount,
  calculateUninvoiced,
  ContractLineItem,
  removeLineItemsByProjectItemId,
} from "./utils";

export type InvoiceEditorProps = {
  mode: FormMode;
  projectId: string;
  currentUserId: string;
  projectStages: ProjectStageDetailFragment[];
  invoice: InvoiceEditorInvoiceFragment | undefined;
  invoiceV2?: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (formState: SaveInvoiceInput | undefined, redirect?: boolean, submitForApproval?: boolean) => Promise<void>;
  onDelete: () => Promise<void>;
};

export function InvoiceEditor(props: InvoiceEditorProps) {
  const { invoice, mode } = props;
  const readOnly = mode === "read";
  const { clientNoun } = useProjectContext();
  const { openModal } = useModal();
  const openApproval = useApprovalSuperDrawer();

  const formState = useFormState({
    config: formConfigInvoiceEditor,
    init: {
      input: invoice,
      map: (invoice) => mapToFormInvoiceEditor(invoice),
      ifUndefined: emptyInputInvoiceEditor(),
    },
    readOnly,
  });
  const testIds = useTestIds({}, "invoiceEditor");

  const totalsRowData: ComputedTotals = useMemo(
    () =>
      computed(() => ({
        thisInvoiceInCents: [
          ...formState.lineItems.rows.filter((r) => !r.delete.value).map((r) => r.amountInCents.value || 0),
          ...formState.drawLineItems.rows.filter((r) => !r.delete.value).map((r) => r.amountInCents.value || 0),
        ].reduce(sum, 0),
      })),
    [formState],
  );

  return (
    <Observer>
      {() => renderForm(props, formState, totalsRowData, openModal, openApproval, testIds, clientNoun)}
    </Observer>
  );
}

function renderForm(
  props: InvoiceEditorProps,
  formState: FormState,
  totalsRowData: ComputedTotals,
  openModal: (props: ModalProps) => void,
  openApproval: (approvalIds: string | string[] | undefined) => void,
  testIds: Record<string, object>,
  clientNoun: string,
): JSX.Element {
  const { projectId, projectStages, mode, invoice, onCancel, onDelete, onEdit, invoiceV2 = false, onSave } = props;

  const isApproved = invoice?.status.code === InvoiceStatus.Approved;

  return (
    <>
      <PageHeaderActions>
        <>
          <FormActions
            {...{ mode, onEdit, onCancel, formState, onDelete }}
            entityType="Invoice"
            entityName={`Invoice ${invoice?.id}`}
            deleteDisabledReason={isApproved ? "Cannot delete an invoice that has been approved" : ""}
            onSave={() => onSave(mapToInputInvoiceEditor(formState), true, mode === "create")}
          />
          {mode === "read" && invoice && (
            <Button
              label="Approvals"
              disabled={
                isApproved && invoice.intacctId
                  ? "Cannot unapprove an invoice that has been synced to Sage Intacct"
                  : undefined
              }
              data-testid="approvalsBtn"
              onClick={() =>
                invoice.approval
                  ? openApproval(invoice.approval.id)
                  : openModal({
                      content: (
                        <SubmitApprovalModal
                          subject="Invoice"
                          subjectId={invoice.id}
                          predictedApprovers={invoice.predictedApprovers}
                        />
                      ),
                    })
              }
            />
          )}
        </>
      </PageHeaderActions>
      <ScrollableContent>
        <FormLines>
          <FormHeading title="Details" />
          {invoice?.intacctSyncError && (
            <div css={Css.maxw("550px").$}>
              <StaticField label="Sync Error">
                <div css={Css.red700.$}> {invoice?.intacctSyncError} </div>
              </StaticField>
            </div>
          )}
          {invoice && (
            <>
              <StaticField value={invoice.approval ? invoice.approval.status.name : "-"} label="Approval" />
              <StaticField value={invoice.intacctId ?? ""} label="Intacct ID" />
            </>
          )}
          <BoundSelectField
            label={
              invoice?.id || formState.lineItems.rows.length === 0
                ? "Related Contract"
                : "Related Contract - Cannot change if there are existing Line Items"
            }
            field={formState.projectStageId}
            options={projectStages.filter((s) => isContractualStage(s.stage.code))}
            getOptionLabel={({ stage }) => stage.name}
            getOptionValue={({ id }) => id}
            readOnly={Boolean(
              invoice ||
                formState.lineItems.rows.length > 0 ||
                formState.drawLineItems.rows.length > 0 ||
                mode === "read",
            )}
            disabledOptions={projectStages.filter((ps) => !ps.hasSignedContract).map((ps) => ps.id)}
            helperText={mode === "read" ? undefined : "Only signed contracts can be selected"}
          />
          <BoundTextField field={formState.title} />
          {invoice && <StaticField value={invoice.type.name} label="Type" />}
          {isDefined(formState.projectStageId.value) && (
            <InvoiceNumber
              formState={formState}
              isHistoricalInvoiceNumber={invoice?.isHistoricalInvoiceNumber}
              mode={mode}
              stage={projectStages.find((s) => s.id === formState.projectStageId.value)!.stage.code}
            />
          )}
          <BoundBeamDateField field={formState.invoiceDate} helperText="Enter the date of this invoice" />
          <BoundBeamDateField
            field={formState.dueDate}
            helperText={`Enter the date the ${clientNoun.toLowerCase()} needs to pay this invoice by`}
          />

          {formState?.paidDate ? (
            <BoundBeamDateField field={formState.paidDate} readOnly helperText={`Invoice Paid Date`} />
          ) : null}

          <BoundTextAreaField
            field={formState.description}
            label={`Memo${!formState.readOnly ? " (optional)" : ""}`}
            helperText={`This will be visible to the ${clientNoun.toLowerCase()}`}
          />

          {mode === "read" && invoice && (
            <>
              <FormHeading title="Files" />
              <div {...testIds.files}>
                {!invoice.document ? (
                  <p css={Css.gray700.$}>
                    Files will show here after this invoice is approved and synced with Quickbooks.
                  </p>
                ) : (
                  <FileList files={[invoice.document]} />
                )}
              </div>
            </>
          )}
        </FormLines>

        <div css={Css.mt3.sm.$}>
          <FormHeading title="Line Items" />
          <GridTable
            id="invoiceEditorTable"
            stickyHeader
            style={{ allWhite: true, bordered: true }}
            columns={createLineItemColumns(
              projectId,
              invoice,
              formState,
              mode,
              testIds.contractItem,
              openModal,
              clientNoun,
              invoiceV2,
            )}
            rows={createLineItemRows(formState, totalsRowData, mode)}
          />
          {mode !== "read" && (
            <p css={Css.mt2.sm.gray700.$} {...testIds.tableCaption}>
              Line items with empty amounts are for reference only and will not be included on the invoice.
            </p>
          )}
        </div>

        {mode === "read" && invoice && (
          <>
            <Divider xss={Css.mt5.mb3.$} />
            <CommentFeed commentable={invoice} />
            <div css={Css.mt5.$}>
              <HistoryFeed historyItems={invoice.history} />
            </div>
          </>
        )}
      </ScrollableContent>
    </>
  );
}

type HeaderRow = { kind: "header" };
type IndividualItemRow = { kind: "contractLineItem"; data: FormState["lineItems"]["rows"][0] };
type DrawItemRow = { kind: "drawLineItem"; data: FormState["drawLineItems"]["rows"][0] };
type TotalRow = { kind: "total"; data: TotalData };
type Row = HeaderRow | IndividualItemRow | DrawItemRow | TotalRow;

function createLineItemRows(formState: FormState, totalsRowData: ComputedTotals, mode: FormMode): GridDataRow<Row>[] {
  return [
    simpleHeader,
    // Add the contract line items
    ...formState.lineItems.rows
      .filter((r) => r.delete.value !== true)
      // In "read" mode omit ChangeOrder rows that are not part of this invoice
      .filter((r) => (mode !== "read" || !r.isChangeOrder.value ? true : r.id.value))
      .map((data, i) => ({ kind: "contractLineItem" as const, id: `contractLineItem-${i}`, data })),
    // Add the draw line items
    ...formState.drawLineItems.rows
      .filter((r) => r.delete.value !== true)
      .map((data, i) => ({ kind: "drawLineItem" as const, id: `drawLineItem-${i}`, data })),
    { kind: "total" as const, id: "total", data: totalsRowData.get() },
  ];
}

function createLineItemColumns(
  projectId: string,
  invoice: InvoiceEditorInvoiceFragment | undefined,
  formState: FormState,
  mode: FormMode,
  contractItemId: object,
  openModal: (props: ModalProps) => void,
  clientNoun: string,
  invoiceV2: boolean,
): GridColumn<Row>[] {
  const projectItemOrChangeOrderColumn = column<Row>({
    header: () => ({ ...emptyCell, colspan: 2 }),
    contractLineItem: (row) => (
      <span {...contractItemId} css={Css.if(!!row.isChangeOrder.value).pl2.$}>
        {row.name.value}
      </span>
    ),
    drawLineItem: (row) => ({
      content: (
        <Link
          data-testid="drawLineItemLink"
          target="_blank"
          to={
            row.contractId.value.startsWith("hcco:")
              ? createHomeownerContractChangeOrderDrawsUrl(projectId, row.contractId.value)
              : createHomeownerContractDrawsUrl(projectId, row.contractId.value)
          }
        >
          {row.description.value}
        </Link>
      ),
      colspan: 2,
    }),
    total: () => ({
      content: (
        <>
          <AddContractLineItemButton
            lineItems={formState.lineItems}
            projectId={projectId}
            invoice={invoice}
            projectStageId={formState.projectStageId.value}
            invoiceV2={invoiceV2}
          />
          <AddDrawLineItemButton
            lineItems={formState.drawLineItems}
            projectId={projectId}
            invoice={invoice}
            projectStageId={formState.projectStageId.value}
            invoiceV2={invoiceV2}
          />
        </>
      ),
      colspan: 4,
    }),
    w: "3fr",
  });

  const billsLinkColumn = column<Row>({
    header: () => "",
    contractLineItem: (row) => {
      if (row.isChangeOrder.value || row.billIds.value.length <= 0) {
        return emptyCell;
      }
      return (
        <Button
          variant="tertiary"
          data-testid="viewBills"
          label="Bills"
          onClick={() => {
            openModal({ content: <BillsModal projectItemId={row.projectItemId.value} />, size: "xl" });
          }}
        />
      );
    },
    // TODO Link to schedule
    drawLineItem: "",
    total: "",
    w: "2fr",
  });

  const homeownerPriceColumn = numericColumn<Row>({
    header: () => `${clientNoun} Price`,
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      return <Price id="homeownerPrice" valueInCents={row.priceChangeInCents.value} />;
    },
    drawLineItem: "",
    total: "",
    w: "150px",
  });

  const otherInvoicesColumn = numericColumn<Row>({
    header: () => "Other Invoices",
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      return (
        <PricePercentage
          id="otherInvoices"
          amountInCents={row.otherInvoices.value?.price}
          percentage={row.otherInvoices.value?.percentage}
        />
      );
    },
    drawLineItem: "",
    total: "",
    w: "150px",
  });

  const uninvoicedColumn = numericColumn<Row>({
    header: () => "Uninvoiced",
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      return (
        <PricePercentage
          id="uninvoiced"
          amountInCents={row.uninvoiced.value?.price}
          percentage={row.uninvoiced.value?.percentage}
        />
      );
    },
    drawLineItem: "",
    total: emptyCell,
    w: "175px",
  });

  const percentCompleteColumn = numericColumn<Row>({
    header: () => "% Complete",
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      return (
        <BoundNumberField
          field={row.percentComplete}
          type="percent"
          numFractionDigits={2}
          onChange={(percentComplete = 0) => {
            const amountInCents = calculateAmountByPercentage(row, percentComplete);
            row.set({
              amountInCents,
              percentComplete,
              uninvoiced: calculateUninvoiced(row, amountInCents, percentComplete),
            });
          }}
        />
      );
    },
    drawLineItem: "",
    total: () => ({ alignment: "right", content: "Invoice Total:" }),
    w: "1fr",
  });

  const amountColumn = numericColumn<Row>({
    header: () => "This Invoice",
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      if (mode === "read") {
        const thisPercentComplete = !row.value.percentComplete
          ? 0
          : row.value.percentComplete - (row.otherInvoices.value?.percentage || 0);
        return (
          <PricePercentage
            id="thisInvoice"
            amountInCents={row.value.amountInCents ?? undefined}
            percentage={thisPercentComplete}
          />
        );
      }
      return (
        <BoundNumberField
          data-testid="invoiceAmount"
          type="cents"
          field={row.amountInCents}
          onChange={(amountInCents) => {
            amountInCents = amountInCents || 0;
            const percentComplete = calculatePercentageCompleteByAmount(row, amountInCents);
            row.set({
              amountInCents,
              percentComplete,
              uninvoiced: calculateUninvoiced(row, amountInCents, percentComplete),
            });
          }}
        />
      );
    },
    drawLineItem: (row) => priceCell({ id: "thisInvoice", valueInCents: row.amountInCents.value }),
    total: (row) => priceTotal({ id: "invoicedTotal", valueInCents: row.thisInvoiceInCents }),
    w: "140px",
  });

  // Use the `emptyCell` override from Beam to ensure the action column cells do not render anything (except a trash can when it makes sense)
  const deleteColumn = actionColumn<Row>({
    header: emptyCell,
    contractLineItem: (row) =>
      row.isChangeOrder.value ? (
        emptyCell
      ) : (
        <DeleteLineItemButton
          allowEmpty
          list={formState.lineItems}
          readOnly={invoiceV2}
          row={row}
          onClick={() => {
            row.projectItemId.value && removeLineItemsByProjectItemId(row.projectItemId.value, formState);
          }}
        />
      ),
    drawLineItem: (row) => (
      <DeleteLineItemButton allowEmpty list={formState.drawLineItems} row={row} readOnly={invoiceV2} />
    ),
    total: emptyCell,
  });

  const columns = [
    projectItemOrChangeOrderColumn,
    billsLinkColumn,
    homeownerPriceColumn,
    otherInvoicesColumn,
    amountColumn,
    uninvoicedColumn,
    deleteColumn,
  ];

  // Add `percentCompleteColumn` if not in `read` mode
  if (mode !== "read") {
    columns.splice(4, 0, percentCompleteColumn);
  }

  return columns;
}

type TotalData = { thisInvoiceInCents: number };
type ComputedTotals = IComputedValue<TotalData>;

type FormState = ObjectState<FormInput>;
export type InvoiceFormState = FormState;
type FormInput = Omit<SaveInvoiceInput, "lineItems" | "drawLineItems"> & {
  lineItems: ContractLineItem[];
  drawLineItems: DrawLineItem[];
  scheduledLineItems: ScheduledLineItem[];
};

type DrawLineItem = Omit<SaveInvoiceDrawLineItemInput, "drawId"> & {
  // Make this required
  drawId: string;
  // Computed properties for display purposes
  description: string;
  contractId: string;
  taskId: string;
  amountInCents: number;
};

type ScheduledLineItem = {
  id: string;
  name: string;
  amountInCents: number;
  marginInCents: number;
  contractId: string;
  costClassification: CostClassificationType;
};

export const formConfigInvoiceEditor: ObjectConfig<FormInput> = {
  id: { type: "value" },
  projectStageId: { type: "value", rules: [required] },
  invoiceNumber: { type: "value" },
  invoiceDate: { type: "value", rules: [required] },
  dueDate: { type: "value", rules: [required] },
  paidDate: { type: "value" },
  description: { type: "value" },
  title: { type: "value", rules: [required] },
  creditForInvoiceId: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      isChangeOrder: { type: "value" },
      billIds: { type: "list", config: { id: { type: "value" } } },
      projectItemId: { type: "value" },
      homeownerContractLineItemId: { type: "value" },
      name: { type: "value" },
      otherInvoices: { type: "value" },
      percentComplete: { type: "value" },
      minPercentage: { type: "value" },
      amountInCents: { type: "value" },
      marginInCents: { type: "value" },
      costClassification: { type: "value" },
      priceChangeInCents: { type: "value" },
      uninvoiced: { type: "value" },
      delete: { type: "value" },
    },
    // TODO: @bdow - Add rule to ensure there is at least one line item
  },
  drawLineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      drawId: { type: "value" },
      contractId: { type: "value" },
      delete: { type: "value" },
      // TODO Should 'computed' be renamed to 'read only'?
      description: { type: "value", computed: true },
      amountInCents: { type: "value", computed: true },
      taskId: { type: "value", computed: true },
    },
  },
  scheduledLineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      amountInCents: { type: "value" },
      marginInCents: { type: "value" },
      contractId: { type: "value" },
      costClassification: { type: "value" },
    },
  },
};

export function mapToFormInvoiceEditor(invoice: InvoiceEditorInvoiceFragment): FormInput {
  const contractLineItemsByProjectItem = invoice.projectStage.homeownerContracts[0]?.lineItemsByProjectItem || [];

  return {
    ...invoice,
    status: invoice.status.code,
    projectStageId: invoice.projectStage.id,
    // Get the unique list of projectItems for the invoice line items, then add each
    // original contract line item + any C/O line items right after it.
    lineItems: unique(invoice.lineItems.map((li) => li.homeownerContractLineItem.projectItem.id)).flatMap((pId) =>
      buildLineItemsForProjectItem(pId, contractLineItemsByProjectItem, invoice),
    ),
    drawLineItems: invoice.drawLineItems.map((dli) => ({
      id: dli.id,
      drawId: dli.draw.id,
      contractId: dli.draw.parent.id,
      description: dli.draw.description,
      amountInCents: dli.draw.amountInCents,
      taskId: dli.draw.task.id,
      delete: false,
    })),
    scheduledLineItems: invoice.scheduledLineItems.map((sli) => ({
      id: sli.id,
      amountInCents: sli.amountInCents ?? 0,
      marginInCents: sli.marginInCents,
      name: sli.invoiceScheduleItem.parent!.name, // We must have a parent for an SLI to be generated
      contractId:
        (sli.invoiceScheduleItem.parent &&
          ("project" in sli.invoiceScheduleItem.parent
            ? sli.invoiceScheduleItem.parent.project?.homeownerContracts.first?.id
            : "planSchedule" in sli.invoiceScheduleItem.parent
              ? sli.invoiceScheduleItem.parent.planSchedule.parent?.homeownerContracts.first?.id
              : undefined)) ??
        "",
      costClassification: sli.invoiceScheduleItem.costClassificationType.code,
    })),
  };
}

export function mapToInputInvoiceEditor(formState: FormState): SaveInvoiceInput {
  const { lineItems, drawLineItems, scheduledLineItems, ...others } = formState.value;
  return {
    ...others,
    lineItems: lineItems
      .map((li) => ({
        id: li.id,
        homeownerContractLineItemId: li.homeownerContractLineItemId,
        amountInCents: li.amountInCents,
        delete: li.delete,
      }))
      .filter((li) => li.homeownerContractLineItemId && typeof li.amountInCents === "number"),
    drawLineItems: drawLineItems.map((dli) => ({
      id: dli.id,
      drawId: dli.drawId,
      delete: dli.delete,
    })),
  };
}

export const emptyInputInvoiceEditor = () => ({ ...empty<FormInput>(), lineItems: [], drawLineItems: [] });
