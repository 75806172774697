import { Css, LoadingSkeleton } from "@homebound/beam";
import { useMemo } from "react";
import {
  ItivOrderField,
  Maybe,
  Order,
  User,
  usePlanPackageOverviewQuery,
  usePlanSummaryAndOptionsQuery,
} from "src/generated/graphql-types";
import { ElevationsList } from "src/routes/libraries/plan-package/details/plan-overview/components/ElevationsList";
import { OptionsList } from "src/routes/libraries/plan-package/details/plan-overview/components/OptionsList";
import { PlanPackageRecentActivity } from "src/routes/libraries/plan-package/details/plan-overview/components/PlanPackageRecentActivity";
import { PlanPackageSummary } from "src/routes/libraries/plan-package/details/plan-overview/components/PlanPackageSummary";
import { OptionsDataStore } from "src/routes/libraries/plan-package/details/plan-overview/utils";
import { queryResult } from "src/utils";
import { create } from "zustand";
import { PlanPackageComments } from "./components/PlanPackageComments";

export type PlanDataProps = {
  planPackageId: string;
  versionId: string;
  currentUser?: Maybe<Pick<User, "id">>;
};

export function OverviewTab({ planPackageId, versionId, currentUser }: PlanDataProps) {
  const query = usePlanPackageOverviewQuery({
    variables: {
      filter: { readyPlan: [planPackageId] },
      versionId,
      order: [{ field: ItivOrderField.Options, direction: Order.Asc }],
    },
  });

  const planQuery = usePlanSummaryAndOptionsQuery({ variables: { id: planPackageId, versionId } });

  // create a store for requesting number of items within a specific option
  const useOptions = useMemo(() => {
    return create<OptionsDataStore>((_, get) => ({
      groupTotals: query.data?.itemCount.groupTotals || [],
      getNumberOfItemsByOption: (optionId: string) => {
        // Find the groups that include this option and sum the number of items in each group
        return get()
          .groupTotals.filter((group) => group.groupId.includes(optionId))
          .reduce((acc, group) => acc + group.tlisInGroup, 0);
      },
    }));
  }, [query.data?.itemCount.groupTotals]);

  const activeOptions = planQuery.data?.planPackage.options.filter((o) => o.active) ?? [];
  const [elevations, options] = activeOptions.partition((o) => o.type.isElevation);

  return (
    <div css={Css.maxwPx(1156).mxa.$}>
      {queryResult(planQuery, (data) => (
        <PlanPackageSummary planPackage={data.planPackage} elevations={elevations} />
      ))}
      <div css={Css.df.aifs.gap4.mt4.$}>
        {queryResult(query, {
          data: () => (
            <div css={Css.fg1.df.fdc.gap4.$}>
              <div>
                <ElevationsList elevations={elevations} useOptions={useOptions} />
              </div>
              <div>
                <OptionsList options={options} useOptions={useOptions} />
              </div>
            </div>
          ),
          loading: () => <LoadingSkeleton rows={5} size="lg" />,
        })}
        <div css={Css.wPx(370).fs0.fg0.df.fdc.gap4.$}>
          <PlanPackageComments planPackageId={planPackageId} />
          <PlanPackageRecentActivity planPackageId={planPackageId} currentUser={currentUser} />
        </div>
      </div>
    </div>
  );
}
