import { Chip, Css, useTestIds } from "@homebound/beam";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { createPlanPackageTakeoffUrl } from "src/RouteUrls";
import { Card } from "src/components/Card";
import {
  PlanPackageOverview_ReadyPlanOptionFragment,
  PlanPackageSummary_PlanPackageFragment,
} from "src/generated/graphql-types";
import { SectionTitle } from "src/routes/libraries/plan-package/details/plan-overview/components/SectionTitle";
import { pluralize } from "src/utils";
import { programDataBathRange, programDataRange } from "src/utils/programData";
import { PLAN_FALLBACK_IMG } from "../../../takeoffs/utils";

type PlanPackageSummaryProps = {
  planPackage: PlanPackageSummary_PlanPackageFragment;
  elevations: PlanPackageOverview_ReadyPlanOptionFragment[];
};

export function PlanPackageSummary({ planPackage, elevations }: PlanPackageSummaryProps) {
  const tid = useTestIds({}, "ppSummary");

  const coverPhotoAsset = planPackage.assets.find((rpa) => rpa.active && rpa.isCoverPhoto);

  const { minProgramData, maxProgramData, designPackageCount, productOfferingCount, projectCount, displayName } =
    planPackage;
  const displayElevations = [...elevations].sort((a, b) => a.order - b.order).slice(0, 3);
  const moreOptions = elevations.length - 3;

  return (
    <Card xss={Css.p0.df.mhPx(232).$}>
      {/* Using a background image rather than an inline image so that the <img>'s height won't unintentionally cause the container to be really tall */}
      <div
        {...tid.coverPhoto}
        css={
          Css.wPx(326)
            .bgGray200.df.aic.jcc.oh.add(
              "backgroundImage",
              `url(${coverPhotoAsset?.asset.previewUrl || PLAN_FALLBACK_IMG})`,
            )
            .add("backgroundSize", coverPhotoAsset?.asset.previewUrl ? "cover" : "303px")
            .add("backgroundPosition", "center")
            .add("backgroundRepeat", "no-repeat").$
        }
      />
      <div css={Css.df.fdc.gap2.jcsb.fg1.py5.px4.sm.$}>
        <div css={Css.dg.gtc("auto auto auto").jcfs.cgPx(72).$}>
          <div>
            <SectionTitle title={displayName} />
            <div {...tid.sellableSqft}>
              {programDataRange(minProgramData, maxProgramData, "sellableSqft") ?? 0} sqft
            </div>
            <div {...tid.bedsBaths}>
              {`${programDataRange(minProgramData, maxProgramData, "bedrooms") ?? 0} bed ${
                programDataBathRange(minProgramData, maxProgramData) ?? 0
              } bath`}
            </div>
            <div {...tid.stories}>{programDataRange(minProgramData, maxProgramData, "stories")} stories</div>
          </div>

          <div css={Css.df.fdc.$}>
            <SectionTitle title="Elevations" />
            <div css={Css.dg.gtc("repeat(2, max-content)").cg2.$} {...tid.elevationList}>
              {displayElevations.map(({ id, name, programData }, idx) => (
                <Fragment key={id}>
                  <span>
                    {name} {idx === 0 && <Chip compact text="Base" />}
                  </span>
                  <span>{programData.sellableSqft} sqft</span>
                </Fragment>
              ))}
            </div>
            {moreOptions > 0 && (
              <div {...tid.moreElevations}>
                <span css={Css.smMd.$}>+{moreOptions}</span> more
              </div>
            )}
          </div>

          <div>
            <SectionTitle title="Usage" />
            <div {...tid.designPackages} css={Css.if(designPackageCount === 0).gray600.smMd.$}>
              {designPackageCount} design {pluralize(designPackageCount, "package")}
            </div>
            <div {...tid.productOfferings} css={Css.if(productOfferingCount === 0).gray600.smMd.$}>
              {productOfferingCount} product {pluralize(productOfferingCount, "offering")}
            </div>
            <div {...tid.projectConfigs} css={Css.if(projectCount === 0).gray600.smMd.$}>
              {projectCount} {pluralize(projectCount, "project")}
            </div>
          </div>
        </div>
        <div>
          <Link
            to={createPlanPackageTakeoffUrl(planPackage.id, planPackage.aggregateLatest.id)}
            css={Css.dif.aic.gap1.baseMd.$}
            {...tid.takeoffs}
          >
            Manage Takeoffs
          </Link>
        </div>
      </div>
    </Card>
  );
}
