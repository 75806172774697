import { Chip, column, Css, emptyCell, GridColumn, GridDataRow, GridTable } from "@homebound/beam";
import { CollapseRowToggle } from "src/components/formFields/CollapseRowToggle";
import { DesignPackageCombinationOfferingPageFragment } from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { BidOutCell } from "./BidOutCell";

type DesignPackageTableProps = {
  designPackageCombinations: DesignPackageCombinationOfferingPageFragment[];
  developmentId: string;
};

export function DesignPackageTable({ designPackageCombinations, developmentId }: DesignPackageTableProps) {
  if (designPackageCombinations.length === 0) {
    return null;
  }

  const rows = createRows(designPackageCombinations);
  const columns = createColumns(developmentId);
  return (
    <div css={Css.br8.oh.$}>
      <GridTable<Row>
        rowStyles={{
          designPackage: { cellCss: Css.py3.$ },
          head: { cellCss: Css.xsMd.py1.$ },
        }}
        columns={columns}
        rows={rows}
      />
    </div>
  );
}

type Row =
  | { kind: "designPackage"; data: { combos: DesignPackageCombinationOfferingPageFragment[] } }
  | { kind: "head" }
  | {
      kind: "spec";
      data: DesignPackageCombinationOfferingPageFragment["readyPlanOptions"][0];
    }
  | {
      kind: "combo";
      data: DesignPackageCombinationOfferingPageFragment;
    };

const createColumns = (developmentId: string): GridColumn<Row>[] => [
  column<Row>({
    designPackage: ({ combos }) => {
      const readyPlanOptions = combos.flatMap((c) => c.readyPlanOptions).uniqueByKey("id");
      const groups = readyPlanOptions.groupByObject((rpo) => rpo.optionGroup);
      return {
        content: (
          <div css={Css.df.gap2.$}>
            <div css={Css.lgSb.$}>Design Package</div>
            {groups.map(([group, rpos]) => (
              <Chip key={group.id} text={`${rpos.length} ${pluralize(rpos.length, group.name)}`} />
            ))}
          </div>
        ),
        colspan: 3,
      };
    },
    head: "Option Name",
    spec: ({ name }) => ({
      content: name,
      css: Css.gray700.smBd.$,
    }),
    combo: ({ readyPlanOptions }) =>
      readyPlanOptions
        .filter((rpo) => !rpo.type.isSpecLevel)
        .map((rpo) => rpo.name)
        .join(" "),
    w: "500px",
  }),
  // TODO: there's probably a better way of aligning this table to the standard option type table
  column<Row>({
    head: emptyCell,
    designPackage: emptyCell,
    spec: emptyCell,
    combo: emptyCell,
  }),
  column<Row>({
    head: emptyCell,
    designPackage: emptyCell,
    spec: emptyCell,
    combo: emptyCell,
  }),
  column<Row>({
    head: "Cost Source",
    designPackage: (data, { row, api }) => (
      <div css={Css.mla.mr2.df.aic.$}>
        <CollapseRowToggle label="" rowId={row.id} api={api} />,
      </div>
    ),
    spec: ({ bidOutPercentage }) => <BidOutCell bidOutPercentage={bidOutPercentage} developmentId={developmentId} />,
    combo: ({ bidOutPercentage }) => <BidOutCell bidOutPercentage={bidOutPercentage} developmentId={developmentId} />,
  }),
];

function createRows(designPackageCombinations: DesignPackageCombinationOfferingPageFragment[]): GridDataRow<Row>[] {
  const hasSpecLevels = designPackageCombinations.some((d) => d.readyPlanOptions.some((rpo) => rpo.type.isSpecLevel));
  if (hasSpecLevels) {
    const specLevels = designPackageCombinations.groupByObject(
      (d) => d.readyPlanOptions.find((rpo) => rpo.type.isSpecLevel)!,
    );
    return [
      {
        kind: "designPackage",
        id: "designPackage",
        data: { combos: designPackageCombinations },
      },
      {
        kind: "head",
        id: "head",
        data: {},
      },
      ...specLevels.map(([spec, combos]) => ({
        kind: "spec" as const,
        id: `${spec.id}-${combos.map((c) => c.readyPlanOptions.map((rpo) => rpo.id).join(","))}`,
        data: spec,
        children: combos.map((c) => ({
          kind: "combo" as const,
          id: c.readyPlanOptions.map((rpo) => rpo.id).join(","),
          data: c,
        })),
      })),
    ];
  }
  return [
    {
      kind: "designPackage",
      id: "designPackage",
      data: { combos: designPackageCombinations },
    },
    {
      kind: "head",
      id: "head",
      data: {},
    },
    ...designPackageCombinations.map((c) => ({
      kind: "combo" as const,
      id: c.readyPlanOptions.map((rpo) => rpo.id).join(","),
      data: c,
    })),
  ];
}
