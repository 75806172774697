import {
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  ButtonMenu,
  Css,
  Icon,
  Palette,
  SelectField,
  useComputed,
} from "@homebound/beam";
import omit from "lodash/omit";
import xor from "lodash/xor";
import { useMemo } from "react";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";
import {
  MaterialCatalogMetadataDocument,
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  MaterialType,
  PotentialOperation2,
  useSaveMaterialBrandMutation,
} from "src/generated/graphql-types";
import { useReaction } from "src/hooks";
import { useMaterialTypes } from "src/hooks/enums/useMaterialTypes";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { ObjectState } from "src/utils/formState";
import { MaterialDimensionsForm } from "../MaterialDimensionsForm";
import { MaterialImageEditor } from "../MaterialImageEditor";
import { MaterialAssemblyForm } from "../material-assembly-form/MaterialAssemblyForm";
import {
  MaterialListingFormState,
  MaterialVariantFormValue,
} from "../material-super-drawer/MaterialSuperDrawerContext";

export type MaterialListingFormProps = {
  isNew: boolean;
  formState: MaterialListingFormState;
  canEditItem: PotentialOperation2 | undefined;
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

export function MaterialListingForm(props: MaterialListingFormProps) {
  const { isNew, formState, canEditItem, items, brands } = props;
  const [saveMaterialBrand] = useSaveMaterialBrandMutation();
  const types = useMaterialTypes();

  const type = useComputed(() => formState.type.value, [formState]);
  const brand = useComputed(() => formState.brandId.value, [formState]);
  const item = useComputed(() => items.find((i) => i.id === formState.itemId.value), [formState, items]);
  const variants = useComputed(() => formState.variants.rows, [formState]);

  const variantsErrors: Record<string, string> = useComputed(() => {
    const variantMAVs = formState.variants.rows.map((mv) => mv.mavIds.value);

    return variantMAVs.reduce((acc, mavIds, i) => {
      // Find another variant that has the same mavIds
      const index = variantMAVs.findIndex((mavs, j) => i !== j && xor(mavIds ?? [], mavs ?? []).isEmpty);
      if (index !== -1) {
        return { ...acc, [i]: `This is the same as “Variant ${index + 1}”` };
      }
      return acc;
    }, {});
  }, [formState]);

  const website = useComputed(() => {
    const url = formState.manufacturerUrl.value;
    if (!url) return undefined;
    return url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
  }, [formState]);

  // Locks name to the item's name for Placeholder materials (and it's ignored by the backend)
  useReaction(
    () => [formState.type.value, formState.itemId.value],
    ([type, itemId]) => {
      if (type === MaterialType.Placeholder) {
        const name = items.find((i) => i.id === itemId)?.name;
        formState.name.set(name);
      }
    },
    [formState],
  );

  // Clear any unsaved components for Product  materials
  useReaction(
    () => [formState.variants, formState.type.value] as const,
    ([variants, type]) => {
      if (!canHaveComponents(type)) {
        variants.rows.forEach((mv) => mv.components.revertChanges());
      }
    },
    [formState.variants, formState.type.value],
  );

  async function saveBrand(newBrand: string | undefined) {
    if (newBrand) {
      const { data } = await saveMaterialBrand({
        variables: { input: { name: newBrand } },
        refetchQueries: [MaterialCatalogMetadataDocument],
      });
      if (data) {
        formState.brandId.set(data.saveMaterialBrand.materialBrand.id);
      }
    }
  }

  return (
    <div css={Css.df.fdc.gap2.w100.bgWhite.br8.$}>
      <div css={Css.w75.df.fdc.gap2.$}>
        {isNew && (
          <div css={Css.w100.df.gap2.$}>
            <div css={Css.wPx(150).$}>
              <BoundSelectField field={formState.type} options={types} label="Type" />
            </div>
            <BoundTextField field={formState.name} label="Name" fullWidth />
          </div>
        )}
        <div css={Css.df.gap2.$}>
          <BoundSelectField
            label="Item"
            field={formState.itemId}
            options={items}
            disabled={!canEditItem?.allowed ? canEditItem?.disabledReasons.map((r) => r.message).join(" ") : undefined}
          />
          {/* Placeholders  can't have brand or website */}
          {type !== MaterialType.Placeholder && (
            <>
              <SelectMaybeNewField
                label="Brand"
                options={brands}
                value={brand}
                onSelect={(brandId) => formState.brandId.set(brandId)}
                getOptionLabel={(brand) => brand.name}
                getOptionValue={(brand) => brand.id}
                onAdd={saveBrand}
              />
              <BoundTextField
                endAdornment={
                  website && (
                    <a href={website} target="_blank" rel="noreferrer">
                      <Icon icon="linkExternal" color={Palette.Gray700} />
                    </a>
                  )
                }
                field={formState.manufacturerUrl}
                label="Website"
              />
            </>
          )}
        </div>
        <div css={Css.df.gap2.$}>
          <BoundTextAreaField label="Description" field={formState.description} />
        </div>
      </div>
      <div css={Css.df.fdc.gap4.mt3.$}>
        {variants.map((variant, i) => (
          <MaterialVariantForm
            key={variant.id.value ?? i}
            number={i + 1}
            menuOpts={{
              onCopy: () => formState.variants.add(omit(variant.value, "id", "code", "images")),
              onDelete: variant.value.id ? undefined : () => formState.variants.remove(variant.value),
            }}
            type={type}
            variant={variant}
            item={item}
            error={variantsErrors[i]}
          />
        ))}
      </div>
      <div css={Css.w100.$}>
        <Button label="Add Variant" variant="secondary" onClick={() => formState.variants.add({})} />
      </div>
    </div>
  );
}

type MaterialVariantFormProps = {
  number: number;
  type: MaterialType | null | undefined;
  variant: ObjectState<MaterialVariantFormValue>;
  item: MaterialCatalog_ItemFragment | undefined;
  error: string | undefined;
  menuOpts: {
    onCopy: ((variant: ObjectState<MaterialVariantFormValue>) => void) | undefined;
    onDelete: VoidFunction | undefined;
  };
};

function MaterialVariantForm({ number, type, menuOpts, variant, item, error }: MaterialVariantFormProps) {
  const isArchived = useComputed(() => variant.isArchived.value, [variant]);
  const menuItems = useMemo(() => {
    return [
      ...(menuOpts.onCopy
        ? [
            {
              label: "Make a copy",
              onClick: () => menuOpts.onCopy!(variant),
            },
          ]
        : []),
      ...(menuOpts.onDelete
        ? [
            {
              label: "Delete",
              onClick: menuOpts.onDelete,
            },
          ]
        : []),
    ];
  }, [menuOpts, variant]);

  return (
    <div css={Css.df.fdc.gap1.$}>
      <div css={Css.df.jcsb.pb1.bb.bcGray400.$}>
        <div css={Css.df.gap2.$}>
          <span css={Css.baseMd.$}>Variant {number}</span>
          <span css={Css.base.gray600.$}>{variant.code.value}</span>
        </div>
        <ButtonMenu trigger={{ icon: "verticalDots" }} items={menuItems} />
      </div>
      <div css={Css.df.gap2.mt2.$}>
        <MaterialImageEditor variant={variant} />
        <div css={Css.df.fg1.fdc.gap2.$}>
          {error && (
            <span css={Css.red600.$} data-testid="variantError">
              {error} please <b>modify</b> or{" "}
              <span css={Css.tdu.cursorPointer.$} onClick={menuOpts.onDelete}>
                delete
              </span>
            </span>
          )}
          <div css={Css.df.fg1.gap2.$}>
            {/* Placeholders  can't have model number */}
            {type !== MaterialType.Placeholder && (
              <>
                <div css={Css.wPx(160).$}>
                  <BoundTextField
                    field={variant.modelNumber}
                    label="SKU / Model"
                    disabled={variant.canEdit.value && disableBasedOnPotentialOperation(variant.canEdit.value)}
                  />
                </div>
                <div css={Css.wPx(160).$}>
                  <BoundTextField
                    label="Price"
                    field={variant.priceEstimate}
                    disabled="This is a price estimate based on market data and cost entries. Update it via the price link."
                  />
                </div>
              </>
            )}
            <div css={Css.wPx(120).$}>
              <SelectField
                value={isArchived ? "archive" : "active"}
                options={[
                  { id: "active", name: "Active" },
                  { id: "archive", name: "Archive" },
                ]}
                onSelect={(status) => variant.isArchived.set(status === "archive")}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                label="Status"
              />
            </div>
          </div>
          <div css={Css.w75.$}>
            <MaterialDimensionsForm
              type={type}
              variant={variant}
              dimensions={item?.materialAttributeDimensions ?? []}
            />
          </div>
          {canHaveComponents(type) && <MaterialAssemblyForm variant={variant} />}
        </div>
      </div>
    </div>
  );
}

function canHaveComponents(type: MaterialType | null | undefined): boolean {
  return type === MaterialType.Placeholder || type === MaterialType.Construction;
}
