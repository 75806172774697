import {
  actionColumn,
  Chip,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  getTableStyles,
  GridTable,
  selectColumn,
  SelectToggle,
  TextField,
  useComputed,
  useGridTableApi,
} from "@homebound/beam";
import { useEffect, useMemo } from "react";
import { useMap } from "react-use";
import { Percentage, Price } from "src/components";
import { StepActions } from "src/components/stepper";
import { ScheduleDraftMode_NewlyCompletedPlanTaskFragment } from "src/generated/graphql-types";
import { PublishDraftScheduleStep } from "src/routes/projects/dynamic-schedules/draft-mode/PublishDraftScheduleStepper";
import { useDraftScheduleStore } from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import {
  getPlanTaskLineItemRows,
  groupPlanTaskProtoBills,
  mapPlanTaskToSaveBillInput,
  PlanTaskBillRow,
  ungroupedTradeLineId,
} from "src/routes/projects/dynamic-schedules/utils";
import { isDefined } from "src/utils";

type PayTradesPublishStepProps = {
  newlyCompletedPlanTasks: ScheduleDraftMode_NewlyCompletedPlanTaskFragment[];
  updateStepStatus: (currentStepCompleted: boolean, currentStepValue: PublishDraftScheduleStep) => void;
};

export function PayTradesPublishStep(props: PayTradesPublishStepProps) {
  const { newlyCompletedPlanTasks, updateStepStatus } = props;

  return (
    <PayTradesPublishStepDataView
      newlyCompletedPlanTasks={newlyCompletedPlanTasks}
      updateStepStatus={updateStepStatus}
    />
  );
}

type TaskWithBills = {
  planTask: ScheduleDraftMode_NewlyCompletedPlanTaskFragment;
  commitmentDrawIds: string[];
  tradeLineIds: string[];
  lineItemsByOwner: ReturnType<typeof groupPlanTaskProtoBills>;
};

type TaskOwnerRow = {
  kind: "taskOwner";
  id: string;
  data: TaskWithBills;
};

type HeaderRow = {
  kind: "header";
};

type Row = PlanTaskBillRow | TaskOwnerRow | HeaderRow;

// common place to build an id for edge case trade line items that are neither draw nor trade line
function buildPlanTaskUngroupedTradeLineId(planTask: ScheduleDraftMode_NewlyCompletedPlanTaskFragment) {
  return planTask.name + planTask.id + planTask.tradePartner?.id + ungroupedTradeLineId;
}

function PayTradesPublishStepDataView({ newlyCompletedPlanTasks, updateStepStatus }: PayTradesPublishStepProps) {
  const setDraftTaskTradeC2PInput = useDraftScheduleStore((state) => state.setDraftTaskTradeC2PInput);

  const tableApi = useGridTableApi<Row>();

  // Track possible deferment reasons separately to keep re-renders to a minimum
  const [defermentReasonMap, { set, remove }] = useMap<{ [taskId: string]: string | undefined }>();

  const rows = useMemo(() => createTaskBillRows(newlyCompletedPlanTasks), [newlyCompletedPlanTasks]);

  const selectedDrawRowIds = useComputed(() => tableApi.getSelectedRowIds("draw"), [tableApi]);
  const selectedTradeLineRowIds = useComputed(() => tableApi.getSelectedRowIds("tradeLine"), [tableApi]);

  const columns = useMemo(() => getColumns(set, defermentReasonMap), [defermentReasonMap, set]);

  const tableStyles = getTableStyles({
    allWhite: true,
    bordered: false,
  });

  const canContinue = useMemo(() => {
    return defermentReasonMap.toEntries().every(([taskId, reason]) => !!reason);
  }, [defermentReasonMap]);

  useEffect(() => {
    setDraftTaskTradeC2PInput({
      bills: rows.flatMap(({ data }) => {
        const { tradeLineIds, commitmentDrawIds, planTask } = data;
        const defermentReason = defermentReasonMap[planTask.id];
        const taskLineItems = planTask.protoBills.flatMap((protoBill) => protoBill.lineItems);
        const taskSelectedDrawIds = selectedDrawRowIds.filter((drawRowId) => commitmentDrawIds.includes(drawRowId));
        const taskSelectedDrawLineItems = taskLineItems.filter(
          (li) => isDefined(li.commitmentDraw) && taskSelectedDrawIds.includes(li.commitmentDraw!.id),
        );
        const drawClisIds = new Set(taskSelectedDrawLineItems.map((li) => li.commitmentLineItem.id));

        const taskSelectedTradeLineIds = selectedTradeLineRowIds.filter((tradeLineRowId) =>
          tradeLineIds.includes(tradeLineRowId),
        );
        const taskSelectedTradeLineItems = taskLineItems.filter(
          (li) =>
            !isDefined(li.commitmentDraw) &&
            taskSelectedTradeLineIds.includes(
              li.commitmentLineItem.primaryBidContractLineItem?.prorations?.first?.tradeLineItem.id ??
                buildPlanTaskUngroupedTradeLineId(planTask),
            ),
        );

        const taskSelectedTradeCliIds = new Set(taskSelectedTradeLineItems.map((li) => li.commitmentLineItem.id));

        const taskDeselectedLineItems = taskLineItems.filter(
          (li) => !drawClisIds.has(li.commitmentLineItem.id) && !taskSelectedTradeCliIds.has(li.commitmentLineItem.id),
        );
        return mapPlanTaskToSaveBillInput(
          planTask.id,
          planTask.protoBills,
          taskDeselectedLineItems,
          undefined,
          defermentReason,
        );
      }),
    });
  }, [selectedDrawRowIds, selectedTradeLineRowIds, setDraftTaskTradeC2PInput, defermentReasonMap, rows]);

  useEffect(() => {
    updateStepStatus(canContinue, PublishDraftScheduleStep.PayTrades);
  }, [canContinue, updateStepStatus]);

  return (
    <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="payTradesPublishStep">
      <h1 css={Css.xl3Sb.$}>Confirm Trade Payments</h1>
      <p css={Css.base.my2.mb5.$}>
        For the tasks that have been marked as “complete”, confirm payment amounts to send to the following trades for
        completed work.
      </p>
      <GridTable
        rows={rows}
        columns={columns}
        onRowSelect={{
          taskOwner: ({ planTask }, isSelected) => {
            if (!isSelected) {
              set(planTask.id, "");
            } else {
              remove(planTask.id);
            }
          },
        }}
        style={{
          // Note: for `levels` prop to work correctly we must spread the result from getTableStyles first
          ...tableStyles,
          // Beam Update Opportunity: Low Effort
          // 1. Can levels be added to `getTableStyles`?
          // 2. Fix up levels or table styles to add an option to skip manipulating header rows. We have to skip index 0
          // here so the headers width isn't cut short, shortening the bb border and exposing the bg of the table
          // container on the rhs. The bg color being exposed also happens with our other rows but on the lhs instead so
          // the rootCss merge is needed here to cover that up but even then the highlight wont extend over the spacing
          rootCss: { ...tableStyles["rootCss"], ...Css.bgWhite.br8.$ },
          levels: (level) => ({ rowIndent: level > 0 ? 12 * level : undefined }),
        }}
        api={tableApi}
      />
      <StepActions>
        <>{!canContinue && <div css={Css.df.order(-1).wsnw.red500.$}>Missing deferment reason(s)</div>}</>
      </StepActions>
    </div>
  );
}

function createTaskBillRows(newlyCompletedPlanTasks: ScheduleDraftMode_NewlyCompletedPlanTaskFragment[]) {
  const tasksWithBills = newlyCompletedPlanTasks
    .filter((t) => t.protoBills.nonEmpty)
    .map((planTask) => {
      const lineItemsByOwner = groupPlanTaskProtoBills(
        planTask.protoBills,
        buildPlanTaskUngroupedTradeLineId(planTask),
      );

      const [commitmentDrawIds, tradeLineIds] = lineItemsByOwner.flatMap(({ draws, tradeLines }) => [
        draws.flatMap(([commitmentDraw]) => commitmentDraw.id),
        tradeLines.flatMap(([tradeLine]) => tradeLine.id),
      ]);

      return {
        planTask,
        commitmentDrawIds,
        tradeLineIds,
        lineItemsByOwner,
      };
    });

  return tasksWithBills.map((taskWithBill) => ({
    kind: "taskOwner" as const,
    id: taskWithBill.planTask.id,
    initSelected: true,
    data: taskWithBill,
    children: getPlanTaskLineItemRows(taskWithBill.lineItemsByOwner),
  }));
}

function getColumns(
  handleDefermentReasonChange: (taskId: string, reason?: string) => void,
  defermentReasonMap: { [taskId: string]: string | undefined },
) {
  return [
    actionColumn<Row>({
      header: emptyCell,
      taskOwner: (_, { row }) => <CollapseToggle row={row} />,
      lineItemOwner: (_, { row }) => <CollapseToggle row={row} />,
      draw: emptyCell,
      tradeLine: emptyCell,
      w: "32px",
    }),
    selectColumn<Row>({
      header: emptyCell,
      taskOwner: (_data, { row }) => ({
        content: () => <SelectToggle id={row.id} />,
      }),
      lineItemOwner: (_data, { row }) => ({
        content: () => <SelectToggle id={row.id} />,
      }),
      draw: (_data, { row }) => ({
        content: () => <SelectToggle id={row.id} />,
      }),
      tradeLine: (_data, { row }) => ({
        content: () => <SelectToggle id={row.id} />,
      }),
      w: "28px",
    }),
    column<Row>({
      header: emptyCell,
      taskOwner: ({ planTask: { name } }, { api, row }) => ({
        content: () => {
          const needsDeferralReason = !api.getSelectedRows().includes(row);
          const deferralValue = defermentReasonMap[row.id];
          return (
            <div css={Css.df.aic.jcsb.w100.$}>
              <div>{name}</div>
              {needsDeferralReason && (
                <TextField
                  autoFocus
                  errorMsg={
                    !deferralValue
                      ? "Provide reasoning for completing this task and not issuing payment for deselected items (Deselected items will be added to an outstanding bill for the trade)."
                      : undefined
                  }
                  onChange={(v) => handleDefermentReasonChange(row.id, v)}
                  label="Deferment Reason"
                  value={deferralValue}
                  labelStyle="inline"
                />
              )}
            </div>
          );
        },
        colspan: 4,
        css: Css.baseBd.$,
      }),
      lineItemOwner: (row) => ({
        content: () => {
          return (
            <div>
              <div css={Css.xsBd.lh(2).$} data-testid="tradePartner">
                {row.tradePartnerName}
              </div>
              <Chip text={`${row.ownerType}# ${row.accountingNumber}`} data-testid="accountingNumber" />
            </div>
          );
        },
        value: row.accountingNumber,
      }),
      draw: (row) => ({
        content: (
          <div css={Css.xsMd.$} data-testid="drawPercent">
            {row.drawDescription ?? "Draw"} (<Percentage basisPoints percent={row.drawBasisPoints} />)
          </div>
        ),
      }),
      tradeLine: (row) => ({
        content: () => (
          <div css={Css.xsMd.$} data-testid="tradeLineDescription">
            {row.displayName}
          </div>
        ),
      }),
      w: 4,
    }),
    column<Row>({
      header: emptyCell,
      taskOwner: emptyCell,
      lineItemOwner: emptyCell,
      draw: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      tradeLine: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      w: 1,
      align: "right",
    }),
  ];
}
