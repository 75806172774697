import { Button, Checkbox, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useState } from "react";
import { Link } from "react-router-dom";
import { createPlanPackageTakeoffUrl } from "src/RouteUrls";
import { FormattedDate } from "src/components";
import {
  DesignUpdatesAvailable_PlanUpdateFragment,
  DesignUpdatesAvailable_ReadyPlanLocationVersionFragment,
  useAcceptDesignUpdatesMutation,
} from "src/generated/graphql-types";
import { useNavigateToRpav } from "src/hooks/useNavigateToRpav";
import { renderListItem } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { pluralize, sanitizeHtml } from "src/utils";
import { useDesignPackageConfiguratorContext } from "../DesignPackageConfiguratorContext";

/**
 * We have this enum on the backend, used as the return value for RPAV line item change types.
 * It’s not stored in the database or shared with the frontend, so we created this enum to mirror the backend version.
 */
export enum AggregateMemberVersionChangeType {
  ADDED = "added",
  UPDATED = "updated",
  REMOVED = "removed",
}

export function DesignUpdatesAvailableButton() {
  const { designPackage, readOnly } = useDesignPackageConfiguratorContext();
  const { copyInProgress, availableUpdates, id: designPackageId } = designPackage;
  const { openModal } = useModal();

  if (copyInProgress || availableUpdates.isEmpty || readOnly) return null;

  return (
    <Button
      variant="caution"
      icon="arrowFromBottom"
      label="Updates available"
      tooltip={availableUpdates.map((u) => `${u.planVersion.readyPlan.name} - v${u.planVersion.version}`).join(", ")}
      onClick={() =>
        openModal({
          content: <DesignUpdatesAvailableModal updates={availableUpdates} designPackageId={designPackageId} />,
        })
      }
    />
  );
}

type DesignUpdatesAvailableModalProps = {
  designPackageId: string;
  updates: DesignUpdatesAvailable_PlanUpdateFragment[];
};

export function DesignUpdatesAvailableModal({ updates, designPackageId }: DesignUpdatesAvailableModalProps) {
  const [acceptDesignUpdates] = useAcceptDesignUpdatesMutation();
  const { closeModal } = useModal();
  const navigateToRpav = useNavigateToRpav();

  const groupedUpdatesByPlan = updates.groupBy((u) => u.planVersion.readyPlan.id);
  const planPackageIds = Object.keys(groupedUpdatesByPlan);
  const [selectedPlanIds, setSelectedPlanIds] = useState<string[]>(planPackageIds);

  const onAcceptUpdate = async () => {
    const { data } = await acceptDesignUpdates({
      variables: {
        input: {
          designPackageId,
          designPackagePlanUpdateIds: selectedPlanIds.flatMap((id) => groupedUpdatesByPlan[id].map((u) => u.id)),
        },
      },
    });
    navigateToRpav(data?.acceptDesignPackageUpdates.designPackage.aggregateDraft?.id);
    closeModal();
  };

  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {Object.entries(groupedUpdatesByPlan).map(([rpId, updates]) => {
            return (
              <div key={rpId}>
                <div data-testid="planInfo" css={Css.sm.df.gap1.$}>
                  {/* Only show the checkbox when there are multiple plans that have updates */}
                  {Object.keys(groupedUpdatesByPlan).length > 1 && (
                    <Checkbox
                      label="plan-checkbox"
                      checkboxOnly
                      onChange={() => {
                        return selectedPlanIds.includes(rpId)
                          ? setSelectedPlanIds(selectedPlanIds.filter((id) => id !== rpId))
                          : setSelectedPlanIds(selectedPlanIds.concat(rpId));
                      }}
                      selected={selectedPlanIds.includes(rpId)}
                    />
                  )}
                  <span css={Css.smMd.$}>{updates.first!.planVersion.readyPlan.name} </span>
                  {/* Show the plan version info in-line with the checkbox when only 1 update exists */}
                  {updates.length === 1 && <PlanVersionInfo update={updates.first!} />}
                </div>
                {/* apply padding when checkboxes are visible */}
                <PlanUpdates updates={updates} addPadding={planPackageIds.length > 1} />
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button disabled={selectedPlanIds.isEmpty} label="Accept Update" onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}

type PlanUpdatesProps = {
  updates: DesignUpdatesAvailable_PlanUpdateFragment[];
  addPadding: boolean;
};

function PlanUpdates({ updates, addPadding }: PlanUpdatesProps) {
  const showPlanVersionInfo = updates.length > 1;
  const itemSlotLabel = "item-slot";
  const locationLabel = "location";
  return (
    <>
      {updates.map((update) => {
        const { newItemSlotCount, removedItemSlotCount, updatedItemSlotCount } = getTlivChangeCounts(update);
        const { newRplvCount, removedRplvCount, updatedRplvCount } = getRplvChangeCounts(
          update.planVersion.rplVersions,
        );
        return (
          <div key={update.id}>
            {/* Show the plan version info with each update when multiple versioned updates exist */}
            {showPlanVersionInfo && (
              <div css={Css.if(addPadding).pl3.mt1.$}>
                <PlanVersionInfo update={update} />
              </div>
            )}
            {update.planVersion.versionNotes && (
              <div
                data-testid="versionNotes"
                css={Css.gray700.sm.if(addPadding).pl3.$}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(update.planVersion.versionNotes || "") }}
              />
            )}
            <ul css={Css.m0.mt1.gray700.if(addPadding).pl6.else.pl3.$}>
              {/* Item Slots */}
              {renderListItem(newItemSlotCount, "Added", itemSlotLabel)}
              {renderListItem(removedItemSlotCount, "Removed", itemSlotLabel)}
              {renderListItem(updatedItemSlotCount, "Modified", itemSlotLabel)}
              {/* Locations */}
              {renderListItem(newRplvCount, "Added", locationLabel)}
              {renderListItem(removedRplvCount, "Removed", locationLabel)}
              {renderListItem(updatedRplvCount, "Modified", locationLabel)}
            </ul>
          </div>
        );
      })}
    </>
  );
}

type PlanUpdateProps = {
  update: DesignUpdatesAvailable_PlanUpdateFragment;
};

function PlanVersionInfo({ update }: PlanUpdateProps) {
  return (
    <div css={Css.gray700.df.gap1.$}>
      <Link to={createPlanPackageTakeoffUrl(update.planVersion.readyPlan.id, update.planVersion.id)}>
        v{update.planVersion.version}
      </Link>
      <span> - </span>
      <FormattedDate date={update.createdAt} dateFormatStyle="long" />
    </div>
  );
}

function getTlivChangeCounts(update: DesignUpdatesAvailable_PlanUpdateFragment) {
  const placeholders = update.planVersion.tliVersions.filter((tliv) => tliv.identity.isPlaceholderMaterial);
  const newItemSlotCount = placeholders.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedItemSlotCount = placeholders.count(
    (tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED,
  );
  const updatedItemSlotCount = placeholders.count(
    (tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED,
  );

  return { newItemSlotCount, removedItemSlotCount, updatedItemSlotCount };
}

function getRplvChangeCounts(rplVersions: DesignUpdatesAvailable_ReadyPlanLocationVersionFragment[]) {
  const newRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedRplvCount = rplVersions.count((rplv) => rplv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newRplvCount, removedRplvCount, updatedRplvCount };
}
