import { Button, ButtonMenu, Css, Icon, useComputed, useModal } from "@homebound/beam";
import { useMemo } from "react";
import { baseDownloadUrl } from "src/context";
import { TakeoffLineItemFilter } from "src/generated/graphql-types";
import {
  useTakeoffsManagerContext,
  useTakeoffsStore,
} from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { BulkTaskAllocationModal } from "src/routes/libraries/plan-package/takeoffs/components/BulkTaskAllocationModal/BulkTaskAllocationModal";
import { DeleteItemsConfirmationModal } from "src/routes/libraries/plan-package/takeoffs/components/DeleteItemsConfirmationModal";
import { count, getDisabledReasons } from "src/utils";
import { AddEditLaborItemModal, AddEditMaterialItemModal, AddEditPlaceholderItemModal } from "./AddItems";
import { AddItemsFromExistingPlanModal } from "./AddItemsFromExistingPlanModal";
import { DuplicateItemsConfirmationModal } from "./DuplicateItemsConfirmationModal";

export function TakeoffsItemsTableBulkActionsMenu() {
  const getSelectedTliIds = useTakeoffsStore((state) => state.getSelectedTliIds);
  const itemTableApi = useTakeoffsStore((state) => state.itemTableApi);

  const selectedTliIds = useComputed(() => getSelectedTliIds(), [getSelectedTliIds]);

  return (
    <div css={Css.df.aic.gap1.$}>
      {/* Only show if multiple items are selected per spec */}
      {selectedTliIds.length > 1 && (
        <Button
          data-testid="clearSelections"
          variant="tertiary"
          label={`${count(selectedTliIds, "item")}  selected`}
          endAdornment={<Icon icon="x" />}
          onClick={() => itemTableApi && itemTableApi.clearSelections()}
        />
      )}
      <ActionsMenuButton />
    </div>
  );
}

function ActionsMenuButton() {
  const store = useTakeoffsManagerContext();
  const { planPackage, getSelectedTliIds, copyFromPlan, saveTli, filter } = store.getState();
  const { id: planPackageId, canEditLineItems } = planPackage;
  const selectedTliIds = getSelectedTliIds();
  const { openModal } = useModal();

  const items = useMemo(() => {
    const filterString = `&filter=${encodeURIComponent(
      JSON.stringify({
        ...(selectedTliIds.nonEmpty && { id: selectedTliIds }),
        ...filter,
      } as TakeoffLineItemFilter),
    )}&versionId=${encodeURIComponent(planPackage.version.id)}`;

    return [
      {
        label: "Add Material",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditMaterialItemModal takeoffsManagerStore={store} planPackageId={planPackageId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Add Placeholder",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditPlaceholderItemModal takeoffsManagerStore={store} planPackageId={planPackageId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Add Labor",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditLaborItemModal takeoffsManagerStore={store} planPackageId={planPackageId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Import items",
        icon: "upload",
        onClick: () => {
          openModal({
            content: (
              <AddItemsFromExistingPlanModal
                planPackageId={planPackageId}
                copyFromPlan={copyFromPlan}
                saveTli={saveTli}
              />
            ),
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Delete",
        icon: "trash",
        onClick: () =>
          openModal({ content: <DeleteItemsConfirmationModal store={store} itemIdsToDelete={selectedTliIds} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedTliIds.isEmpty
              ? "Select items to delete"
              : false,
      },
      {
        label: "Download to .csv",
        icon: "download",
        // TODO: Update backend CSV to ingest rpId instead of itId (Please ping this line if you see it in a PR to make sure it doesn't slip through)
        onClick: `${baseDownloadUrl()}/csv?type=takeoff${filterString}`,
      },
      {
        label: "Duplicate",
        icon: "duplicate",
        onClick: () => openModal({ content: <DuplicateItemsConfirmationModal store={store} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedTliIds.isEmpty
              ? "Select items to duplicate"
              : false,
      },
      {
        label: "Allocate Task",
        icon: "task",
        onClick: () => openModal({ content: <BulkTaskAllocationModal store={store} tliIdsToEdit={selectedTliIds} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedTliIds.isEmpty
              ? "No items selected"
              : false,
      },
    ];
  }, [planPackageId, selectedTliIds, canEditLineItems, openModal, store, copyFromPlan, saveTli, filter]);

  return <ButtonMenu trigger={{ icon: "verticalDots" }} items={items} />;
}
