import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import {
  ProjectFinishScheduleVisualMode_ProjectItemFragment,
  ProjectFinishScheduleVisualMode_ReadyPlanOptionFragment,
  ProjectFinishScheduleVisualModePdfQuery,
  useProjectFinishScheduleVisualModePdfQuery,
} from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail, queryResult } from "src/utils";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import { parseFilterArray } from "../FinishSchedulePdf/FinishSchedulePdf";

export function ProjectFinishScheduleVisualModePdf() {
  const [{ projectId, costCodeIds, locationInPath }] = useQueryParams({
    projectId: StringParam,
    costCodeIds: ArrayParam,
    locationInPath: ArrayParam,
  });

  const query = useProjectFinishScheduleVisualModePdfQuery({
    variables: {
      projectId: projectId ?? fail("Project Id is required"),
      filter: {
        isProduct: true,
        costCode: parseFilterArray(costCodeIds),
        locationInPath: parseFilterArray(locationInPath),
      },
    },
  });

  return queryResult(query, (data) => <VisualModePdfView data={data} />);
}

function VisualModePdfView(props: { data: ProjectFinishScheduleVisualModePdfQuery }) {
  const { data } = props;
  const projectOptions = data.project.readyPlanConfig?.options.map((option) => option.readyPlanOption) ?? [];
  const designOptions = projectOptions
    .filter((rpo) => rpo.optionGroup.forDesignPackages)
    .sortBy((rpo) => rpo.optionGroup.order);
  return (
    <>
      <Global styles={{ "@page": { size: "landscape" } }} />
      <VisualModePdfContent
        projectItems={data.project.stage.projectItems}
        rpos={designOptions}
        projectName={data.project.name}
      />
    </>
  );
}

type VisualModePdfContentProps = {
  projectItems: ProjectFinishScheduleVisualMode_ProjectItemFragment[];
  rpos: ProjectFinishScheduleVisualMode_ReadyPlanOptionFragment[];
  projectName: string;
};

function VisualModePdfContent(props: VisualModePdfContentProps) {
  const { projectName, projectItems, rpos } = props;
  const locations = projectItems.groupByObject((pi) => pi.location.room ?? pi.location);
  const [primaryRpo] = rpos;

  // After troubleshooting, we found that the background color wasn't filling the entire page.
  // The standard size of a PDF page is 8.5x11 inches, which translates to 816px x 1056px.
  // To ensure the background color covers the entire page, we set the margins to 0 and adjusted the height and width to 1056px and 816px, respectively.

  const pageHeight = 816;
  const pageWidth = 1056;

  return (
    <div css={Css.wPx(pageWidth).$}>
      <div /* Cover Page */ css={Css.hPx(pageHeight).df.fdc.p4.add("backgroundColor", "rgba(235, 234, 228, 1)").$}>
        <div css={Css.df.jcsb.$}>
          <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(40).$} />
        </div>
        <div css={Css.hPx(600).df.fdc.jcc.gap2.$}>
          <div css={Css.xl4Md.fontFamily("PT Serif").$}>{projectName}</div>
          <div css={Css.df.gap1.$}>
            <img css={Css.hPx(40).$} src="/images/spec-level-pdf-icon.svg" alt="" />
            <div css={Css.p1.fw(400).color("rgba(198, 151, 117, 1)").fs("26px").$}>
              {/* Option Names */}
              {rpos.map((rpo) => rpo.name).join(" ") ?? ""}
            </div>
          </div>
        </div>
      </div>
      {/* Loop over each location to create a page, i.e. Kitchen, Whole House, etc. */}
      {locations.map(([location, projectItems]) => {
        return (
          <div key={location.id}>
            {/* Loop through each group of project items within a location, setting groups of 8 to limit the number displayed per page. */}
            {projectItems.batched(8).map((pis, i) => (
              <div key={i} style={{ pageBreakBefore: "always" }} css={Css.hPx(pageHeight).df.fdc.p4.$}>
                <div /* Location Header */ css={Css.df.jcsb.$}>
                  <div css={Css.xl4Md.fontFamily("PT Serif").$}>{location.name}</div>
                  <div css={Css.df.gap1.$}>
                    <img css={Css.hPx(26).$} src="/images/spec-level-pdf-icon.svg" alt="" />
                    <div css={Css.pPx(2).fw(400).color("rgba(198, 151, 117, 1)").fs("20px").$}>{primaryRpo.name}</div>
                  </div>
                </div>

                {/* Setting a height here will contain the product cards and prevent them from spilling onto the next page if a card's height exceeds a certain limit. */}
                <div /* List of Project Item Cards */ css={Css.df.fww.gap3.pt2.hPx(700).$}>
                  {pis.map((pi) => (
                    <div key={pi.id} css={Css.wPx(175).$}>
                      <div /* Product Image */ css={Css.hPx(175).w100.ba.bcGray200.bw2.p1.df.aic.jcc.$}>
                        <img
                          src={
                            pi.bidItem?.parentMaterialVariant?.featuredImage?.asset?.previewUrl ??
                            PRODUCT_FALLBACK_IMG_URL
                          }
                          alt={pi.item.name}
                          css={Css.maxw100.maxh100.$}
                        />
                      </div>
                      <div /* Product Details */ css={Css.df.fdc.mt1.$}>
                        <div css={Css.smBd.fontFamily("PT Serif").$}>{pi.item.name}</div>
                        <div css={Css.smMd.fs("12px").$}>{pi.bidItem?.parentMaterialVariant?.listing.brand?.name}</div>
                        <div css={Css.fs("12px").$}>{pi.bidItem?.parentMaterialVariant?.listing.name}</div>
                        {pi.bidItem?.parentMaterialVariant?.materialAttributeValues
                          .filter((value) => value.dimension.useInVisualModePdf)
                          .map((value) => (
                            <div key={value.id} css={Css.fs("12px").$}>
                              {value.dimension.name}: {value.textValue}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div /* Footer */ css={Css.df.jcfe.$}>
                  <div>
                    <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(30).$} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
