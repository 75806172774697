import { Css, FilterDefs, Filters, multiFilter, useComputed, useFilter, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { SearchBox, useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import {
  AddOptionsGlobalOptionTypeFragment,
  useAddOptionsMetadataQuery,
  useAddOptionsPageQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { OptionDetailsTable } from "src/routes/developments/plan-and-options/components/OptionDetailsTable";
import { mapReadyPlanToFormState } from "src/routes/developments/plan-and-options/components/utils";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { PlanPackageNextStepButton } from "./components/PlanPackageNextStepButton";
import { addRpoConfig, savePlanPackageOptionsProgramData } from "./utils";

type OptionDetailsStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};
type OptionTypeFilter = { option: string[] };

export function OptionDetailsStep({ id, versionId, setStepDirty }: OptionDetailsStepProps) {
  // const tid = useTestIds({}, "optionDetailsStep");
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const { data: metadata } = useAddOptionsMetadataQuery();
  const query = useAddOptionsPageQuery({ variables: { id, versionId } });
  const [save] = useSavePlanPackageDetailsMutation();
  const globalOptionTypes = useMemo(() => query.data?.globalOptionTypes ?? [], [query.data?.globalOptionTypes]);
  const disabled = disableBasedOnPotentialOperation(query.data?.readyPlan.canEdit);

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Filter by",
      options: globalOptionTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option };
  }, [globalOptionTypes]);
  const { filter, setFilter } = useFilter({ filterDefs });

  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    const options = globalOptionTypes.map(({ id }) => id);
    return globalOptionTypes.filter(
      (got) =>
        !got.isSpecLevel && (!filter?.option?.length ? options.includes(got.id) : filter.option.includes(got.id)),
    );
  }, [globalOptionTypes, filter.option]);

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      query: query,
      map: ({ readyPlan }) => mapReadyPlanToFormState(readyPlan),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const onSave = async () => {
    if (formState.dirty && query.data?.readyPlan) {
      const response = await savePlanPackageOptionsProgramData(formState, query.data?.readyPlan, save);
      formState.commitChanges();
      triggerNotice({
        message: `Your Ready Plan Options have been updated!`,
      });
      return response;
    }
  };

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Option Details"
          subtitle="Enter the program data for the Plan Options."
          tooltip="Decide if any of the options that you selected in the previous step will add or subtract square footage or bedrooms, bathrooms, garages, or carport."
        />
      }
      body={
        <div css={Css.pt3.df.fdc.fg1.$}>
          <TableActions onlyRight>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <SearchBox onSearch={setSearchFilter} clearable updateQueryString={false} />
            </div>
          </TableActions>

          <div css={Css.fg1.pb1.$}>
            <OptionDetailsTable
              formState={formState}
              searchFilter={searchFilter}
              optionsTypes={optionsTypes}
              metadata={metadata}
              filter={{}}
              showActions={false}
              disabled={disabled}
            />
          </div>
          <PlanPackageNextStepButton lastStep planId={id} versionId={versionId} onSave={onSave} />
        </div>
      }
    />
  );
}
