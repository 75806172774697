import { Global } from "@emotion/react";
import { Css, GridColumn, GridDataRow, GridTable, column } from "@homebound/beam";
import { format } from "date-fns";
import startCase from "lodash/startCase";
import { Fragment, useMemo } from "react";
import { emptyCellDash } from "src/components";
import {
  FinishSchedule_MaterialAttributeValueFragment,
  ProjectFinishSchedulePdfMetadataQuery,
  ProjectFinishScheduleQuery,
  ProjectFinishSchedule_ProjectItemFragment,
  useProjectFinishSchedulePdfMetadataQuery,
  useProjectFinishScheduleQuery,
} from "src/generated/graphql-types";
import {
  StackedAttributes,
  fixedDimensionsConfiguration,
  maybeAddUnitSuffix,
} from "src/routes/libraries/design-catalog/finish-schedules/FinishScheduleTable";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { getDynamicAttributes } from "src/routes/projects/finish-schedule/components/ProjectFinishScheduleTable";
import { fail, queryResult, sanitizeHtml } from "src/utils";
import { ArrayParam, DateParam, StringParam, useQueryParams } from "use-query-params";
import { parseFilterArray } from "../FinishSchedulePdf";

export function ProjectFinishSchedulePdf() {
  const [{ projectId, costCodeIds, locationInPath, exportBy, exportDate }] = useQueryParams({
    projectId: StringParam,
    costCodeIds: ArrayParam,
    locationInPath: ArrayParam,
    exportDate: DateParam,
    exportBy: StringParam,
  });

  const parsedCostCodeIds = parseFilterArray(costCodeIds);
  const parsedLocationInPath = parseFilterArray(locationInPath);

  const metadataQuery = useProjectFinishSchedulePdfMetadataQuery({
    variables: {
      projectId: projectId ?? fail("Project Id is required"),
      costCodeIds: parsedCostCodeIds ?? [],
      locationIds: parsedLocationInPath ?? [],
    },
    skip: parsedCostCodeIds?.isEmpty && parsedCostCodeIds?.isEmpty,
  });

  const query = useProjectFinishScheduleQuery({
    variables: {
      projectId: projectId ?? fail("Project Id is required"),
      filter: { costCode: parsedCostCodeIds, locationInPath: parsedLocationInPath, isProduct: true },
    },
  });

  return queryResult(metadataQuery, (metadata) =>
    queryResult(query, (data) => (
      <ProjectFinishSchedulePdfView data={data} metadata={metadata} exportBy={exportBy} exportDate={exportDate} />
    )),
  );
}

type ProjectFinishSchedulePdfViewProps = {
  data: ProjectFinishScheduleQuery;
  metadata: ProjectFinishSchedulePdfMetadataQuery;
  exportDate: Date | null | undefined;
  exportBy: string | null | undefined;
};

export function ProjectFinishSchedulePdfView(props: ProjectFinishSchedulePdfViewProps) {
  const { data, metadata, exportBy, exportDate } = props;
  const {
    costCodes,
    locations,
    project: { buildAddress, readyPlanConfig },
  } = metadata;
  const { project } = data;
  const {
    stage: { projectItems },
  } = project;

  const optionsByGOT = readyPlanConfig?.readyPlanOptions.groupByObject((rpo) => rpo.type) ?? [];

  const costCodeGroups = Object.values(projectItems.groupBy((pi) => pi.item.costCode.id)).map((costCodePIs) => {
    const locationGroups = costCodePIs.groupBy((pi) => pi.location.room?.id ?? "none");
    return {
      costCode: costCodePIs.first!.item.costCode.name,
      rooms: Object.values(locationGroups).map((locationsPIs) => ({
        location: locationsPIs.first?.location.room,
        basePIs: locationsPIs,
      })),
    };
  });

  return (
    <Fragment>
      <Global styles={{ "@page": { size: "letter landscape" } }} />
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xlSb.$}>{project.name}</div>
          <div css={Css.df.gap1.sm.aic.$}>
            <span>
              Cost Codes:{" "}
              <span css={Css.smBd.$}>{costCodes.nonEmpty ? costCodes.map((c) => c.name).join(", ") : "All"}</span>
            </span>
            <span>
              Rooms:{" "}
              <span css={Css.smBd.$}>{locations.nonEmpty ? locations.map((c) => c.name).join(", ") : "All"}</span>
            </span>
          </div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <span>Exported: {format(exportDate ?? new Date(), "MM/dd/yyyy")}</span>
          <span>Exported by: {exportBy}</span>
        </div>
      </div>
      <div css={Css.df.fdc.mt3.$}>
        <div css={Css.df.gap1.aic.$}>
          <span css={Css.smMd.wPx(130).$}>Lot Address</span>
          <span>{buildAddress.street1}</span>
        </div>
        <div css={Css.df.gap1.aic.$}>
          <span css={Css.smMd.wPx(130).$}>Floor Plan</span>
          <span>{readyPlanConfig?.readyPlan?.name}</span>
        </div>
      </div>
      <div css={Css.df.fdc.gap2.mt3.$}>
        <span css={Css.xlSb.$}>Options</span>
        {optionsByGOT
          .sortBy(([got, _]) => got.order)
          .map(([got, rpos]) => (
            <div key={got.id} css={Css.df.gap1.$}>
              <div css={Css.smMd.wPx(130).$}>{got.name}</div>
              <div css={Css.df.fdc.gap1.$}>
                {rpos.sortByKey("order").map((rpo) => (
                  <span key={rpo.id}>{rpo.name}</span>
                ))}
              </div>
            </div>
          ))}
      </div>
      <div css={Css.df.fdc.gap2.mt3.$}>
        {costCodeGroups.map(({ costCode, rooms }) => (
          <div key={costCode} css={Css.df.fdc.gap1.$}>
            <span css={Css.lgSb.$}>{costCode}</span>
            {rooms.map(({ location, basePIs }) => (
              <div key={location?.id}>
                <span css={Css.baseMd.$}>{location?.name}</span>
                <ProjectFinishScheduleTable projectItems={basePIs} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Fragment>
  );
}

function ProjectFinishScheduleTable({ projectItems }: { projectItems: ProjectFinishSchedule_ProjectItemFragment[] }) {
  const rows = useMemo(() => createRows(projectItems ?? []), [projectItems]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <GridTable
      style={{ rowHover: false, bordered: true }}
      rowStyles={{ data: { cellCss: Css.bcGray200.bt.br.$ } }}
      columns={columns}
      rows={rows}
      as="table"
    />
  );
}

type ProjectFinishScheduleTableRow = {
  id: string;
  kind: "data";
  data: ProjectFinishSchedule_ProjectItemFragment;
  dynamicAttributes: FinishSchedule_MaterialAttributeValueFragment[][];
};

function createRows(data: ProjectFinishSchedule_ProjectItemFragment[]): GridDataRow<ProjectFinishScheduleTableRow>[] {
  return data.map((pi) => ({ kind: "data", id: pi.id, data: pi, dynamicAttributes: getDynamicAttributes(pi) }));
}

function createColumns(): GridColumn<ProjectFinishScheduleTableRow>[] {
  return [
    column<ProjectFinishScheduleTableRow>({
      data: (pi) => (
        <div css={Css.w100.h100.mwPx(75).df.aic.jcc.$}>
          <img
            css={Css.hPx(75).wPx(75).$}
            src={pi.bidItem?.parentMaterialVariant?.featuredImage?.asset?.previewUrl || PRODUCT_FALLBACK_IMG_URL}
            alt="product"
          />
        </div>
      ),
      w: "100px",
      mw: "100px",
    }),
    column<ProjectFinishScheduleTableRow>({
      data: (pi) => (
        <div css={Css.df.fdc.gap2.$}>
          <div>
            <div data-testid="productType" css={Css.smMd.df.fww.$}>
              {startCase(pi.slot.name || pi.item.name)}
            </div>
            <div css={Css.gray600.$}>PRODUCT TYPE</div>
          </div>
          <div data-testid="productCode">{pi.bidItem?.parentMaterialVariant?.code}</div>
          {pi.bidItem?.parentMaterialVariant?.listing.manufacturerUrl && (
            <a
              target="_blank"
              href={pi.bidItem?.parentMaterialVariant?.listing.manufacturerUrl}
              css={Css.blue600.tdu.$}
              rel="noreferrer"
            >
              Manufacturer Link
            </a>
          )}
        </div>
      ),
      w: "250px",
    }),
    column<ProjectFinishScheduleTableRow>({
      data: (pi) => (
        <StackedAttributes
          topAttribute={{
            value: pi.bidItem?.parentMaterialVariant?.listing.name ?? emptyCellDash,
            label: "PRODUCT NAME",
          }}
          bottomAttribute={{
            value: pi.bidItem?.parentMaterialVariant?.listing.brand?.name ?? emptyCellDash,
            label: "BRAND",
          }}
        />
      ),
      w: "250px",
    }),
    column<ProjectFinishScheduleTableRow>({
      data: (pi) => (
        <div css={Css.h100.gap2.$}>
          <div>
            <div data-testid="skuModel" css={Css.smMd.$}>
              {pi.bidItem?.parentMaterialVariant?.modelNumber ?? emptyCellDash}
            </div>
            <div css={Css.gray600.$}>SKU/MODEL #</div>
          </div>
        </div>
      ),
      w: "150px",
    }),
    // 3 "fixed attribute" columns.
    ...Object.entries(fixedDimensionsConfiguration).map(([_, [topDimName, bottomDimName]]) =>
      column<ProjectFinishScheduleTableRow>({
        data: (pi) => {
          const topMav = pi.bidItem?.parentMaterialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === topDimName?.toLowerCase(),
          );
          const bottomMav = pi.bidItem?.parentMaterialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === bottomDimName?.toLowerCase(),
          );
          return (
            <StackedAttributes
              topAttribute={{
                value: topMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(topMav) ?? topDimName,
              }}
              bottomAttribute={{
                value: bottomMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(bottomMav) ?? bottomDimName,
              }}
            />
          );
        },
        w: "120px",
      }),
    ),
    // We try to render up to 4 attribute fields in addition to the 6 fixed attributes
    // 'row.dynamicAttributes' is a 2D array of FinishSchedule_MaterialAttributeValueFragment[][]
    // i.e. [[attrValue1, attrValue2], [attrValue3, attrValue4]]
    column<ProjectFinishScheduleTableRow>({
      data: (_, { row }) => {
        const columnOne = row.dynamicAttributes.first ?? [];
        const [topMav, bottomMav] = columnOne;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<ProjectFinishScheduleTableRow>({
      data: (_, { row }) => {
        const columnTwo = row.dynamicAttributes.last ?? [];
        const [topMav, bottomMav] = columnTwo;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<ProjectFinishScheduleTableRow>({
      data: (pi) => (
        <div /* Design Notes */ dangerouslySetInnerHTML={{ __html: sanitizeHtml(pi.specifications ?? "") }} />
      ),
      w: "210px",
    }),
  ];
}
