import {
  BoundCheckboxField,
  BoundMultiSelectField,
  Css,
  Tag,
  Tooltip,
  useComputed,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { addWeeks, startOfToday } from "date-fns";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  DraftPlanScheduleDocument,
  Maybe,
  SaveTradePartnerAvailabilityRequestInput,
  ScheduleDraftMode_PlanTaskFragment,
  SendTradePartnerAvailabilityRequestEmail_PlanTaskFragment,
  TradePartnerAvailabilityRequestStatus,
  useSaveTradePartnerAvailabilityRequestsMutation,
  useSendTradePartnerAvailabilityRequestEmailQuery,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { formatTradePartnerContacts } from "../draft-mode/TradeConfirmPublishStep";
import { getMarketsContacts } from "../utils";

type SendTradePartnerAvailabilityRequestEmailModalProps = {
  planTasks: SendTradePartnerAvailabilityRequestEmail_PlanTaskFragment[] | ScheduleDraftMode_PlanTaskFragment[];
};

export function SendBulkTradePartnerAvailabilityRequestEmailModal({ scheduleParentId }: { scheduleParentId: string }) {
  const today = new DateOnly(startOfToday());
  const twoWeeksFromToday = new DateOnly(addWeeks(today, 2));

  const query = useSendTradePartnerAvailabilityRequestEmailQuery({
    variables: {
      currentDate: today,
      scheduleParentId,
      twoWeeksFromToday: twoWeeksFromToday,
    },
  });

  return queryResult(query, (data) => {
    const {
      planTasks: { entities },
    } = data;
    // must have a trade partner in order to send a request
    return <SendTradePartnerAvailabilityRequestEmailModal planTasks={entities.filter((pt) => pt.tradePartner)} />;
  });
}

export function SendTradePartnerAvailabilityRequestEmailModal({
  planTasks,
}: SendTradePartnerAvailabilityRequestEmailModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      input: planTasks,
      map: (planTasks) => mapToForm(planTasks),
    },
  });

  const { triggerNotice } = useSnackbar();
  const { id: currentUserId } = useCurrentUser();
  const [saveTradePartnerAvailabilityRequests] = useSaveTradePartnerAvailabilityRequestsMutation();

  const shouldDisableTradePartnerButton = useComputed(() => {
    // the checkbox and contact list should be be selected in order to send the email
    return formState.tradePartnerAvailabilityRequests.value.every((tpar) => tpar.tradePartnerContactIds?.isEmpty);
  }, [formState.tradePartnerAvailabilityRequests.rows]);

  const onConfirmAction = useCallback(async () => {
    const result = await saveTradePartnerAvailabilityRequests({
      variables: {
        // we only want to send selected tasks a trade partner request
        input: formState.tradePartnerAvailabilityRequests.value
          .filter((tpar) => tpar.isTaskSelected)
          .map((tpar) => {
            return {
              taskId: tpar.taskId,
              internalUserId: currentUserId,
              tradePartnerContactIds: tpar.tradePartnerContactIds,
              tradePartnerId: tpar.tradePartnerId,
              status: TradePartnerAvailabilityRequestStatus.Waiting,
              id: tpar.maybeExistingTradePartnerAvailabilityRequestId,
            };
          }),
      },
      refetchQueries: [DraftPlanScheduleDocument],
    });
    const numberOfTradePartners = result.data?.saveTradePartnerAvailabilityRequests.tradePartnerAvailabilityRequests
      .map((tpar) => tpar.tradePartner)
      .uniqueByKey("id").length;

    triggerNotice({
      message: `An email was sent to ${numberOfTradePartners} trade ${pluralize(2, "partner")}, notifying them of their assigned task(s).`,
    });
  }, [saveTradePartnerAvailabilityRequests, currentUserId, formState.tradePartnerAvailabilityRequests, triggerNotice]);

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          confirmationMessage={
            <>
              <span>
                An email will be sent to the Trade Partner’s email below. The trade will be emailed and informed of the
                date that they should be available for their assigned task.
              </span>
              <table css={Css.mt3.w100.$}>
                <thead>
                  <tr>
                    <></>
                    <Label label="Task" />
                    <Label label="Trade Partner Company" />
                    <Label label="Trade Partner Contact" />
                  </tr>
                </thead>
                <tbody>
                  {planTasks.map((task) => {
                    const os = formState.tradePartnerAvailabilityRequests.rows.find(
                      (row) => row.taskId.value === task.id,
                    );
                    const otherTradePartnerContacts = getMarketsContacts(task.tradePartner);
                    // Combine known scheduling contacts with trade partner market contacts
                    const tradePartnerContacts = formatTradePartnerContacts(
                      task.knownSchedulingContacts,
                      otherTradePartnerContacts,
                    );

                    if (!os) return;
                    if (!task.tradePartner) return;
                    return (
                      <tr key={task.id}>
                        {/* Task Name Column */}
                        <td css={Css.pt2.df.gap1.$}>
                          <span css={Css.df.gap1.aic.$}>
                            <BoundCheckboxField
                              field={os.isTaskSelected}
                              checkboxOnly
                              onChange={(val) => {
                                os.isTaskSelected.set(val);
                                // if the task is selected, automatically set the contact to the scheduling contact, if unselected, the remove all selections
                                if (val) {
                                  os.tradePartnerContactIds.set(
                                    task.knownSchedulingContacts.nonEmpty
                                      ? task.knownSchedulingContacts.map((c) => c.id)
                                      : otherTradePartnerContacts.map((c) => c?.id ?? ""),
                                  );
                                } else {
                                  os.tradePartnerContactIds.set([]);
                                }
                              }}
                            />
                            {task.name}
                          </span>
                          {task.tradePartnerAvailabilityRequests?.last?.emails.nonEmpty && (
                            <Tooltip
                              title={`Previously sent ${task.tradePartnerAvailabilityRequests?.last?.emails.length} times`}
                            >
                              <Tag text={task.tradePartnerAvailabilityRequests?.last?.emails.length} />
                            </Tooltip>
                          )}
                        </td>
                        {/* Trade Partner Company Column */}
                        <td css={Css.wsbs.w("30%").vat.pt2.$}>{task.tradePartner.name}</td>
                        {/* Trade Partner Contact Column */}
                        <td css={Css.w("35%").pb2.$}>
                          <BoundMultiSelectField
                            field={os.tradePartnerContactIds}
                            compact
                            label="Trade Partner Contact"
                            labelStyle="hidden"
                            getOptionLabel={({ name, email }) => (email ? `${name}, <${email}>` : name)}
                            getOptionValue={({ id }) => id}
                            // disable the "suggested + other "fake rows" so users cannot select them
                            disabledOptions={tradePartnerContacts
                              .filter((contact) => !contact.email)
                              .map((contact) => contact.id)}
                            options={tradePartnerContacts}
                            onSelect={(selected) => {
                              os.tradePartnerContactIds.set(selected);
                              if (selected.nonEmpty) {
                                os.isTaskSelected.set(true);
                              } else {
                                os.isTaskSelected.set(false);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
          onConfirmAction={onConfirmAction}
          title="Send Schedule Task to Trade Partner"
          label="Send Email To Trades"
          disabled={shouldDisableTradePartnerButton}
        />
      )}
    </Observer>
  );
}

type TradePartnerAvailabilityFormState = {
  tradePartnerAvailabilityRequests: (Pick<
    SaveTradePartnerAvailabilityRequestInput,
    "tradePartnerContactIds" | "taskId" | "tradePartnerId"
  > & {
    isTaskSelected: Maybe<boolean>;
    maybeExistingTradePartnerAvailabilityRequestId: Maybe<string>;
  })[];
};

const formConfig: ObjectConfig<TradePartnerAvailabilityFormState> = {
  tradePartnerAvailabilityRequests: {
    type: "list",
    config: {
      taskId: { type: "value" },
      tradePartnerId: { type: "value" },
      tradePartnerContactIds: { type: "value" },
      isTaskSelected: { type: "value" },
      maybeExistingTradePartnerAvailabilityRequestId: { type: "value" },
    },
  },
};

function Label({ label }: { label: string }) {
  return <th css={Css.gray900.smMd.pb1.tal.$}>{label}</th>;
}

function mapToForm(
  tasks: SendTradePartnerAvailabilityRequestEmail_PlanTaskFragment[] | ScheduleDraftMode_PlanTaskFragment[],
) {
  return {
    tradePartnerAvailabilityRequests: tasks.flatMap((task) => ({
      taskId: task.id,
      tradePartnerId: task.tradePartner?.id,
      isTaskSelected: false,
      tradePartnerContactIds: [],
      maybeExistingTradePartnerAvailabilityRequestId:
        task.tradePartnerAvailabilityRequests?.sortByKey("createdAt").last?.id,
    })),
  };
}
