import {
  BoundCheckboxField,
  BoundMultiSelectField,
  BoundTextField,
  Button,
  Css,
  IconButton,
  ModalProps,
  useComputed,
} from "@homebound/beam";
import { useState } from "react";
import { HasIdAndName } from "src/utils";
import { ObjectState } from "src/utils/formState";
import { AddConstraintItemModal, ConstraintModalConfig } from "../../task-catalog/components/AddConstraintItemModal";
import { SaveGlobalPlanMilestoneFormValue } from "../MilestoneCatalogForm";

type AddAdditionalMilestoneCardProps = {
  formState: ObjectState<SaveGlobalPlanMilestoneFormValue>;
  globalPlanConstraintItems: HasIdAndName[];
  openModal: (modal: ModalProps) => void;
};

type AdditionalMilestoneCardItem = {
  name: string | undefined;
  constraints: string[];
  isDefault: boolean;
};

export function AddAdditionalMilestoneCard({
  formState,
  globalPlanConstraintItems,
  openModal,
}: AddAdditionalMilestoneCardProps) {
  const additionalMilestoneCardItems = formState.additionalMilestoneCards;
  const [newAdditionalMilestoneCardItem, setAdditionalMilestoneCardItem] = useState<AdditionalMilestoneCardItem>({
    constraints: [],
    name: undefined,
    isDefault: false,
  });

  const additionalMilestoneCards = useComputed(() => {
    return additionalMilestoneCardItems.rows.map((milestone, i) => {
      const { id } = milestone.value;
      return (
        <div css={Css.bgWhite.br8.p3.mb3.$} key={"mci" + (id || i)} data-testid="additionalMilestoneCard">
          <div css={Css.tar.$}>
            <IconButton
              data-testid="removeAdditionalMilestoneCard"
              icon="x"
              onClick={() => additionalMilestoneCardItems.remove(i)}
            />
          </div>
          <div css={Css.df.fdc.gap2.$}>
            <BoundTextField label="Milestone Name*" field={milestone.name} fullWidth />
            <BoundMultiSelectField
              field={milestone.constraints}
              label="Constraints"
              options={globalPlanConstraintItems ?? []}
            />
            <Button
              label="Add New"
              variant="text"
              onClick={() =>
                openModal({
                  content: (
                    <AddConstraintItemModal
                      constraintType={ConstraintModalConfig.ConstraintItem}
                      fieldState={milestone.constraints}
                    />
                  ),
                })
              }
            />
            <div css={Css.mt2.$}>
              <BoundCheckboxField
                label="Set as default milestone"
                field={milestone.isDefault}
                onChange={(val) => {
                  milestone.isDefault.set(val);
                  formState.isAllDefaultSelected.set(null);
                }}
              />
            </div>
          </div>
        </div>
      );
    });
  }, [additionalMilestoneCardItems, globalPlanConstraintItems]);

  return (
    <div>
      {additionalMilestoneCards}
      <div css={Css.br4.bsDashed.bgBlue50.blue500.bw("2px").py1.df.jcc.ma.maxwPx(600).mt4.$}>
        <Button
          label="Add another milestone"
          onClick={() => {
            additionalMilestoneCardItems.add({
              constraints: newAdditionalMilestoneCardItem.constraints,
              name: newAdditionalMilestoneCardItem.name,
              isDefault: newAdditionalMilestoneCardItem.isDefault,
              groups: formState.groups.value,
            });
            setAdditionalMilestoneCardItem((prevState) => ({
              ...prevState,
              name: undefined,
              isDefault: false,
              constraints: [],
            }));
          }}
          variant="text"
          icon="plus"
        />
      </div>
    </div>
  );
}
