import { useSnackbar } from "@homebound/beam";
import omit from "lodash/omit";
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";
import { formatToPrice } from "src/components/Price";
import {
  InputMaybe,
  MaterialCatalogDocument,
  MaterialSuperDrawer_MaterialListingFragment,
  MaterialSuperDrawer_MaterialVariantFragment,
  MaterialType,
  MaterialVariant,
  PotentialOperationDetailsFragment,
  SaveMaterialVariantAssemblyComponentInput,
  SaveMaterialVariantImageInput,
  Scalars,
  useMaterialSuperDrawerQuery,
  useSaveMaterialListingMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { isDefined, noop } from "src/utils";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";

export type MaterialSuperDrawerContextProps = {
  listing: MaterialSuperDrawer_MaterialListingFragment | undefined;
  variants: MaterialSuperDrawer_MaterialVariantFragment[];
  selectedVariant: MaterialSuperDrawer_MaterialVariantFragment | undefined;
  setSelectedVariant: (id: string | undefined) => void;
  formState: MaterialListingFormState;
  loading: boolean;
  readOnly: boolean;
  toggleReadOnly: VoidFunction;
  save: () => Promise<void>;
  cancel: VoidFunction;
};

const MaterialSuperDrawerContext = createContext<MaterialSuperDrawerContextProps>({
  listing: undefined,
  variants: [],
  selectedVariant: undefined,
  setSelectedVariant: () => {},
  loading: false,
  formState: undefined!,
  readOnly: false,
  toggleReadOnly: () => {},
  save: () => Promise.resolve(),
  cancel: () => {},
});

type MaterialSuperDrawerContextProviderProps = PropsWithChildren<{
  variantId: string;
  readOnly: boolean;
}>;

export function MaterialSuperDrawerContextProvider(props: MaterialSuperDrawerContextProviderProps) {
  const { children, variantId } = props;
  const [selectedMVId, setSelectedMVId] = useState<string | undefined>(variantId);
  const { data, loading } = useMaterialSuperDrawerQuery({
    variables: { filter: { id: [variantId], includeArchived: true } },
  });

  const [saveMaterialListing, { loading: saving }] = useSaveMaterialListingMutation({
    errorPolicy: "all", // Overriding error handling to avoid our default error banner
    onError: noop,
  });
  const [readOnly, toggleReadOnly] = useToggle(props.readOnly);
  const { triggerNotice } = useSnackbar();

  const listing = data?.materialVariants.entities.first?.listing;
  const variants = listing?.materialVariants ?? [];
  const selectedVariant = variants.find((mv) => mv.id === selectedMVId);

  const formConfig = useMemo(() => materialListingFormConfig({ canEditItem: true, canEditType: true }), []);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: listing,
      map: (listing) => {
        return {
          id: listing?.id,
          name: listing?.name,
          description: listing?.description,
          type: listing?.type.code,
          itemId: listing?.item.id,
          brandId: listing?.brand?.id,
          manufacturerUrl: listing?.manufacturerUrl,
          variants: listing?.materialVariants.map((mv) => ({
            id: mv.id,
            code: mv.code,
            canEdit: mv.canEdit,
            codeOverride: mv.codeOverride,
            modelNumber: mv.modelNumber,
            isArchived: mv.isArchived,
            designFeedback: mv.designFeedback,
            mavIds: mv.materialAttributeValues.map((mav) => mav.id),
            priceEstimate: getPriceEstimateText(mv, "None added"),
            images: mv.images.map(({ id, sortOrder, asset }) => ({
              id,
              asset: { id: asset?.id, fileName: asset?.fileName, contentType: asset?.contentType },
              attachmentUrl: asset?.attachmentUrl,
              downloadUrl: asset?.downloadUrl,
              sortOrder,
            })),
            components: mv.components.map(({ id, qualifier, component }) => ({
              id,
              qualifier,
              componentId: component.id,
              componentItemId: component.listing.item.id,
              componentValues: component.materialAttributeValues.map((mav) => mav.id),
            })),
          })),
        };
      },
    },
    loading,
    readOnly,
  });

  // Cancel edit or copy mode
  const cancel = useCallback(() => {
    formState.revertChanges();
    toggleReadOnly(true);
  }, [formState, toggleReadOnly]);

  const save = useCallback(async () => {
    const { data, errors } = await saveMaterialListing({
      variables: {
        input: {
          ...formState.changedValue,
          variants: formState.changedValue.variants?.map((mv) => ({
            ...omit(mv, ["code", "priceEstimate"]),
            images: formState.value.variants
              ?.find((v) => v.id === mv.id)!
              .images?.map((im) => omit(im, "downloadUrl", "attachmentUrl")), // We include all children images so that we don't accidentally delete any
            components: mv.components?.map(({ componentId, componentItemId, componentValues, ...otherOpts }) => ({
              ...otherOpts,
              ...(componentItemId ? { componentItemId, componentValues } : { componentId }), // Take out the component Id since it references the placeholder used as base for the new MV
            })),
          })),
        },
      },
      refetchQueries: [MaterialCatalogDocument],
    });

    if (errors?.nonEmpty) {
      const mvUniquenessError = errors?.some(
        (e) => e.message.includes("have the same attributes") || e.message.includes("material_variants_code_key"),
      );

      triggerNotice({
        message: mvUniquenessError
          ? "This material conflicts with an existing material. Please add or alter it's attributes to make it unique"
          : errors.map((e) => e.message).join(", "),
        icon: "error",
      });

      return;
    }

    const savedMaterial = data?.saveMaterialListing;

    if (savedMaterial) {
      triggerNotice({
        message: `Material saved successfully`,
        icon: "success",
      });

      toggleReadOnly();
    }
  }, [formState, saveMaterialListing, toggleReadOnly, triggerNotice]);

  return (
    <MaterialSuperDrawerContext.Provider
      value={{
        listing,
        variants,
        selectedVariant,
        setSelectedVariant: setSelectedMVId,
        formState,
        loading: loading || saving,
        cancel,
        save,
        readOnly,
        toggleReadOnly,
      }}
    >
      {children}
    </MaterialSuperDrawerContext.Provider>
  );
}

export function useMaterialSuperDrawerContext() {
  return useContext(MaterialSuperDrawerContext);
}

export type VariantImageAsset = SaveMaterialVariantImageInput & {
  downloadUrl?: string;
  attachmentUrl?: string;
};

export type MaterialVariantFormValue = {
  id?: InputMaybe<Scalars["ID"]>;
  code?: InputMaybe<Scalars["String"]>;
  canEdit?: InputMaybe<PotentialOperationDetailsFragment>;
  modelNumber?: InputMaybe<Scalars["String"]>;
  codeOverride?: InputMaybe<Scalars["String"]>;
  mavIds?: InputMaybe<Array<Scalars["ID"]>>;
  images?: InputMaybe<Array<VariantImageAsset>>;
  components?: InputMaybe<Array<SaveMaterialVariantAssemblyComponentInput>>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  designFeedback?: InputMaybe<Scalars["String"]>;
  priceEstimate?: InputMaybe<Scalars["String"]>;
};

export type MaterialListingFormValue = {
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  manufacturerUrl?: InputMaybe<Scalars["String"]>;
  brandId?: InputMaybe<Scalars["String"]>;
  itemId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MaterialType>;
  variants: InputMaybe<Array<MaterialVariantFormValue>>;
};

export type MaterialListingFormState = ObjectState<MaterialListingFormValue>;

export const materialListingFormConfig: (props: {
  canEditItem: boolean;
  canEditType: boolean;
}) => ObjectConfig<MaterialListingFormValue> = ({ canEditItem, canEditType }) => ({
  id: { type: "value" },
  type: { type: "value", rules: [required], readOnly: !canEditType },
  itemId: { type: "value", rules: [required], readOnly: !canEditItem },
  brandId: { type: "value" },
  name: { type: "value", rules: [required] },
  description: { type: "value" },
  manufacturerUrl: { type: "value" },
  variants: {
    type: "list",
    config: {
      id: { type: "value" },
      code: { type: "value" },
      canEdit: { type: "value" },
      codeOverride: { type: "value" },
      modelNumber: { type: "value" },
      designFeedback: { type: "value" },
      isArchived: { type: "value" },
      mavIds: { type: "value" },
      priceEstimate: { type: "value" },
      images: {
        type: "list",
        config: {
          id: { type: "value" },
          sortOrder: { type: "value" },
          attachmentUrl: { type: "value" },
          downloadUrl: { type: "value" },
          asset: {
            type: "object",
            config: {
              id: { type: "value" },
              s3Key: { type: "value" },
              fileName: { type: "value" },
              contentType: { type: "value" },
              sizeInBytes: { type: "value" },
              delete: { type: "value" },
            },
          },
        },
      },
      components: {
        type: "list",
        config: {
          id: { type: "value" },
          assemblyId: { type: "value" },
          componentId: { type: "value" },
          componentItemId: { type: "value" },
          componentValues: { type: "value" },
          qualifier: { type: "value" },
        },
      },
    },
  },
});

export function getPriceEstimateText(
  variant: Pick<MaterialVariant, "minCostInMills" | "maxCostInMills">,
  fallback: string,
) {
  const { minCostInMills, maxCostInMills } = variant;

  if (!isDefined(minCostInMills) && !isDefined(maxCostInMills)) return fallback;

  const formattedMin = formatToPrice({ valueInCents: minCostInMills ?? 0 / 100, maxDecimalPlaces: 0 });
  const formattedMax = formatToPrice({ valueInCents: maxCostInMills ?? 0 / 100, maxDecimalPlaces: 0 });
  if (minCostInMills === maxCostInMills) return formattedMin;

  return `${formattedMin} - ${formattedMax}`;
}
