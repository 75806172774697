import { Accordion, Css } from "@homebound/beam";
import { VersionDetailsCard_VersionFragment } from "src/generated/graphql-types";
import { count } from "src/utils";
import { AggregateMemberVersionChangeType } from "../design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";

export type LocationChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

export function LocationChanges(props: LocationChangesProps) {
  const { version } = props;
  const { rplVersions } = version;

  const groupedTlivs = rplVersions.groupBy((rplv) => rplv.changeType);
  const newRplvs = groupedTlivs[AggregateMemberVersionChangeType.ADDED] ?? [];
  const removedRplvs = groupedTlivs[AggregateMemberVersionChangeType.REMOVED] ?? [];
  const updatedRplvs = groupedTlivs[AggregateMemberVersionChangeType.UPDATED] ?? [];

  if (newRplvs.isEmpty && removedRplvs.isEmpty && updatedRplvs.isEmpty) return null;

  return (
    <Accordion
      compact
      xss={Css.py0.$}
      title="Adjusted Plan Locations"
      children={
        <>
          {newRplvs.nonEmpty && (
            <Accordion
              compact
              xss={Css.py0.$}
              title={`Added ${count(newRplvs, "location")}`}
              children={
                <ul css={Css.m0.$}>
                  {newRplvs.map((rplv) => (
                    <li key={rplv.id}>{rplv.location.name}</li>
                  ))}
                </ul>
              }
            />
          )}
          {updatedRplvs.nonEmpty && (
            <Accordion
              compact
              xss={Css.py0.$}
              title={`Modified ${count(updatedRplvs, "location")}`}
              children={updatedRplvs.map((rplv) => (
                <div key={rplv.id}>
                  <span css={Css.smBd.$}>
                    {/* We use ! since any RPLV that is modified should have a previous RPLV */}
                    {rplv.previous!.location.name}
                  </span>
                  <span> changed to </span>
                  <span css={Css.smBd.$}>{rplv.location.name}</span>
                </div>
              ))}
            />
          )}
          {removedRplvs.nonEmpty && (
            <Accordion
              compact
              xss={Css.py0.$}
              title={`Removed ${count(removedRplvs, "location")}`}
              children={
                <ul css={Css.m0.$}>
                  {removedRplvs.map((rplv) => (
                    <li key={rplv.id}>{rplv.location.name}</li>
                  ))}
                </ul>
              }
            />
          )}
        </>
      }
    />
  );
}
