import { BoundTextAreaField, Css } from "@homebound/beam";
import { PublishBidPackageStepProps } from "../PublishBidPackagePage";

export function AddInvitationTextStep({ formState }: PublishBidPackageStepProps) {
  return (
    <div css={Css.maxw50.mxa.$}>
      <div css={Css.xl2Bd.mt4.tac.mb6.$}>Send Email to Trades</div>
      <div css={Css.bgWhite.br8.p4.pb8.bshBasic.$}>
        <div css={Css.lgBd.mb3.$}>Invitation Text</div>
        <BoundTextAreaField fullWidth field={formState.existingInvitationText} labelStyle="hidden" />
      </div>
    </div>
  );
}
