import {
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  SelectField,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import { IncrementalCollectionOp } from "src/generated/graphql-types";
import { HasIdAndName } from "src/utils";
import { AddConstraintItemModal, ConstraintModalConfig } from "./AddConstraintItemModal";
import { SaveGlobalPlanTaskFormValue, TaskVariantInput } from "./TaskCatalogForm";

type AddCatalogTaskVariantProps = {
  formState: ObjectState<SaveGlobalPlanTaskFormValue>;
  markets: HasIdAndName[];
};

export function AddCatalogTaskVariantModal({ formState, markets }: AddCatalogTaskVariantProps) {
  const [selectedMarketId, setSelectedMarketId] = useState<string | undefined>(undefined);
  const { closeModal } = useModal();

  const existingMarketVariants = useComputed(
    () => formState.variants.rows.map((v) => v.marketId.value),
    [formState.variants],
  );
  const filteredMarkets = useMemo(
    () => markets.filter((m) => !existingMarketVariants.includes(m.id)).sortByKey("name"),
    [markets, existingMarketVariants],
  );

  const onConfirmClick = useCallback(async () => {
    const selectedMarket = markets.find((m) => m.id === selectedMarketId)!;
    formState.variants.add({
      marketId: selectedMarket.id,
      marketName: selectedMarket.name,
      op: IncrementalCollectionOp.Include,
      // Default the new variant values from the base task
      name: formState.name.value,
      optimisticDurationInDays: formState.optimisticDurationInDays.value,
      expectedDurationInDays: formState.expectedDurationInDays.value,
      pessimisticDurationInDays: formState.pessimisticDurationInDays.value,
      allowances: [...(formState.allowances.value ?? [])],
      constraints: [...(formState.constraints.value ?? [])],
      stageId: formState.stageId.value,
    });
    closeModal();
  }, [closeModal, selectedMarketId, formState, markets]);

  return (
    <>
      <ModalHeader>Add Task Variant</ModalHeader>
      <ModalBody>
        <p css={Css.sm.mb1.$}>Select the market for this task variant</p>
        <SelectField
          label="Market"
          required
          options={filteredMarkets}
          getOptionValue={(m) => m.id}
          value={selectedMarketId}
          onSelect={(marketId) => setSelectedMarketId(marketId)}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => <Button disabled={!selectedMarketId} label="Confirm" onClick={onConfirmClick} variant="primary" />}
        </Observer>
      </ModalFooter>
    </>
  );
}

export function AddVariantButton({ formState, markets }: AddCatalogTaskVariantProps) {
  const { openModal } = useModal();

  return (
    <Button
      label="Add Variant"
      variant="text"
      onClick={() => {
        openModal({
          content: <AddCatalogTaskVariantModal formState={formState} markets={markets} />,
        });
      }}
    />
  );
}

export function TaskVariantList({
  formState,
  constraintItems,
  stages,
}: {
  formState: ObjectState<SaveGlobalPlanTaskFormValue>;
  constraintItems: HasIdAndName[];
  stages: HasIdAndName[];
}) {
  return (
    <Observer>
      {() => (
        <div css={Css.mt4.$}>
          {formState.variants.rows.map((variant, i) => (
            <TaskVariant
              key={variant.marketId.value}
              formState={variant}
              constraintItems={constraintItems}
              stages={stages}
            />
          ))}
        </div>
      )}
    </Observer>
  );
}

function TaskVariant({
  formState,
  constraintItems,
  stages,
}: {
  formState: ObjectState<TaskVariantInput>;
  constraintItems: HasIdAndName[];
  stages: HasIdAndName[];
}) {
  const { openModal } = useModal();
  return (
    <div css={Css.mt2.mb4.$} data-testid="variant">
      <div css={Css.bb.bcGray400.pb1.$}>
        <span css={Css.baseMd.$}>Variant: {formState.marketName.value}</span>
      </div>
      <div css={Css.dg.gtc("1fr 130px 130px 65px 41px").rg4.mt3.$}>
        {/* Name Input Row */}
        <TaskGridFormLabel label="Task Name" />
        <div css={Css.gc("2 / span 4").$}>
          <BoundTextField field={formState.name} labelStyle="hidden" required />
        </div>
        {/* Name Input Row */}
        <TaskGridFormLabel label="Stage" />
        <div css={Css.gc("2 / span 4").$}>
          <BoundSelectField field={formState.stageId} labelStyle="hidden" options={stages} required />
        </div>
        {/* Duration Input Row */}
        <TaskGridFormLabel label="Days Duration" />
        <div css={Css.gc(2).pr3.$}>
          <BoundNumberField field={formState.expectedDurationInDays} label="Expected" required type="days" />
        </div>
        <div css={Css.gc(3).pr3.$}>
          <BoundNumberField field={formState.optimisticDurationInDays} label="Min" required type="days" />
        </div>
        <div css={Css.gc("4 / span 2").$}>
          <BoundNumberField field={formState.pessimisticDurationInDays} label="Max" required type="days" />
        </div>
        {/* Constraint Input Row */}
        <TaskGridFormLabel label="Constraints" />
        <div css={Css.gc("2 / span 3").$}>
          <BoundMultiSelectField
            field={formState.constraints}
            label="Constraints"
            labelStyle="hidden"
            options={constraintItems ?? []}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            multiline
          />
        </div>
        <div css={Css.gc("5").asc.jse.$}>
          <IconButton
            label="Add New Constraint"
            icon="plus"
            color={Palette.Gray700}
            onClick={() =>
              openModal({
                content: (
                  <AddConstraintItemModal
                    constraintType={ConstraintModalConfig.Constraint}
                    fieldState={formState.constraints}
                  />
                ),
              })
            }
          />
        </div>
        {/* Allowance Input Row */}
        <TaskGridFormLabel label="Allowances" />
        <div css={Css.gc("2 / span 3").$}>
          <BoundMultiSelectField
            field={formState.allowances}
            label="Constraints"
            labelStyle="hidden"
            options={constraintItems ?? []}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            multiline
          />
        </div>
        <div css={Css.gc("5").asc.jse.$}>
          <IconButton
            label="Add New Allowance"
            icon="plus"
            color={Palette.Gray700}
            onClick={() =>
              openModal({
                content: (
                  <AddConstraintItemModal
                    constraintType={ConstraintModalConfig.Allowance}
                    fieldState={formState.allowances}
                  />
                ),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export function TaskGridFormLabel({ label, tooltip }: { label: string; tooltip?: string }) {
  return (
    <div css={Css.df.aic.gap1.$}>
      <label css={Css.gray700.sm.gc(1).asc.$}>{label}</label>
      {tooltip && <Icon icon="infoCircle" color={Palette.Gray500} inc={2} tooltip={tooltip} />}
    </div>
  );
}
