import { Checkbox, Css, IconButton, MultiSelectField, Palette, useModal, useTestIds } from "@homebound/beam";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  PlanTask,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_TradePartnerContactFragment,
  ScheduleDraftMode_TradePartnerFragment,
  TradeConfirmPublishStep_TradePartnerContactFragment,
  useTradeConfirmPublishStepQuery,
} from "src/generated/graphql-types";
import { PublishDraftScheduleStep } from "src/routes/projects/dynamic-schedules/draft-mode/PublishDraftScheduleStepper";
import { ProjectParams } from "src/routes/routesDef";
import { TradePartnerContactModal } from "src/routes/trade-partners/TradePartnerContactModal";
import { formatMonthDay } from "src/utils/dates";
import { getMarketsContacts } from "../utils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

type TradeConfirmPublishStepProps = {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  updateStepStatus: (currentStepCompleted: boolean, currentStepValue: PublishDraftScheduleStep) => void;
};

type TasksForDropdown = Pick<PlanTask, "id" | "name" | "startDate"> & {
  knownSchedulingContacts: ScheduleDraftMode_TradePartnerContactFragment[];
};

export function TradeConfirmPublishStep({ draftTasks, updateStepStatus }: TradeConfirmPublishStepProps) {
  const setTparAvailabilityRequestInput = useDraftScheduleStore((state) => state.setTparAvailabilityRequestInput);
  const { projectId } = useParams<ProjectParams>();

  const getUnconfirmedPinnedTasks = useDraftScheduleStore((state) => state.getUnconfirmedPinnedTasks);
  const getConfirmedTasksToBeRescheduled = useDraftScheduleStore((state) => state.getConfirmedTasksToBeRescheduled);

  // local state to handle selecting/unselecting trade partner contacts to send emails to
  const [selectedTaskContacts, setSelectedTaskContacts] = useState<Record<string, string[]>>({});
  const name = useDraftScheduleStore((state) => state.scheduleParentName);

  // tasks that have been manually scheduled but not confirmed by the trade partner will be used to send tradePartnerAvailabilityRequests
  // we also send requests for trade partners who have been updated/or added to a task
  const unConfirmedPinnedTasks = useMemo(
    () => getUnconfirmedPinnedTasks(draftTasks),
    [draftTasks, getUnconfirmedPinnedTasks],
  );

  // tasks that have been confirmed and have a new start date will be used to send tradePartnerAvailabilityRequests
  const confirmedTasksToBeRescheduled = useMemo(
    () => getConfirmedTasksToBeRescheduled(draftTasks),
    [getConfirmedTasksToBeRescheduled, draftTasks],
  );

  // Create a map of trade partners with two lists (tasks and trade partners) to easily pluck out data
  const { groupedTasks, tradePartnerIds } = useMemo(() => {
    const tasksToBeSent = [...unConfirmedPinnedTasks, ...confirmedTasksToBeRescheduled];
    // first group by the trade partner id
    const groupedById = tasksToBeSent.groupBy((task) => task.tradePartner?.id ?? "");
    // then create two children arrays, one for the trade partner and one for the tasks
    const groupedTasks = Object.entries(groupedById).map(([tradePartnerId, tasks]) => ({
      tradePartner: tasks.find((task) => task.tradePartner?.id === tradePartnerId)?.tradePartner,
      tasks: tasks.map((task) => ({
        id: task.id,
        name: task.name,
        startDate: task.startDate,
        knownSchedulingContacts: task.knownSchedulingContacts ?? [],
      })),
    }));
    return { groupedTasks, tradePartnerIds: Object.keys(groupedById) };
  }, [unConfirmedPinnedTasks, confirmedTasksToBeRescheduled]);

  // grab the trade partners contactsByMarket so we can refresh the multi-select dropdown when a new contact is added
  const { data } = useTradeConfirmPublishStepQuery({
    variables: { projectId, tradePartnerId: tradePartnerIds },
    skip: tradePartnerIds.isEmpty,
  });

  // Handler function to update trade partner contacts on select
  const handleSelectedTask = useCallback(
    (
      selected: boolean,
      suggestedContacts: ScheduleDraftMode_TradePartnerContactFragment[],
      otherContacts: ScheduleDraftMode_TradePartnerContactFragment[],
      taskId: string,
    ) => {
      setSelectedTaskContacts((prevState) => {
        const newState = { ...prevState };
        if (selected) {
          // pre-select the known scheduling contacts if they exist, otherwise use the other contacts
          newState[taskId] = suggestedContacts.nonEmpty
            ? suggestedContacts.map((c) => c.id)
            : otherContacts.map((c) => c?.id ?? "");
        } else {
          // Remove the task key/value from local state
          delete newState[taskId];
        }
        return newState;
      });
    },
    [setSelectedTaskContacts],
  );

  useEffect(() => {
    const formattedTparInput = groupedTasks.flatMap(({ tasks, tradePartner }) => {
      // Map tasks to create trade partner availability request input shape
      return tasks.map((task) => ({
        taskId: task.id,
        tradePartnerId: tradePartner?.id ?? "",
        tradePartnerContactIds: selectedTaskContacts[task.id],
      }));
    });
    setTparAvailabilityRequestInput(formattedTparInput);
  }, [groupedTasks, selectedTaskContacts, setTparAvailabilityRequestInput]);

  useEffect(() => {
    const shouldDisableTradePartnerButton =
      Object.keys(selectedTaskContacts).isEmpty ||
      !Object.values(selectedTaskContacts).every((contactIds) => contactIds.nonEmpty);

    updateStepStatus(!shouldDisableTradePartnerButton, PublishDraftScheduleStep.TradeConfirm);
  }, [selectedTaskContacts, updateStepStatus]);

  // prepopulate known scheduling contacts in the dropdown (so users don't have to manually select)
  useEffect(() => {
    if (groupedTasks.nonEmpty) {
      // get a flat list of tasks
      const tasks = groupedTasks.flatMap(({ tasks }) => tasks);
      tasks.forEach((task) => {
        if (task.knownSchedulingContacts.nonEmpty) {
          // Pre-select known scheduling contacts
          setSelectedTaskContacts((prevState) => ({
            ...prevState,
            [task.id]: task.knownSchedulingContacts.map((contact) => contact.id),
          }));
        }
      });
    }
  }, [groupedTasks, setSelectedTaskContacts]);

  return (
    <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="tradeConfirmPublishStep">
      <h6 css={Css.base.gray700.mb1.$}>{name}</h6>
      <h1 css={Css.xl3Sb.$}>Select Trades to Email</h1>
      <p css={Css.base.my2.mb5.$}>
        The following Trade Partners are associated to the tasks that have been updated. Select the ones that you want
        to send emails to. The emails will inform trades of the date that they should be available for their assigned
        task(s).
      </p>
      {groupedTasks.isEmpty ? (
        <TradePartnerAvailabilityRequestEmptyState />
      ) : (
        <div css={Css.dg.gtc("1fr 100px 120px 300px").rg1.cg3.bgWhite.p4.maxwPx(1000).br8.ma.$}>
          <span css={Css.smSb.tal.$}>Trade Partner</span>
          <span css={Css.smSb.tal.$}>Original Date</span>
          <span css={Css.smSb.tal.$}>Updated Date</span>
          <span css={Css.smSb.tal.$}>Contact</span>
          {groupedTasks.map(({ tasks, tradePartner }) => {
            const otherTradePartnerContacts = getMarketsContacts(
              data?.tradePartners.find((tp) => tp?.id === tradePartner?.id),
            );
            return (
              <TradePartnerColumnData
                key={tradePartner?.id}
                tasks={tasks}
                tradePartner={tradePartner}
                handleSelectedTask={handleSelectedTask}
                selectedTaskContacts={selectedTaskContacts}
                setSelectedTaskContacts={setSelectedTaskContacts}
                otherTradePartnerContacts={otherTradePartnerContacts}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function TradePartnerAvailabilityRequestEmptyState() {
  return (
    <div
      css={
        Css.df.jcc.aic.bgGray200.gray700.p4
          .wPx(731)
          .hPx(515)
          .br8.boxShadow("0px 4px 8px 0px rgba(53, 53, 53, 0.08), 0px 2px 16px 0px rgba(53, 53, 53, 0.03)").ma.gap3.smMd
          .$
      }
      data-testid="tradePartnerAvailabilityRequestEmptyState"
    >
      The changes made do not require any Trade <br /> Partner availability updates.
    </div>
  );
}

type TradePartnerColumnDataProps = {
  tasks: TasksForDropdown[];
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  handleSelectedTask: (
    selected: boolean,
    suggestedContacts: ScheduleDraftMode_TradePartnerContactFragment[],
    otherContacts: ScheduleDraftMode_TradePartnerContactFragment[],
    taskId: string,
  ) => void;
  selectedTaskContacts: Record<string, string[]>;
  setSelectedTaskContacts: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  otherTradePartnerContacts: TradeConfirmPublishStep_TradePartnerContactFragment[];
};

function TradePartnerColumnData({
  tasks,
  tradePartner,
  handleSelectedTask,
  selectedTaskContacts,
  setSelectedTaskContacts,
  otherTradePartnerContacts,
}: TradePartnerColumnDataProps) {
  const tids = useTestIds({}, "tradeConfirmStep");
  const getInitialTaskValue = useDraftScheduleStore((state) => state.getInitialTaskValue);

  return (
    <Fragment key={tradePartner?.id}>
      <div css={Css.df.fdc.gap2.tal.gc(1).mt1.$}>
        <label css={Css.df.aic.gap2.sm.gray900.$}>
          {tradePartner ? (
            <span css={Css.sm.gray900.$} {...tids.tradeName}>
              {tradePartner.name}
            </span>
          ) : (
            "No Trade Partner"
          )}
        </label>
      </div>

      {tasks.map((task) => {
        // Combine known scheduling contacts with other trade partner market contacts
        const tradePartnerContacts = formatTradePartnerContacts(
          task.knownSchedulingContacts,
          otherTradePartnerContacts,
        );

        const initialTaskValue = getInitialTaskValue(task.id);

        return (
          <Fragment key={task.id}>
            <div css={Css.sm.gray700.mb1.tal.truncate.gc(1).df.aic.gap2.$} {...tids.taskName}>
              <Checkbox
                label=""
                checkboxOnly
                selected={!!(tradePartner && selectedTaskContacts[task.id])}
                onChange={(selected) =>
                  handleSelectedTask(selected, task.knownSchedulingContacts, otherTradePartnerContacts, task.id)
                }
                disabled={otherTradePartnerContacts?.isEmpty}
              />
              {task.name}
            </div>
            <div css={Css.gc(2).mb1.tal.$} {...tids.taskInitialDate}>
              {initialTaskValue ? formatMonthDay(initialTaskValue.startDate) : "--"}
            </div>
            <div css={Css.gc(3).mb1.tal.$} {...tids.taskNewDate}>
              {formatMonthDay(task.startDate)}
            </div>
            <TradePartnerContactColumn
              tradePartner={tradePartner}
              selectedTaskContacts={selectedTaskContacts}
              setSelectedTaskContacts={setSelectedTaskContacts}
              tradePartnerContacts={tradePartnerContacts ?? []}
              taskId={task.id}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

type TradePartnerContactColumnProps = {
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  selectedTaskContacts: Record<string, string[]>;
  setSelectedTaskContacts: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  tradePartnerContacts: ScheduleDraftMode_TradePartnerContactFragment[];
  taskId: string;
};

function TradePartnerContactColumn({
  tradePartner,
  setSelectedTaskContacts,
  selectedTaskContacts,
  tradePartnerContacts,
  taskId,
}: TradePartnerContactColumnProps) {
  const { openModal } = useModal();

  return (
    <div css={Css.df.gap2.aic.$}>
      {tradePartnerContacts?.nonEmpty ? (
        <div css={Css.tal.df.aic.gap2.$}>
          <MultiSelectField
            label="tradePartnerContactIds"
            labelStyle="hidden"
            getOptionLabel={({ name, email }) => (email ? `${name}, <${email}>` : name)}
            getOptionValue={({ id }) => id}
            // Disable the "suggested + other" fake rows so users cannot select them
            disabledOptions={tradePartnerContacts.filter((contact) => !contact.email).map((contact) => contact.id)}
            onSelect={(selectedContacts) => {
              setSelectedTaskContacts((prevState) => ({
                ...prevState,
                [taskId]: selectedContacts,
              }));
            }}
            options={tradePartnerContacts}
            values={selectedTaskContacts[taskId] ?? []}
            compact
          />
          <IconButton
            icon="plus"
            onClick={() =>
              openModal({
                size: "lg",
                content: (
                  <TradePartnerContactModal
                    tradePartnerId={tradePartner?.id!}
                    markets={tradePartner?.markets ?? []}
                    contact={undefined}
                    onTradeConfirmPublishStep
                  />
                ),
              })
            }
            color={Palette.Blue600}
          />
        </div>
      ) : (
        <span>No contact information available for this trade partner</span>
      )}
    </div>
  );
}

export function formatTradePartnerContacts(
  suggestedContacts: ScheduleDraftMode_TradePartnerContactFragment[],
  otherContacts: ScheduleDraftMode_TradePartnerContactFragment[],
): ScheduleDraftMode_TradePartnerContactFragment[] {
  // Dedupe the other contacts by filtering out those that are already in the "suggested" list
  const dedupedOtherContacts = otherContacts?.filter(
    (otherContact) => !suggestedContacts.some((suggestedContact) => suggestedContact.id === otherContact?.id),
  );

  // Combine "Suggested" and deduped "Other" contacts
  return [
    ...(suggestedContacts.nonEmpty
      ? [{ id: "suggested-placeholder", name: "Suggested", email: null }, ...suggestedContacts]
      : []),
    ...(dedupedOtherContacts?.nonEmpty
      ? [{ id: "other-placeholder", name: "Other", email: null }, ...dedupedOtherContacts]
      : []),
  ];
}
