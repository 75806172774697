import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { useAddEditItemModal_CreateLocationMutation } from "src/generated/graphql-types";
import { TakeoffsStoreState } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { StoreApi } from "zustand";
import { AddEditItemLocationsField } from "./";
import {
  AddEditItemModalDataViewProps,
  AddEditItemObjectState,
  CommonFields,
  OnTaskSelect,
  PublishedTakeoffBanner,
  TakeoffTaskBoundSelectField,
  TliData,
  saveAddEditItem,
  useAddEditItemModalFormState,
  withGetTliQuery,
} from "./utils";

export const AddEditLaborItemModal = withGetTliQuery(AddEditLaborItemModalDataView);

function AddEditLaborItemModalDataView(props: AddEditItemModalDataViewProps) {
  const { planPackageId, initialTliData, takeoffsManagerStore } = props;
  const [saveLocation] = useAddEditItemModal_CreateLocationMutation();

  const { saveTli, planPackage } = takeoffsManagerStore.getState();
  const { closeModal, addCanClose } = useModal();
  const isEditing = !!initialTliData?.id;
  const readOnly = !planPackage.canEditLineItems.allowed;

  const formState = useAddEditItemModalFormState("labor", readOnly, initialTliData);

  addCanClose(() => {
    return formState.dirty ? window.confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>{readOnly ? "Labor Details" : isEditing ? "Edit Labor" : "Add Labor"}</span>
          </ModalHeader>
          <ModalBody>
            {readOnly && <PublishedTakeoffBanner />}
            <div css={Css.df.fdc.gap2.pb3.$}>
              <LaborFields
                store={takeoffsManagerStore}
                planPackageId={planPackageId}
                initialTliData={initialTliData}
                readOnly={readOnly}
                formState={formState}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {readOnly ? (
              <Button label="Close" onClick={closeModal} />
            ) : (
              <>
                <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
                <Button
                  label="Save"
                  data-testid="saveBtn"
                  disabled={!formState.valid}
                  onClick={async () => {
                    await saveAddEditItem({
                      formState,
                      planPackageId,
                      saveTli,
                      saveLocation,
                    });
                    closeModal();
                  }}
                  size="lg"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type LaborFieldProps = {
  store: StoreApi<TakeoffsStoreState>;
  formState: ObjectState<AddEditItemObjectState>;
  planPackageId: string;
  initialTliData?: TliData;
  readOnly: boolean;
};

function LaborFields({ store, planPackageId, initialTliData, readOnly, formState }: LaborFieldProps) {
  // When a material cost type is selected then...
  const onTaskSelect: OnTaskSelect = useCallback(
    (id: string | undefined) => {
      // Set the task on the line
      formState.taskId.set(id);
    },
    [formState],
  );

  return (
    <>
      <TakeoffTaskBoundSelectField
        required
        selectedTask={initialTliData?.task}
        readOnly={readOnly}
        formState={formState}
        onSelect={onTaskSelect}
      />
      <AddEditItemLocationsField
        planPackageId={planPackageId}
        rpavId={store.getState().planPackage.version.id}
        newLocationNameField={formState.newLocationName}
        field={formState.locationId}
        onSelect={(locationId: string | undefined, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
        }}
      />
      <CommonFields store={store} planPackageId={planPackageId} readOnly={readOnly} formState={formState} />
    </>
  );
}
