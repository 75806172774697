import {
  Button,
  Css,
  DnDGrid,
  DnDGridItemHandle,
  IconButton,
  NumberField,
  SelectField,
  useComputed,
  useDnDGridItem,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useEffect, useRef, useState } from "react";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  PlanPackageLocationStepQuery,
  PlanPackageLocationStepQueryResult,
  SaveReadyPlanLocationInput,
  useBulkSaveRplsMutation,
  usePlanPackageLocationStepQuery,
  useRoomTypeQuery,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { createPlanPackageUrl } from "src/RouteUrls";
import { fail } from "src/utils";
import { useDebounceCallback } from "usehooks-ts";
import { PlanPackageEditorHeader } from "./components/PlanPackageEditorHeader";

type LocationStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};

export function LocationStep({ id, versionId, setStepDirty }: LocationStepProps) {
  const { loading, groupedRpls, reorderLevel, markDeleted, addRoomOrLevel, onSave, hasDupes, levels } =
    useQueryToGroupedData(id, versionId, setStepDirty);
  const [hoverRt, setHoverRt] = useState<string>();
  const [isAddingLevel, toggleIsAddingLevel] = useToggle(false);
  const [lastLevel] = groupedRpls.filter(([level]) => isLevel(level)).last ?? [];
  return (
    <StepLayout header={<PlanPackageEditorHeader title="Location" />}>
      <div css={Css.py3.mt2.df.ais.jcc.$}>
        <div css={Css.df.fdc.w25.wPx(450).$}>
          <div css={Css.df.jcsb.aic.mb2.pr4.$}>
            <div css={Css.baseSb.$}>Building</div>
            <Button label="+Add Level" variant="text" onClick={toggleIsAddingLevel} />
          </div>
          {loading && <div>Loading...</div>}
          {!loading && groupedRpls.isEmpty && <div>No locations found</div>}
          {isAddingLevel && (
            <div css={Css.mb2.$}>
              <SelectField
                label="Add a Level"
                placeholder="Select a new Level to add"
                options={levels}
                value={undefined}
                getOptionLabel={(l) => l.name}
                getOptionValue={(l) => l.id}
                disabledOptions={groupedRpls.map(([level]) => level.id)}
                onSelect={(levelId) => {
                  if (!levelId) return;
                  addRoomOrLevel(levelId);
                  toggleIsAddingLevel();
                }}
              />
            </div>
          )}
          {/* Levels */}
          {groupedRpls.map(([level, roomRpls]) => (
            <div key={level.id} css={Css.mb2.$}>
              <div css={Css.df.jcsb.pb2.$}>
                <div css={Css.baseMd.$}>{level.name}</div>
                {/* For non-Levels or the Last Level, render delete icon. This means given Levels 1/2/3 you can't delete 1 & 2 */}
                {(!isLevel(level) || lastLevel === level) && (
                  <IconButton icon="trash" label="Delete Level" onClick={() => markDeleted(level.id, true)} />
                )}
              </div>
              <div css={Css.df.fdc.pl3.$}>
                <DnDGrid onReorder={(rplIds) => reorderLevel(level.id, rplIds)}>
                  {roomRpls.map((roomRpl) => (
                    <RoomLocationRow
                      key={roomRpl.id.value}
                      roomRpl={roomRpl}
                      markDeleted={() => markDeleted(roomRpl.id.value)}
                      highlightRoomType={!!(hoverRt && hoverRt === roomRpl.value.roomType?.id)}
                      onInteract={setHoverRt}
                    />
                  ))}
                </DnDGrid>
                <div css={Css.df.fdrr.py2.pr4.$}>
                  <Button label="+ Add Room" variant="text" onClick={() => addRoomOrLevel(level.id)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NextStepButton
        label="Continue"
        onClick={onSave}
        onCloseReturnUrl={createPlanPackageUrl(id, versionId)}
        disabled={hasDupes}
        tooltip={hasDupes ? "Please resolve duplicate room numbers" : undefined}
        exitButton={{
          variant: "secondary",
          label: "Save & Exit",
          onClick: onSave,
          disabled: hasDupes,
          tooltip: hasDupes ? "Please resolve duplicate room numbers" : undefined,
        }}
      />
    </StepLayout>
  );
}

type ReadyPlanLocationRow = Omit<PlanPackageLocationStepQuery["planPackage"]["roomLocations"][number], "location"> & {
  delete?: boolean;
  hasDupes?: boolean;
  location: PlanPackageLocationStepQuery["planPackage"]["roomLocations"][number]["location"] | undefined;
};

type RoomLocationRowProps = {
  roomRpl: ObjectState<ReadyPlanLocationRow>;
  markDeleted: VoidFunction;
  /** Highlights all instances of this room type on hover or edit to easily identify duplicates or other bedrooms, hallways, bathrooms, closets, etc */
  highlightRoomType: boolean;
  onInteract: (roomTypeId: string | undefined) => void;
};

function RoomLocationRow({ roomRpl, markDeleted, highlightRoomType, onInteract }: RoomLocationRowProps) {
  const ref = useRef(null);
  const { roomType, location, roomNumber, id } = roomRpl.value;
  const { dragHandleProps, dragItemProps } = useDnDGridItem({ id, itemRef: ref });
  const { openModal } = useModal();
  const roomTypeQuery = useRoomTypeQuery({ fetchPolicy: "cache-first" });
  const isNewRow = !c.isRealId(id);
  const levelNumber =
    roomRpl.value.level?.name.match(/\d+$/)?.[0] || (roomRpl.value.level?.name.match(/basement/i) && 0); // basement is Level 0

  // every keystroke will bounce the row they're editing around the Level Order so debounce it (and skip empty inputs) for a smoother experience
  const debouncedSetRoomNumber = useDebounceCallback((val) => {
    if (val) {
      if (roomRpl.relatedTakeoffLineItemsCount.value > 0) {
        openModal({
          content: (
            <ConfirmationModal
              confirmationMessage={`Are you sure you want to rename this room, and the attached scope lines (${roomRpl.relatedTakeoffLineItemsCount.value})?`}
              onConfirmAction={() => roomRpl.set({ roomNumber: val })}
              title="Rename Room?"
              label="Rename"
              danger
            />
          ),
        });
      } else {
        roomRpl.set({ roomNumber: val });
      }
    }
  }, 300);

  return (
    <div
      ref={ref}
      {...dragItemProps}
      onMouseEnter={() => onInteract(roomType?.id)}
      onMouseLeave={() => onInteract(undefined)}
      css={{
        ...Css.df.jcsb.aic.mb1.if(highlightRoomType).bgGray200.onHover.bgGray300.$,
        // reveals the DnD handle as well as the Trash/Remove button
        [`&:hover #hidebutton`]: Css.o100.vv.$,
      }}
    >
      <div id="hidebutton" css={Css.o0.vh.$}>
        <DnDGridItemHandle icon="drag" dragHandleProps={dragHandleProps} />
      </div>
      <div css={Css.pl2.df.jcsb.aic.pr4.f1.$}>
        <SelectField
          label="Room Type"
          labelStyle="hidden"
          options={roomTypeQuery.data?.roomTypes ?? []}
          value={roomType?.id}
          onSelect={(val) => {
            roomRpl.set({ roomType: roomTypeQuery.data?.roomTypes.find((rt) => rt.id === val) });
          }}
          compact
          readOnly={!isNewRow}
          unsetLabel={isNewRow ? "Select Room Type" : `${location?.name} (err no roomType)`.trim()}
        />
      </div>
      <div css={Css.mr1.$}>{levelNumber}</div>
      <div
        css={Css.wPx(70).fs0.$} /* NumberField is ignoring this CSS in favor of w100, so wrap it to give w100 a max */
      >
        <NumberField // unbound because we need to debounce the input
          label="Room Number"
          labelStyle="hidden"
          value={roomNumber!}
          onFocus={() => onInteract(roomType?.id)}
          onBlur={() => onInteract(undefined)}
          onChange={debouncedSetRoomNumber}
          errorMsg={roomRpl.hasDupes.value ? "Duplicate room number" : undefined}
          errorInTooltip
          positiveOnly
          numIntegerDigits={2} // UX includes Level like: `1-01` and `2-03`, so padStart
          compact
        />
      </div>
      <div id="hidebutton" css={Css.o0.vh.df.aic.jcfe.pl1.$}>
        <IconButton icon="trash" onClick={markDeleted} />
      </div>
    </div>
  );
}

/** [Level, RPLs[]] tuples derived from `RPLs.groupByObject((rpl) => rpl.level)` */
type GroupedData = [NonNullable<ReadyPlanLocationRow["level"]>, ObjectState<ReadyPlanLocationRow>[]];
type FormState = { readyPlanLocations: ReadyPlanLocationRow[] };

function useQueryToGroupedData(ppId: string, versionId: string, setStepDirty: (dirty: boolean) => void) {
  const query = usePlanPackageLocationStepQuery({ variables: { id: ppId, versionId } });
  const [mutate] = useBulkSaveRplsMutation();
  const { openModal } = useModal();

  const fs = useFormState<FormState, PlanPackageLocationStepQueryResult["data"]>({
    config: formConfig,
    init: {
      query,
      map: (data) => ({ readyPlanLocations: data.planPackage.roomLocations }),
      onlyOnce: true,
    },
  });

  useComputed(() => setStepDirty(fs.dirty), [fs]);

  // These groupings will be rendered out. Grouped to [Level, [RoomRPL]] tuples. Includes things like
  // [Roof, []] so the Section renders but without any Rooms.
  const groupedRpls = useComputed<GroupedData[]>(() => {
    // Complicating factors:
    // - Keep in mind ReadyPlanLocation & Location are different entities
    // - RPLs look like RoomRPL.level or LevelRPL.location
    //   - `level` and `location` are both Locations (not RPL)
    //   - LevelRPL.level is NOT self-referential to LevelRPL.location as it complicates backend code
    //   - RoomRPL.location is not set for New RoomRPLs, as users are only selecting RoomType and we end up findOrCreating `Bedroom 113`
    // - We want [Level, [RoomRPL]] tuples. [RoomRpl] may be empty, for example Roof doesn't have Rooms
    //   - (legacy data issue) LevelRPL may not be present for all RoomRPL
    //   - ^ As a result, we may have to pull Level from `RoomRPL.level` instead of `LevelRPL.location`
    const allPossibleLocations = [
      ...fs.readyPlanLocations.rows.map((tryLevelRpl) => tryLevelRpl.location.value),
      ...fs.readyPlanLocations.rows.map((tryRoomRpl) => tryRoomRpl.level.value),
    ]
      .compact()
      .uniqueByKey("id");
    return fs.readyPlanLocations.rows
      .filter((anyRpl) => !anyRpl.delete.value)
      .sortBy((anyRpl) => anyRpl.roomNumber.value ?? 0)
      .groupByObject(
        (anyRpl) =>
          anyRpl.value.level?.id ?? // RoomRPL.level should be set if it's a room
          anyRpl.value.location?.id ?? // LevelRPL.location should be set
          fail(`Found an RPL with neither level nor location ${anyRpl.id.value}`),
      )
      .map<GroupedData>(([levelId, anyRpls]) => [
        // map levelId to a Level (Location Entity)
        allPossibleLocations.find((loc) => loc.id === levelId) ??
          fail(`Could not find location for ${anyRpls[0].id.value}`),
        // Filter [anyRPL] to [RoomRPL]. If we filter earlier than this we nix `[Roof, []]` which would be a problem. We need to wait until now so that tuple gets generated.
        anyRpls.filter((tryRoomRpl) => !!tryRoomRpl.roomNumber.value),
      ])
      .sortBy(([level]) => {
        // It's unfortunately "Level Basement" which would sort after "Level N" so redirect it to Basement so it alphasorts above "Level"
        if (level.name.match(/basement/i)) return "Basement";
        return level.name;
      });
  }, [fs]);

  /**
   * Detect duplicates. We can't autofix these because given [OFFC106, HLWY106, KTCH107], the user may REALLY want KTCH107 and
   * we can't go bumping around that number. So the best we can do is flag the conflict and let the user handle it, expecting
   * backend rules will throw if any actual duplicates make it through.
   */
  useEffect(() => {
    groupedRpls.forEach(([level, roomRpls]) => {
      const [dupes, uniques] = roomRpls
        .groupByObject((roomRpl) => roomRpl.roomNumber.value!)
        .map(([roomNumber, roomRpls]) => roomRpls)
        .partition((roomRpls) => roomRpls.length > 1);

      dupes.flat().forEach((roomRpl) => roomRpl.set({ hasDupes: true }));
      uniques.flat().forEach((roomRpl) => roomRpl.set({ hasDupes: false }));
    });
  }, [groupedRpls]);

  const usableLevels = useComputed(() => {
    // Basement, Level 1/2/3, Roof, etc
    const levelsInUse = fs.readyPlanLocations.value
      .filter((tryLevelRpl) => !tryLevelRpl.level)
      .map((levelRpl) => levelRpl.location?.name!)
      .filter((name) => /Level \d+/.test(name));
    const [allLevels, nonLevels] = (query.data?.levels ?? []).partition((l) => /Level \d+/.test(l.name));
    return (
      allLevels
        .sortByKey("name")
        // Assuming ["Level 1"] is in use, then sort All Levels to ["Level 1", "Level 2", "Level 3", ...] and slice out
        // ["Level 1"].length + 1 to get ["Level 1", "Level 2"]
        .slice(0, levelsInUse.length + 1)
        .concat(nonLevels) // fold Basement/Roof/etc back in
        .sortByKey("name") // this is mostly luck that "Basement" > "Level 1/2/3" > "Roof"
    );
  }, [fs, query.data]);

  return {
    loading: query.loading,
    levels: usableLevels,
    groupedRpls,
    hasDupes: useComputed(
      // deleted rows are all set to 0 so ignore those "dupes"
      () => fs.value.readyPlanLocations?.some((anyRpl) => anyRpl.roomNumber && anyRpl.hasDupes),
      [fs],
    ),
    reorderLevel: (levelId: string, rplIdsNewOrder: string[]) => {
      const levelRooms = fs.readyPlanLocations.rows
        .filter((tryRoomRpl) => tryRoomRpl.level?.value?.id === levelId) // LevelRPL.level is unset and will filter out
        .filter((roomRpl) => roomRpl.roomNumber.value! > 0) // deleted rows are all set to 0 so ignore those
        .sortBy((roomRpl) => roomRpl.roomNumber.value!);

      function reorder() {
        const currentNumbers = levelRooms.map((roomRpl) => roomRpl.roomNumber.value);
        if (rplIdsNewOrder.length !== levelRooms.length)
          fail(`expected newOrder and levelRooms to be the same length. Are they doing different filtering?`);
        rplIdsNewOrder
          .map(
            (rplId) => levelRooms.find((rpl) => rpl.id.value === rplId) ?? fail(`Could not find rpl with id ${rplId}`),
          )
          .forEach((roomRpl, ix) => roomRpl.roomNumber.set(currentNumbers[ix]));
      }

      if (levelRooms.some((rpl) => rpl.relatedTakeoffLineItemsCount.value > 0)) {
        openModal({
          content: (
            <ConfirmationModal
              confirmationMessage={`Are you sure you want to rename this room, and the attached scope lines?`}
              onConfirmAction={reorder}
              title="Rename Room?"
              label="Rename"
              danger
            />
          ),
        });
      } else {
        reorder();
      }
    },
    markDeleted: (rplOrLevelId: string, wholeLevel = false) => {
      fs.readyPlanLocations.rows
        .filter((anyRpl) => {
          // if the whole level is being deleted, then rplOrLevelId is a Level, and match RoomRPL.level or LevelRPL.location
          if (wholeLevel) return anyRpl.value.level?.id === rplOrLevelId || anyRpl.value.location?.id === rplOrLevelId;
          // otherwise rplOrLevelId is a RoomRPL, and find RoomRPL.id
          return anyRpl.id.value === rplOrLevelId;
        })
        .forEach((anyRpl) => {
          c.isRealId(anyRpl.id.value)
            ? anyRpl.set({ delete: true, roomNumber: 0 }) // Set roomNumber to 0 to avoid dupes
            : fs.readyPlanLocations.remove(anyRpl.value); // actually delete fake/new rows
        });
    },
    addRoomOrLevel: (levelId: string) => {
      // if there's no [Level, [RoomRPL]] tuple for this level, create a new LevelRPL.
      const isNewLevel = !groupedRpls.some(([level]) => level.id === levelId);
      const levelRooms = fs.readyPlanLocations.value.filter((tryRoomRpl) => tryRoomRpl.level?.id === levelId); // only RoomRPL.level is set, not LevelRPL.level
      const maybeDeletedLevel = fs.readyPlanLocations.rows.find(
        (tryLevelRpl) => tryLevelRpl.value.location?.id === levelId && tryLevelRpl.delete,
      );
      if (isNewLevel && maybeDeletedLevel) return maybeDeletedLevel.set({ delete: false });
      isNewLevel
        ? fs.readyPlanLocations.add({
            id: c.next(),
            roomNumber: 0,
            location: query.data?.levels.find((l) => l.id === levelId) ?? fail("Could not find new level")!,
            level: undefined!,
            relatedTakeoffLineItemsCount: 0,
          })
        : fs.readyPlanLocations.add({
            id: c.next(),
            roomNumber: levelRooms.isEmpty ? 1 : levelRooms.max((roomRpl) => roomRpl.roomNumber!) + 1,
            location: undefined!,
            level:
              fs.readyPlanLocations.value.find((tryRoomRpl) => tryRoomRpl.level?.id === levelId)?.level ??
              fs.readyPlanLocations.value.find((tryLevelRpl) => tryLevelRpl.location?.id === levelId)?.location ??
              fail("Could not determine level"),
            relatedTakeoffLineItemsCount:
              fs.readyPlanLocations.value.find((tryRoomRpl) => tryRoomRpl.level?.id === levelId)
                ?.relatedTakeoffLineItemsCount ??
              fs.readyPlanLocations.value.find((tryLevelRpl) => tryLevelRpl.location?.id === levelId)
                ?.relatedTakeoffLineItemsCount ??
              fail("Could not determine level"),
          });
    },
    onSave: async () => {
      // The existing cache/page-data will technically be stale now (Created entities will get IDs that didn't merge) but since
      // this is a `Save & Exit` and a `Continue,` the page will disappear so we shouldn't have to deal with that. If that changes
      // then a full refetch will be necesasry.
      return void mutate({
        variables: {
          inputs: fs.readyPlanLocations.rows.map<SaveReadyPlanLocationInput>((anyRpl) => {
            const { roomNumber, id, delete: del, roomType, location, level } = anyRpl.value;
            const levelId = level?.id;
            const roomTypeId = roomType?.id;
            const locationId = location?.id;
            // update
            if (c.isRealId(id)) return { id, delete: del, roomNumber: roomNumber ?? undefined };
            // create RoomRPL
            if (levelId) return { readyPlanId: ppId, roomTypeId, levelId, roomNumber: roomNumber ?? undefined };
            // create LevelRPL
            return { readyPlanId: ppId, locationId };
          }),
        },
      });
    },
  };
}

const isLevel = (lvl: ReadyPlanLocationRow["level"]) => /Level \d+/i.test(lvl?.name ?? "");

const c = new (class NewIdCounter {
  count = 0;
  next() {
    return String(this.count++);
  }
  /** IDs that came from the backend will be `rpl:123` */
  isRealId = (val: string) => val.includes(":");
})();

const formConfig: ObjectConfig<FormState> = {
  readyPlanLocations: {
    type: "list",
    config: {
      // from the GQL Type
      id: { type: "value" },
      level: { type: "value" },
      location: { type: "value" }, // passthrough type
      roomNumber: { type: "value" },
      roomType: { type: "value" },
      // Adhoc types used locally
      delete: { type: "value" },
      hasDupes: { type: "value" },
      relatedTakeoffLineItemsCount: { type: "value" },
    },
  },
};
