import { Button, Css, FullBleed, RouteTabWithContent, Tabs, Tag, useModal } from "@homebound/beam";
import { createPlanPackagesUrl } from "src/RouteUrls";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { PlanPackageDetailsFragment, ReadyPlanStatus } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PlanPackageButtonMenu } from "src/routes/libraries/plan-package/details/components/PlanPackageButtonMenu";
import { PublishAggregateVersionModal } from "src/routes/libraries/plan-package/details/components/PublishAggregateVersionModal";
import { fail } from "src/utils";

type PlanPackageHeaderProps = {
  planPackage: PlanPackageDetailsFragment;
  tabs: RouteTabWithContent[];
};

export function PlanPackageHeader({ planPackage, tabs }: PlanPackageHeaderProps) {
  const { openModal } = useModal();
  const { name, constructionDocumentCompletePercentage, status, aggregateDraft } = planPackage;

  return (
    <FullBleed>
      <div css={Css.bgWhite.bb.bw1.bcGray200.$}>
        {/* Max-width based on Figma designs */}
        <div css={Css.maxwPx(1156).mxa.$}>
          <PageHeader
            title={name}
            titleSize="xl3Sb"
            xss={Css.bn.mb0.$}
            left={
              <>
                {status.code === ReadyPlanStatus.Archived && <Tag text="Archived" type="warning" />}
                <span css={Css.smSb.gray800.$}>{constructionDocumentCompletePercentage}% CD</span>
              </>
            }
            right={
              <>
                <ReadyPlanVersionHeader readyPlan={planPackage} versionId={planPackage.version.id} />
                {/* TODO: Work with Product & Design to determine when/how this can be Published */}
                <Button
                  size="md"
                  disabled={!aggregateDraft}
                  label="Publish"
                  onClick={() =>
                    openModal({
                      content: (
                        <PublishAggregateVersionModal
                          draftAggregateVersion={aggregateDraft ?? fail("No draft aggregate version found")}
                          copyText="This update will be made available to any Design Package and Product Offering that reference it."
                          readyPlanName={name}
                        />
                      ),
                    })
                  }
                />
                <PlanPackageButtonMenu planPackage={planPackage} iconTrigger redirectOnDiscardDraft />
              </>
            }
            breadcrumb={[{ label: "Plans", href: createPlanPackagesUrl() }]}
          />
          <Tabs tabs={tabs} />
        </div>
      </div>
    </FullBleed>
  );
}
