import { Css, useComputed, useSnackbar, useTestIds } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  ReadyPlanAssetType,
  ReadyPlanStatus,
  usePlanPackageDetailsStepQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { PlanOverviewCard } from "./components/PlanOverviewCard";
import { PlanPackageNextStepButton } from "./components/PlanPackageNextStepButton";
import { planPackageDetailsConfig } from "./utils";

type PlanDetailsStepProps = {
  id: string | undefined;
  versionId: string | undefined;
  setStepDirty: (dirty: boolean) => void;
};

export function PlanDetailsStep({ id, versionId, setStepDirty }: PlanDetailsStepProps) {
  const { triggerNotice } = useSnackbar();
  const query = usePlanPackageDetailsStepQuery({ variables: { id: id!, versionId }, skip: !id });
  const [save] = useSavePlanPackageDetailsMutation();
  const canEdit = query.data?.planPackage.canEdit;

  const formState = useFormState({
    config: planPackageDetailsConfig,
    init: {
      query,
      map: ({ planPackage }) => ({
        id: planPackage?.id,
        name: planPackage?.name,
        description: planPackage?.description,
        code: planPackage?.code ? Number(planPackage.code) : undefined,
        type: planPackage?.type.code,
        status: planPackage?.status.code,
        lotTypes: planPackage?.lotTypes,
        assets:
          planPackage?.assets?.map((readyPlanAsset) => {
            const { id, asset, parent, type } = readyPlanAsset;
            return { id, asset, parentId: parent?.id, type: type.code };
          }) ?? [],
        markets: planPackage?.markets?.map((market) => market.id) ?? [],
      }),
    },
  });
  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const tid = useTestIds({}, "planDetailsStep");

  const onSave = useCallback(async () => {
    if (formState.dirty) {
      const { code, ...otherValues } = formState.changedValue;
      const response = await save({
        variables: {
          input: {
            id,
            type: formState.type.value,
            ...otherValues,
            ...(code && { code: code.toString().padStart(3, "0") }),
            ...(!id && { status: ReadyPlanStatus.Draft }),
            assets: [
              ...(formState.changedValue.assets?.map((readyPlanAsset) => ({
                ...readyPlanAsset,
                asset: readyPlanAsset.asset ? { ...readyPlanAsset.asset, previewUrl: undefined } : undefined,
              })) ?? []),
            ],
          },
        },
      });
      formState.commitChanges();
      triggerNotice({ message: `Your Plan has been updated!` });
      return response;
    }
  }, [formState, id, save, triggerNotice]);

  const coverPhoto = useComputed(
    () =>
      formState.assets.value
        ?.filter((a) => a.active)
        .find((readyPlanAsset) => readyPlanAsset.type === ReadyPlanAssetType.CoverPhoto),
    [formState],
  );

  const saveCoverPhoto = async (file: UppyFile | undefined) => {
    // No file means we are removing the cover photo
    if (!file) {
      formState.assets.set(
        formState.assets.value.map((a) => ({
          ...a,
          active: a.type === ReadyPlanAssetType.CoverPhoto ? false : a.active,
        })),
      );
      return;
    }

    const assets = formState.assets.value;
    const prevCoverPhoto = assets.find((rpa) => rpa.type === ReadyPlanAssetType.CoverPhoto);
    // If there is an existing RPA, we only update the asset info
    if (prevCoverPhoto?.id) {
      formState.assets.set(
        assets.map((rpa) =>
          rpa.id === prevCoverPhoto.id
            ? {
                ...rpa,
                active: true,
                asset: {
                  contentType: file.type,
                  fileName: file.name,
                  s3Key: file.meta.s3Key as string,
                  sizeInBytes: file.data.size,
                  previewUrl: file.meta.previewUrl as string,
                },
              }
            : rpa,
        ),
      );
    } else {
      // Create a new RPA
      formState.assets.set([
        ...assets,
        {
          asset: {
            contentType: file.type,
            fileName: file.name,
            s3Key: file.meta.s3Key as string,
            sizeInBytes: file.data.size,
            previewUrl: file.meta.previewUrl as string,
          },
          parentId: id,
          type: ReadyPlanAssetType.CoverPhoto,
          active: true,
        },
      ]);
    }
  };

  return (
    <Observer>
      {() => (
        <UploaderProvider>
          <StepLayout
            header={
              <PlanPackageEditorHeader
                title={id ? "Edit Plan" : "Create New Plan"}
                subtitle={
                  "A Plan is the the foundation of a product offering, and is where things like elevations, options and takeoffs are defined. Plans are later used to build Plan Offerings."
                }
              />
            }
            body={
              <div css={Css.py3.$}>
                <div css={Css.df.aic.gap1.jcc.$}>
                  <span css={Css.$} {...tid.titleSection}>
                    Name your new Plan and select the type and code.
                  </span>
                </div>
                <div css={Css.mt2.df.fww.ais.gap2.jcc.$} {...tid.sections}>
                  <PlanOverviewCard
                    canEdit={canEdit}
                    formState={formState}
                    lotTypes={formState.lotTypes.value}
                    coverPhotoUrl={coverPhoto?.asset?.previewUrl}
                    saveCoverPhoto={saveCoverPhoto}
                  />
                </div>
                <PlanPackageNextStepButton
                  planId={id}
                  versionId={versionId}
                  disabled={!formState.valid}
                  onSave={onSave}
                />
              </div>
            }
          />
        </UploaderProvider>
      )}
    </Observer>
  );
}
