import { Maybe } from "src/generated/graphql-types";
import { Rule } from "src/utils/formState";

export const maxExternalAccountingNumber = 5000;

export const accountingNumberRule: Rule<Maybe<number>> = ({ value, originalValue }) => {
  if (
    // If accountingNumber is undefined or null consider it valid. This signals the graphql-service that we want a new accounting number generated
    value === undefined ||
    value === null ||
    // If the accountingNumber is equal to its original value, then consider it valid.
    originalValue === value ||
    // If a new value has been entered, then that means the user is entering an external account number and that must fit between the defined bounds to be valid.
    (value > 0 && value < maxExternalAccountingNumber)
  ) {
    return undefined;
  }
  // If it did not pass the above validation, then the user entered an external accounting number outside of the bounds.
  return "External Accounting Numbers must be less than 5000";
};

export function costFromTaxableAmount(taxable: Maybe<number>, isTaxExempt: boolean, salesTaxInBasisPoints: number) {
  return (taxable ?? 0) * (isTaxExempt ? 1 : 1 + salesTaxInBasisPoints / 100_00);
}

export function getUnbilledAmounts(
  taxable: Maybe<number>,
  billed: Maybe<number>,
  isTaxExempt?: Maybe<boolean>,
  salesTaxInBasisPoints: number = 0,
): number {
  return costFromTaxableAmount(taxable, isTaxExempt ?? true, salesTaxInBasisPoints) - (billed ?? 0);
}
