import { HbLoadingSpinner, useModal } from "@homebound/beam";
import { Wrapper } from "@homebound/rtl-utils";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import {
  DesignPackageConfiguratorFragment,
  DesignPackageItemSlotEditorQuery,
  newDesignPackage,
  useDesignPackageConfiguratorQuery,
  useDesignPackageItemSlotEditorQuery,
} from "src/generated/graphql-types";
import useQueryString from "src/hooks/useQueryString";
import { fail } from "src/utils";
import { DesignUpdatesAvailableModal } from "./components/DesignUpdatesAvailableButton";

type DesignPackageConfiguratorContextState = {
  /** Basic metadata about the loaded DesignPackage */
  designPackage: DesignPackageConfiguratorFragment;
  /** The slots LefNav has selected for "main content" display in the ItemSlotEditor */
  selectedSlots: DesignPackageItemSlotEditorQuery["designPackage"]["slots"] | undefined;
  /** The selected Options via DesignWithFilters */
  selectedOptionIds: string[];
  /** The topic of interest in the New>Product Search>Detail flow */
  bidItemId: DesignPackageConfiguratorQueryString["bidItemId"] | undefined;
  setCtxState: (partial: Partial<DesignPackageConfiguratorQueryString>) => void;
  /** The latest RPAV is either in draft, or can be used to create the next draft. */
  readOnly: boolean;
  loading: boolean;
};

export const DesignPackageConfiguratorContext = createContext<DesignPackageConfiguratorContextState>({
  designPackage: undefined!,
  setCtxState() {},
  selectedOptionIds: [],
  selectedSlots: undefined,
  loading: false,
  bidItemId: undefined,
  readOnly: true,
});

export function DesignPackageConfiguratorProvider({ children }: React.PropsWithChildren<unknown>) {
  const { designPackageId, designPackageVersionId } = useParams<{
    designPackageId: string;
    designPackageVersionId: string;
  }>();
  const [qs, setQs] = useQueryString<DesignPackageConfiguratorQueryString>({
    optionIds: [],
    slotIds: [],
    acceptUpdates: undefined,
  });
  const { openModal } = useModal();

  // getting new arrays on each render cycle, so stabilize them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const slotIds = useMemo(() => qs.slotIds, [qs.slotIds.toString()]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionIds = useMemo(() => qs.optionIds, [qs.optionIds.toString()]);

  /** This is the Background data for all pages */
  const designCatalogQuery = useDesignPackageConfiguratorQuery({
    variables: { id: designPackageId, versionId: designPackageVersionId },
  });

  /** This is the "Center Panel" data that "Left Nav" is Setting */
  const selectedSlotQuery = useDesignPackageItemSlotEditorQuery({
    variables: { dpId: designPackageId, slotIds, rpavId: designPackageVersionId, optionIds },
    skip: !designPackageId || !designPackageVersionId || (slotIds.isEmpty && optionIds.isEmpty),
  });

  useEffect(() => {
    const dp = designCatalogQuery.data?.designPackage;
    if (qs.acceptUpdates && dp?.availableUpdates.nonEmpty) {
      openModal({
        content: <DesignUpdatesAvailableModal updates={dp.availableUpdates} designPackageId={dp.id} />,
      });
      setQs({ acceptUpdates: undefined });
    }
  }, [designCatalogQuery.data?.designPackage, openModal, qs.acceptUpdates, setQs]);

  // If the sync is running keep polling the DP
  useEffect(() => {
    designCatalogQuery.data?.designPackage.copyInProgress
      ? designCatalogQuery.startPolling(2_500)
      : designCatalogQuery.stopPolling();
  }, [designCatalogQuery]);

  return (
    <DesignPackageConfiguratorContext.Provider
      value={{
        designPackage:
          designCatalogQuery.data?.designPackage! ?? (!designCatalogQuery.loading && fail("No design package")),
        setCtxState: setQs,
        selectedOptionIds: optionIds,
        bidItemId: qs.bidItemId,
        selectedSlots:
          // If user had options selected (DWF), then de-selected the last option, return `[]` to return back to "nothing selected; please pick a slot/filters" screen
          (slotIds.isEmpty && optionIds.isEmpty && []) ||
          // prevent flicker when switching between slots as `data` clears out when new variables are set before new data is returned
          (selectedSlotQuery.data?.designPackage.slots ?? selectedSlotQuery.previousData?.designPackage.slots),
        loading: designCatalogQuery.loading || selectedSlotQuery.loading,
        // The latest RPAV is either in draft, or can be used to create the next draft.
        readOnly: designPackageVersionId !== designCatalogQuery.data?.designPackage.aggregateVersions.last?.id,
      }}
    >
      {designCatalogQuery.loading ? <HbLoadingSpinner /> : children}
    </DesignPackageConfiguratorContext.Provider>
  );
}

/**
 * Several pages within DesignPackage are trying to leverage QueryString including:
 * 1. Context: selected Slots from leftnav
 * 2. SlotEditor: selectedOptionIds for DesignWithFilters
 * 3. ItemSlotBidItemPage: bidItemId (possible "noBidItem" for isDisabledBidItem)
 */
type DesignPackageConfiguratorQueryString = {
  /** Set by LeftNav to tell ItemSlotEditor which Slots to load */
  slotIds: string[];
  /** Used by DesignWithFilters. Propagates to ItemSlotBidItemPage so the selected filters auto-apply to new TLIs */
  optionIds: string[];
  /**
   * The selected BidItem for the ItemSlotBidItemPage, either from the selected Card or forwarded from the
   * ProductSearchPage and about to be committed to the card. Pass in `"none"` to show the "No Bid Item" UI.
   */
  bidItemId: `bi:${number}` | "noBidItem";
  /** Automatically opens a modal to accept incoming plan updates*/
  acceptUpdates: boolean | undefined;
};

export const useDesignPackageConfiguratorContext = () => useContext(DesignPackageConfiguratorContext);

export const withDesignPackageConfiguratorContext = (
  opts: Partial<DesignPackageConfiguratorContextState>,
): Wrapper => ({
  wrap: (children) => (
    <DesignPackageConfiguratorContext.Provider
      value={{
        bidItemId: undefined,
        designPackage: opts.designPackage ?? newDesignPackage({ id: "rp:1", version: { id: "rpav:1" } }),
        loading: false,
        readOnly: false,
        selectedOptionIds: [],
        selectedSlots: [],
        setCtxState() {},
        ...opts,
      }}
    >
      {children}
    </DesignPackageConfiguratorContext.Provider>
  ),
});
