import { TypePolicies } from "@apollo/client";
import { enumTypePolicies, scalarTypePolicies } from "src/generated/graphql-types";
import { dateArrayTypePolicy, dateTypePolicy } from "src/utils/dates";
import { infiniteScroll } from "./utils/utils";

export const typePolicies: TypePolicies = {
  ...enumTypePolicies,
  ...scalarTypePolicies,
  // If separate queries ask for different subsets of value objects, just merge them
  CommentStreams: { merge: true },
  Query: {
    fields: {
      toDos: {
        keyArgs: ["filter"],
        merge(existing, incoming, { args }) {
          const merged = existing ? existing.slice(0) : [];
          if (args) {
            const { first = 0 } = args;
            for (let i = 0; i < incoming.length; ++i) {
              merged[first + i] = incoming[i];
            }
          } else {
            // eslint-disable-next-line prefer-spread
            merged.push.apply(merged, incoming);
          }
          return merged;
        },
      },
      projectsPage: {
        ...infiniteScroll(),
      },
      projectItemsPage: {
        ...infiniteScroll({ dataKey: "projectItems" }),
      },
      commitmentsPage: {
        ...infiniteScroll({ dataKey: "commitments" }),
      },
      expensesPage: {
        ...infiniteScroll(),
      },
      jobLogsPage: {
        ...infiniteScroll(),
      },
      changeRequests: {
        ...infiniteScroll(),
      },
      itemTemplateItemVersionsPage: {
        ...infiniteScroll({ dataKey: "items" }),
        keyArgs: ["filter", "order"],
      },
      takeoffLineItemsPage: {
        ...infiniteScroll({ dataKey: "items" }),
        keyArgs: ["filter", "order"],
      },
      tradePartnersPage: {
        ...infiniteScroll(),
      },
      developmentCommitmentsPage: {
        ...infiniteScroll(),
      },
      userRelevantCommentStreamsPaged: {
        ...infiniteScroll(),
      },
      globalPlanTasks: {
        ...infiniteScroll(),
      },
    },
  },
  // JobLogsPageResult.logDates returns a custom list of dates that do not have a type policy within `scalarTypePolicies`
  JobLogsPageResult: {
    fields: {
      logDates: dateArrayTypePolicy,
    },
  },
  TradePartnerAvailabilityRequest: {
    fields: {
      rescheduleDates: dateArrayTypePolicy,
      createdAt: dateTypePolicy,
    },
  },
  CalendarInterval: { ...scalarTypePolicies.CalendarInterval, merge: true },
  // HSPO's are transient entities, so we want to disable normalization (i.e. skip caching)
  HomeownerSelectionProductOption: { keyFields: false },
  // Custom merge function added so that cache will update properly when task dependencies are deleted
  // `merge: false` means the incoming data will be preferred over existing data
  ScheduleTask: {
    fields: {
      kind: {
        read() {
          // TODO: Change to ScheduleTask
          return "task";
        },
      },
    },
  },
  // We didn't use `id` as the identity field for these types...
  ItemTemplateItemVersionGroupTotal: { keyFields: ["groupId"] },
  TakeoffLineItemGroupTotal: { keyFields: ["groupId"] },
  // always override the cache with the incoming data
  DesignPackageSlot: { fields: { items: { merge: (existing, incoming) => incoming } } },
};
