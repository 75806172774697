import {
  Avatar,
  Banner,
  Button,
  ButtonMenu,
  Chip,
  Css,
  GridColumn,
  GridTable,
  HbLoadingSpinner,
  Icon,
  SimpleHeaderAndData,
  Switch,
  Tag,
  column,
  dateColumn,
  emptyCell,
  numericColumn,
  simpleDataRows,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { usePrevious } from "react-use";
import {
  addEntityParam,
  createDesignPackageUrl,
  createPlanPackageUrl,
  createProductOfferingApplyToProjectsUrl,
  createProductOfferingConfigEditUrl,
  createProductOfferingConfigUrl,
  createProductOfferingConfigsUrl,
  createProductOfferingEditUrl,
  createProductOfferingScopeUrl,
  createProductOfferingVersionHistoryUrl,
} from "src/RouteUrls";
import { StaticHeaderField, dateCell, priceCell } from "src/components";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { StatusIndicator } from "src/components/StatusIndicator";
import { CollapseRowToggle } from "src/components/formFields/CollapseRowToggle";
import {
  ConfigProductOfferingPageFragment,
  OptionProductOfferingPageFragment,
  OptionTypeProductOfferingPageFragment,
  ProductOfferingDetailFragment,
  ReadyPlanStatus,
  useProductOfferingPageQuery,
  useProductOffering_SaveProductOfferingMutation,
} from "src/generated/graphql-types";
import { useNavigateToRpav } from "src/hooks/useNavigateToRpav";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PublishAggregateVersionModal } from "src/routes/libraries/plan-package/details/components/PublishAggregateVersionModal";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { ProductOfferingParams } from "src/routes/routesDef";
import { fail, pluralize, queryResult } from "src/utils";
import { programDataBathRange, programDataRange } from "src/utils/programData";
import { FilteredProjectsTable } from "./FilteredProjectsTable";
import { BidOutCell } from "./components/BidOutCell";
import { DesignPackageTable } from "./components/DesignPackageTable";
import { UpdatesAvailableButton } from "./components/UpdatesAvailable";

export function ProductOfferingPage() {
  const { developmentId, productOfferingId, productOfferingVersionId } = useParams<ProductOfferingParams>();
  const query = useProductOfferingPageQuery({
    variables: { id: productOfferingId, versionId: productOfferingVersionId },
  });
  const navigateToRpav = useNavigateToRpav();
  const prevProductOffering = usePrevious(query.data?.productOffering);

  /** Slots may be Syncing on the backend. Detect if that's happening, and start polling until the sync is done. */
  useEffect(() => {
    query.data?.productOffering.copyInProgress
      ? // 2.5 sec intervals seems like enough
        query.startPolling(2_500)
      : query.stopPolling();
  }, [query]);

  // If we have created a new draft version, we should navigate to it
  // We have to wait until the sync job finishes to know the id of the rpav, that's why we cant just trigger this after accepting the updates
  useEffect(() => {
    // If we are no longer copying, and we were previously copying, and we have a new draft, navigate to it
    if (
      prevProductOffering?.copyInProgress &&
      !query.data?.productOffering.copyInProgress &&
      prevProductOffering.aggregateDraft?.id !== query.data?.productOffering.aggregateDraft?.id
    ) {
      navigateToRpav(query.data?.productOffering.aggregateDraft?.id);
    }
  }, [query, prevProductOffering, navigateToRpav]);

  return queryResult(query, {
    data: ({ productOffering }) => {
      return (
        <ProductOfferingPageView
          developmentId={developmentId}
          productOffering={productOffering}
          productOfferingVersionId={productOfferingVersionId}
        />
      );
    },
  });
}
type ProductOfferingPageViewProps = {
  productOffering: ProductOfferingDetailFragment;
  productOfferingVersionId: string;
  developmentId: string;
};

function ProductOfferingPageView(props: ProductOfferingPageViewProps) {
  const { productOffering, productOfferingVersionId, developmentId } = props;
  const [showNewOptions, setShowNewOptions] = useState(true);
  const [saveProductOffering] = useProductOffering_SaveProductOfferingMutation();

  const { openModal } = useModal();
  const tid = useTestIds({});
  const types = productOffering.options
    .filter((o) => o.active)
    .map((o) => o.type)
    // Filter out interior design package options because they have a unique display
    .filter((t) => !t.forDesignInterior)
    .unique();
  const optionTypeGroups = productOffering.options.filter((rpo) => rpo.active).groupBy((o) => o.type.id);
  const coverPhotoUrl = productOffering.coverAsset?.previewUrl ?? PLAN_FALLBACK_IMG;
  const viewingLatestDraft = productOffering.version.id === productOffering.aggregateDraft?.id;
  const newOptionsFromUpstreamCount =
    productOffering.aggregateDraft?.optionVersions.filter((o) => o.isNewFromUpstream).length || 0;

  return (
    <div>
      <PageHeader
        title={productOffering.name}
        titleSize="xl2Sb"
        right={
          <>
            {productOffering.copyInProgress && (
              <div css={Css.df.fdr.aic.baseBd.red400.pr4.gap2.$}>
                <div css={Css.maxwPx(40).maxhPx(40).$}>
                  <HbLoadingSpinner iconOnly />
                </div>
                Sync in progress...
              </div>
            )}
            <UpdatesAvailableButton
              copyInProgress={productOffering.copyInProgress}
              updates={productOffering.availableUpdates}
              productOfferingId={productOffering.id}
            />
            <ReadyPlanVersionHeader readyPlan={productOffering} versionId={productOffering.version.id} />
            <Button
              size="md"
              disabled={!productOffering.aggregateDraft || productOffering.copyInProgress}
              label="Publish"
              onClick={() =>
                openModal({
                  content: (
                    <PublishAggregateVersionModal
                      draftAggregateVersion={productOffering.aggregateDraft ?? fail("No draft aggregate version found")}
                      copyText="This update and version notes will be made available to any active projects that reference it."
                      readyPlanName={productOffering.name}
                    />
                  ),
                })
              }
            />
            <ButtonMenu
              trigger={{ icon: "verticalDots" }}
              items={[
                ...(productOfferingVersionId
                  ? [
                      {
                        label: "Apply to Projects",
                        disabled:
                          productOffering.status.code !== ReadyPlanStatus.Active
                            ? "Cannot apply a draft Product Offering"
                            : false,
                        onClick: createProductOfferingApplyToProjectsUrl(
                          productOffering.id,
                          productOfferingVersionId,
                          developmentId,
                        ),
                      },
                    ]
                  : []),
                {
                  label: "Edit Offering",
                  onClick: createProductOfferingEditUrl(productOffering.id, developmentId),
                  disabled:
                    disableBasedOnPotentialOperation(productOffering.canEdit) ||
                    disableBasedOnPotentialOperation(productOffering.canEditOptions),
                },
                {
                  label: "Version History",
                  onClick: createProductOfferingVersionHistoryUrl(productOffering.id, developmentId),
                },
                {
                  label: "Create Configurations",
                  onClick: createProductOfferingConfigUrl({
                    idOrAdd: addEntityParam,
                    // Update url params with metaIds to load the page with selectable options & delta costs
                    productOfferingId: productOffering.id,
                    developmentId: developmentId,
                  }),
                },
                {
                  label: "Compare Configurations",
                  onClick: createProductOfferingConfigsUrl(developmentId, productOffering.id),
                },
              ]}
            />
          </>
        }
        left={
          // Don't show the status field if there has been any versioning
          !(productOffering.aggregateActive || productOffering.aggregateDraft) && (
            <div css={Css.ml("150px").$}>
              <StaticHeaderField
                label="Status"
                value={
                  <div css={Css.df.aic.$}>
                    <StatusIndicator status={productOffering.status.code} />
                    <span css={Css.ml1.$}>{productOffering.status.name}</span>
                  </div>
                }
              ></StaticHeaderField>
            </div>
          )
        }
      />
      <div css={Css.bshBasic.br8.df.oh.bgWhite.$} {...tid.imageAsset}>
        <img src={coverPhotoUrl} alt="Product Offering cover photo" {...tid.coverPhoto} css={Css.w50.ha.$} />

        <div css={Css.mlPx(58).mtPx(79).$}>
          <div css={Css.dg.gtc("minmax(auto, 1fr) minmax(auto, 1fr)").mlPx(-32).$}>
            <div css={Css.py2.px4.bb.bcGray200.$}>
              <StaticHeaderField
                label="Sq Ft"
                value={
                  <div css={Css.xlSb.$}>
                    {programDataRange(productOffering.minProgramData, productOffering.maxProgramData, "sellableSqft")}
                  </div>
                }
              />
            </div>
            <div css={Css.py2.px4.bl.bcGray200.bb.$}>
              <StaticHeaderField
                label="Bid Out"
                value={<div css={Css.xlSb.$}>{`${productOffering.bidOutPercentage}%`}</div>}
              />
            </div>
            <div css={Css.py2.px4.$}>
              <StaticHeaderField
                label="Beds"
                value={
                  <div css={Css.xlSb.$}>
                    {programDataRange(productOffering.minProgramData, productOffering.maxProgramData, "bedrooms")}
                  </div>
                }
              />
            </div>
            <div css={Css.py2.px4.bl.bcGray200.$}>
              <StaticHeaderField
                label="Baths"
                value={
                  <div css={Css.xlSb.$}>
                    {programDataBathRange(productOffering.minProgramData, productOffering.maxProgramData)}
                  </div>
                }
              />
            </div>
          </div>
          <div css={Css.pt2.$}>
            <Switch
              data-testid="publishToUnderwritingToggle"
              label="Publish to Underwriting"
              selected={productOffering.publishToUnderwriting}
              onChange={async (shouldPublish) => {
                await saveProductOffering({
                  variables: {
                    input: {
                      id: productOffering.id,
                      publishToUnderwriting: shouldPublish,
                    },
                  },
                });
              }}
            />
          </div>
          <div css={Css.mt4.$}>
            <div css={Css.gap3.df.fdr.$}>
              <Button
                variant="text"
                onClick={createPlanPackageUrl(productOffering.planPackage.id, "latest")}
                label="Plan Package"
                endAdornment={<Icon icon="chevronRight" inc={3} />}
              />
              {productOffering.exteriorDesignPackage && (
                <Button
                  variant="text"
                  onClick={createDesignPackageUrl(productOffering.exteriorDesignPackage.id, "latest")}
                  label="Exterior Design Package"
                  endAdornment={<Icon icon="chevronRight" inc={3} />}
                />
              )}
            </div>
            <div css={Css.gap3.df.fdr.mt2.$}>
              <Button
                variant="text"
                onClick={createDesignPackageUrl(productOffering.interiorDesignPackage.id, "latest")}
                label="Interior Design Package"
                endAdornment={<Icon icon="chevronRight" inc={3} />}
              />
              {productOffering.aggregateDraft?.tliVersionsCount && viewingLatestDraft ? (
                <Button
                  variant="text"
                  onClick={createProductOfferingScopeUrl(productOffering.id, productOffering.version.id, developmentId)}
                  label={
                    <div css={Css.df.gap("2px").$}>
                      <div css={Css.smBd.$}>Scope</div>
                      <Tag
                        type="caution"
                        text={<div css={Css.xsSb.$}>{productOffering.aggregateDraft?.tliVersionsCount}</div>}
                        preventTooltip
                      />
                    </div>
                  }
                  endAdornment={<Icon icon="chevronRight" inc={3} />}
                />
              ) : (
                <Button
                  variant="text"
                  onClick={createProductOfferingScopeUrl(productOffering.id, productOffering.version.id, developmentId)}
                  label="Scope"
                  endAdornment={<Icon icon="chevronRight" inc={3} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div css={Css.xlSb.mt6.mb1.$}>Offering Summary</div>
      {newOptionsFromUpstreamCount > 0 &&
        viewingLatestDraft &&
        showNewOptions &&
        productOffering.aggregateDraft?.version !== 1 && (
          <div css={Css.mb("12px").$} {...tid.newOptionsBanner}>
            <Banner
              type="warning"
              message={
                <>
                  There {pluralize(newOptionsFromUpstreamCount, "is", "are")} {newOptionsFromUpstreamCount} new{" "}
                  {pluralize(newOptionsFromUpstreamCount, "option")} that you can&nbsp;
                  <Link css={Css.tdu.$} to={createProductOfferingEditUrl(productOffering.id, developmentId)}>
                    add to the Offering
                  </Link>
                </>
              }
              showIcon={false}
              onClose={() => setShowNewOptions(false)}
            ></Banner>
          </div>
        )}
      <div css={Css.df.fdc.gap2.$}>
        <DesignPackageTable
          designPackageCombinations={productOffering.designPackageCombinations}
          developmentId={developmentId}
        />
        {types.sortByKey("order").map((type) => {
          const options = optionTypeGroups[type.id];
          return (
            <div key={type.id} css={Css.br8.oh.$}>
              <OptionTypeGroupTable type={type} options={options} developmentId={developmentId} />
            </div>
          );
        })}
      </div>
      <div css={Css.xlSb.mb1.mt6.$}>Offering Usage</div>
      <div css={Css.w100.bgWhite.br8.$}>
        <FilteredProjectsTable projectFilter={{ readyPlans: [productOffering.id] }} />
      </div>
      <div css={Css.xlSb.mb1.mt6.$}>Saved Configurations</div>
      <div css={Css.w100.bgWhite.br8.oh.$}>
        <ConfigsTable productOffering={productOffering} developmentId={developmentId} />
      </div>
    </div>
  );
}

type ConfigsTableProps = {
  productOffering: ProductOfferingDetailFragment;
  developmentId: string;
};

function ConfigsTable({ productOffering, developmentId }: ConfigsTableProps) {
  const columns = createConfigColumns();
  return (
    <GridTable<ConfigRow>
      columns={columns}
      rowStyles={{
        data: {
          rowLink: ({ id }) =>
            createProductOfferingConfigEditUrl({
              productOfferingConfigId: id,
              // Update url params with metaIds to load the page with selectable options & delta costs
              productOfferingId: productOffering.id,
              developmentId,
            }),
        },
      }}
      rows={simpleDataRows(productOffering.configs)}
      sorting={{
        on: "client",
      }}
      fallbackMessage="There aren't any configurations to display."
    />
  );
}

type ConfigRow = SimpleHeaderAndData<ConfigProductOfferingPageFragment>;

const createConfigColumns = (): GridColumn<ConfigRow>[] => [
  column<ConfigRow>({ header: "Name", data: ({ name }) => name }),
  column<ConfigRow>({
    header: "Created by",
    data: ({ createdBy }) => ({
      content: <Avatar src={createdBy.internalUser?.avatar} name={createdBy.internalUser?.name} showName size="sm" />,
      value: createdBy.internalUser?.name,
    }),
  }),
  dateColumn<ConfigRow>({ header: "Created", data: ({ createdAt }) => dateCell(createdAt) }),
  numericColumn<ConfigRow>({
    header: "Est. Cost",
    data: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
  }),
];

type OptionRow =
  | { kind: "head" }
  | {
      kind: "data";
      data: OptionProductOfferingPageFragment;
      id: string;
    };
type TypeRow = { id: string; kind: "type"; data: OptionTypeProductOfferingPageFragment; children: OptionRow[] };
type OptionTypeRow = TypeRow | OptionRow;

type OptionTypeGroupTableProps = {
  type: OptionTypeProductOfferingPageFragment;
  options: OptionProductOfferingPageFragment[];
  developmentId: string;
};

function OptionTypeGroupTable({ type, options, developmentId }: OptionTypeGroupTableProps) {
  if (options.isEmpty) return null;
  const rows = [
    {
      kind: "type" as const,
      id: type.id,
      data: type,
      children: [
        { kind: "head" as const, id: `head-${type.id}`, data: {} },
        ...options.map((o) => ({ id: o.id, data: o, kind: "data" as const })),
      ],
    },
  ];
  const columns = createOptionColumns(
    developmentId,
    options.some((o) => o.programData?.sellableSqft),
  );
  return (
    <GridTable<OptionTypeRow>
      rowStyles={{
        type: {
          cellCss: Css.py3.$,
        },
        head: {
          cellCss: Css.xsMd.py1.$,
        },
      }}
      columns={columns}
      rows={rows}
    />
  );
}

const createOptionColumns = (developmentId: string, showSqft: boolean): GridColumn<OptionTypeRow>[] => [
  column<OptionTypeRow>({
    head: "Option Name",
    data: ({ name }) => name,
    type: ({ name }, { row }) => ({
      content: (
        <div css={Css.df.gap2.$}>
          <div css={Css.lgSb.$}>{name}</div>
          {/* Don't count the header row in child count */}
          <Chip text={String(row.children.length - 1)} />
        </div>
      ),
      colspan: 3,
    }),
    w: "500px",
  }),
  column<OptionTypeRow>({
    head: "Option Code",
    data: ({ globalOption }) => globalOption?.code || emptyCell,
    type: emptyCell,
  }),
  column<OptionTypeRow>({
    head: showSqft ? "Sq Ft" : emptyCell,
    data: ({ programData }) =>
      showSqft ? (programData?.sellableSqft ? `+${programData.sellableSqft}` : "n/a") : emptyCell,
    type: emptyCell,
  }),
  column<OptionTypeRow>({
    head: "Cost Source",
    data: ({ bidOutPercentage }) => <BidOutCell bidOutPercentage={bidOutPercentage} developmentId={developmentId} />,
    // TODO: add percentage here
    type: (data, { row, api }) => (
      <div css={Css.mla.mr2.df.aic.$}>
        <CollapseRowToggle label="" rowId={row.id} api={api} />,
      </div>
    ),
  }),
];
