import {
  ButtonGroup,
  CollapseToggle,
  Css,
  Filter,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  RowStyles,
  SelectToggle,
  collapseColumn,
  column,
  emptyCell,
} from "@homebound/beam";
import startCase from "lodash/startCase";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { SearchBox, emptyCellDash } from "src/components";
import { CollapseRowToggle } from "src/components/formFields/CollapseRowToggle";
import {
  FinishSchedule_MaterialAttributeValueFragment,
  ProjectFinishSchedule_ProjectItemFragment,
  ProjectItemFilter,
  useProjectFinishScheduleQuery,
} from "src/generated/graphql-types";
import { OptionChips } from "src/routes/libraries/design-catalog/designCatalogAtoms";
import {
  StackedAttributes,
  fixedDimensionsConfiguration,
  getLocationName,
} from "src/routes/libraries/design-catalog/finish-schedules/FinishScheduleTable";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { createChangeEventUrl } from "src/RouteUrls";
import { Remap, foldEnum, sanitizeHtml } from "src/utils";
import { ProjectFinishScheduleFilters, ProjectFinishScheduleParams } from "../ProjectFinishSchedulePage";

enum FinishScheduleGroupBy {
  costCode = "costCode",
  location = "location",
}

type ProjectFinishScheduleFilterDefs = Remap<ProjectFinishScheduleFilters, (key: string) => Filter<string[]>>;

type ProjectFinishScheduleTableProps = {
  filter: ProjectFinishScheduleFilters;
  setFilter: (filter: ProjectFinishScheduleFilters) => void;
  filterDefs: ProjectFinishScheduleFilterDefs;
};

export function ProjectFinishScheduleTable(props: ProjectFinishScheduleTableProps) {
  const { filter, setFilter, filterDefs } = props;
  const { projectId } = useParams<ProjectFinishScheduleParams>();
  const [groupBy, setGroupBy] = useState(FinishScheduleGroupBy.costCode);
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

  const query = useProjectFinishScheduleQuery({
    variables: { projectId, filter: mapToFilter(filter, searchFilter) },
  });

  const rows = useMemo(() => createRows(query.data?.project.stage?.projectItems ?? [], groupBy), [query, groupBy]);
  const columns = useMemo(() => createColumns(projectId), [projectId]);

  return (
    <div css={Css.bcGray200.$}>
      <div css={Css.py3.bb.bw2.bcGray300.df.jcsb.$}>
        <Filters<ProjectFinishScheduleFilters> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <div css={Css.wPx(600).df.gap2.$}>
          <SearchBox fullWidth placeholder="Search by cost code, item code, product..." onSearch={setSearchFilter} />
          <ButtonGroup
            buttons={[
              {
                text: "Cost Code",
                active: groupBy === FinishScheduleGroupBy.costCode,
                onClick: () => setGroupBy(FinishScheduleGroupBy.costCode),
              },
              {
                text: "Room",
                active: groupBy === FinishScheduleGroupBy.location,
                onClick: () => setGroupBy(FinishScheduleGroupBy.location),
              },
            ]}
          />
        </div>
      </div>
      <div css={Css.pbPx(1).bgWhite.$}>
        <GridTable rows={rows} columns={columns} style={{ vAlign: "top" }} rowStyles={rowStyles} />
      </div>
    </div>
  );
}

type ProjectFinishScheduleParentRow = {
  id: string;
  kind: "parentGroup";
  data: string;
};

type ProjectFinishScheduleChildRow = {
  id: string;
  kind: "childGroup";
  data: string;
};

type ProjectFinishScheduleRow = {
  id: string;
  kind: "data";
  data: ProjectFinishSchedule_ProjectItemFragment;
  dynamicAttributes: FinishSchedule_MaterialAttributeValueFragment[][];
};

type Row = ProjectFinishScheduleChildRow | ProjectFinishScheduleParentRow | ProjectFinishScheduleRow;

const rowStyles: RowStyles<Row> = {
  parentGroup: { cellCss: Css.bgGray100.py2.$ },
  // reducing the width of the last column due to the row margin pushing the last column out of table view
  data: {
    rowCss: Css.m2.boxShadow("0 4px 8px rgba(0, 0, 0, 0.2)").addIn("& > div:nth-of-type(11)", Css.wPx(38).$).$,
    cellCss(row) {
      return row.data.lastAcceptedChangeEventLineItem ? Css.bgYellow50.$ : {};
    },
  },
};

function createRows(
  projectItems: ProjectFinishSchedule_ProjectItemFragment[],
  groupByKey: FinishScheduleGroupBy,
): GridDataRow<Row>[] {
  return foldEnum(groupByKey, {
    costCode: () => groupByCostCode(projectItems),
    location: () => groupByLocation(projectItems),
  });
}

function groupByCostCode(projectItems: ProjectFinishSchedule_ProjectItemFragment[]): GridDataRow<Row>[] {
  return (
    projectItems
      // First, we sort and group by cost code so the parent groups are sorted by cost code
      .sortBy((pi) => pi.item.costCode.name)
      .groupByObject((pi) => pi.item.costCode)
      .map(([costCode, costCodePis]) => ({
        id: costCode.id,
        kind: "parentGroup" as const,
        data: costCode.name,
        // Then we sort and group by location so the child groups are sorted by location
        children: Object.entries(sortAndGroupProjectItemsByLocation(costCodePis)).map(([locationId, locationPis]) => {
          const [pi] = locationPis;
          return {
            id: costCode.id + locationId,
            kind: "childGroup" as const,
            data: getLocationName(pi.location),
            children: locationPis
              // Finally, we sort the data rows by item name to ensure they appear in order
              .sortBy((pi) => pi.item.name)
              .map((pi) => ({
                id: pi.id,
                kind: "data" as const,
                data: pi,
                dynamicAttributes: getDynamicAttributes(pi),
              })),
          };
        }),
      }))
  );
}

function groupByLocation(projectItems: ProjectFinishSchedule_ProjectItemFragment[]) {
  // First, we sort and group by location so the parent groups are sorted by location
  return Object.entries(sortAndGroupProjectItemsByLocation(projectItems)).map(([locationId, locationPis]) => {
    const [pi] = locationPis;
    return {
      id: locationId,
      kind: "parentGroup" as const,
      data: getLocationName(pi.location),
      children: locationPis
        // Then we sort and group by cost code so the child groups are sorted by cost code
        .sortBy((pi) => pi.item.costCode.name)
        .groupByObject((pi) => pi.item.costCode)
        .map(([costCode, costCodePis]) => ({
          id: costCode.id + locationId,
          kind: "childGroup" as const,
          data: costCode.name,
          children: costCodePis
            // Finally, we sort the data rows by item name to ensure they appear in order
            .sortBy((pi) => pi.item.name)
            .map((pi) => ({
              id: pi.id,
              kind: "data" as const,
              data: pi,
              dynamicAttributes: getDynamicAttributes(pi),
            })),
        })),
    };
  });
}

function sortAndGroupProjectItemsByLocation(projectItems: ProjectFinishSchedule_ProjectItemFragment[]) {
  return projectItems
    .sortBy((pi) => getLocationName(pi.location))
    .groupBy((pi) => {
      const { room, feature } = pi.location;
      // We try to group by feature and room, if feature is not available, we group by room
      return room && feature ? `${room.id}${feature.id}` : (room?.id ?? "none");
    });
}

export function getDynamicAttributes(projectItem: ProjectFinishSchedule_ProjectItemFragment) {
  // Get the current projectItem's attribute values
  const attributeValues = projectItem.bidItem?.parentMaterialVariant?.materialAttributeValues ?? [];
  // Get the fixed attributes
  const fixedDimensionNames = Object.entries(fixedDimensionsConfiguration).flatMap(([_, attributes]) => attributes);
  // Try to fill out 4 dynamic attribute spots
  // Filter out the fixed attributes and then destructure the first four attribute values
  const [va1, va2, va3, va4] = attributeValues.filter(
    (av) => !fixedDimensionNames.includes(av.dimension.name.toLowerCase()),
  );
  // Then we split values into a two-dimensional array to match column 4 (val1 & val2) and column 5 (val3 & val4)
  return [
    [va1, va2],
    [va3, va4],
  ];
}

function createColumns(projectId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      parentGroup: (parentGroupName) => ({
        content: parentGroupName,
        colspan: 4,
        alignment: "left",
        css: Css.xlMd.$,
      }),
      childGroup: (childGroupName) => ({
        content: childGroupName,
        colspan: 4,
        alignment: "left",
        css: Css.smMd.$,
      }),
      data: (pi) => {
        const lastChangeEvent = pi.lastAcceptedChangeEventLineItem?.changeEvent;
        return (
          <div css={Css.df.fdc.$}>
            <div css={Css.df.gap2.$}>
              <div css={Css.hPx(100).wPx(100).bgWhite.df.aic.jcc.$}>
                <img
                  css={Css.maxw100.maxh100.ma.p1.$}
                  src={pi.bidItem?.parentMaterialVariant?.featuredImage?.asset?.previewUrl || PRODUCT_FALLBACK_IMG_URL}
                  alt="product"
                />
              </div>
              <div css={Css.df.fdc.gap2.$}>
                <div>
                  <div data-testid="productType" css={Css.smMd.df.fww.$}>
                    {startCase(pi.slot.name || pi.item.name)}
                  </div>
                  <div css={Css.gray600.$}>PRODUCT TYPE</div>
                </div>
                <div data-testid="productCode">{pi.bidItem?.parentMaterialVariant?.code}</div>
                <div css={Css.df.fdr.aic.gap1.w100.oys.$}>
                  {pi.configuredOptions
                    .groupByObject((rpo) => rpo.optionGroup)
                    .sortBy(([rpog, _]) => rpog.order)
                    .map(([rpog, rpos]) => (
                      <OptionChips key={rpog.id} rpos={rpos} />
                    ))}
                </div>
              </div>
            </div>
            {lastChangeEvent && (
              <div css={Css.df.gap1.$}>
                <span>Updated: {lastChangeEvent.updatedAt.toLocaleDateString()}</span>
                <span data-testid="changeEventLink">
                  <Link
                    to={createChangeEventUrl(projectId, lastChangeEvent.id)}
                  >{`CE #${lastChangeEvent.identifier} - ${lastChangeEvent.name}`}</Link>
                </span>
              </div>
            )}
          </div>
        );
      },
      w: "325px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: () => (
        <div css={Css.h100.ais.$}>
          <Icon icon="link" inc={4} />
        </div>
      ),
      w: "40px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (pi) => (
        <StackedAttributes
          topAttribute={{
            value: pi.bidItem?.parentMaterialVariant?.listing.name ?? emptyCellDash,
            label: "PRODUCT NAME",
          }}
          bottomAttribute={{
            value: pi.bidItem?.parentMaterialVariant?.listing.brand?.name ?? emptyCellDash,
            label: "BRAND",
          }}
        />
      ),
      w: "208px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (pi) => (
        <div css={Css.h100.gap2.$}>
          <div>
            <div data-testid="skuModel" css={Css.smMd.$}>
              {pi.bidItem?.parentMaterialVariant?.modelNumber ?? emptyCellDash}
            </div>
            <div css={Css.gray600.$}>SKU/MODEL #</div>
          </div>
        </div>
      ),
    }),
    // 3 "fixed attribute" columns.
    ...Object.entries(fixedDimensionsConfiguration).map(([_, [topDimName, bottomDimName]]) =>
      column<Row>({
        parentGroup: (c) => emptyCell,
        childGroup: (l) => emptyCell,
        data: (pi) => {
          const topMav = pi.bidItem?.parentMaterialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === topDimName?.toLowerCase(),
          );
          const bottomMav = pi.bidItem?.parentMaterialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === bottomDimName?.toLowerCase(),
          );
          return (
            <StackedAttributes
              topAttribute={{
                value: topMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(topMav) ?? topDimName,
              }}
              bottomAttribute={{
                value: bottomMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(bottomMav) ?? bottomDimName,
              }}
            />
          );
        },
      }),
    ),
    // We try to render up to 4 attribute fields in addition to the 6 fixed attributes
    // 'row.dynamicAttributes' is a 2D array of FinishSchedule_MaterialAttributeValueFragment[][]
    // i.e. [[attrValue1, attrValue2], [attrValue3, attrValue4]]
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (_, { row }) => {
        const columnOne = row.dynamicAttributes.first ?? [];
        const [topMav, bottomMav] = columnOne;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (_, { row }) => {
        const columnTwo = row.dynamicAttributes.last ?? [];
        const [topMav, bottomMav] = columnTwo;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (pi) => (
        <div data-testid="designNotes" dangerouslySetInnerHTML={{ __html: sanitizeHtml(pi.specifications ?? "") }} />
      ),
      w: "210px",
    }),
    collapseColumn<Row>({
      parentGroup: (groupName, { row, api }) => ({
        content: () => <CollapseRowToggle label={`Collapse ${groupName}`} rowId={row.id} api={api} />,
        alignment: "right",
      }),
      childGroup: (c, { row }) => <CollapseToggle row={row} />,
      data: (_, { row }) => <SelectToggle id={row.id} />,
      w: "70px",
    }),
  ];
}

export function maybeAddUnitSuffix(mav: FinishSchedule_MaterialAttributeValueFragment | undefined) {
  const dim = mav?.dimension;
  return dim?.unitOfMeasure ? `${dim.name} (${dim.unitOfMeasure.abbreviation})` : dim?.name;
}

function mapToFilter(filter: ProjectFinishScheduleFilters, search: string | undefined) {
  const { costCodes, locationInPath } = filter;
  return {
    costCode: costCodes,
    locationInPath,
    ...(search && { search }),
    isProduct: true,
  } satisfies ProjectItemFilter;
}
