import { defaultTestId, Filter, IconButton, Palette, TestIds } from "@homebound/beam";

type IconFilterProps<V> = {
  label?: string;
  onValue?: V | undefined;
  offValue?: V | undefined;
  defaultValue?: V | undefined;
};

/** Prototype IconFilter, should move to Beam. */
export function iconFilter<V = boolean>(props: IconFilterProps<V>): (key: string) => Filter<V> {
  return (key) => new IconFilter(key, { defaultValue: props.offValue, ...props });
}

class IconFilter<V> implements Filter<V> {
  constructor(
    private key: string,
    private props: IconFilterProps<V>,
  ) {}

  render(value: V | undefined, setValue: (value: V | undefined) => void, tid: TestIds, inModal: boolean): JSX.Element {
    const { defaultValue, onValue = true as any as V, offValue = undefined, ...props } = this.props;
    return (
      <IconButton
        icon="star"
        color={value ? Palette.Gray700 : Palette.Gray300}
        onClick={() => {
          // Toggle the value
          setValue(value ? offValue : onValue);
        }}
        {...this.testId(tid)}
      />
    );
  }

  get hideLabelInModal() {
    return true;
  }

  get label(): string {
    return this.props.label || "Icon";
  }

  get defaultValue() {
    return this.props.defaultValue;
  }

  protected testId(tid: TestIds): object {
    return tid[defaultTestId(this.label)];
  }
}
