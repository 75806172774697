import { Chip, Css, Switch, Tag, useTestIds } from "@homebound/beam";
import { useState } from "react";
import { AccordionCard } from "src/components/AccordionCard";
import { PlanPackageOverview_ReadyPlanOptionFragment } from "src/generated/graphql-types";
import { ElevationDetails } from "src/routes/libraries/plan-package/details/plan-overview/components/ElevationDetails";
import { SectionTitle } from "src/routes/libraries/plan-package/details/plan-overview/components/SectionTitle";
import { OptionsDataStore } from "src/routes/libraries/plan-package/details/plan-overview/utils";
import { pluralize } from "src/utils";
import { StoreApi, UseBoundStore } from "zustand";

type ElevationsListProps = {
  elevations: PlanPackageOverview_ReadyPlanOptionFragment[];
  useOptions: UseBoundStore<StoreApi<OptionsDataStore>>;
};

export function ElevationsList({ useOptions, elevations }: ElevationsListProps) {
  const [showOptions, setShowOptions] = useState(false);
  const getNumberOfItemsByOption = useOptions((state) => state.getNumberOfItemsByOption);
  const tid = useTestIds({}, "elevationsList");

  return (
    <>
      <SectionTitle
        title={`Elevations${elevations.length ? ` (${elevations.length})` : ""}`}
        right={
          <Switch label="Show Option Data" selected={showOptions} onChange={setShowOptions} disabled="Coming soon..." />
        }
      />

      <div css={Css.df.fdc.gap2.$}>
        {[...elevations]
          .sort((a, b) => a.order - b.order)
          .map((option, idx) => {
            const numItems = getNumberOfItemsByOption(option.id);
            return (
              <AccordionCard
                {...tid.elevationCard}
                key={option.id}
                defaultExpanded={idx === 0}
                title={
                  <div css={Css.df.aic.jcsb.gap2.$}>
                    <div css={Css.df.gap2.aic.$}>
                      <div css={Css.lgSb.$}>{option.name}</div>
                      <div css={Css.lg.$}>{option.code}</div>
                      {idx === 0 && <Chip text="Base" />}
                    </div>
                    <Tag text={`${numItems} ${pluralize(numItems, "item")}`} type="warning" />
                  </div>
                }
              >
                <ElevationDetails elevationId={option.id} showOptions={showOptions} />
              </AccordionCard>
            );
          })}
      </div>
    </>
  );
}
