import { Button, Css, TextField, useComputed, useModal } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { useState } from "react";
import { useHistory } from "react-router";
import { createConstraintItemCatalogUrl } from "src/RouteUrls";
import { Maybe, useSaveGlobalPlanConstraintItemMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize } from "src/utils";

type AddConstraintItemModalProps = {
  constraintType: ConstraintModalConfig;
  fieldState?: FieldState<Maybe<string[]>>;
};

export enum ConstraintModalConfig {
  Allowance,
  Constraint,
  ConstraintItem,
}
export type ConstraintTypeFormKey = "constraints" | "allowances";

type ConstraintItem = {
  header: string;
  title: string;
  body: string;
  shouldRedirect: boolean;
};

export function AddConstraintItemModal({ constraintType, fieldState }: AddConstraintItemModalProps) {
  const [saveGlobalPlanConstraintItem] = useSaveGlobalPlanConstraintItemMutation({});
  const [name, setName] = useState<string | undefined>("");
  const { closeModal } = useModal();
  const history = useHistory();

  const { header, title, body, shouldRedirect }: ConstraintItem = {
    [ConstraintModalConfig.Constraint]: {
      header: "Add a Constraint",
      title: "Constraint",
      body: `What “buffer” must have occurred before this task can start? Prior to starting this task the following must be
    done.`,
      shouldRedirect: true,
    },
    [ConstraintModalConfig.Allowance]: {
      header: "Add an Allowance",
      title: "Allowance",
      body: "What buffer is triggered on completion of this task.",
      shouldRedirect: true,
    },
    // Creating a "generic" constraint item config - not associated to an allowance or constraint yet
    [ConstraintModalConfig.ConstraintItem]: {
      header: "Add a Constraint",
      title: "Constraint",
      body: "",
      shouldRedirect: false,
    },
  }[constraintType];

  const currentValues = useComputed(() => fieldState?.value ?? [], [fieldState]);

  const onSubmit = async () => {
    const result = await saveGlobalPlanConstraintItem({
      variables: { input: { name } },
      refetchQueries: [
        "TaskCatalogFormSchedulingDropdown",
        "ConstraintAndAllowanceCatalogPage",
        "MilestoneCatalogFormDropdown",
      ],
    });
    const itemId = result.data?.saveGlobalPlanConstraintItem.globalPlanConstraintItem.id;
    // we only want to auto-select constraints/allowances if we use the dropdown from the form
    if (!fieldState || !itemId) return;

    // Then merge the newly created value in with any existing values
    fieldState.set([...currentValues, itemId]);
  };

  return (
    <ConfirmationModal
      title={header}
      onConfirmAction={onSubmit}
      confirmationMessage={
        <>
          {" "}
          <div css={Css.mb3.$}>{body}</div>
          <TextField label={`Add a new ${title}`} onChange={(val) => setName(val)} value={name} />
          <div css={Css.mt2.$}>
            <Button
              label={`Manage ${pluralize(2, title)}`}
              variant="tertiary"
              onClick={() => {
                // only redirect to the constraint/allowance catalog if we're on the dropdown form
                shouldRedirect && history.push(createConstraintItemCatalogUrl());
                closeModal();
              }}
            />
          </div>
        </>
      }
      label={`Add ${title}`}
    />
  );
}
