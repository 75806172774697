export enum BidPackageStep {
  PACKAGE_DETAILS = "packageDetails",
  REVIEW_SCOPE = "reviewScope",
  SELECT_BIDDERS = "selectBidders",
  ASSIGN_TRADE_CONTACTS = "assignTradeContacts",
  REVIEW_AND_SEND = "reviewAndSend",
}

export enum BidPackagePublishStep {
  UPDATE_BID_INFO = "updateBidInfo",
  ADD_INVITATION_TEXT = "addInvitationText",
  REVIEW_AND_PUBLISH = "reviewAndPublish",
}
