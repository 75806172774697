import {
  ButtonMenu,
  Css,
  GridDataRow,
  GridTable,
  GridTableApi,
  IconButton,
  ModalProps,
  RowStyles,
  ScrollableContent,
  actionColumn,
  collapseColumn,
  column,
  emptyCell,
  selectColumn,
  simpleDataRows,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { History } from "history";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { createTaskCatalogFolderUrl, createTaskCatalogFormUrl } from "src/RouteUrls";
import { LoadingTable } from "src/components/LoadingTable";
import {
  GlobalPlanTaskFilter,
  TaskCatalogTable_GlobalPlanTagFragment,
  TaskCatalogTable_GlobalPlanTaskFragment,
  useTaskCatalogTableMetadataQuery,
  useTaskCatalogTableQuery,
} from "src/generated/graphql-types";
import { CustomTaskCatalogPageFilter, TaskCatalogGroupBy } from "./TaskCatalogPage";
import { ArchiveCatalogTaskModal } from "./components/ArchiveCatalogTaskModal";

type TaskCatalogTableProps = {
  filter: CustomTaskCatalogPageFilter;
  groupBy: TaskCatalogGroupBy;
  api: GridTableApi<Row>;
};

export function TaskCatalogTable({ filter, groupBy, api }: TaskCatalogTableProps) {
  const { openModal } = useModal();
  const { data: metadata, loading: loadingMetadata } = useTaskCatalogTableMetadataQuery();
  const query = useTaskCatalogTableQuery({
    variables: {
      filter: mapToFilter(filter),
      page: {
        offset: 0,
        limit: 100,
      },
    },
  });
  const { data, loading: loadingData } = query;
  const loading = loadingMetadata || loadingData;
  const history = useHistory();

  const rows = useMemo(
    () => createRows(data?.globalPlanTasks.entities ?? [], groupBy, metadata?.globalPlanTags ?? []),
    [data?.globalPlanTasks.entities, groupBy, metadata?.globalPlanTags],
  );

  const columns = useMemo(() => createColumns(openModal, history), [openModal, history]);

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.globalPlanTasks.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data?.globalPlanTasks.entities.length,
            limit: 100,
          },
        },
      });
    }
  }, [query]);

  if (loading) {
    return <LoadingTable />;
  }

  return (
    <ScrollableContent virtualized>
      <GridTable
        columns={columns}
        rows={rows}
        as="virtual"
        infiniteScroll={{ onEndReached: maybeFetchMore }}
        rowStyles={rowStyles}
        api={api}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type FolderRow = { kind: "folder"; data: { name: string; folderType: "tag" | "team" } };
type DataRow = { kind: "data"; data: TaskCatalogTable_GlobalPlanTaskFragment };

export type Row = HeaderRow | FolderRow | DataRow;

function createColumns(openModal: (props: ModalProps) => void, history: History) {
  return [
    collapseColumn<Row>(),
    selectColumn<Row>({
      header: emptyCell,
    }),
    column<Row>({
      header: "Task Name",
      folder: ({ name }) => <span css={Css.smBd.$}>{name}</span>,
      data: ({ name }) => <span css={Css.sm.$}>{name}</span>,
      w: 3,
    }),
    column<Row>({
      header: "Description",
      folder: emptyCell,
      data: ({ description }) => <span css={Css.sm.$}>{description}</span>,
      w: 3,
    }),
    column<Row>({
      header: "Variants",
      folder: emptyCell,
      data: ({ variants }) => <span css={Css.sm.if(variants.isEmpty).gray400.$}>{variants.length}</span>,
      w: 1,
    }),
    column<Row>({
      header: "Times Used",
      folder: emptyCell,
      data: ({ numberOfTimesUsed }) => <span css={Css.sm.$}>{numberOfTimesUsed}</span>,
      w: 1,
    }),
    column<Row>({
      header: "Constraints",
      folder: emptyCell,
      data: ({ constraints }) => <span css={Css.sm.$}>{constraints.length}</span>,
      w: 1,
    }),
    column<Row>({
      header: "Allowances",
      folder: emptyCell,
      data: ({ allowances }) => <span css={Css.sm.$}>{allowances.length}</span>,
      w: 1,
    }),
    // TODO: implement
    // column<Row>({
    //   header: "Cost Variance",
    //   folder: emptyCell,
    //   // TODO: implement
    //   data: emptyCell,
    // }),
    // column<Row>({
    //   header: "Duration Variance",
    //   folder: emptyCell,
    //   data: emptyCell,
    // }),
    actionColumn<Row>({
      header: emptyCell,
      folder: (data, { row }) => (
        <IconButton
          icon="chevronRight"
          onClick={() => {
            history.push(createTaskCatalogFolderUrl(row.id));
          }}
        />
      ),
      data: (row) => (
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          items={[
            {
              label: "Make a Copy",
              onClick: createTaskCatalogFormUrl("add", row.id),
            },
            {
              label: "Archive",
              onClick: () =>
                openModal({
                  content: <ArchiveCatalogTaskModal catalogTaskName={row.name} catalogTaskId={row.id} />,
                }),
            },
          ]}
        />
      ),
      w: "48px",
    }),
  ];
}

function createRows(
  data: TaskCatalogTable_GlobalPlanTaskFragment[],
  groupBy: TaskCatalogGroupBy,
  globalPlanTags: TaskCatalogTable_GlobalPlanTagFragment[],
) {
  switch (groupBy) {
    case TaskCatalogGroupBy.None:
      return simpleDataRows(data);
    case TaskCatalogGroupBy.Team:
      return groupByTeam(data);
    case TaskCatalogGroupBy.Tag:
      return groupByTag(data, globalPlanTags);
  }
}

function groupByTeam(data: TaskCatalogTable_GlobalPlanTaskFragment[]) {
  return [
    simpleHeader,
    ...data
      .groupByObject((task) => task.businessFunctionType)
      .map(([team, tasks]) => {
        return {
          kind: "folder" as const,
          id: team.code,
          data: { name: team.name ?? "Unknown", folderType: "team" as const },
          children: tasks.map((task) => ({ kind: "data" as const, data: task, id: `${team.code}-${task.id}` })),
        };
      }),
  ];
}

const rowStyles: RowStyles<Row> = {
  data: {
    rowLink: (row) => createTaskCatalogFormUrl(row.data.id),
  },
};

function mapToFilter(filter: GlobalPlanTaskFilter): GlobalPlanTaskFilter {
  const { archived, ...others } = filter;
  return {
    ...others,
    /* intentionally leaving archived as undefined when "showing archived tasks", so we can show all tasks.
    Default is not showing archived tasks
    */
    archived: archived ? undefined : false,
  };
}

function groupByTag(
  data: TaskCatalogTable_GlobalPlanTaskFragment[],
  globalPlanTags: TaskCatalogTable_GlobalPlanTagFragment[],
) {
  // group tasks by their tag id
  const groupedTasks = data.reduce<Record<string, GridDataRow<Row>[]>>(
    (groupedTasks, task) => {
      if (task.tags.isEmpty) {
        // If the task has no tags, we put it in the "No Tags" group
        return {
          ...groupedTasks,
          "no-tags": [
            ...(groupedTasks["no-tags"] || []),
            { kind: "data" as const, id: `no-tags-${task.id}`, data: task },
          ],
        };
      }
      task.tags.forEach((tag) => {
        const tagId = tag.id;
        groupedTasks[tagId] = [
          ...(groupedTasks[tagId] || []),
          { kind: "data" as const, id: `${tagId}-${task.id}`, data: task },
        ];
      });
      return groupedTasks;
    },
    globalPlanTags.reduce<Record<string, GridDataRow<Row>[]>>((acc, tag) => ({ ...acc, [tag.id]: [] }), {
      "no-tags": [],
    }),
  );

  return [
    simpleHeader,
    ...Object.entries(groupedTasks)
      // No Tags should always be last
      .sort(([a], [b]) => (a === "no-tags" ? 1 : b === "no-tags" ? -1 : a.localeCompare(b)))
      .map(([tagId, tasks]) => ({
        kind: "folder" as const,
        id: tagId ?? "no-tags",
        data: { name: globalPlanTags.find(({ id }) => id === tagId)?.name ?? "No Tags", folderType: "tag" as const },
        children: tasks,
      })),
  ];
}
