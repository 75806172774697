import { SelectField, SelectFieldProps } from "@homebound/beam";
import { GlobalPlanTaskFilter, NamedFragment, useGlobalPlanTaskSelectQuery } from "src/generated/graphql-types";

type GlobalPlanTaskSelectFieldProps = Omit<
  SelectFieldProps<NamedFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  filter?: GlobalPlanTaskFilter;
};

export function GlobalPlanTaskSelectField(props: GlobalPlanTaskSelectFieldProps) {
  const { placeholder = "Select a Task", filter = {}, ...otherProps } = props;
  const { data, loading } = useGlobalPlanTaskSelectQuery({
    fetchPolicy: "cache-first",
    variables: { filter: filter },
  });

  return (
    <SelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={data?.globalPlanTasks.entities ?? []}
    />
  );
}
