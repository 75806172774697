import { Accordion, Css } from "@homebound/beam";
import {
  VersionDetailsCard_ReadyPlanOptionVersionFragment,
  VersionDetailsCard_VersionFragment,
} from "src/generated/graphql-types";
import { ConfigChanges } from "src/routes/my-blueprint/activity-feed/events/ProjectReadyPlanConfig/components";

export type OptionsChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

export function OptionsChanges(props: OptionsChangesProps) {
  const { version } = props;
  const { optionVersions, finalReadyPlanOptionVersions } = version;

  const previousRposState = finalReadyPlanOptionVersions.groupBy(({ identity }) => identity.id);

  const [optionsAddedOrRemoved, optionsWithChanged] = optionVersions.partition((rpov) => {
    const [previousState] = previousRposState[rpov.identity.id] ?? [];
    return !previousState || previousState.active !== rpov.active;
  });

  const [optionsAdded, optionsRemoved] = optionsAddedOrRemoved
    .partition((rpov) => rpov.active)
    .map((rpov) =>
      rpov.map(({ identity }) => ({ ...identity, name: identity.displayName })).groupBy((o) => o.type.name),
    );

  const optionsChanged = optionsWithChanged.filter(
    (rpov) =>
      isConfigChanged(previousRposState, rpov, "optionConflicts") ||
      isConfigChanged(previousRposState, rpov, "optionPrerequisites") ||
      isConfigChanged(previousRposState, rpov, "optionDefaultsIf"),
  );

  const hasChanges =
    Object.keys(optionsAdded).nonEmpty || Object.keys(optionsRemoved).nonEmpty || optionsChanged.nonEmpty;

  if (!hasChanges) {
    return <></>;
  }

  return (
    <Accordion
      compact
      xss={Css.py0.$}
      title="Options"
      children={
        <>
          <ConfigChanges optionsAdded={optionsAdded} optionsRemoved={optionsRemoved} fieldsChanged={[]} />
          {optionsChanged.map((rpov) => {
            const configChanges = [
              ...(isConfigChanged(previousRposState, rpov, "optionConflicts")
                ? [
                    {
                      attributeName: "Conflicts",
                      newValue: rpov.optionConflicts?.map((rpo) => rpo.displayName).join(", ") || "No Conflicts",
                      oldValue: (previousRposState[rpov.identity.id]?.[0]?.optionConflicts || [])
                        .map((rpo) => rpo.displayName)
                        .join(", "),
                    },
                  ]
                : []),
              ...(isConfigChanged(previousRposState, rpov, "optionPrerequisites")
                ? [
                    {
                      attributeName: "Prerequisites",
                      newValue:
                        rpov.optionPrerequisites?.map((rpo) => rpo.displayName).join(", ") || "No Preqrequisites",
                      oldValue: (previousRposState[rpov.identity.id]?.[0]?.optionPrerequisites || [])
                        .map((rpo) => rpo.displayName)
                        .join(", "),
                    },
                  ]
                : []),
              ...(isConfigChanged(previousRposState, rpov, "optionDefaultsIf")
                ? [
                    {
                      attributeName: "Defaults If",
                      newValue:
                        rpov.optionDefaultsIf?.map((rpo) => rpo.displayName).join(", ") || "No longer a default",
                      oldValue: (previousRposState[rpov.identity.id]?.[0]?.optionDefaultsIf || [])
                        .map((rpo) => rpo.displayName)
                        .join(", "),
                    },
                  ]
                : []),
            ];

            return (
              <Accordion
                key={rpov.id}
                compact
                xss={Css.py0.$}
                defaultExpanded
                title={rpov.identity.displayName}
                children={<ConfigChanges optionsAdded={{}} optionsRemoved={{}} fieldsChanged={configChanges} />}
              />
            );
          })}
        </>
      }
    />
  );
}

/** This function will check the requested relation and return true if the "new" value is different as the "old" value */
function isConfigChanged(
  previousRposState: Record<string, VersionDetailsCard_ReadyPlanOptionVersionFragment[]>,
  rpov: VersionDetailsCard_ReadyPlanOptionVersionFragment,
  relationField: "optionConflicts" | "optionPrerequisites" | "optionDefaultsIf",
) {
  const [previousState] = previousRposState[rpov.identity.id] ?? [];
  const oldRpoIds = previousState[relationField]?.map((rpo) => rpo.id).sort() ?? [];
  const newRpoIds = rpov[relationField]?.map((rpo) => rpo.id).sort() ?? [];
  const isConfigUnchanged = oldRpoIds.length === newRpoIds.length && oldRpoIds.every((id, i) => id === newRpoIds[i]);
  return !isConfigUnchanged;
}
