import { Global } from "@emotion/react";
import { Css, GridColumn, GridTable, px, simpleDataRows, SimpleHeaderAndData, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { emptyCellDash, Price, priceCell } from "src/components";
import {
  BidContractPdfLineItemsFragment,
  DevelopmentContractPdfFragment,
  PandaDocRole,
  useDevelopmentContractPdfQuery,
} from "src/generated/graphql-types";
import { Signature } from "src/routes/pdfs/components/PandaDocField";
import { fail, queryResult, sortBy } from "src/utils";
import { formatWithShortYear } from "src/utils/dates";
import { StringParam, useQueryParam } from "use-query-params";
import { CommitmentAddresses } from "../components/CommitmentAddresses";
import { HideFromPrint } from "../components/HideFromPrint";
import { ObnoxiousText } from "../components/ObnoxiousText";
import { Section } from "../components/Section";

export function DevelopmentContractPdf() {
  const [bidContractId] = useQueryParam("bidContractId", StringParam);
  const query = useDevelopmentContractPdfQuery({ variables: { bidContractId: bidContractId! } });

  return queryResult(query, ({ bidContract }) => <ContractPdf bidContract={bidContract!} />);
}

type DevelopmentContractPdfProp = {
  bidContract: DevelopmentContractPdfFragment;
};

function ContractPdf({ bidContract }: DevelopmentContractPdfProp) {
  const tid = useTestIds({});
  const {
    parent: {
      market: { name: marketName },
    },
  } = bidContract;

  const revision =
    // Verify if there's a unsigned revision or a signed revision via a price agreement
    bidContract.unsignedRevision
      ? bidContract.unsignedRevision
      : bidContract.latestSignedRevision && !bidContract.latestSignedRevision.pandaDoc
        ? bidContract.latestSignedRevision
        : undefined;

  if (!revision) {
    fail("no revision found");
  }
  const [templates, bidItems] = useMemo(() => revision.lineItems.partition((li) => li.ofIti), [revision.lineItems]);

  const hbSignatory = revision.pandaDocRecipients.find((pdr) => pdr.role === PandaDocRole.HeadOfConstruction);
  const tpSignatory = revision.pandaDocRecipients.find(
    (pdr) => pdr.role === PandaDocRole.TradePartner,
  )?.tradePartnerContact;
  if (!hbSignatory) {
    return <div>Unable to find valid Homebound signatory</div>;
  }
  if (!tpSignatory || !tpSignatory.address) {
    return <div>Unable to find valid Trade Partner signatory</div>;
  }

  return (
    <>
      <Global
        styles={{
          "@page": { size: "landscape" },
          ul: Css.ml1.my2.add({ listStyleType: "disc" }).$,
          li: Css.my2.$,
        }}
      />
      <img src="/wordmark.svg" alt="Homebound" css={Css.h(px(40)).$} />

      <div {...tid.scheduleTitle} css={Css.ttu.lgSb.tac.mt2.$}>
        SCHEDULE B
      </div>
      <div {...tid.contractTitle} css={Css.ttu.lgSb.tac.mt2.$}>
        Pricing Agreement
      </div>

      <div css={Css.df.fdr.bgGray600.white.ttu.mt2.$}>
        <div css={Css.f1.p1.$}>Prime Contractor ("Homebound")</div>
        <div css={Css.f1.p1.$}>Trade Partner Representative</div>
        <div css={Css.f1.p1.$}>Development</div>
      </div>

      <CommitmentAddresses tradePartner={tpSignatory.tradePartner} signatoryContact={tpSignatory}>
        <div {...tid.marketName} css={Css.f1.p1.$}>
          {marketName}
        </div>
      </CommitmentAddresses>
      {/** Todo: Add bidcontract effective Dates */}
      <div
        css={{
          ...Css.dig.gtc("auto auto auto auto").cgPx(150).w("100%").py8.$,
          "& > div:nth-of-type(2n-1)": Css.base.aifs.fwb.$, // 1st column
          "& > div:nth-of-type(2n)": Css.base.aifs.$, // 2nd column
        }}
      >
        <div>Effective Start Date:</div>
        <div {...tid.startDate}>{formatWithShortYear(revision.startDate)} </div>
        <div>Version #:</div>
        <div {...tid.versionNumber}>{revision.version}</div>
      </div>

      {!templates.isEmpty && (
        <Section title="Plan based costs">
          <div css={Css.mb2.$}>
            <PlanBasedCostsTable revisedLineItems={templates} />
          </div>
        </Section>
      )}

      {!bidItems.isEmpty && (
        <Section title="Itemized costs">
          <div css={Css.mb2.$}>
            <ItemizedCostsTable revisedLineItems={bidItems} />
          </div>
        </Section>
      )}

      <div style={{ pageBreakBefore: "always" }}></div>
      <Section title="Trade Partner Price Agreement">
        <div css={Css.mb2.$}>
          <p>
            This Trade Partner Price Agreement dated effective as of the ‘Effective Date’ shown on this Schedule B, is
            issued to and is hereby made a part of the Master Services Agreement (“Agreement”) between Homebound
            Technologies, Inc. (“Homebound”) and Trade Partner, including any Project Assignment Agreement(s) and/or
            Purchase Order(s) as entered into by the parties. This Trade Partner Contract Price Agreement incorporates
            herein by reference all the definitions, terms, and conditions of the Agreement as though set forth in full
            herein. Homebound and Trade Partner agree as follows:
          </p>
          <ol css={Css.plPx(16).$}>
            <li>
              Trade Partner hereby agrees and acknowledges that it will perform Work at assigned Project(s) based upon
              the Contract Prices set forth on this Schedule B. The Contract Prices may be based on the specific plans
              and spec levels as may be further illustrated on this Schedule B (i.e., Plan 40A Modern, 50 A Coastal,
              etc.) and agreed upon by the parties. Each Purchase Order or application for payment submitted by Trade
              Partner shall be based only upon the agreed upon Contract Prices as set forth on this Schedule B.
            </li>
            <li>
              Trade Partner further agrees and acknowledges that the Contract Prices as shown on this Schedule B have
              been mutually agreed to by the parties hereto and shall constitute as full and complete compensation that
              may be owed to Trade Partner for any and all Work, materials, labors, services, equipment, overhead,
              and/or tools performed or rendered at a Project.
            </li>
            <li>
              The Contract Prices set forth on this Schedule B hereto will remain in effect for the duration of the
              Agreement and will apply to all Work for Projects and subsequent Purchase Orders (or Project Assignment
              Agreements as the case may be). The Contract Prices set forth on this Schedule B may be only amended or
              modified by written instrument signed by both Homebound and Trade Partner.
            </li>
          </ol>
        </div>
      </Section>

      <div css={Css.add({ breakInside: "avoid" }).$}>
        <div css={Css.df.fdr.bgGray400.mt2.mb1.base.ttu.p1.$}>
          <div css={Css.f1.tac.$}>Homebound</div>
          <div css={Css.f1.tac.$}>Trade Partner</div>
        </div>
        <div css={Css.df.fdr.$}>
          <div css={Css.f1.p1.$}>
            <Signature role={PandaDocRole.HeadOfConstruction} prefix="By: " />
            <div {...tid.hbAuthorizedSignatory}>Name: {hbSignatory.name}</div>
            <div>Title: Homebound Authorized Signatory</div>
          </div>
          <div css={Css.f1.p1.$}>
            <Signature role={PandaDocRole.TradePartner} prefix="By: " />
            <div {...tid.signatoryTradePartner}>Name: {tpSignatory.name}</div>
            <div>Title: {tpSignatory.title}</div>
          </div>
        </div>
      </div>

      <HideFromPrint>
        <ObnoxiousText>
          Developer/Viewer Note: AWS Lambda - Events service - PdfGenerator.tsx will append more to this page. Headless
          chromium can't load PDFs directly so rather than link to it here, Lambda stitches it in.
        </ObnoxiousText>
      </HideFromPrint>
    </>
  );
}

type LineItemsSectionTableProps = {
  revisedLineItems: BidContractPdfLineItemsFragment[];
};

function PlanBasedCostsTable({ revisedLineItems }: LineItemsSectionTableProps) {
  const { rows, columns } = useMemo(
    () => ({
      columns: createPlanBasedColumns(),
      rows: simpleDataRows(
        revisedLineItems.sortBy((li) => {
          const iti = li.itemTemplateItem!;
          return [
            iti.parent.displayName,
            ...iti.otherOptionIds?.map((id) => iti.options?.find((rpo) => rpo.id === id)?.name).compact(),
            iti.item.fullCode,
            ...iti.specOptionIds?.map((id) => iti.options?.find((rpo) => rpo.id === id)?.name).compact(),
          ].join("");
        }),
      ),
    }),
    [revisedLineItems],
  );

  return <GridTable as="table" columns={columns} rows={rows} />;
}

function ItemizedCostsTable({ revisedLineItems }: LineItemsSectionTableProps) {
  const { rows, columns } = useMemo(
    () => ({
      columns: createItemizedCostColumns(),
      rows: simpleDataRows(sortBy(revisedLineItems, (li) => li.bidItem?.code)),
    }),
    [revisedLineItems],
  );

  return <GridTable as="table" columns={columns} rows={rows} />;
}

type Row = SimpleHeaderAndData<BidContractPdfLineItemsFragment>;

// Headers need to be wrapped in a span to avoid default styling applied to simple strings in `maybeAddHeaderStyling`
const h = (header: string) => () => <span>{header}</span>;

function createPlanBasedColumns(): GridColumn<Row>[] {
  return [
    {
      header: h("Plan"),
      data: (l) => l.itemTemplateItem?.parent.displayName,
    },
    {
      header: h("Elevations"),
      data: (l) => getOptionNames(l, l.itemTemplateItem?.elevationIds),
    },
    {
      header: h("Options"),
      data: (l) => getOptionNames(l, l.itemTemplateItem?.otherOptionIds),
    },
    {
      header: h("Cost Code"),
      data: (l) => l.itemTemplateItem?.item.fullCode,
    },
    { header: h("Description"), data: (l) => l.itemTemplateItem?.name },
    {
      header: h("Spec Levels"),
      data: (l) => getOptionNames(l, l.itemTemplateItem?.specOptionIds),
    },
    { header: h("Cost Type"), data: (l) => l.itemTemplateItem?.costType },
    {
      header: h("Location"),
      data: (l) => l.itemTemplateItem?.location.name ?? "-",
    },
    {
      header: h("Qty"),
      data: (l) => (!l.itemTemplateItem?.unitOfMeasure.useQuantity ? "N/A" : l.itemTemplateItem.quantity),
    },
    {
      header: h("Unit"),
      data: (l) => l.itemTemplateItem?.unitOfMeasure.name,
    },
    {
      header: h("Unit Cost"),
      data: (l) => {
        if (!l.itemTemplateItem?.unitOfMeasure.useQuantity || l.itemTemplateItem.quantity === 0) {
          return "N/A";
        }
        return priceCell({ valueInCents: l.unitCostInCents });
      },
      align: "right",
    },
    {
      header: h("Cost"),
      data: (l) => priceCell({ valueInCents: l.totalCostInCents }),
      align: "right",
    },
  ];
}

function createItemizedCostColumns(): GridColumn<Row>[] {
  return [
    {
      header: h("Bid Item Code"),
      data: ({ bidItem }) => bidItem?.code,
    },
    {
      header: h("Description"),
      data: ({ bidItem }) => bidItem?.name,
    },
    {
      header: h("Cost Type"),
      data: ({ bidItem }) => bidItem?.costType.name,
    },
    {
      header: h("Unit"),
      data: ({ bidItem }) => bidItem?.unitOfMeasure.shortName,
      align: "center",
    },
    {
      header: h("Unit Cost"),
      data: ({ totalCostInCents }) => <Price valueInCents={totalCostInCents} />,
      align: "left",
    },
  ];
}

function getOptionNames(bcli: BidContractPdfLineItemsFragment, ids?: string[]) {
  return (
    ids
      ?.map((id) => bcli.itemTemplateItem?.options?.find((rpo) => rpo.id === id)?.name)
      .compact()
      .join(", ") || emptyCellDash
  );
}
