import { Css, Icon } from "@homebound/beam";
import { useRef, useState } from "react";
import { Maybe } from "src/generated/graphql-types";

export type FileFieldProps = {
  label?: string;
  errors?: string[] | undefined;
  setFile: (file: File) => void;
  file?: Maybe<File>;
  // List of file types accepted by this component
  allowedFileTypes?: Array<string>;
};

/**
 * Provides a simple file input field that allows you to drag and drop files. This follows the display style of csv uploader.
 */
export function FileField(props: FileFieldProps) {
  const { label = "Drag & drop files", errors, file, setFile, allowedFileTypes } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [internalError, setInternalError] = useState<string | undefined>(undefined);

  const unsupportedTypeMessage = `The file must be a
    ${allowedFileTypes?.map((type) => type.toUpperCase()).join(", ")}. Please use a different file.`;

  function handleOnClick(e: any) {
    ref?.current?.click();
  }

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function handleOnDrop(event: React.DragEvent<HTMLButtonElement>) {
    event.preventDefault();
    const [file] = Array.from(event.dataTransfer.files);
    if (file) {
      if (allowedFileTypes && !allowedFileTypes.includes(file.type)) {
        setInternalError(unsupportedTypeMessage);
        return;
      } else {
        setInternalError(undefined);
      }
      setFile(file);
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [file] = event.target.files || [];
    if (file) {
      if (allowedFileTypes && !allowedFileTypes.includes(file.type)) {
        setInternalError(unsupportedTypeMessage);
        return;
      } else {
        setInternalError(undefined);
      }
      setFile(file);
    }
  }

  const hasErrors = errors?.nonEmpty || !!internalError;

  return (
    <>
      <div>
        <input type="file" ref={ref} hidden onChange={handleOnChange} />
        <button
          onClick={handleOnClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleOnDrop}
          css={{
            ...Css.hPx(75)
              .w100.mt3.br8.bcGray700.ba.bsDashed.relative.add("overflow", "hidden")
              .df.aic.jcc.cursorPointer.add("transition", "all 0.2s")
              .base.df.aic.blue700.if(hasErrors).red700.$,
            ":hover": Css.bgGray300.$,
            // Background
            ":before": Css.add("content", "''").bgGray300.add("opacity", 0.25).absolute.left0.right0.top0.bottom0.z(-1)
              .$,
          }}
        >
          <span css={Css.mr1.$}>
            <Icon icon={file ? "file" : "upload"} />
          </span>
          {file?.name || label}
        </button>
        <div css={Css.m2.red700.$}>{internalError || errors?.map((e, i) => <li key={i}>{e}</li>)}</div>
      </div>
    </>
  );
}
