import { QueryResult } from "@apollo/client";
import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import { FormattedDate } from "src/components";
import {
  CommitmentChangeOrderPdfQuery,
  CommitmentOrChangeOrderPdf_ReadyPlanConfigFragment,
  CommitmentPdfQuery,
  PandaDocRole,
  ProjectReadyPlanConfigOrientation,
  useCommitmentChangeOrderPdfQuery,
  useCommitmentPdfQuery,
} from "src/generated/graphql-types";
import { fail, queryResult, removeTag } from "src/utils";
import { createTradePortalPurchaseOrderUrl } from "src/utils/tradeLinks";
import { StringParam, useQueryParams } from "use-query-params";
import { BidCommitmentTerms } from "../components/BidCommitmentTerms";
import { CommitmentAddresses } from "../components/CommitmentAddresses";
import { HideFromPrint } from "../components/HideFromPrint";
import { ObnoxiousText } from "../components/ObnoxiousText";
import { Signature } from "../components/PandaDocField";
import { Section } from "../components/Section";
import { CommitmentChangeOrderTable } from "./components/CommitmentChangeOrderTable";
import { CommitmentTable } from "./components/CommitmentTable";
import { PaymentScheduleTable } from "./components/PaymentScheduleTable";

type Commitment = CommitmentPdfQuery["commitment"];
type CommitmentChangeOrder = CommitmentChangeOrderPdfQuery["commitmentChangeOrder"];

type CommitmentPdfData = {
  commitment: Commitment | undefined;
  commitmentChangeOrder: CommitmentChangeOrder | undefined;
};

export function CommitmentOrChangeOrderPdf() {
  const [{ commitmentId, bidCommitmentId, commitmentChangeOrderId, bidCommitmentChangeOrderId }] = useQueryParams({
    commitmentId: StringParam,
    bidCommitmentId: StringParam,
    commitmentChangeOrderId: StringParam,
    bidCommitmentChangeOrderId: StringParam,
  });
  if (!commitmentId && !bidCommitmentId && !commitmentChangeOrderId && !bidCommitmentChangeOrderId)
    fail("No commitment or commitment change order id found");

  // Queries the commitment or bid commitment
  const commitmentQuery = useCommitmentPdfQuery({
    skip: !commitmentId && !bidCommitmentId,
    variables: { commitmentId: commitmentId ?? bidCommitmentId! },
  });
  // Queries the commitment change order or bid commitment change order
  const commitmentChangeOrderQuery = useCommitmentChangeOrderPdfQuery({
    skip: !commitmentChangeOrderId && !bidCommitmentChangeOrderId,
    variables: { commitmentChangeOrderId: commitmentChangeOrderId ?? bidCommitmentChangeOrderId! },
  });

  const activeQuery = [commitmentQuery, commitmentChangeOrderQuery].find((q) => q.loading || q.data || q.error);
  const query = activeQuery || ({ loading: false, data: null, error: null } as unknown as QueryResult);

  return queryResult(query, (data) => <PdfView data={data} />);
}

type PdfViewProps = {
  data: CommitmentPdfData;
};

function PdfView(props: PdfViewProps) {
  const { commitment, commitmentChangeOrder } = props.data;
  const commitmentLike = commitment || commitmentChangeOrder;
  if (!commitmentLike) fail("No commitment or change order found");

  const c = "commitment" in commitmentLike ? commitmentLike.commitment : commitmentLike;
  const cco = commitmentChangeOrder;

  const { project, isBidCommitment, pandaDocRecipients } = c;
  const { buildAddress, readyPlanConfig, enableProductConfigPlan } = project;

  const hbSignatory = pandaDocRecipients?.find((pdr) => pdr.role === PandaDocRole.HomeboundAuthorizedSignatory);
  const tpSignatory = pandaDocRecipients?.find((pdr) => pdr.role === PandaDocRole.TradePartner)?.tradePartnerContact;
  const tpAddress = tpSignatory?.address;

  // HB Signatory is not required for bid commitments
  if (!isBidCommitment && !hbSignatory) fail(`Unable to find valid Homebound signatory ${c.name}`);
  if (!tpSignatory || !tpAddress) fail(`Unable to find valid Trade Partner signatory for ${c.name}`);
  if (isBidCommitment && !c.bidContractRevision?.id) fail("No commitment version found");

  const version = c.bidContractRevision?.id ? removeTag(c.bidContractRevision.id) : undefined;

  return (
    <>
      <Global
        styles={{
          "@page": { size: "letter landscape" },
          ul: Css.ml1.my2.add({ listStyleType: "disc" }).$,
          li: Css.my2.$,
        }}
      />
      <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(40).$} />

      <div data-testid="title" css={Css.ttu.lgSb.tac.mt2.fwb.$}>
        {cco ? "Change Order" : "Purchase Order"}
      </div>

      <div css={Css.df.fdr.bgGray600.white.ttu.mt2.fwb.$}>
        <div css={Css.df.f1.p1.$}>Prime Contractor ("Homebound")</div>
        <div css={Css.df.f1.p1.$}>Trade Partner Representative</div>
        <div css={Css.df.f1.p1.$}>Project Address</div>
      </div>

      <CommitmentAddresses tradePartner={c.tradePartner!} signatoryContact={tpSignatory}>
        <div /* Project Address */ css={Css.df.f1.fdc.p1.$}>
          <div>
            {buildAddress.street1} {buildAddress.street2}
          </div>
          {buildAddress.city}, {buildAddress.state} {buildAddress.postalCode} {buildAddress.country}
        </div>
      </CommitmentAddresses>

      <div /* PO Details */ css={Css.df.jcsb.py1.$}>
        <div css={Css.df.fdc.f1.base.$}>
          <div css={Css.df.gap1.p1.$}>
            PO #:
            <a data-testid="accountingNumber" href={createTradePortalPurchaseOrderUrl(c.project.id, c.id)}>
              {cco?.accountingNumber ?? c.accountingNumber}
            </a>
          </div>

          <div css={Css.df.gap1.p1.$}>
            PO DATE:
            <FormattedDate date={commitmentLike.updatedAt} dateFormatStyle="medium" />
          </div>

          {version && (
            <div data-testid="version" css={Css.df.f1.fdc.p1.$}>
              Contract Version #: {version}
            </div>
          )}
        </div>

        <PlansAndOptionsSection readyPlanConfig={readyPlanConfig} />
      </div>

      {commitment?.draws.nonEmpty && <PaymentScheduleTable commitment={commitment} />}

      <div /* PO Table */>
        {cco ? (
          <CommitmentChangeOrderTable commitmentChangeOrder={cco} />
        ) : (
          <CommitmentTable commitment={c} enableProductConfigPlan={enableProductConfigPlan} />
        )}
      </div>

      {c.contractExhibits && <Section title="Drawings and Exhibits" html={c.contractExhibits} />}

      {c.contractSchedule && <Section title="Schedule" html={c.contractSchedule} />}

      {c.contractExclusions && <Section title="Exclusions" html={c.contractExclusions} />}

      {isBidCommitment ? (
        <BidCommitmentTerms />
      ) : (
        c.contractTerms && <Section title="Terms and Conditions" html={c.contractTerms} />
      )}

      {/* Signature is not required for bid commitments */}
      {!isBidCommitment && hbSignatory && (
        <div data-testid="signature" css={Css.add({ breakInside: "avoid" }).$}>
          <div css={Css.df.fdr.bgGray400.mt2.mb1.base.ttu.p1.$}>
            <div css={Css.f1.$}>Homebound</div>
            <div css={Css.f1.$}>Trade Partner Representative</div>
          </div>
          <div css={Css.df.fdr.$}>
            <div css={Css.f1.p1.$}>
              <Signature role={PandaDocRole.HomeboundAuthorizedSignatory} prefix="By: " />
              <div>Name: {hbSignatory.name}</div>
              <div>Title: Homebound Authorized Signatory</div>
            </div>
            <div css={Css.f1.p1.$}>
              <Signature role={PandaDocRole.TradePartner} prefix="By: " />
              <div>Name: {tpSignatory.name}</div>
              <div>Title: {tpSignatory.title}</div>
            </div>
          </div>
        </div>
      )}

      <HideFromPrint>
        <ObnoxiousText>
          Developer/Viewer Note: AWS Lambda - Events service - PdfGenerator.tsx will append more to this page. Headless
          chromium can't load PDFs directly so rather than link to it here, Lambda stitches it in.
        </ObnoxiousText>
      </HideFromPrint>
    </>
  );
}

type PlansAndOptionsProps = {
  readyPlanConfig: CommitmentOrChangeOrderPdf_ReadyPlanConfigFragment | null | undefined;
};

function PlansAndOptionsSection({ readyPlanConfig }: PlansAndOptionsProps) {
  const planName = readyPlanConfig?.readyPlan?.displayName;
  const specLevelName = readyPlanConfig?.specOption?.readyPlanOption.name;
  const elevationName = readyPlanConfig?.options.find((o) => o.readyPlanOption.optionGroup.group.type.isElevation)
    ?.readyPlanOption.name;
  return (
    <div css={Css.df.f1.$}>
      <div css={Css.df.fdc.gap2.base.$}>
        {planName && (
          <div css={Css.df.gap1.$}>
            <div>Plan Name:</div>
            <div data-testid="plan">
              {`${planName} ${
                readyPlanConfig
                  ? readyPlanConfig.orientationDetail.code === ProjectReadyPlanConfigOrientation.Right
                    ? "(RH)"
                    : "(LH)"
                  : ""
              }`}
            </div>
          </div>
        )}
        {specLevelName && (
          <div css={Css.df.gap1.$}>
            <div>Spec Level:</div>
            <div data-testid="specLevel">{specLevelName}</div>
          </div>
        )}
        {elevationName && (
          <div css={Css.df.gap1.$}>
            <div>Elevation:</div>
            <div data-testid="elevationName">{elevationName}</div>
          </div>
        )}
      </div>
    </div>
  );
}
