import { MutationFunction } from "@apollo/client";
import { BoundMultiSelectField, BoundNumberField, BoundSelectField, BoundSelectFieldProps, Css } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { ReactNode, useMemo } from "react";
import { BannerNotice, Loading } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  AddEditItemModal_CreateLocationMutation,
  AddEditItemModal_CreateLocationMutationVariables,
  AddEditItemModal_GlobalPlanTaskFragment,
  AddEditItemModal_ItemFragment,
  AddEditItemModal_LocationFragment,
  AddEditItemModal_MvFragment,
  AddEditItemModal_TliFragment,
  AddEditItemModal_UomFragment,
  LocationType,
  Maybe,
  SaveTakeoffLineItemInput,
  useAddEditItemModal_GetReadyPlanQuery,
  useAddEditItemModal_GetTliQuery,
  useAddEditItemModal_GlobalPlanTasksLazyQuery,
} from "src/generated/graphql-types";
import { StoreApi } from "zustand";
import { SaveTliResult, TakeoffsStoreState } from "../../TakeoffsManagerContext";

export function PublishedTakeoffBanner() {
  return (
    <BannerNotice message="This takeoff has been published and cannot be edited" variant="form" icon="alertInfo" />
  );
}

export type AddEditItemObjectState = SaveTakeoffLineItemInput & {
  materialVariant: Maybe<AddEditItemModal_MvFragment>;
  unitOfMeasure: Maybe<AddEditItemModal_UomFragment>;
  item: Maybe<AddEditItemModal_ItemFragment>;
  location: Maybe<Omit<AddEditItemModal_LocationFragment, "parents">>;
  newLocationName?: Maybe<string>;
};

export const addItemConfig: ObjectConfig<AddEditItemObjectState> = {
  id: { type: "value" },
  itemId: { type: "value" },
  materialVariantId: { type: "value" },
  quantity: { type: "value" },
  unitOfMeasureId: { type: "value", rules: [required] },
  locationId: { type: "value", rules: [required] },
  optionIds: { type: "value" },
  taskId: { type: "value" },

  // Store a new location path if needed
  newLocationName: { type: "value" },
  // Also store the more detailed objects for easier access/logic needs
  location: { type: "value" },
  materialVariant: {
    type: "object",
    config: {
      id: { type: "value" },
      code: { type: "value" },
      displayName: { type: "value" },
      isArchived: { type: "value" },
      listing: { type: "value" },
      materialAttributeValues: { type: "value" },
      effectiveMADs: { type: "value" },
    },
  },
  unitOfMeasure: { type: "value", rules: [required] },
  item: {
    type: "object",
    config: {
      unitOfMeasure: { type: "value" },
      materialAttributeDimensions: {
        type: "list",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          useInTakeoff: { type: "value" },
          unitOfMeasure: { type: "value" },
          values: {
            type: "list",
            config: {
              id: { type: "value" },
              code: { type: "value" },
              textValue: { type: "value" },
              dimension: { type: "value" },
            },
          },
          type: { type: "value" },
        },
      },
    },
  },
};

export const addMaterialItemConfig: ObjectConfig<AddEditItemObjectState> = {
  ...addItemConfig,
  materialVariantId: { type: "value", rules: [required] },
};

export const addPlaceholderItemConfig: ObjectConfig<AddEditItemObjectState> = {
  ...addItemConfig,
  itemId: { type: "value", rules: [required] },
};

export const addLaborItemConfig: ObjectConfig<AddEditItemObjectState> = {
  ...addItemConfig,
  taskId: { type: "value", rules: [required] },
};

type CommonFieldsProps = {
  store: StoreApi<TakeoffsStoreState>;
  planPackageId: string;
  readOnly: boolean;
  formState: ObjectState<AddEditItemObjectState>;
  disabled?: { uom?: ReactNode; quantity?: ReactNode; options?: ReactNode };
};

/** Provides fields to the "Add Labor", "Add Placeholder", and "Add Construction" modals. */
export function CommonFields({ store, planPackageId, readOnly, formState, disabled }: CommonFieldsProps) {
  const versionId = store.getState().planPackage.version.id;
  const { data: rpData } = useAddEditItemModal_GetReadyPlanQuery({
    variables: { readyPlanId: planPackageId, versionId },
  });
  return (
    <>
      <div css={Css.df.jcsb.gap1.$}>
        <UnitsOfMeasureBoundSelectField
          label="UoM*"
          field={formState.unitOfMeasureId}
          onSelect={(uomId, uom) => {
            formState.unitOfMeasureId.set(uomId);
            formState.unitOfMeasure.set(uom);
          }}
          disabled={disabled?.uom}
        />
        <BoundNumberField
          label="Qty"
          field={formState.quantity}
          disabled={
            disabled?.quantity ||
            (formState.unitOfMeasure.value &&
              !formState.unitOfMeasure.value?.useQuantity &&
              `${formState.unitOfMeasure.value!.name} does not uses Quantity`)
          }
        />
      </div>
      {/* When in 'readOnly', do not show if it is not set. Otherwise, it results in only showing a label with no value and looks odd. */}
      {(!readOnly || formState.optionIds.value?.nonEmpty) && (
        <BoundMultiSelectField
          label="Options"
          field={formState.optionIds}
          options={rpData?.readyPlan?.options ?? []}
          getOptionLabel={(o) => o.displayName + (o.active ? "" : " (Archived)")}
          getOptionValue={(o) => o.id}
          getOptionMenuLabel={(o) => <ArchivedTag active={o.active}>{o.displayName}</ArchivedTag>}
          disabled={disabled?.options}
        />
      )}
    </>
  );
}

export type SelectedTask = {
  id: string | undefined;
  name: string | undefined;
};

export type OnTaskSelect = BoundSelectFieldProps<
  SelectedTask | AddEditItemModal_GlobalPlanTaskFragment | null,
  string
>["onSelect"];

type TakeoffTaskBoundSelectFieldProps = {
  selectedTask?: SelectedTask | null;
  readOnly: boolean;
  formState: ObjectState<AddEditItemObjectState>;
  onSelect?: OnTaskSelect;
  required?: boolean;
  allowNone?: boolean;
};

export function TakeoffTaskBoundSelectField(props: TakeoffTaskBoundSelectFieldProps) {
  const { selectedTask, readOnly, formState, onSelect, required, allowNone } = props;
  const [loadTasks, { data: tasksData }] = useAddEditItemModal_GlobalPlanTasksLazyQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  return (
    <BoundSelectField
      required={required}
      label={`Task Allocation${required ? "*" : ""}`}
      field={formState.taskId}
      nothingSelectedText="Please select a task"
      options={{
        current: selectedTask ?? undefined,
        load: () => loadTasks({ variables: { filter: {} } }),
        options: tasksData?.globalPlanTasks.entities,
      }}
      getOptionLabel={(o: any) => o.name}
      getOptionValue={(o: any) => o.id}
      onSelect={onSelect}
      readOnly={readOnly}
      {...(allowNone && { unsetLabel: "None" })}
    />
  );
}

export type AddItemModalProps = {
  tliId?: string;
  asNew?: boolean;
  planPackageId: string;
  takeoffsManagerStore: StoreApi<TakeoffsStoreState>;
};

export type TliData = Omit<AddEditItemModal_TliFragment, "id"> & { id?: string };
export type AddEditItemModalDataViewProps = {
  initialTliData?: TliData;
  planPackageId: string;
  takeoffsManagerStore: StoreApi<TakeoffsStoreState>;
} & AddItemModalProps;

export function withGetTliQuery(WrappedComponent: React.FC<AddEditItemModalDataViewProps>) {
  return ({ tliId, asNew, ...rest }: AddItemModalProps) => {
    const versionId = rest.takeoffsManagerStore.getState().planPackage.version.id;
    const { loading, data: tliData } = useAddEditItemModal_GetTliQuery({
      variables: { id: tliId!, versionId },
      skip: !tliId,
    });

    if (loading) return <Loading />;

    const initialTliData: TliData | undefined = tliData && {
      ...tliData.takeoffLineItem,
      // Clear the ID if we are creating a new item via duplicate
      ...(asNew ? { id: undefined } : {}),
    };

    return <WrappedComponent {...rest} initialTliData={initialTliData} />;
  };
}

export function useAddEditItemModalFormState(
  type: "material" | "placeholder" | "labor",
  readOnly: boolean,
  initialTliData: TliData = { quantity: 0 } as TliData,
) {
  const formConfig = useMemo(
    () =>
      (
        ({
          material: addMaterialItemConfig,
          placeholder: addPlaceholderItemConfig,
          labor: addLaborItemConfig,
        }) satisfies Record<Parameters<typeof useAddEditItemModalFormState>[0], ObjectConfig<AddEditItemObjectState>>
      )[type],
    [type],
  );

  return useFormState({
    config: formConfig,
    readOnly: readOnly,
    init: {
      input: initialTliData,
      map: (data) => {
        // Display Location should be the nearest non-scope location in the path.
        const displayLocation =
          (data.location &&
            [data.location, ...data.location.parents].find((l) => l.type.code !== LocationType.Scope)) ??
          data.location;

        return {
          id: data.id,
          costType: data.costType,
          itemId: data.item?.id,
          item: data.item,
          name: data.name,
          materialVariant: data.materialVariant,
          materialVariantId: data.materialVariant?.id,
          quantity: data.quantity ?? 0,
          unitOfMeasure: data.unitOfMeasure,
          unitOfMeasureId: data.unitOfMeasure?.id,
          locationId: displayLocation?.id,
          location: displayLocation,
          optionIds: data.options?.map((o) => o.id) ?? [],
          taskId: data.task?.id,
        };
      },
    },
  });
}

export async function saveAddEditItem(params: {
  planPackageId: string;
  formState: ObjectState<AddEditItemObjectState>;
  saveTli: (item: SaveTakeoffLineItemInput | SaveTakeoffLineItemInput[]) => SaveTliResult;
  saveLocation: MutationFunction<
    AddEditItemModal_CreateLocationMutation,
    AddEditItemModal_CreateLocationMutationVariables
  >;
  isPlaceholderItem?: boolean;
}) {
  const { formState, saveLocation, saveTli, planPackageId, isPlaceholderItem } = params;

  // itemId does not need to be sent, since it is derived from materialVariantId or taskId
  const { itemId, item, materialVariant, unitOfMeasure, quantity, newLocationName, locationId, location, ...rest } =
    formState.changedValue;

  // Look for a new `Kitchen > Nook` feature to add
  let locationIdToSave = locationId;
  if (newLocationName) {
    // Mostly to satisfy TS but will indicate a bad state change from MaybeAddLocationsBoundSelectField
    if (!location) throw new Error("Trying to create a new location without a location object");
    const { data } = await saveLocation({
      variables: {
        input: { readyPlanId: planPackageId, newLocationPaths: [`${location.path}~${newLocationName}`] },
      },
    });
    locationIdToSave = data?.createLocationsFromPaths?.locations[0].id;
  }

  await saveTli({
    ...rest,
    ...(locationIdToSave && { locationId: locationIdToSave }),
    ...(formState.unitOfMeasure.value?.useQuantity !== false && quantity && { quantity }),
    ...(isPlaceholderItem && {
      itemId: itemId || formState.itemId.value,
      placeholderMaterialAttributeValues:
        !materialVariant?.materialAttributeValues?.nonEmpty && formState.id.value // Means this item is defined but there are no changes to the MADs
          ? undefined // so we omit sending the empty array to avoid losing the existing configuration
          : // in this case there might be a change in the MADs, or the item is new, so we send the property even as a empty array
            (materialVariant?.materialAttributeValues?.map(({ id, textValue, code, dimension }) => ({
              ...(id && { id }),
              ...(code && { code }),
              textValue,
              dimensionId: dimension.id,
            })) ?? []),
    }),
  });

  // We thought we didn't need this (getting reset by the apollo cache), but the form state is staying dirty
  formState.commitChanges();
}
