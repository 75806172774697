import { BillPage_BillFragment, BillStatus, BillType } from "src/generated/graphql-types";

export function isDraft(billStatus: BillStatus) {
  return billStatus === BillStatus.Draft;
}
export function isHeadlessBill(bill: BillPage_BillFragment) {
  return bill.lineItems.isEmpty || !bill.lineItems[0].commitmentLineItem;
}
export function hasDraws(bill: BillPage_BillFragment) {
  return bill.lineItems.some((li) => li.commitmentDraw);
}
export function isDepositBill(bill: BillPage_BillFragment) {
  return bill.type.code === BillType.Deposit;
}
