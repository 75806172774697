import {
  BoundSelectField,
  BoundSelectFieldProps,
  BoundTextField,
  Css,
  IconButton,
  Palette,
  Tag,
} from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { Maybe } from "graphql/jsutils/Maybe";
import { useState } from "react";
import {
  LocationsSelectField_LocationFragment,
  useAddEditItemModal_GetLocationsQuery,
} from "src/generated/graphql-types";

type AddEditItemLocationsFieldProps = Omit<
  BoundSelectFieldProps<LocationsSelectField_LocationFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  newLocationNameField: FieldState<Maybe<string>>;
  planPackageId: string;
  rpavId: string;
};

export function AddEditItemLocationsField(props: AddEditItemLocationsFieldProps) {
  const {
    placeholder = "Select a Location",
    onSelect,
    newLocationNameField,
    field,
    planPackageId,
    rpavId,
    ...otherProps
  } = props;

  const { data, loading } = useAddEditItemModal_GetLocationsQuery({ variables: { rpavId } });

  // TODO: Update locationFilter to include type, sort by path
  // SC-55529 - https://app.shortcut.com/homebound-team/story/55529/chore-be-update-locationsfilter-to-support-type-and-sortby-path
  // @ts-ignore path is never undefined on v2 locations
  const locations = data?.locations.sortByKey("path") || [];

  // Note: The FormState field will already be set by this point, so this `init` func for the below setState is okay to use.
  // FormState & data fetching are called within `.../takeoffs/components/AddItems/utils.tsx`. See `withGetItivQuery` & `useAddEditItemModalFormState`)
  const [selectedLocation, setSelectedLocation] = useState<LocationsSelectField_LocationFragment | undefined>(
    locations.find((l) => l.id === field.value),
  );
  const [showAddLocationField, setShowAddLocationField] = useState(false);

  return (
    <>
      <div css={Css.df.aic.gap2.$}>
        <BoundSelectField
          {...otherProps}
          field={field}
          label="Location*"
          onSelect={(locationId, location) => {
            setSelectedLocation(location);
            onSelect?.(locationId, location);
          }}
          placeholder={loading ? "Loading..." : placeholder}
          options={locations}
          getOptionValue={(o) => o.id}
          getOptionLabel={(o) => o.name}
          getOptionMenuLabel={({ name, displayLocationPath, type }) => (
            <span>
              <Tag xss={Css.m0.$} type="info" text={type.name} /> {name}{" "}
              {name !== displayLocationPath && <span css={Css.gray500.$}>{displayLocationPath}</span>}
            </span>
          )}
          hideErrorMessage
        />
        {!showAddLocationField && (
          // Add top padding to center
          <div css={Css.ptPx(26).$}>
            <IconButton
              color={Palette.Blue500}
              data-testid="addSubLocationBtn"
              tooltip="Add new feature location"
              disabled={!selectedLocation ? "Select a room location first" : false}
              onClick={() => {
                setShowAddLocationField(true);
              }}
              icon="plus"
            />
          </div>
        )}
      </div>
      {showAddLocationField && (
        <div css={Css.df.aic.gap2.$}>
          <BoundTextField autoFocus label={`Feature of ${selectedLocation?.name}`} field={newLocationNameField} />
          <div css={Css.ptPx(26).$}>
            <IconButton
              color={Palette.Blue500}
              tooltip="Remove child location"
              onClick={() => {
                newLocationNameField.set(undefined);
                setShowAddLocationField(false);
              }}
              icon="x"
            />
          </div>
        </div>
      )}
    </>
  );
}
