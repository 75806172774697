import {
  BoundChipSelectField,
  Button,
  Chips,
  Css,
  Icon,
  IconProps,
  Palette,
  Tooltip,
  useTestIds,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { format } from "date-fns";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { createMilestoneDetailsPageUrl, createTaskMaterialsPageUrl } from "src/RouteUrls";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { BoundTradePartnerSelectField } from "src/components/autoPopulateSelects/TradePartnerSelectField";
import {
  CostType,
  DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment,
  SavePlanTaskInput,
  TaskDetailsPageWithConstraints_PlanTaskFragment,
  TaskDetailsPage_ConstraintItemFragment,
  TaskStatus,
  TradePartnerTaskStatus,
  TradePartnerTaskStatusDetail,
  useSavePlanTaskMutation,
} from "src/generated/graphql-types";
import {
  ReducedTradePartnerTaskStatusOrder,
  ReducedTradePartnerTaskStatuses,
  mapTradePartnerTaskStatusToReducedStatus,
  numToStringWithSign,
  pluralize,
} from "src/utils";
import { AssetPreview } from "../../assets/AssetPreview";
import { TaskDetailCard, TaskDetailCardType } from "./TaskDetailCard";

type SchedulingCardProps = {
  task: TaskDetailsPageWithConstraints_PlanTaskFragment;
  formState: ObjectState<SavePlanTaskInput>;
  tradePartnerTaskStatus: TradePartnerTaskStatusDetail[];
};

type SchedulingCardSectionProps = {
  task: TaskDetailsPageWithConstraints_PlanTaskFragment | DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
  inSidePane?: boolean;
};

enum ConstraintStatus {
  Waiting = "Waiting",
  Ignored = "Ignored",
  Completed = "Completed",
}

export function SchedulingCard(props: SchedulingCardProps) {
  const tid = useTestIds(props, "schedulingCard");
  const { task, tradePartnerTaskStatus, formState } = props;
  const { startDate, endDate, durationInDays, schedule } = task;

  return (
    <TaskDetailCard gridColumn={1} gridRow={2} cardType={TaskDetailCardType.Scheduling}>
      <h2 css={Css.xlBd.mb4.$}>Scheduling</h2>
      <div css={Css.bcGray300.bb.pb3.$}>
        <div css={Css.df.jcsb.pb1.$}>
          <span css={Css.smMd.gray800.$}>Start Date</span>
          <span data-testid="startDate" css={Css.smMd.gray700.$}>
            {format(startDate, "MMM d")}
          </span>
        </div>
        <div css={Css.df.jcsb.pb1.$}>
          <span css={Css.smMd.gray800.$}>End Date</span>
          <span data-testid="endDate" css={Css.smMd.gray700.$}>
            {format(endDate, "MMM d")}
          </span>
        </div>
        <div css={Css.df.jcsb.$}>
          <span css={Css.smMd.gray800.$}>Duration</span>
          <span data-testid="duration" css={Css.smMd.gray700.$}>
            {task.durationInDays} {pluralize(durationInDays, "day")}
          </span>
        </div>
      </div>
      <div css={Css.bcGray300.bb.pb3.pt3.$}>
        <h3 css={Css.baseSb.mb2.$}>Trade Partner</h3>
        <TaskTradePartnerContent task={task} tradePartnerTaskStatus={tradePartnerTaskStatus} formState={formState} />
      </div>
      <DependenciesSection task={task} {...tid} />
      <DelayFlagSection task={task} />
      <MaterialsSection task={task} />
      <div css={Css.df.jcsb.gap2.pt3.if(task.allowsPlanMilestones.isEmpty).fdc.gap1.$}>
        <h3 css={Css.baseSb.mb2.$}>Milestones</h3>
        <div
          data-testid="milestones"
          css={Css.df.fdc.w("300px").truncate.tar.if(task.allowsPlanMilestones.isEmpty).w100.$}
        >
          {task.allowsPlanMilestones.isEmpty ? (
            <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              This task does not contribute to a milestone
            </div>
          ) : (
            task.allowsPlanMilestones.map((pm) => (
              <Link
                key={pm.id}
                to={createMilestoneDetailsPageUrl(schedule.parent.id, pm.id)}
                css={Css.db.truncate.smMd.lh("24px").onHover.tdu.$}
                data-testid="milestoneLink"
                title={pm.name}
              >
                {pm.name}
              </Link>
            ))
          )}
        </div>
      </div>
      <TagsSection task={task} />
    </TaskDetailCard>
  );
}

export function DependenciesSection(props: SchedulingCardSectionProps) {
  const tid = useTestIds(props, "dependency");
  const { task, inSidePane = false } = props;
  const { constraintItems, status, ignoredConstraintsOnTaskEnd, ignoredConstraintsOnTaskStart } = task;
  const ignoredConstraintIds = useMemo(() => {
    // If a task is complete, we only care about the constraints that were ignored upon completion (not upon starting)
    const ignoredConstraintList =
      status.code === TaskStatus.Complete ? ignoredConstraintsOnTaskEnd : ignoredConstraintsOnTaskStart;
    return new Set(ignoredConstraintList.map((constraint) => constraint.id));
  }, [status, ignoredConstraintsOnTaskEnd, ignoredConstraintsOnTaskStart]);

  const constraintItemList = useMemo(
    () =>
      constraintItems.isEmpty ? (
        <div {...tid.noConstraints} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no constraints on this task
        </div>
      ) : (
        constraintItems.map((constraintItem) => (
          <ConstraintItem
            key={constraintItem.id}
            constraintItem={constraintItem}
            ignoredConstraintIds={ignoredConstraintIds}
            task={task}
            {...tid}
          />
        ))
      ),
    [constraintItems, task, ignoredConstraintIds],
  );

  return (
    <div css={Css.if(!inSidePane).bcGray300.bb.pb3.pt3.$}>
      <h3 css={Css.baseSb.mb2.$}>Dependencies</h3>
      {constraintItemList}
    </div>
  );
}

export function MaterialsSection({ task, inSidePane = false }: SchedulingCardSectionProps) {
  const { id, projectItems, schedule, globalPlanTask } = task;
  const { developmentDrops } = globalPlanTask;

  const { materialProjectItems, maybeDropTitle } = useMemo(() => {
    // Only show Material type project items
    const materialProjectItems = projectItems.filter((pi) => pi.costType === CostType.Materials);

    // Right now we are just using the `globalPlanTask.developmentDrops` to figure out if this task is a drop task
    // The expectation is that even though this is an array, it should only have one item. Once we have a better understanding of how these will work
    // in reality, we can add a helper on `PlanTask` to better encapsulate this logic.
    const maybeDropTitle = developmentDrops.nonEmpty ? developmentDrops.first?.name : "View Associated Materials";

    return { materialProjectItems, maybeDropTitle };
  }, [projectItems, developmentDrops]);

  return (
    <div css={Css.if(!inSidePane).bcGray300.bb.pb3.pt3.$}>
      <h3 css={Css.baseSb.mb2.$}>Materials</h3>
      {materialProjectItems.isEmpty ? (
        <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no materials associated with this task
        </div>
      ) : (
        <div css={Css.df.jcsb.$}>
          <span css={Css.gray700.smMd.$} data-testid="materialLabel">
            {maybeDropTitle}
          </span>
          <Button
            data-testid="viewMaterials"
            label="View"
            variant="text"
            onClick={createTaskMaterialsPageUrl(schedule.parent.id, id)}
          />
        </div>
      )}
    </div>
  );
}

export function DelayFlagSection({ task, inSidePane }: SchedulingCardSectionProps) {
  const { scheduleFlags } = task;

  // hide entire section if there are no schedule flags
  if (scheduleFlags.isEmpty) return null;

  return (
    <div css={Css.if(!inSidePane).bcGray300.bb.pb3.pt3.$} data-testid="delayFlags">
      <h3 css={Css.baseSb.mb2.$}>Delay Flags</h3>
      {scheduleFlags.map((sf) => (
        <div key={sf.id}>
          <span css={Css.df.jcsb.truncate.$}>
            <div css={Css.wspw.$}>{`${sf?.reason?.scheduleFlagReasonType.name}, (${sf?.reason?.title})`}</div>
            <div data-testid="flagCreationDate">{format(new Date(sf.createdAt), "MMM d")}</div>
          </span>
          <div>{sf.description}</div>
          <AssetGallery assets={sf.attachments.map((i) => i.asset)} display="vertical">
            {(openGallery) => (
              <>
                {sf.attachments.map(({ id, asset }) => (
                  <div key={id} css={Css.df.relative.mr1.mt1.$}>
                    <AssetPreview
                      asset={asset}
                      dimensions={{ width: 100, height: 100 }}
                      onClick={() => openGallery(asset)}
                    />
                  </div>
                ))}
              </>
            )}
          </AssetGallery>
        </div>
      ))}
    </div>
  );
}

function TaskTradePartnerContent(props: SchedulingCardProps) {
  const { task, tradePartnerTaskStatus, formState } = props;

  const [savePlanTask] = useSavePlanTaskMutation();

  const reducedTradePartnerStatusesDetail = useMemo(
    () =>
      tradePartnerTaskStatus
        .filter(
          // Keep only the status that match ReducedTradePartnerTaskStatus type
          (tps) => ReducedTradePartnerTaskStatuses.includes(tps.code),
        )
        .sortBy((tps) => ReducedTradePartnerTaskStatusOrder[mapTradePartnerTaskStatusToReducedStatus(tps.code)]),
    [tradePartnerTaskStatus],
  );

  const onTradePartnerSelect = useCallback(
    async (value?: string) => {
      // Note: setting to null so unsetting doesn't get swallowed up by GQL/Joist
      await savePlanTask({ variables: { input: { id: task.id, tradePartnerId: value ?? null } } });
    },
    [task.id, savePlanTask],
  );

  const onStatusSelect = useCallback(
    async (value?: TradePartnerTaskStatus) => {
      if (value) {
        await savePlanTask({ variables: { input: { id: task.id, tradePartnerStatus: value } } });
      }
    },
    [task.id, savePlanTask],
  );

  const uniqueCommittedTrades = task.committedTradePartners.uniqueBy((tp) => tp.id);

  const [firstCommitted, ...otherCommitted] = uniqueCommittedTrades;

  return (
    <div>
      <div css={Css.df.gap1.jcsb.$}>
        <div css={Css.df.fdc.$}>
          {task.canSetTraderPartner.allowed ? (
            <BoundTradePartnerSelectField
              allowUnassigned
              field={formState.tradePartnerId}
              filter={{
                markets: [task.schedule.parent.market.id],
              }}
              labelStyle="hidden"
              onSelect={onTradePartnerSelect}
              compact
            />
          ) : (
            firstCommitted && (
              <span css={Css.smMd.gray800.$} data-testid="tradePartnerName">
                {firstCommitted.name}
              </span>
            )
          )}
        </div>
        {firstCommitted || task.tradePartner ? (
          <BoundChipSelectField
            options={reducedTradePartnerStatusesDetail}
            onSelect={onStatusSelect}
            field={formState.tradePartnerStatus}
            getOptionValue={(o) => o.code}
            getOptionLabel={(o) => o.name}
          />
        ) : null}
      </div>
      <div css={Css.df.gap1.jcsb.$}>
        {otherCommitted.map((tp) => (
          <div css={Css.mt1.df.aic.gap1.$} key={tp.id}>
            <span css={Css.smMd.gray800.$}>{tp.name}</span>
            <Icon
              icon="error"
              color={Palette.Red600}
              tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
              inc={2.3}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function getConstraintStatus(
  constraintItem: TaskDetailsPage_ConstraintItemFragment,
  ignoredConstraintIds: Set<string>,
) {
  if (ignoredConstraintIds.has(constraintItem.id)) {
    return ConstraintStatus.Ignored;
  } else if (constraintItem.allowingPlanTasks.every((task) => task.status.code === TaskStatus.Complete)) {
    return ConstraintStatus.Completed;
  } else {
    return ConstraintStatus.Waiting;
  }
}

export function ConstraintItem(props: {
  constraintItem: TaskDetailsPage_ConstraintItemFragment;
  task: TaskDetailsPageWithConstraints_PlanTaskFragment | DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
  ignoredConstraintIds: Set<string>;
}) {
  const tid = useTestIds(props);
  const { constraintItem, ignoredConstraintIds } = props;

  const status = getConstraintStatus(constraintItem, ignoredConstraintIds);
  const pendingTasks = constraintItem.allowingPlanTasks.map((t) => <div key={t.id}>{t.name}</div>);
  const tooltipText: Record<ConstraintStatus, string> = {
    [ConstraintStatus.Waiting]: "The dependency has not yet been completed, so the task cannot move forward.",
    [ConstraintStatus.Ignored]:
      "A dependency can be “ignored” to allow the task to move forward without waiting on the dependency.",
    [ConstraintStatus.Completed]: "The dependency has been completed and the task is permitted to move forward.",
  };
  const iconToConstraintStatus: Record<ConstraintStatus, Pick<IconProps, "icon" | "color">> = {
    [ConstraintStatus.Waiting]: { icon: "time", color: Palette.Orange500 },
    [ConstraintStatus.Ignored]: { icon: "lockOpen" },
    [ConstraintStatus.Completed]: { icon: "checkCircle", color: Palette.Gray600 },
  };

  const lagDays =
    constraintItem.lagInDays !== 0
      ? ` (${numToStringWithSign(constraintItem.lagInDays)} ${pluralize(constraintItem.lagInDays, "day")})`
      : undefined;

  return (
    <div {...tid.dependency} css={Css.df.jcsb.mb1.$} key={constraintItem.id}>
      <Tooltip
        title={
          <>
            <>Waiting on tasks: </>
            {pendingTasks}
          </>
        }
        placement="top"
        disabled={status !== ConstraintStatus.Waiting}
      >
        {/* strike-through dependencies that don't exist in the schedule */}
        <span {...tid.constraintName} css={Css.smMd.gray800.if(constraintItem.allowingPlanTasks.isEmpty).tdlt.$}>
          {constraintItem.name}
          {lagDays}
        </span>
      </Tooltip>
      <span css={Css.wPx(100).df.jcfs.aic.$}>
        <Tooltip title={tooltipText[status]} placement="top">
          <Icon {...iconToConstraintStatus[status]} inc={2} {...tid.icon} />
          <span css={Css.smMd.gray700.pl1.$}>{status}</span>
        </Tooltip>
      </span>
    </div>
  );
}

function TagsSection({ task }: { task: TaskDetailsPageWithConstraints_PlanTaskFragment }) {
  const {
    globalPlanTask: { tags },
  } = task;

  return (
    <div css={Css.bcGray300.bt.pb1.pt3.mt3.$}>
      <h3 css={Css.baseSb.mb2.$}>Tags</h3>
      {tags.nonEmpty ? (
        <Chips values={tags.map((t) => t.name)} />
      ) : (
        <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>No tags associated with this task</div>
      )}
    </div>
  );
}
