import { Css, ScrollableParent, Step, useComputed } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import {
  PublishBidPackagePage_BidPackageFragment,
  SaveBidPackageInput,
  usePublishBidPackagePageQuery,
} from "src/generated/graphql-types";
import { BidPackageParams } from "src/routes/routesDef";
import { isEmptyObject, queryResult } from "src/utils";
import { BidPackagePublishStep } from "../add-bid-package/utils";
import { UpdateBidInfoStep } from "./components/1.UpdateBidInfoStep";
import { AddInvitationTextStep } from "./components/2.AddInvitationTextStep";
import { ReviewAndPublishStep } from "./components/3.ReviewAndPublishStep";
import { PublishBidPackageStepActions } from "./components/PublishBidPackageStepActions";

export function PublishBidPackagePage() {
  const { bidPackageId } = useParams<BidPackageParams>();
  const query = usePublishBidPackagePageQuery({ variables: { id: bidPackageId } });

  return queryResult(query, ({ bidPackage }) => (
    <StepperProvider steps={initialSteps}>
      <PublishBidPackageView bidPackage={bidPackage} />
    </StepperProvider>
  ));
}

function PublishBidPackageView({ bidPackage }: { bidPackage: PublishBidPackagePage_BidPackageFragment }) {
  const { currentStep, setSteps } = useStepperContext();
  const [stepIndex, setStepIndex] = useState(0);
  const formState = useFormState({
    config: formConfig,
    init: { input: bidPackage, map: (bp) => ({ ...bp, fragment: bp }) },
  });

  useEffect(() => {
    const currentStepIndex = initialSteps.findIndex((step) => step.value === currentStep.value);
    if (stepIndex === currentStepIndex) return;
    setStepIndex(currentStepIndex);
    setSteps((prevState) => [
      { ...prevState[0], state: currentStepIndex > 0 ? "complete" : prevState[0].state },
      { ...prevState[1], state: currentStepIndex > 1 ? "complete" : prevState[1].state },
      { ...prevState[2], state: currentStepIndex > 2 ? "complete" : prevState[2].state },
    ]);
  }, [currentStep, setSteps, stepIndex]);

  const step = useMemo(() => {
    switch (currentStep.value) {
      case BidPackagePublishStep.UPDATE_BID_INFO:
        return <UpdateBidInfoStep formState={formState} />;
      case BidPackagePublishStep.ADD_INVITATION_TEXT:
        return <AddInvitationTextStep formState={formState} />;
      case BidPackagePublishStep.REVIEW_AND_PUBLISH:
        return <ReviewAndPublishStep formState={formState} />;
      default:
        return <UpdateBidInfoStep formState={formState} />;
    }
  }, [currentStep.value, formState]);

  // This updates the continue/enable/disable state of the stepper based on required formState
  useComputed(() => {
    if (formState.dirty && !isEmptyObject(formState.changedValue)) {
      setSteps((prevState) => [
        { ...prevState[0], disabled: false },
        { ...prevState[1], disabled: !formState.dueDate.value || !formState.estimatedAwardDate.value },
        { ...prevState[2], disabled: !formState.existingInvitationText.value },
      ]);
    }
  }, [formState]);

  return (
    <ScrollableParent xss={Css.mbPx(stepperBarHeight).pxPx(60).$}>
      {step}
      <PublishBidPackageStepActions formState={formState} />
    </ScrollableParent>
  );
}

const INCOMPLETE = "incomplete" as const;
export const initialSteps: Step[] = [
  { value: BidPackagePublishStep.UPDATE_BID_INFO, label: "Update Bid Info", state: INCOMPLETE },
  { value: BidPackagePublishStep.ADD_INVITATION_TEXT, label: "Invitation Text", state: INCOMPLETE, disabled: true },
  { value: BidPackagePublishStep.REVIEW_AND_PUBLISH, label: "Preview", state: INCOMPLETE, disabled: true },
];

export type BidPackageFormState = Pick<
  SaveBidPackageInput,
  | "id"
  | "existingInvitationText"
  | "planRoomLink"
  | "dueDate"
  | "estimatedAwardDate"
  | "estimatedConstructionDate"
  | "versionNotes"
> & { fragment: PublishBidPackagePage_BidPackageFragment };

export const formConfig: ObjectConfig<BidPackageFormState> = {
  fragment: { type: "value" },
  id: { type: "value" },
  dueDate: { type: "value", rules: [required] },
  estimatedAwardDate: { type: "value", rules: [required] },
  existingInvitationText: { type: "value", rules: [required] },
  estimatedConstructionDate: { type: "value" },
  planRoomLink: { type: "value" },
  versionNotes: { type: "value" },
};

export type PublishBidPackageStepProps = {
  formState: ObjectState<BidPackageFormState>;
};
