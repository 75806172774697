import {
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelectField,
  Switch,
  TreeSelectField,
  useModal,
} from "@homebound/beam";
import { useState } from "react";
import { baseDownloadUrl } from "src/context";
import { ProjectFinishScheduleMetadataQuery } from "src/generated/graphql-types";
import { useProjectLocationsTree } from "src/hooks/useProjectLocationsTree";
import useSetState from "src/hooks/useSetState";
import { openInSelf } from "src/utils/window";
import { ProjectFinishScheduleFilters } from "../ProjectFinishSchedulePage";

type ExportProjectFinishScheduleModalProps = {
  project: ProjectFinishScheduleMetadataQuery["project"];
  exportFilter: ProjectFinishScheduleFilters | undefined;
};

export function ExportProjectFinishScheduleModal(props: ExportProjectFinishScheduleModalProps) {
  const { project, exportFilter } = props;
  const locationOptions = useProjectLocationsTree(project.id);
  const { closeModal } = useModal();
  const [visualMode, setVisualMode] = useState(false);

  // The filter from the parent component is passed in as a prop.
  // Keep a local copy of filters so we don't upsert to parent component
  const [filter, setFilter] = useSetState<ProjectFinishScheduleFilters>(exportFilter);

  return (
    <>
      <ModalHeader>Export {project.name} Finish Schedule</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.my1.gap2.$}>
          <Switch compact label="Visual Mode" selected={visualMode} onChange={setVisualMode} />
          <p>Select cost codes and locations for which you would like to export product selections</p>
          <MultiSelectField
            label="Cost Codes"
            onSelect={(val) => setFilter({ costCodes: val })}
            options={project.stage.costCodes ?? []}
            values={filter.costCodes}
          />
          <TreeSelectField
            label="Locations"
            onSelect={(val) => setFilter({ locationInPath: val.root.values })}
            options={locationOptions}
            values={filter.locationInPath}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap2.$}>
          <Button variant="text" label="Cancel" onClick={closeModal} />
          <Button
            variant="primary"
            label="Export"
            onClick={() => {
              openInSelf(`${baseDownloadUrl()}/pdf?${buildQueryParams(filter, visualMode, project.id)}`);
              closeModal();
            }}
          />
        </div>
      </ModalFooter>
    </>
  );
}

function buildQueryParams(filter: ProjectFinishScheduleFilters, visualMode: boolean, projectId: string) {
  const { costCodes, locationInPath } = filter;
  // build the query params for the PDF download url
  const params = new URLSearchParams({
    projectId,
    type: visualMode ? "projectFinishScheduleVisualMode" : "projectFinishSchedule",
    ...(costCodes?.nonEmpty && { costCodeIds: costCodes.join() }),
    ...(locationInPath?.nonEmpty && { locationInPath: locationInPath.join() }),
  });
  return params.toString();
}
