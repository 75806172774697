import {
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  GridTableApi,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createBillPageUrl, createChangeEventUrl } from "src/RouteUrls";
import {
  BillType,
  ChangeEventBudgetPhase,
  ChangeEventStatus,
  ChangeEventType,
  CreateCeModalQuery,
  SaveChangeEventInput,
  SaveChangeEventLineItemInput,
  SaveCommitmentLineItemInput,
  useBillEditor_CreateCommitmentMutation,
  useBillEditor_SaveChangeEventMutation,
  useCreateCeModalQuery,
} from "src/generated/graphql-types";
import { changeEventDefaultTypeOptionByLotType } from "src/routes/projects/change-events/components/CreateChangeEventModal";
import { changeEventTypeToNameMapper, queryResult } from "src/utils";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { BillFormInput, BillFormLineItem, BillReviewFormLineItem } from "../BillEditor";
import { getBillableLimit } from "../utils";
import { Row } from "./BillLineItemsTable";
import { ChangeEventOverview } from "./ChangeEventOverview";

export const changeEventFormConfig: ObjectConfig<SaveChangeEventInput> = {
  internalNote: { type: "value" },
  projectStageId: { type: "value" },
  reasonId: { type: "value", rules: [required] },
  title: { type: "value" },
  budgetPhase: { type: "value" },
  type: { type: "value", rules: [required] },
};

export const fromBillDefaultChangeEventTitle = {
  prefix: "Bill",
  sufix: "Review",
};

type BillChangeEventSetupModalProps = {
  projectId: string;
  formState: ObjectState<BillFormInput>;
  tableApi: GridTableApi<Row>;
  handleSave: () => Promise<void>;
};

export function BillChangeEventSetupModal(props: BillChangeEventSetupModalProps) {
  const query = useCreateCeModalQuery({ variables: { projectId: props.projectId } });
  return queryResult(query, (data) => <BillChangeEventSetupModalView {...props} data={data} />);
}

function handleBlisWithBillableLimitAmountExceeded(formState: ObjectState<BillFormInput>, tableApi: GridTableApi<Row>) {
  const selectedRowIds = tableApi.getSelectedRowIds();
  return formState.lineItems.value
    .filter((li) => li.amountInCents! > getBillableLimit(li))
    .filter((li) => selectedRowIds.includes(li.commitmentLineItemId ?? ""))
    .map((li) => ({
      ...li,
      amountInCents: li.amountInCents! - getBillableLimit(li),
    }));
}

function handleLineItemsToCreateCommitmentsAndChangeEvent(
  lineItemsOverBillableLimit: BillFormLineItem[],
  reviewLineItems: BillReviewFormLineItem[],
) {
  const changeEventLineItems: SaveChangeEventLineItemInput[] = [];
  const commitmentLineItems: SaveCommitmentLineItemInput[] = [];
  const lineItemsForOverview: (SaveCommitmentLineItemInput & { name: string; budgetImpactInCents?: number | null })[] =
    [];

  lineItemsOverBillableLimit.forEach((li) => {
    changeEventLineItems.push({
      name: li.displayName,
      projectItemId: li.projectItemId,
      quantity: 1,
      totalCostInCents: li.amountInCents!,
    });
    commitmentLineItems.push({
      projectItemId: li.projectItemId,
      costChangeInCents: li.amountInCents!,
      quantity: 1,
    });
    lineItemsForOverview.push({
      projectItemId: li.projectItemId,
      costChangeInCents: li.amountInCents,
      quantity: 1,
      name: li.displayName,
    });
  });
  reviewLineItems.forEach((li) => {
    changeEventLineItems.push({
      name: li.displayName,
      costType: li.costType,
      locationId: li.location?.id,
      projectItemId: li.id,
      quantity: li.quantity,
      totalCostInCents: Number(li.budgetImpactInCents) > 0 ? li.budgetImpactInCents : (li.billAmountInCents ?? 0),
    });
    commitmentLineItems.push({
      projectItemId: li.id,
      costChangeInCents: li.billAmountInCents,
      quantity: li.quantity,
    });
    lineItemsForOverview.push({
      projectItemId: li.id,
      costChangeInCents: li.billAmountInCents,
      budgetImpactInCents: li.budgetImpactInCents,
      quantity: li.quantity,
      name: li.displayName,
    });
  });

  return { changeEventLineItems, commitmentLineItems, lineItemsForOverview };
}

type BillChangeEventSetupModalViewProps = BillChangeEventSetupModalProps & {
  data: CreateCeModalQuery;
};

export function BillChangeEventSetupModalView({
  data,
  formState,
  tableApi,
  handleSave,
}: BillChangeEventSetupModalViewProps) {
  const [saveChangeEvent] = useBillEditor_SaveChangeEventMutation();
  const [createCommitmentFromBill] = useBillEditor_CreateCommitmentMutation();
  const [step, setStep] = useState<"setup" | "overview">("setup");
  const { closeModal } = useModal();
  const history = useHistory();
  const { triggerNotice } = useSnackbar();

  const {
    changeEventReasons: cers,
    project: { lotType },
  } = data;

  const changeEventFormState = useFormState({
    config: changeEventFormConfig,
    init: {
      input: {
        budgetPhase: ChangeEventBudgetPhase.Revised,
        status: ChangeEventStatus.Proposed,
        type: lotType?.code && changeEventDefaultTypeOptionByLotType[lotType.code],
        title: "",
        internalNote: "",
      },
      onlyOnce: true,
    },
  });
  const testIds = useTestIds({}, "changeEventSetupModal");

  const changeEventReasons = filterArchivedUnlessSelected(cers).filter(
    (cer) => (formState.type.value === BillType.Overhead ? !cer.isEpo : true), // Filter out VPO-related reasons for overhead bills
  );

  const overheadLineItems = formState.lineItems.value
    .map((li) => {
      const diff = (li.amountInCents ?? 0) - (li.uncommittedBudgetAmountInCents ?? 0);
      return diff > 0
        ? { name: li.displayName, projectItemId: li.projectItemId, totalCostInCents: diff, quantity: 1 }
        : undefined;
    })
    .compact();

  const lineItemsOverBillableLimit = useMemo(
    () => handleBlisWithBillableLimitAmountExceeded(formState, tableApi),
    [formState, tableApi],
  );

  const { changeEventLineItems, commitmentLineItems, lineItemsForOverview } =
    handleLineItemsToCreateCommitmentsAndChangeEvent(lineItemsOverBillableLimit, formState.reviewLineItems.value);

  const selectedReason = useComputed(
    () => changeEventReasons.find((cer) => cer.id === changeEventFormState.reasonId.value),
    [changeEventFormState.reasonId.value],
  );

  const changeEventInternalNote = `This change event was automatically generated during the review process for Bill #${formState.tradePartnerNumber.value} from ${formState.tradePartner.value?.name}.`;

  return (
    <Observer>
      {() => (
        <div {...testIds}>
          <ModalHeader>Bill review process</ModalHeader>
          <ModalBody>
            {step === "setup" && (
              <FormLines width="full" labelSuffix={{ required: "*" }}>
                <BoundSelectField
                  field={changeEventFormState.reasonId}
                  label="Reason for Change"
                  options={changeEventReasons}
                />
                <BoundSelectField
                  label="Change Event Type"
                  disabled={selectedReason?.isEpo}
                  field={changeEventFormState.type}
                  options={Object.entries(ChangeEventType)}
                  getOptionValue={([_, type]) => type}
                  getOptionLabel={([_, type]) => `${lotType.clientNoun} ${changeEventTypeToNameMapper(type)}`}
                />
                <BoundTextField field={changeEventFormState.title} label="Title" />
                <BoundTextAreaField field={changeEventFormState.internalNote} label="Description" />
                {selectedReason?.isEpo && (
                  <div css={Css.df.fdr.cg1.pr1.$}>
                    <Icon icon="infoCircle" inc={3} />
                    <div>VPO-related change events use funds from the contingency budget.</div>
                  </div>
                )}
              </FormLines>
            )}
            {step === "overview" && (
              <ChangeEventOverview
                changeEventFormState={changeEventFormState}
                formState={formState}
                changeEventData={data}
                overheadLineItems={overheadLineItems}
                changeEventLineItems={changeEventLineItems}
                lineItems={lineItemsForOverview}
                isEpoChangeEvent={!!selectedReason?.isEpo}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button label="Cancel" onClick={closeModal} variant="secondary" />
            {step === "setup" && (
              <Button
                disabled={!changeEventFormState.valid}
                label="Next"
                onClick={() => {
                  if (changeEventFormState.canSave()) {
                    changeEventFormState.set({
                      budgetPhase: ChangeEventBudgetPhase.Revised,
                      projectStageId: formState.projectStageId.value,
                    });
                    setStep("overview");
                  }
                }}
              />
            )}
            {step === "overview" && (
              <Button
                label="Submit"
                onClick={async () => {
                  await handleSave();

                  const defaultTitle = `${fromBillDefaultChangeEventTitle.prefix} #${formState.tradePartnerNumber.value} ${fromBillDefaultChangeEventTitle.sufix}`;
                  const changeEventTitle = changeEventFormState.title.value
                    ? `${changeEventFormState.title.value} (${defaultTitle})`
                    : defaultTitle;
                  const changeEventDescription = changeEventFormState.internalNote.value
                    ? changeEventInternalNote + "\n" + changeEventFormState.internalNote.value
                    : changeEventInternalNote;

                  if (formState.type.value === BillType.Overhead) {
                    const { data } = await saveChangeEvent({
                      variables: {
                        input: {
                          ...changeEventFormState.value,
                          title: changeEventTitle,
                          internalNote: changeEventDescription,
                          createdById: formState.id.value,
                          status: ChangeEventStatus.Requested,
                          tradesToDraftCommitmentsFor: [],
                          lineItems: overheadLineItems,
                        },
                      },
                    });
                    closeModal();
                    const changeEvent = data?.saveChangeEvent.changeEvent;
                    history.push(createChangeEventUrl(formState.project.value?.id, changeEvent?.id!));
                    triggerNotice({
                      message: "Bill submitted for approval",
                      action: {
                        label: "Go to Bill",
                        onClick: createBillPageUrl({ idOrAdd: formState.id.value! }),
                        variant: "tertiary",
                      },
                    });
                  } else {
                    const { budgetPhase, projectStageId, ...ce } = changeEventFormState.value;

                    // Ensure bill line items are using billable limit
                    formState.lineItems.value.forEach((li) => {
                      const billableLimit = getBillableLimit(li);
                      if (li.amountInCents! > billableLimit) {
                        li.amountInCents = billableLimit;
                      }
                    });

                    const { data } = await createCommitmentFromBill({
                      variables: {
                        input: {
                          billId: formState.id.value!,
                          changeEvent: {
                            ...ce,
                            title: changeEventTitle,
                            internalNote: changeEventDescription,
                            createdById: formState.id.value,
                            lineItems: changeEventLineItems,
                          },
                          lineItems: commitmentLineItems,
                        },
                      },
                    });

                    closeModal();

                    const changeEvent = data?.createCommitmentFromBill.changeEvent;

                    history.push(createChangeEventUrl(formState.project.value?.id, changeEvent?.id!));
                    triggerNotice({
                      message: "New commitment created and change event created pending approval",
                      action: {
                        label: "Bill updated",
                        onClick: createBillPageUrl({ idOrAdd: formState.id.value! }),
                        variant: "tertiary",
                      },
                    });
                  }
                }}
              />
            )}
          </ModalFooter>
        </div>
      )}
    </Observer>
  );
}
