import { Button, Css, multiFilter, useModal, usePersistedFilter } from "@homebound/beam";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { createProjectLotSummaryUrl } from "src/RouteUrls";
import { useSideNavContext } from "src/components/layout/SideNavContext";
import {
  NamedFragment,
  ProjectFinishScheduleMetadataQuery,
  useProjectFinishScheduleMetadataQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { count, queryResult } from "src/utils";
import { ExportProjectFinishScheduleModal } from "./components/ExportProjectFinishScheduleModal";
import { ProjectFinishScheduleTable } from "./components/ProjectFinishScheduleTable";
import { useProjectLocationsTreeFilter } from "./useProjectLocationsTreeFilter";

export type ProjectFinishScheduleParams = { projectId: string };

export type ProjectFinishScheduleFilters = {
  costCodes: string[];
  locationInPath: string[];
};

export function ProjectFinishSchedulePage() {
  const { setSideNavState } = useSideNavContext();
  const { projectId } = useParams<ProjectFinishScheduleParams>();

  // Since this component is part of the project layout, we need to update the side navigation state
  // to ensure it remains hidden when the component mounts.
  useEffect(() => {
    setSideNavState("hidden");

    return () => {
      // Cleanup function to reset the side navigation state when the component unmounts
      setSideNavState("expanded");
    };
  }, [setSideNavState]);

  const projectQuery = useProjectFinishScheduleMetadataQuery({
    variables: { projectId },
  });
  return queryResult(projectQuery, ({ project }) => <ProjectFinishScheduleView project={project} />);
}

type ProjectFinishScheduleViewProps = {
  project: ProjectFinishScheduleMetadataQuery["project"];
};

function ProjectFinishScheduleView(props: ProjectFinishScheduleViewProps) {
  const { project } = props;
  const { openModal } = useModal();
  const projectOptions = project.readyPlanConfig?.options.map((o) => o.readyPlanOption) ?? [];
  const designOptions = projectOptions
    .filter((rpo) => rpo.optionGroup.forDesignPackages)
    .sortBy((rpo) => rpo.optionGroup.order);
  // Breadcrumb href is empty since this is only being used as a title in the header
  const breadCrumb = [{ href: "", label: "Finish Schedule" }];

  const { filter, setFilter, filterDefs } = useFinishScheduleFilters(project.stage?.costCodes ?? [], project.id);

  return (
    <>
      <PageHeader
        xss={Css.mb0.$}
        title={project.name}
        breadcrumb={breadCrumb}
        subTitle={`${designOptions.map((rpo) => rpo.name).join(", ")} (${count(projectOptions.length, "option")})`}
        backButton={createProjectLotSummaryUrl(project.id)}
        right={
          <>
            <div css={Css.df.gap3.$}>
              {/* TODO: follow-up task to add DP link */}
              {/* <Button
                variant="text"
                label="Design Package"
                onClick={createDesignPackageUrl(designPackage.id, designPackage.version.id)}
                icon="linkExternal"
              /> */}
              <Button
                label="Export"
                onClick={() =>
                  openModal({
                    content: <ExportProjectFinishScheduleModal exportFilter={filter} project={project} />,
                  })
                }
              />
            </div>
          </>
        }
      />
      <ProjectFinishScheduleTable filter={filter} setFilter={setFilter} filterDefs={filterDefs} />
    </>
  );
}

function useFinishScheduleFilters(costCodes: NamedFragment[], projectId: string) {
  const locationsTreeFilter = useProjectLocationsTreeFilter(projectId);

  const filterDefs = useMemo(() => {
    return {
      costCodes: multiFilter({
        label: "Cost Code",
        options: costCodes?.sortByKey("name") ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
        sizeToContent: true,
      }),
      locationInPath: locationsTreeFilter,
    };
  }, [locationsTreeFilter, costCodes]);

  const { filter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: `project-finish-schedule-filter-${projectId}`,
  });

  return { filter, setFilter, filterDefs };
}
