import { Css, useComputed, useSnackbar, useTestIds } from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import {
  IncrementalCollectionOp,
  InputMaybe,
  PlanPackageProgramDataFragment,
  ProgramDataStepMetadataQuery,
  SaveProgramDataInput,
  useProgramDataStepMetadataQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import {
  BasementCard,
  PlanDetailsCard,
  PlanDetailsCardLine,
} from "../../../developments/plan-and-options/components/PlanDetailsCard";
import {
  AddOptionsMetadata,
  AddProgramDetailsForm,
  OptionsEnumNames,
  readyPlanProgramDataConfig,
} from "../../../developments/plan-and-options/components/utils";

import { useCallback, useEffect } from "react";
import { useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { queryResult } from "src/utils";
import { PlanPackageNextStepButton } from "./components/PlanPackageNextStepButton";

type ProgramDataStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};

export type ProgramDataMetadata = Pick<ProgramDataStepMetadataQuery, "enumDetails"> | undefined;

export function ProgramDataStep({ id, versionId, setStepDirty }: ProgramDataStepProps) {
  const query = useProgramDataStepMetadataQuery({ variables: { id, versionId } });

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Program Data"
          tooltip="Choose the plan details that you would like to be applicable for this Readyplan"
        />
      }
      body={queryResult(query, {
        data: (data) =>
          data.planPackage ? (
            <DataView
              id={id}
              versionId={versionId}
              metadata={data}
              planPackage={data.planPackage}
              setStepDirty={setStepDirty}
            />
          ) : (
            <>"No Plan Package found"</>
          ),
      })}
    />
  );
}

export function DataView({
  id,
  versionId,
  metadata,
  planPackage,
  setStepDirty,
}: {
  id: string;
  versionId: string;
  metadata: ProgramDataMetadata;
  planPackage: PlanPackageProgramDataFragment;
  setStepDirty: (dirty: boolean) => void;
}) {
  const { triggerNotice } = useSnackbar();
  const formState = useFormState({
    config: readyPlanProgramDataConfig,
    init: {
      input: planPackage,
      map: (planPackage) => ({
        id: id,
        description: planPackage?.description,
        programData: {
          ...planPackage?.programData,
          basementConfig: planPackage?.programData?.basementConfig?.code,
          garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
          primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
        },
        minProgramData: {
          ...planPackage?.minProgramData,
          basementConfig: planPackage?.programData?.basementConfig?.code,
          garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
          primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
        },
        maxProgramData: {
          ...planPackage?.maxProgramData,
          basementConfig: planPackage?.programData?.basementConfig?.code,
          garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
          primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
        },
      }),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const tid = useTestIds({}, "programDataStep");
  const generalSection = generalLinesForm(formState);
  const bedroomsSection = bedroomLinesForm(formState, metadata);
  const bathroomsSection = bathroomLinesForm(formState);
  const garageSection = garageLinesForm(formState, metadata);
  const disabled = disableBasedOnPotentialOperation(planPackage.canEdit);

  const [savePlanPackage] = useSavePlanPackageDetailsMutation();

  const onSave = useCallback(async () => {
    // Save Program Data
    if ((formState.programData.dirty || formState.description.dirty) && planPackage) {
      const { programData, ...rest } = formState.changedValue;
      const response = await savePlanPackage({
        variables: {
          input: {
            id,
            ...rest,
            programDatas: [
              {
                id: formState.programData.readyPlanProgramData.value?.id,
                op: IncrementalCollectionOp.Include,
                programData,
              },
            ],
          },
        },
      });
      formState.commitChanges();

      triggerNotice({ message: `Your Plan Package Program Data has been updated!` });
      return response;
    }
  }, [formState, planPackage, savePlanPackage, id, triggerNotice]);

  return (
    <>
      <div css={Css.py3.bgGray100.$}>
        <div css={Css.df.jcc.fdc.aic.gap2.$} {...tid.sections}>
          <PlanDetailsCard title="General" headerTooltips lines={generalSection} disabled={disabled} />
          <PlanDetailsCard title="Bedrooms" icon="bed" lines={bedroomsSection} disabled={disabled} />
          <PlanDetailsCard title="Bathrooms" icon="bath" lines={bathroomsSection} disabled={disabled} />
          <PlanDetailsCard title="Dining" icon="chair" lines={diningLinesForm(formState)} disabled={disabled} />
          <PlanDetailsCard
            title="Entertaining"
            icon="image"
            lines={entertainingLinesForm(formState)}
            disabled={disabled}
          />
          <PlanDetailsCard
            title="Work from Home"
            icon="todo"
            lines={workFromHomeLinesForm(formState)}
            disabled={disabled}
          />
          <PlanDetailsCard title="Garage" icon="car" lines={garageSection} disabled={disabled} />
          <BasementCard
            title="Basement"
            icon="basement"
            formState={formState}
            metadata={metadata}
            disabled={disabled}
          />
        </div>
      </div>
      <PlanPackageNextStepButton planId={id} versionId={versionId} onSave={onSave} />
    </>
  );
}

function generalLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Stories", "stories"),
    { label: "Description", base: formState.description, type: "textArea" as const },
  ];
}

function bedroomLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: ProgramDataMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Bedrooms", "bedrooms"),
    line(formState, "Closets in Primary Suite", "closetsInPrimarySuite"),
    line(formState, "First Floor Bedrooms", "firstFloorBedrooms"),
    line(formState, "Primary Bedroom Location", "primaryBedroom", "programDataPrimaryBedroomConfig", metadata),
  ];
}

function bathroomLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Full Baths", "fullBaths"), line(formState, "Half Baths", "halfBaths")];
}

function diningLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Formal", "diningRoom"), line(formState, "Casual", "casualDining")];
}

function entertainingLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Media Rooms", "mediaRooms"),
    line(formState, "Lofts/Game Rooms/Flex Rooms", "loftGameFlexRooms"),
  ];
}

function workFromHomeLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Offices", "offices"), line(formState, "Workspaces", "workspaces")];
}

function garageLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: ProgramDataMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Garage Load", "garageConfiguration", "programDataGarageConfig", metadata),
    line(formState, "Attached", "garageAttached"),
    line(formState, "Detached", "garageDetached"),
    line(formState, "Carport", "garagePort"),
  ];
}

// Only number fields can work here given we're setting up base/min/max
type ProgramDataNumberField = PickByType<SaveProgramDataInput, InputMaybe<number>>;

type ProgramDataEnumField = PickByType<SaveProgramDataInput, InputMaybe<string>>;

function line(
  formState: ObjectState<AddProgramDetailsForm>,
  label: string,
  field: keyof ProgramDataNumberField | keyof ProgramDataEnumField,
  enumName?: OptionsEnumNames,
  metadata?: AddOptionsMetadata,
): PlanDetailsCardLine {
  return {
    label,
    base: formState.programData[field] as any,
    min: formState.minProgramData[field] as any,
    max: formState.maxProgramData[field] as any,
    minMaxComputed: true,
    enumName,
    metadata,
  };
}

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};
