import { Button, Css, IconButton, ModalBody, ModalFooter, ModalHeader, Palette, useModal } from "@homebound/beam";
import {
  UpdatesAvailableProductOfferingFragment,
  useAcceptProductOfferingUpdatesMutation,
} from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { DropSourceSection, ReadyPlanSourceSection } from "./UpdatesSourceSection";

type UpdatesAvailableButtonProps = {
  productOfferingId: string;
  updates: UpdatesAvailableProductOfferingFragment[];
  copyInProgress?: boolean;
  showText?: boolean;
};

export function UpdatesAvailableButton({
  copyInProgress,
  updates,
  productOfferingId,
  showText = true,
}: UpdatesAvailableButtonProps) {
  const { openModal } = useModal();

  if (copyInProgress || updates.isEmpty) {
    return null;
  }

  if (!showText) {
    return (
      <IconButton
        inc={2}
        label="Updates available"
        icon="arrowFromBottom"
        bgColor={Palette.Yellow200}
        tooltip={updates
          .map((u) =>
            u.readyPlanAggregateVersionSource
              ? `${u.readyPlanAggregateVersionSource?.readyPlan.name} - v${u.readyPlanAggregateVersionSource?.version}`
              : `${u.developmentDropSource?.name}`,
          )
          .join(", ")}
        onClick={() =>
          openModal({ content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} /> })
        }
      />
    );
  }

  return (
    <Button
      variant="caution"
      icon="arrowFromBottom"
      label="Updates available"
      tooltip={updates
        .map((u) =>
          u.readyPlanAggregateVersionSource
            ? `${u.readyPlanAggregateVersionSource?.readyPlan.name} - v${u.readyPlanAggregateVersionSource?.version}`
            : `${u.developmentDropSource?.name}`,
        )
        .join(", ")}
      onClick={() =>
        openModal({ content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} /> })
      }
    />
  );
}

type UpdatesAvailableModalProps = UpdatesAvailableButtonProps;

export function UpdatesAvailableModal({ updates, productOfferingId }: UpdatesAvailableModalProps) {
  const [acceptProductOfferingUpdates] = useAcceptProductOfferingUpdatesMutation();
  const { closeModal } = useModal();

  async function onAcceptUpdate() {
    await acceptProductOfferingUpdates({
      variables: { input: { productOfferingId, productOfferingUpdateIds: updates.map((u) => u.id) } },
      refetchQueries: ["ProductOfferingPage"],
    });
    closeModal();
  }
  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {updates.map((u) =>
            u.readyPlanAggregateVersionSource ? (
              <ReadyPlanSourceSection key={u.id} update={u} />
            ) : (
              <DropSourceSection key={u.id} update={u} />
            ),
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button label={`Accept ${pluralize(updates.length, "Update")}`} onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}
