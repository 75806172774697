import { ObjectConfig, useFormState } from "@homebound/form-state";
import {
  CommitmentEditorDataFragment,
  DocumentEditorDetailFragment,
  Maybe,
  SaveCommitmentInput,
  useSaveNewCommitmentMutation,
} from "src/generated/graphql-types";
import {
  CommitmentEditorLineItem,
  CommitmentEditorLineItems,
  commitmentLineItemsFormConfig,
  mapCommitmentLineItemToForm,
  mapToSaveLineItemInput,
} from "src/routes/projects/commitments/CommitmentEditorLineItems";

type LineItemsTabProps = {
  commitment: CommitmentEditorDataFragment;
};

export function LineItemsTab({ commitment }: LineItemsTabProps) {
  const [saveCommitment] = useSaveNewCommitmentMutation();
  const canEditLineItems = commitment.canEditLineItems.allowed;

  const formState = useFormState({
    config: formConfig,
    init: { input: commitment, map: mapToForm },
    autoSave: async (c) => {
      // new cli has no id prior to auto save
      // added to the UI via modal (formState.lineItems.add) in CommitmentEditorLineItems.tsx
      await saveCommitment({ variables: { input: mapToInput({ ...c.changedValue }) } });
    },
    readOnly: !canEditLineItems,
  });

  return (
    <CommitmentEditorLineItems
      projectId={commitment.project.id}
      projectStageId={commitment.projectStage.id}
      formState={formState}
      stage={commitment.projectStage.stage.code}
      canEditLineItems={canEditLineItems}
      enableProductConfigPlan={commitment.project.enableProductConfigPlan}
      salesTaxInBasisPoints={commitment.project.salesTaxInBasisPoints}
    />
  );
}

// Tweak documentId as CommitmentEditorLineItems formState needs it
type FormInput = Omit<SaveCommitmentInput, "commitmentType" | "documentId" | "lineItems"> & {
  document?: Maybe<DocumentEditorDetailFragment>;
  lineItems: Maybe<CommitmentEditorLineItem[]>;
};

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  lineItems: {
    type: "list",
    config: commitmentLineItemsFormConfig,
  },
};

function mapToForm(commitment: CommitmentEditorDataFragment): FormInput {
  return {
    id: commitment.id,
    lineItems: mapCommitmentLineItemToForm(commitment.lineItems),
  };
}

function mapToInput(form: FormInput): SaveCommitmentInput {
  const { lineItems, ...others } = form;
  return {
    ...others,
    lineItems: mapToSaveLineItemInput(lineItems),
  };
}
