import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  Tag,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import { MaterialVariantSelectField } from "src/components/autoPopulateSelects/MaterialVariantSelectField";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  AddEditItemModal_ItemFragment,
  AddEditItemModal_LocationFragment,
  AddEditItemModal_MvFragment,
  AddEditItemModal_UomFragment,
  AddMaterialItemModalMetadataQuery,
  CostType,
  MaterialType,
  Maybe,
  SaveTakeoffLineItemInput,
  useAddChangeEventLineItemsTabMutation,
  useAddMaterialItemModalMetadataQuery,
} from "src/generated/graphql-types";
import {
  SelectedTask,
  TakeoffTaskBoundSelectField,
} from "src/routes/libraries/plan-package/takeoffs/components/AddItems/utils";

type AddMaterialItemModalProps = {
  projectId: string;
  changeEventId: string;
};

export function AddMaterialItemModal(props: AddMaterialItemModalProps) {
  const { projectId, changeEventId } = props;
  const { data } = useAddMaterialItemModalMetadataQuery({ variables: { projectId } });
  const [addCeli] = useAddChangeEventLineItemsTabMutation();

  const { closeModal, addCanClose } = useModal();

  const formState = useFormState({
    config: addItemConfig,
  });

  addCanClose(() => {
    // eslint-disable-next-line no-restricted-globals
    return formState.dirty ? confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  const onSaveCELI = async () => {
    const { itemId, locationId, materialVariantId, taskId, unitOfMeasureId, quantity } = formState.value;
    await addCeli({
      variables: {
        input: {
          changeEventLineItems: [
            {
              changeEventId,
              itemId,
              locationId,
              unitOfMeasureId,
              quantity,
              // Q: is this the correct cost type? or should ir derive from the material variant?
              costType: CostType.Materials,
              proposedMaterialVariantId: materialVariantId,
              proposedTaskId: taskId,
            },
          ],
        },
      },
    });
    formState.commitChanges();

    closeModal();
  };

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>Add Material</span>
          </ModalHeader>
          <ModalBody>
            <div css={Css.df.fdc.gap2.pb3.$}>
              <MaterialFields formState={formState} locations={data?.locations} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
            <SubmitButton label="Save" form={formState} onClick={onSaveCELI} size="lg" />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type AddEditItemObjectState = SaveTakeoffLineItemInput & {
  materialVariant: Maybe<AddEditItemModal_MvFragment>;
  unitOfMeasure: Maybe<AddEditItemModal_UomFragment>;
  item: Maybe<AddEditItemModal_ItemFragment>;
  location: Maybe<Omit<AddEditItemModal_LocationFragment, "parents">>;
  newLocationName?: Maybe<string>;
};

type MaterialFieldsProps = {
  formState: ObjectState<AddEditItemObjectState>;
  locations?: AddMaterialItemModalMetadataQuery["locations"];
};

function MaterialFields({ formState, locations = [] }: MaterialFieldsProps) {
  const [selectedTask, setSelectedTask] = useState<SelectedTask | undefined | null>();
  const setDefaults = useCallback(
    (selectedMaterialVariant: AddEditItemModal_MvFragment | undefined) => {
      // Default the item UOM, even if a value had been specified
      if (selectedMaterialVariant?.listing?.item.unitOfMeasure?.id) {
        formState.unitOfMeasureId.set(selectedMaterialVariant?.listing?.item.unitOfMeasure?.id);
        formState.unitOfMeasure.set(selectedMaterialVariant?.listing?.item.unitOfMeasure);
      }
      // Default the item task
      if (
        formState.locationId.value &&
        formState.materialVariantId.value &&
        !formState.taskId.value &&
        selectedMaterialVariant?.listing?.item?.planTaskMappings?.nonEmpty
      ) {
        const taskMappings = selectedMaterialVariant?.listing?.item?.planTaskMappings;
        const exactTaskMatch = taskMappings.find((ptm) => ptm.location?.id === formState.locationId.value);
        const wildCardLocationMatch = taskMappings.find((ptm) => !ptm.location);
        const defaultTask = exactTaskMatch ?? wildCardLocationMatch;

        if (defaultTask) {
          formState.taskId.set(defaultTask?.task.id);
          setSelectedTask(defaultTask.task);
        }
      }
    },
    [formState],
  );
  // When a material cost type is selected then...
  const onMaterialSelect = useCallback(
    (id: string | undefined, selectedMaterialVariant: AddEditItemModal_MvFragment | undefined) => {
      // Set the material variant on the line
      formState.materialVariantId.set(id);
      formState.materialVariant.set(selectedMaterialVariant);

      // We set the defaults
      setDefaults(selectedMaterialVariant);
    },
    [formState, setDefaults],
  );

  return (
    <>
      <MaterialVariantSelectField
        label="Material*"
        field={formState.materialVariantId}
        nothingSelectedText="Please select a material"
        filter={{
          type: [MaterialType.Construction, MaterialType.Product],
          includeArchived: false,
        }}
        initialOptions={[]}
        getOptionLabel={(o) => `${o.code} - ${o.displayName}`}
        getOptionValue={(o) => o.id}
        placeholder="Select material"
        onSelect={onMaterialSelect}
      />
      <BoundSelectField
        field={formState.locationId}
        label="Location*"
        onSelect={(locationId, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
          formState.materialVariant.value && setDefaults(formState.materialVariant.value);
        }}
        placeholder={locations ? "Select a location" : "Loading..."}
        options={locations}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => o.name}
        getOptionMenuLabel={({ name, displayLocationPath, type }) => (
          <span>
            <Tag xss={Css.m0.$} type="info" text={type.name} /> {name}{" "}
            {name !== displayLocationPath && <span css={Css.gray500.$}>{displayLocationPath}</span>}
          </span>
        )}
        hideErrorMessage
      />
      <TakeoffTaskBoundSelectField
        allowNone={true}
        selectedTask={selectedTask}
        readOnly={false}
        formState={formState}
        required={true}
      />
      <CommonFields formState={formState} />
    </>
  );
}

type CommonFieldsProps = {
  formState: ObjectState<AddEditItemObjectState>;
};

function CommonFields({ formState }: CommonFieldsProps) {
  return (
    <div css={Css.df.jcsb.gap1.$}>
      <UnitsOfMeasureBoundSelectField
        label="UoM*"
        field={formState.unitOfMeasureId}
        onSelect={(uomId, uom) => {
          formState.unitOfMeasureId.set(uomId);
          formState.unitOfMeasure.set(uom);
        }}
      />
      <BoundNumberField
        label="Qty"
        field={formState.quantity}
        disabled={
          formState.unitOfMeasure.value &&
          !formState.unitOfMeasure.value?.useQuantity &&
          `${formState.unitOfMeasure.value!.name} does not uses Quantity`
        }
      />
    </div>
  );
}

const addItemConfig: ObjectConfig<AddEditItemObjectState> = {
  id: { type: "value" },
  itemId: { type: "value" },
  materialVariantId: { type: "value" },
  quantity: { type: "value" },
  unitOfMeasureId: { type: "value", rules: [required] },
  locationId: { type: "value", rules: [required] },
  taskId: { type: "value", rules: [required] },

  // Also store the more detailed objects for easier access/logic needs
  location: { type: "value" },
  materialVariant: {
    type: "object",
    config: {
      id: { type: "value" },
      code: { type: "value" },
      displayName: { type: "value" },
      isArchived: { type: "value" },
      listing: { type: "value" },
      materialAttributeValues: { type: "value" },
      effectiveMADs: { type: "value" },
    },
  },
  unitOfMeasure: { type: "value", rules: [required] },
  item: {
    type: "object",
    config: {
      unitOfMeasure: { type: "value" },
      materialAttributeDimensions: {
        type: "list",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          useInTakeoff: { type: "value" },
          unitOfMeasure: { type: "value" },
          values: {
            type: "list",
            config: {
              id: { type: "value" },
              code: { type: "value" },
              textValue: { type: "value" },
              dimension: { type: "value" },
            },
          },
          type: { type: "value" },
        },
      },
    },
  },
};
