import { Css, FullBleed, Icon } from "@homebound/beam";
import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router";
import {
  createDevelopmentBidPackagesUrl,
  createDevelopmentChangeLogUrl,
  createDevelopmentDocumentsUrl,
  createDevelopmentDropsUrl,
  createDevelopmentJobLogsUrl,
  createDevelopmentLotSchedulesUrl,
  createDevelopmentLotSummaryUrl,
  createDevelopmentPlanAndOptionsUrl,
  createDevelopmentProcurementUrl,
  createDevelopmentPurchaseOrdersUrl,
  createDevelopmentScheduleUrl,
  createDevelopmentScopeTemplatesUrl,
  createDevelopmentSettingsUrl,
  createDevelopmentTaskLookaheadReportUrl,
  createDevelopmentTeamMembersUrl,
  createProductOfferingsUrl,
} from "src/RouteUrls";
import { SideNavAndRouterConfig, SideNavProps, navLinksToRoutes } from "src/components/layout/SideNav";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { FeatureFlagType, useDevelopmentAllLotsQuery } from "src/generated/graphql-types";
import { DevelopmentChangeLogPage } from "src/routes/developments/change-log/DevelopmentChangeLogPage";
import { LotSummaryPage } from "src/routes/developments/lot-summary/LotSummaryPage";
import { DevelopmentContractLineItemsPage } from "src/routes/developments/procurement/DevelopmentContractLineItemsPage";
import { DevelopmentContractOverviewPage } from "src/routes/developments/procurement/DevelopmentContractOverviewPage";
import { DevelopmentContractPage } from "src/routes/developments/procurement/DevelopmentContractPage";
import { DevelopmentProcurementPage } from "src/routes/developments/procurement/DevelopmentProcurementPage";
import { PurchaseOrdersPage } from "src/routes/developments/purchase-orders/PurchaseOrdersPage";
import { DevelopmentBulkSchedulePage } from "src/routes/developments/schedule/DevelopmentBulkSchedulePage";
import { DevelopmentSettingsPage } from "src/routes/developments/settings/DevelopmentSettingsPage";
import { DevelopmentScopeTemplatePage } from "src/routes/developments/templates/DevelopmentScopeTemplatePage";
import { DevelopmentScopeTemplatesPage } from "src/routes/developments/templates/DevelopmentScopeTemplatesPage";
import { ScopeTemplateCostCalculatorPage } from "src/routes/developments/templates/ScopeTemplateCostCalculatorPage";
import { JobLogsPage } from "src/routes/projects/job-logs/JobLogsPage";
import { developmentPaths } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { DocumentsPage } from "../projects/documents/DocumentsPage";
import { BidPackagesPage } from "./bid-packages/BidPackagesPage";
import { DevelopmentContextProvider } from "./context/DevelopmentContext";
import { DevelopmentDropsPage } from "./drops/DevelopmentDropsPage";
import { PlanAndOptionsPage } from "./plan-and-options/PlanAndOptionsPage";
import { PlanDetailsPage } from "./plan-and-options/PlanDetailsPage";
import { ProductOfferingConfigsComparePage } from "./product-offering-configs/ProductOfferingConfigsComparePage";
import { ProductOfferingConfigsPage } from "./product-offering-configs/ProductOfferingConfigsPage";
import { ProductOfferingApplyToProjectsPage } from "./product-offerings/ProductOfferingApplyToProjectsPage";
import { ProductOfferingConfigsPage as ProductOfferingSpecificConfigsPage } from "./product-offerings/ProductOfferingConfigsPage";
import { ProductOfferingPage } from "./product-offerings/ProductOfferingPage";
import { ProductOfferingsPage } from "./product-offerings/ProductOfferingsPage";
import { ProductOfferingVersionHistoryPage } from "./product-offerings/version-history/ProductOfferingVersionHistoryPage";
import { TaskLookaheadPage } from "./reports/task-lookahead/TaskLookaheadPage";
import { DevelopmentSchedulePage } from "./schedule/development-level-schedule/DevelopmentSchedulePage";
import { DevelopmentTeamMembers } from "./team-members/DevelopmentTeamMembers";

export const DevelopmentLayout = () => {
  const { developmentId } = useParams<{ developmentId: string }>();
  const query = useDevelopmentAllLotsQuery({ variables: { developmentId } });

  return queryResult(query, (data) => {
    const featureFlags = data.development.featureFlags;
    return (
      <DevelopmentContextProvider
        id={developmentId}
        featureFlags={featureFlags.map((ff) => ff.type.code)}
        key={developmentId}
      >
        <DevelopmentLayoutContent developmentId={developmentId} data={data} />
      </DevelopmentContextProvider>
    );
  });
};

type DevelopmentLayoutContentProps = {
  developmentId: string;
  data: ReturnType<typeof useDevelopmentAllLotsQuery>["data"];
};

function DevelopmentLayoutContent({ developmentId, data }: DevelopmentLayoutContentProps) {
  const { featureIsEnabled } = useFeatureFlags();

  const sideNavProps: SideNavProps = useMemo(
    () => ({
      sideNavConfig: developmentNavLinks(developmentId),
      contrast: true,
      top: (
        <FullBleed>
          <div css={Css.p3.pb5.$}>
            <div css={Css.df.jcc.aic.bgGray800.br8.wPx(48).hPx(48).gray50.$}>
              <Icon icon="buildingHouse" />
            </div>
            {data && (
              <>
                <h1 css={Css.lgSb.mt1.gray100.$}>{data.development.name}</h1>
                <span css={Css.xs.gray400.$}>{data.cohorts.flatMap((c) => c.projects).length} Lots Acquired</span>
              </>
            )}
          </div>
        </FullBleed>
      ),
    }),
    [developmentId, data],
  );
  return (
    <SideNavLayout sideNavProps={sideNavProps}>
      <Switch>
        {navLinksToRoutes(developmentNavLinks(developmentId), featureIsEnabled)}

        {/* I think these are deprecated by newer components and need to be URL-hacked to reach, or added to SidebarConfig if we want links to them  */}
        {/* developmentPaths.contract is a header/tab renderer, so if a user lands directly on it, redirect them to an appropriate root */}
        <Redirect exact from={developmentPaths.contract} to={developmentPaths.contractOverview} />
        <Route path={developmentPaths.contract} component={DevelopmentContractPage} />
        <Route path={developmentPaths.contractOverview} component={DevelopmentContractOverviewPage} />
        <Route path={developmentPaths.contractLineItems} component={DevelopmentContractLineItemsPage} />
        <Route path={developmentPaths.scopeTemplateCostReport} component={ScopeTemplateCostCalculatorPage} />
        <Route path={developmentPaths.scopeTemplate} component={DevelopmentScopeTemplatePage} />
        <Route path={developmentPaths.scopeTemplates} component={DevelopmentScopeTemplatesPage} />
        <Route path={developmentPaths.planDetails} component={PlanDetailsPage} />
        <Route path={developmentPaths.bidPackages} component={BidPackagesPage} />
        <Route
          path={developmentPaths.productOfferingVersionHistory}
          exact
          component={ProductOfferingVersionHistoryPage}
        />
        <Route path={developmentPaths.productOffering} exact component={ProductOfferingPage} />
        <Route path={developmentPaths.productOfferings} exact component={ProductOfferingsPage} />
        <Route
          path={developmentPaths.productOfferingSpecificConfigs}
          exact
          component={ProductOfferingSpecificConfigsPage}
        />
        <Route path={developmentPaths.productOfferingConfigs} exact component={ProductOfferingConfigsPage} />
        <Route
          path={developmentPaths.productOfferingSpecificConfigsCompare}
          exact
          component={ProductOfferingConfigsComparePage}
        />
        <Route
          path={developmentPaths.productOfferingConfigsCompare}
          exact
          component={ProductOfferingConfigsComparePage}
        />
        <Route
          path={developmentPaths.productOfferingApplyToProject}
          exact
          component={ProductOfferingApplyToProjectsPage}
        />
        {/* Deprecating the ambiguous `/schedule` route now that we have both `bulk-schedules` and `development-schedule` */}
        <Redirect exact from={developmentPaths.legacyScheduleRoute} to={developmentPaths.lotSchedules} />
        <Redirect to={developmentPaths.lotSummary} />
      </Switch>
    </SideNavLayout>
  );
}

function developmentNavLinks(developmentId: string): SideNavAndRouterConfig[] {
  return [
    {
      title: "Development",
      items: [
        {
          label: "Overview",
          href: createDevelopmentLotSummaryUrl(developmentId),
          path: developmentPaths.lotSummary,
          component: LotSummaryPage,
        },
        {
          label: "Documents",
          href: createDevelopmentDocumentsUrl(developmentId),
          path: developmentPaths.documents,
          component: DocumentsPage,
        },
        {
          label: "Team Members",
          href: createDevelopmentTeamMembersUrl(developmentId),
          path: developmentPaths.team,
          component: DevelopmentTeamMembers,
        },
        {
          label: "Drops",
          href: createDevelopmentDropsUrl(developmentId),
          path: developmentPaths.drops,
          component: DevelopmentDropsPage,
          featureFlag: FeatureFlagType.ProductConfig,
        },
        {
          label: "Job Logs",
          href: createDevelopmentJobLogsUrl(developmentId),
          path: developmentPaths.jobLogs,
          component: JobLogsPage,
        },
      ],
    },
    {
      title: "Schedules",
      items: [
        {
          label: "Development Schedule",
          href: createDevelopmentScheduleUrl(developmentId),
          path: developmentPaths.developmentSchedule,
          component: DevelopmentSchedulePage,
        },
        {
          label: "Lot Schedules",
          href: createDevelopmentLotSchedulesUrl(developmentId),
          path: developmentPaths.lotSchedules,
          component: DevelopmentBulkSchedulePage,
        },
        {
          label: "Task Lookahead",
          href: createDevelopmentTaskLookaheadReportUrl(developmentId),
          path: developmentPaths.taskLookaheadReport,
          component: TaskLookaheadPage,
        },
      ],
    },
    {
      title: "Scope",
      items: [
        {
          label: "Plan & Options",
          href: createDevelopmentPlanAndOptionsUrl(developmentId),
          path: developmentPaths.planAndOptions,
          component: PlanAndOptionsPage,
          routeIsExact: true,
        },
        {
          label: "Product Offerings",
          href: createProductOfferingsUrl(developmentId),
          path: developmentPaths.productOfferings,
          component: ProductOfferingsPage,
          routeIsExact: true,
          featureFlag: FeatureFlagType.ProductConfig,
        },
        {
          label: "Templates",
          href: createDevelopmentScopeTemplatesUrl(developmentId),
          path: developmentPaths.scopeTemplates,
          component: DevelopmentScopeTemplatesPage,
          routeIsExact: true,
        },
        {
          label: "Change Log",
          href: createDevelopmentChangeLogUrl(developmentId),
          path: developmentPaths.changeLog,
          component: DevelopmentChangeLogPage,
          featureFlag: FeatureFlagType.ChangeRequestMvp,
        },
      ],
    },
    {
      title: "Trades",
      items: [
        {
          label: "Bids",
          href: createDevelopmentBidPackagesUrl(developmentId),
          path: developmentPaths.bidPackages,
          component: BidPackagesPage,
          routeIsExact: true,
          featureFlag: FeatureFlagType.ProductConfig,
        },
        {
          // Note the discrepancies here: developmentPaths.contract and DevelopmentContractPage needs to be URL-hacked until it's deprecated
          label: "Contracts",
          href: createDevelopmentProcurementUrl(developmentId),
          path: developmentPaths.procurement,
          component: DevelopmentProcurementPage,
          routeIsExact: true,
        },
        {
          label: "Purchase Orders",
          href: createDevelopmentPurchaseOrdersUrl(developmentId),
          path: developmentPaths.purchaseOrders,
          component: PurchaseOrdersPage,
        },
      ],
    },
    {
      items: [
        {
          label: "Settings",
          href: createDevelopmentSettingsUrl(developmentId),
          path: developmentPaths.settings,
          component: DevelopmentSettingsPage,
          routeIsExact: true,
          featureFlag: FeatureFlagType.DynamicSchedules,
        },
      ],
    },
  ];
}
