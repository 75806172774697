import {
  Button,
  ButtonMenu,
  Css,
  MenuItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabsWithContent,
  Tag,
  TagType,
  TextField,
  useModal,
  useSnackbar,
  useSuperDrawer,
  useTestIds,
} from "@homebound/beam";
import { Fragment, useState } from "react";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import {
  createBillPageUrl,
  createChangeEventCommitmentsUrl,
  createChangeEventEstimatesUrl,
  createChangeEventHistoryUrl,
  createChangeEventLineItemsUrl,
  createChangeEventOverviewUrl,
  createChangeEventsUrl,
} from "src/RouteUrls";
import {
  ChangeEventCreatedByFragment,
  ChangeEventPageFragment,
  ChangeEventStatus,
  ChangeEventType,
  CommitmentStatus,
  EstimateType,
  ProjectFeature,
  useChangeEventPageQuery,
  useDeleteChangeEventMutation,
  useSaveChangeEventOverviewTabMutation,
} from "src/generated/graphql-types";
import { useMaybeAllocateItemTaskModal } from "src/hooks/useMaybeAllocateItemTaskModal";
import { useOnDismount } from "src/hooks/useOnDismount";
import { NotFound } from "src/routes/NotFound";
import { fromBillDefaultChangeEventTitle } from "src/routes/bills/components/BillChangeEventSetupModal";
import {
  MonitorApprovalSuperdrawerDeepLinks,
  useApprovalSuperDrawer,
} from "src/routes/components/Approval/ApprovalSuperDrawer";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ChangeEventCommitmentsTab } from "src/routes/projects/change-events/ChangeEventCommitmentsTab";
import { ChangeEventEstimatesTab } from "src/routes/projects/change-events/ChangeEventEstimatesTab";
import { ChangeEventHistoryTab } from "src/routes/projects/change-events/ChangeEventHistoryTab";
import { ChangeEventLineItemsTab } from "src/routes/projects/change-events/ChangeEventLineItemsTab";
import { ChangeEventOverviewTab } from "src/routes/projects/change-events/ChangeEventOverviewTab";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { CreateEstimateModal } from "src/routes/projects/estimates/CreateEstimateModal";
import type { ChangeEventParams, ProjectParams } from "src/routes/routesDef";
import { projectPaths } from "src/routes/routesDef";
import { assertNever, changeEventStatusToNameMapper, hasData, renderLoadingOrError } from "src/utils";
import { entityNotFoundError } from "src/utils/error";
import { openNewTab } from "src/utils/window";
import { AcceptChangeEventModal } from "./components/AcceptChangeEventModal";
import { CommitmentBulkUploadModal } from "./components/CommitmentBulkUploadModal";

export function ChangeEventPage() {
  const { openModal } = useModal();
  const { lotType, features } = useProjectContext();
  const { projectId, changeEventId } = useParams<ProjectParams & ChangeEventParams>();
  const query = useChangeEventPageQuery({ variables: { id: changeEventId } });
  const [saveChangeEvent] = useSaveChangeEventOverviewTabMutation();
  const { triggerNotice } = useSnackbar();
  const { closeDrawer } = useSuperDrawer();
  const openApproval = useApprovalSuperDrawer();
  const maybeOpenAllocateModal = useMaybeAllocateItemTaskModal();
  /**
   * When un-mounting this page, close open Superdrawers.
   * This is to prevent having a SuperDrawer stay open when navigating multiple
   * pages back/forward as the SuperDrawer is outside the ReactRouter scope.
   */
  useOnDismount(closeDrawer);

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const changeEvent = query.data?.changeEvent;
  if (!changeEvent) {
    return <NotFound error={entityNotFoundError(changeEventId)} />;
  }

  const tabs = [
    {
      href: createChangeEventLineItemsUrl(projectId, changeEventId),
      path: projectPaths.changeEvent,
      name: "Line Items",
      render: () => <ChangeEventLineItemsTab />,
    },
    {
      href: createChangeEventOverviewUrl(projectId, changeEventId),
      path: projectPaths.changeEventOverview,
      name: "Overview",
      render: () => <ChangeEventOverviewTab />,
    },
    {
      href: createChangeEventEstimatesUrl(projectId, changeEventId),
      path: projectPaths.changeEventEstimates,
      name: "Estimates",
      render: () => <ChangeEventEstimatesTab />,
    },
    {
      href: createChangeEventCommitmentsUrl(projectId, changeEventId),
      path: projectPaths.changeEventCommitments,
      name: "Commitments",
      render: () => <ChangeEventCommitmentsTab />,
    },
    {
      href: createChangeEventHistoryUrl(projectId, changeEventId),
      path: projectPaths.changeEventHistory,
      name: "History",
      render: () => <ChangeEventHistoryTab changeEventId={changeEventId} />,
    },
  ];

  const isDraftOrProposedStatus = [ChangeEventStatus.Draft, ChangeEventStatus.Proposed].includes(changeEvent.status);

  const acceptDisabledReason = !isDraftOrProposedStatus
    ? "The change event is no longer draft or proposed"
    : changeEvent.lineItems.length === 0
      ? "Cannot accept a change event with no line items"
      : !changeEvent.canCreateFinalEstimate
        ? "All selections must be finalized or in a cutoff to accept a change event"
        : undefined;

  // determine if every element in the array has been uploaded to pandaDocs
  const isUploaded =
    changeEvent.commitments.length > 0 &&
    changeEvent.commitments.every((co) => co.status === CommitmentStatus.Uploaded);
  const hasUploadedAllCommitments = changeEvent.commitments.every((c) => !!c.pandaDoc?.externalPandaDocUrl);
  const isAcceptedChangeEvent = changeEvent.status === ChangeEventStatus.Accepted;

  const handleSave = async (name: string, status: ChangeEventStatus) => {
    await saveChangeEvent({
      variables: {
        input: {
          id: changeEvent.id,
          status,
        },
      },
    });

    triggerNotice({
      message: `${name} successfully updated to ${status}`,
      icon: "success",
    });
  };
  const openAcceptChangeEventModal = () =>
    openModal({ content: <AcceptChangeEventModal changeEventId={changeEvent.id} /> });

  return (
    <>
      <PageHeader
        breadcrumb={{ label: "All Change Events", href: createChangeEventsUrl(projectId) }}
        left={
          <div css={Css.df.aic.gap2.$}>
            <ChangeEventTitle
              identifier={changeEvent.identifier}
              title={changeEvent.title}
              createdBy={changeEvent.createdBy}
            />
            <span css={Css.ttc.$}>
              <Tag
                type={isUploaded ? "caution" : tagTypeMapper(changeEvent.status)}
                text={isUploaded ? "uploaded" : changeEventStatusToNameMapper[changeEvent.status]}
              />
            </span>
          </div>
        }
        right={
          <>
            <ActionsMenu changeEvent={changeEvent} />
            {/* Allow viewing the customer panda doc for change events */}
            {changeEvent.pandaDoc?.id && (
              <Button
                label={"View Change Order in PandaDoc"}
                disabled={!changeEvent.pandaDoc?.externalPandaDocUrl && "PandaDoc is uploading"}
                onClick={changeEvent.pandaDoc?.externalPandaDocUrl ?? ""}
              />
            )}
            {/* Allow uploading/re-uploading any commitments created by the 'Accept CE' modal.  */}
            {isAcceptedChangeEvent &&
              !changeEvent.isReadyHomeManaged &&
              (!hasUploadedAllCommitments ? (
                <Button
                  label={"Upload All to PandaDoc"}
                  onClick={() =>
                    openModal({ content: <CommitmentBulkUploadModal commitments={changeEvent.commitments} /> })
                  }
                />
              ) : (
                <Button
                  label={"View All in PandaDoc"}
                  onClick={() => openModal({ content: <ViewInPandaDocModal changeEvent={changeEvent} /> })}
                />
              ))}
            {changeEvent.approval?.id && (
              <Button
                variant="secondary"
                label="View Approval Request"
                onClick={() => openApproval(changeEvent.approval?.id)}
              />
            )}
            {isDraftOrProposedStatus && !changeEvent.isReadyHomeManaged && (
              <ButtonMenu
                disabled={!isDraftOrProposedStatus}
                trigger={{ label: "Mark as" }}
                items={[
                  {
                    disabled: acceptDisabledReason,
                    label: "Accepted",
                    onClick: () => {
                      // if items are unallocated open the allocate items to task modal
                      // otherwise perform originalOp
                      maybeOpenAllocateModal({
                        projectItems: changeEvent.lineItems.map((li) => li.projectItem),
                        otherwise: openAcceptChangeEventModal,
                        projectId,
                        stage: changeEvent.projectStage.stage.code,
                        showClickToPayFlag: features.includes(ProjectFeature.ClickToPay),
                        lotType,
                        isChangeEventPage: true,
                      });
                    },
                  },
                  {
                    label: "Proposed",
                    disabled: isAcceptedChangeEvent || changeEvent.status === ChangeEventStatus.Proposed,
                    onClick: () => handleSave(changeEvent.name, ChangeEventStatus.Proposed),
                  },
                  {
                    label: "Rejected",
                    disabled: !isDraftOrProposedStatus,
                    onClick: () => handleSave(changeEvent.name, ChangeEventStatus.Rejected),
                  },
                ]}
              />
            )}
          </>
        }
      />
      <TabsWithContent tabs={tabs} />
      <MonitorApprovalSuperdrawerDeepLinks />
    </>
  );
}

function ActionsMenu({ changeEvent }: { changeEvent: ChangeEventPageFragment }) {
  const { openModal, closeModal } = useModal();
  const { clientNoun } = useProjectContext();
  const isDraftOrProposedStatus = [ChangeEventStatus.Draft, ChangeEventStatus.Proposed].includes(changeEvent.status);

  const menuItems: MenuItem[] = [
    {
      label: "Create Estimate",
      disabled:
        changeEvent.type.code === ChangeEventType.BudgetReallocation ||
        !(isDraftOrProposedStatus && changeEvent.type.code === ChangeEventType.External),
      onClick: () => {
        openModal({
          content: (
            <CreateEstimateModal
              changeEvent={changeEvent}
              initialFormState={{
                title: `CE #${changeEvent.identifier} - ${changeEvent.title}`,
                type: EstimateType.Final,
              }}
              projectId={changeEvent.projectStage.project.id}
              projectStage={changeEvent.projectStage}
              successConfirmation={
                <>
                  <ModalHeader>Estimate Created</ModalHeader>
                  <ModalBody>
                    <p>
                      An estimate has been successfully created. To view this estimate, navigate to the <b>Estimates</b>{" "}
                      tab.
                    </p>
                    <br />
                    <p>
                      Once the {clientNoun.toLowerCase()} approves the estimate, be sure to mark the status as{" "}
                      <b>Accepted</b>.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button label="Close" onClick={closeModal} />
                  </ModalFooter>
                </>
              }
            />
          ),
        });
      },
    },
    {
      label: "Reupload to PandaDoc",
      disabled: changeEvent.status !== ChangeEventStatus.Accepted,
      onClick: () => openModal({ content: <CommitmentBulkUploadModal commitments={changeEvent.commitments} /> }),
    },
    {
      label: "Delete Change Event",
      disabled: !isDraftOrProposedStatus,
      onClick: () => openModal({ content: <DeleteChangeEventModal changeEvent={changeEvent} /> }),
    },
  ];

  return <ButtonMenu items={menuItems} placement="right" trigger={{ label: "Actions" }} />;
}

function DeleteChangeEventModal(props: { changeEvent: ChangeEventPageFragment }) {
  const { changeEvent } = props;
  const history = useHistory();
  const [deleteChangeEventMutation] = useDeleteChangeEventMutation();
  const [confirmDelete, setConfirmDelete] = useState("");

  return (
    <DeleteConfirmationModal
      confirmationMessage={
        <div>
          <p css={Css.baseMd.gray900.mb2.$}>Are you sure you want to delete this change event?</p>
          <p css={Css.sm.gray700.$}>This will also delete all estimates associated with this change event.</p>
          <p css={Css.py3.$}>
            Please type "<strong>delete</strong>" to confirm:
          </p>
          <TextField
            label=""
            value={confirmDelete}
            onChange={(text) => setConfirmDelete(text || "")}
            placeholder="Confirm Deletion"
          />
        </div>
      }
      entityType="Change Event"
      onConfirmDelete={async () => {
        await deleteChangeEventMutation({
          variables: { input: { id: changeEvent.id } },
        });
        history.push(createChangeEventsUrl(changeEvent.projectStage.project.id));
      }}
      disabled={!/delete/gi.test(confirmDelete)}
    />
  );
}

export function ViewInPandaDocModal({ changeEvent }: { changeEvent: ChangeEventPageFragment }) {
  const { closeModal } = useModal();
  return (
    <>
      <ModalHeader>View In PandaDoc</ModalHeader>
      <ModalBody>
        <div
          css={{
            ...Css.dg.gtc("auto auto").mt3.rg1.gray700.$,
            "& > div:nth-of-type(-n+2)": Css.tinySb.$,
            "& > div:nth-of-type(n+3)": Css.asc.xs.$,
          }}
        >
          <div>PO Number</div>
          <div>Link</div>
          {changeEvent.commitments.map((c) => {
            return (
              <Fragment key={c.id}>
                <div>{c.accountingNumber}</div>
                <div>
                  {c.pandaDoc?.externalPandaDocUrl ? (
                    <Button
                      label="View"
                      icon="linkExternal"
                      variant="text"
                      onClick={() => openNewTab(c.pandaDoc!.externalPandaDocUrl!)}
                    />
                  ) : (
                    "Not uploaded"
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Close" variant="tertiary" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}

// TODO: Redundant with other mappers?
function tagTypeMapper(status: ChangeEventStatus): TagType {
  switch (status) {
    case ChangeEventStatus.Draft:
      return "info";
    case ChangeEventStatus.PendingSignature:
    case ChangeEventStatus.Proposed:
    case ChangeEventStatus.Requested:
      return "caution";
    case ChangeEventStatus.Rejected:
      return "neutral";
    case ChangeEventStatus.Accepted:
      return "success";
    case ChangeEventStatus.Void:
      return "warning";
    default:
      return assertNever(status);
  }
}

const ChangeEventTitle = ({
  identifier,
  title,
  createdBy,
}: {
  identifier: number;
  title: string;
  createdBy?: ChangeEventCreatedByFragment | null;
}) => {
  const { prefix, sufix } = fromBillDefaultChangeEventTitle;
  // Regex to validate if the title was built from a user input or default build
  const regex = new RegExp(`(?=.*\\b${prefix}\\b)(?=.*#)(?=.*\\b${sufix}\\b)`);
  const hasCustomTitle = regex.test(title);

  const testId = useTestIds({}, "changeEventTitle");
  const isChangeEventCreatedByBill = createdBy?.__typename === "Bill";

  if (isChangeEventCreatedByBill) {
    const titleProvidedByUser = title.split(`(${fromBillDefaultChangeEventTitle.prefix}`)[0];
    return (
      <div css={Css.xlSb.$} {...testId}>
        <span>{`CE #${identifier} - `}</span>
        {hasCustomTitle && <span>{titleProvidedByUser}</span>}
        <Link to={createBillPageUrl({ idOrAdd: createdBy.id })}>{createdBy.name}</Link>
        <span>{` Review`}</span>
      </div>
    );
  }
  return <span {...testId} css={Css.xlSb.$}>{`CE #${identifier} - ${title}`}</span>;
};
