import {
  actionColumn,
  Button,
  Chip,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  selectColumn,
  SelectToggle,
  TextAreaField,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { Percentage, Price } from "src/components";
import {
  PlanTaskBillModal_ProtoBillFragment,
  usePlanTaskBillModal_ProtoBillsQuery,
  useTaskBillModal_SaveBillsMutation,
} from "src/generated/graphql-types";
import {
  getPlanTaskLineItemRows,
  groupPlanTaskProtoBills,
  mapPlanTaskToSaveBillInput,
  PlanTaskBillRow,
  ungroupedTradeLineId,
} from "src/routes/projects/dynamic-schedules/utils";
import { isDefined, pluralize, queryResult } from "src/utils";

export type TaskBillModalProps = {
  taskId: string;
  /**
   * Since a task can be tied to multiple allocated items & trades,
   * Payment to specific trade(s) before task completion is allowed.
   * This can be triggered via clicking on one of the task's trade partners in the task detail pane/ passing tradePartnerId
   */
  tradePartnerId?: string | undefined;
  onComplete?: () => Promise<void>;
};

export function PlanTaskBillModal(props: TaskBillModalProps) {
  const { taskId, tradePartnerId, onComplete } = props;
  const query = usePlanTaskBillModal_ProtoBillsQuery({ variables: { planTaskId: taskId } });
  return queryResult(query, (data) => (
    <BillModalView
      protoBills={data.planTask.protoBills}
      tradePartnerId={tradePartnerId}
      onComplete={onComplete}
      taskId={taskId}
    />
  ));
}

type BillModalViewProps = TaskBillModalProps & {
  protoBills: PlanTaskBillModal_ProtoBillFragment[];
};

function BillModalView(props: BillModalViewProps) {
  const { protoBills, tradePartnerId, onComplete, taskId } = props;

  const tableApi = useGridTableApi<PlanTaskBillRow>();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveTaskBills] = useTaskBillModal_SaveBillsMutation();

  const [defermentReason, setDefermentReason] = useState<string | undefined>(undefined);

  const allowPayBeforeTaskCompletion = isDefined(tradePartnerId);
  const title = allowPayBeforeTaskCompletion
    ? "Confirm payment amounts to send to the following trades for completed work:"
    : "Confirm payment amounts to send to the following trades for completed work. Deselected items will be added to an outstanding bill for the trade:";

  const { tradeBills, lineItemsByOwner } = useMemo(() => {
    // If a tradeId has been passed to the modal, show only bills for that trade
    const tradeBills = isDefined(tradePartnerId)
      ? protoBills.filter((pb) => pb.tradePartner.id === tradePartnerId)
      : protoBills;

    const lineItemsByOwner = groupPlanTaskProtoBills(tradeBills);

    return { tradeBills, lineItemsByOwner };
  }, [protoBills, tradePartnerId]);

  const selectedDrawRows = useComputed(() => tableApi.getSelectedRows("draw"), [tableApi]);
  const selectedTradeLineRows = useComputed(() => tableApi.getSelectedRows("tradeLine"), [tableApi]);
  const allSelectedRows = [...selectedDrawRows, ...selectedTradeLineRows];

  const deselectedLineItems = useMemo(() => {
    const lineItems = tradeBills.flatMap((protoBill) => protoBill.lineItems);
    const selectedDrawIds = selectedDrawRows.map((r) => r.id);
    const selectedDrawLineItems = lineItems.filter(
      (li) => isDefined(li.commitmentDraw) && selectedDrawIds.includes(li.commitmentDraw!.id),
    );
    const drawClisIds = new Set(selectedDrawLineItems.map((li) => li.commitmentLineItem.id));

    const selectedTradeLineIds = selectedTradeLineRows.map((r) => r.id);
    const selectedTradeLineItems = lineItems.filter(
      (li) =>
        !isDefined(li.commitmentDraw) &&
        selectedTradeLineIds.includes(
          li.commitmentLineItem.primaryBidContractLineItem?.prorations?.first?.tradeLineItem.id ?? ungroupedTradeLineId,
        ),
    );
    const selectedTradeCliIds = new Set(selectedTradeLineItems.map((li) => li.commitmentLineItem.id));

    const deselectedLineItems = lineItems.filter(
      (li) => !drawClisIds.has(li.commitmentLineItem.id) && !selectedTradeCliIds.has(li.commitmentLineItem.id),
    );

    return deselectedLineItems;
  }, [selectedDrawRows, tradeBills, selectedTradeLineRows]);

  const rows = useMemo(() => getPlanTaskLineItemRows(lineItemsByOwner), [lineItemsByOwner]);
  const columns = useMemo(() => getColumns(allowPayBeforeTaskCompletion), [allowPayBeforeTaskCompletion]);

  const mutationInput = useMemo(
    () => mapPlanTaskToSaveBillInput(taskId, tradeBills, deselectedLineItems, tradePartnerId, defermentReason),
    [taskId, tradeBills, deselectedLineItems, tradePartnerId, defermentReason],
  );

  const onSave = useCallback(async () => {
    // Save the `C2P bills` based on modal selections
    const saveBillsResult = await saveTaskBills({
      variables: {
        input: { bills: mutationInput },
      },
      onCompleted: async () => {
        onComplete && (await onComplete());
      },
    });

    if (saveBillsResult.data) {
      saveBillsResult.data.saveBills.bills.forEach((b) => {
        const message = b.isDeferred
          ? `Outstanding bill #${b.tradePartnerNumber} for ${b.tradePartner.name} created`
          : `Bill #${b.tradePartnerNumber} sent to ${b.tradePartner.name} for approval`;
        triggerNotice({ message });
      });
      closeModal();
    }
  }, [mutationInput, triggerNotice, closeModal, onComplete, saveTaskBills]);

  const needsDefermentReason =
    deselectedLineItems.nonEmpty &&
    (!isDefined(defermentReason) || defermentReason === "") &&
    "Deferment reason is required for deselected items";

  return (
    <>
      <ModalHeader>Issue {pluralize(tradeBills.length, "bill")}</ModalHeader>
      <ModalBody>
        <div css={Css.gray700.sm.mb2.$} data-testid="title">
          {title}
        </div>
        <GridTable rows={rows} columns={columns} style={{ allWhite: true, bordered: false }} api={tableApi} />
        {deselectedLineItems.nonEmpty && (
          <div>
            <div css={Css.xs.gray700.mt3.mb2.$}>
              Provide reasoning for completing this task and not issuing payment for deselected items (Deselected items
              will be added to an outstanding bill for the trade).
            </div>
            {/* Per design, although this modal can initiate multiple deferred bills at a time, there is only 1
            textfield for a deferment reason. The assumption is if bills are deferred together, one reason can be
            applied to all bills.
            Multiple deferred Bills however is a rare exception case as we don't expect multiple trade allocations to a single task.*/}
            <TextAreaField
              label="Deferment Reason"
              value={defermentReason}
              onChange={(value) => setDefermentReason(value)}
              labelStyle="hidden"
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        {!allowPayBeforeTaskCompletion && (
          <Button
            label="Complete without Payment"
            variant="secondary"
            onClick={onSave}
            disabled={
              (allSelectedRows.nonEmpty &&
                "Enabled when all items are deselected. Individually deselected items will be added to outstanding bills.") ||
              needsDefermentReason ||
              (mutationInput.isEmpty && "All items have been billed")
            }
          />
        )}
        <Button
          label="Release Bill"
          variant="primary"
          onClick={onSave}
          disabled={
            (!allowPayBeforeTaskCompletion && allSelectedRows.isEmpty && "No items selected for immediate release") ||
            needsDefermentReason
          }
        />
      </ModalFooter>
    </>
  );
}

function getColumns(allowPayBeforeTaskCompletion: boolean) {
  return [
    actionColumn<PlanTaskBillRow>({
      lineItemOwner: (_, { row }) => <CollapseToggle row={row} />,
      draw: emptyCell,
      tradeLine: emptyCell,
      w: "32px",
    }),
    // Prevent deselecting line items when directly in pay trade view
    ...(!allowPayBeforeTaskCompletion
      ? [
          selectColumn<PlanTaskBillRow>({
            lineItemOwner: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            draw: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            tradeLine: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            w: "28px",
          }),
        ]
      : []),
    column<PlanTaskBillRow>({
      lineItemOwner: (row) => ({
        content: () => {
          return (
            <div>
              <div css={Css.xsBd.lh(2).$} data-testid="tradePartner">
                {row.tradePartnerName}
              </div>
              <Chip text={`${row.ownerType}# ${row.accountingNumber}`} data-testid="accountingNumber" />
            </div>
          );
        },
        value: row.accountingNumber,
      }),
      draw: (row) => (
        <div css={Css.xsMd.$} data-testid="drawPercent">
          {row.drawDescription ?? "Draw"} (<Percentage basisPoints percent={row.drawBasisPoints} />)
        </div>
      ),
      tradeLine: (row) => (
        <div css={Css.xsMd.$} data-testid="tradeLineDescription">
          {row.displayName}
        </div>
      ),
      w: 4,
    }),
    column<PlanTaskBillRow>({
      lineItemOwner: emptyCell,
      draw: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      tradeLine: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      w: 1,
    }),
  ];
}
