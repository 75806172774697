import {
  Button,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  dateColumn,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  Pagination,
  ScrollableContent,
  simpleHeader,
  treeFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { dateCell, SearchBox } from "src/components";
import { LoadingTable } from "src/components/LoadingTable";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  BidPackageDetailFragment,
  BidPackageGroupDetailFragment,
  BidPackageGroupOrderBy,
  BidPackageGroupsFilter,
  BidPackageRequestDetailFragment,
  BidPackageStatus,
  DevelopmentFilter,
  MarketBidPackagesPageMetadataFragment,
  NamedFragment,
  Order,
  useBidPackageGroupsQuery,
  useBidPackagesPageMetadataQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import {
  createBidPackagesDetailUrl,
  createBidPackagesEditUrl,
  createBidPackageUrl,
  createDevelopmentProductOfferingUrl,
} from "src/RouteUrls";
import { pageSchema, queryResult } from "src/utils";
import { parseOrder, toOrder } from "src/utils/ordering";
import { PageHeader } from "../layout/PageHeader";
import { TableActions } from "../layout/TableActions";

export function BidPackagesPage() {
  // Loading markets, projects, and bid categories before rendering the page
  const query = useBidPackagesPageMetadataQuery();
  return queryResult(query, {
    data: ({ costCodes, markets }) => <BidPackagesPageView marketsOrDevelopment={markets} costCodes={costCodes} />,
  });
}

export type BidPackagesPageViewProps = {
  costCodes: NamedFragment[];
  // Configures the view to show all markets and developments or a specific development
  marketsOrDevelopment: MarketBidPackagesPageMetadataFragment[] | string;
};

export function BidPackagesPageView({ costCodes, marketsOrDevelopment }: BidPackagesPageViewProps) {
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [order, setOrder] = useState<BidPackageGroupOrderBy>({ id: Order.Asc });
  const filterDefs: FilterDefs<BidPackageGroupsFilter> = useMemo(
    () => ({
      ...(Array.isArray(marketsOrDevelopment)
        ? {
            development: treeFilter({
              defaultCollapsed: true,
              label: "Markets and Developments",
              filterBy: "leaf",
              getOptionLabel: (o) => o.name,
              getOptionValue: (o) => o.id,
              options: marketsOrDevelopment.map((m) => ({
                id: m.id,
                name: m.name,
                children: m.developments.map((d) => ({ id: d.id, name: d.name })),
              })),
            }),
          }
        : {}),
      costCode: multiFilter({
        label: "Cost Code",
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
        options: costCodes,
      }),
    }),
    [marketsOrDevelopment, costCodes],
  );

  const { filter, setFilter } = usePersistedFilter<BidPackageGroupsFilter>({
    storageKey: "bidPackageGroupsFilter",
    filterDefs,
  });
  const [searchFilter, setSearchFilter] = useState<string>("");

  const developmentId = typeof marketsOrDevelopment === "string" ? marketsOrDevelopment : undefined;
  const { loading, data } = useBidPackageGroupsQuery({
    variables: {
      filter: {
        search: searchFilter || undefined,
        development: developmentId ? [developmentId] : undefined,
        ...filter,
      },
      orderBy: order,
      packageOrderBy: order,
      page: pageSettings,
      costCode: filter.costCode,
    },
  });

  const columns = useMemo(() => createColumns(developmentId), [developmentId]);
  const rows = useMemo(() => createRows(data?.bidPackageGroups.entities || []), [data?.bidPackageGroups]);

  return (
    <div>
      <PageHeader
        title="Bids"
        right={
          <>
            <ButtonMenu
              trigger={{ label: "Create Bids", variant: "primary" }}
              items={[
                {
                  label: "Create New Bid",
                  onClick: createBidPackageUrl("add", { developmentId }),
                },
                {
                  label: "Enter Historical Bid",
                  onClick: createBidPackageUrl("add", { developmentId, isHistorical: true }),
                },
              ]}
            />
          </>
        }
      />
      <TableActions>
        <Filters<DevelopmentFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent>
        {!loading ? (
          <>
            <GridTable
              columns={columns}
              rows={rows}
              sorting={{
                on: "server",
                onSort: (key, direction) => setOrder(toOrder(key, direction)),
                value: parseOrder(order),
              }}
              stickyHeader
              fallbackMessage="There aren't any bid packages to display."
            />
            <Pagination
              page={[pageSettings, setPageSettings]}
              totalCount={data?.bidPackageGroups.pageInfo.totalCount || 0}
            />
          </>
        ) : (
          <LoadingTable />
        )}
      </ScrollableContent>
    </div>
  );
}

type HeaderRow = { kind: "header" };
type BidPackageGroupedRow = { kind: "group"; data: BidPackageGroupDetailFragment; children: BidPackageRow[] };
type BidPackageRow = { kind: "package"; data: BidPackageDetailFragment; children: BidPackageRequestRow[] };
type BidPackageRequestRow = { kind: "request"; data: BidPackageRequestDetailFragment };
type Row = HeaderRow | BidPackageGroupedRow | BidPackageRow | BidPackageRequestRow;

const createColumns = (developmentId?: string): GridColumn<Row>[] => [
  collapseColumn<Row>(),
  column<Row>({
    header: "Name",
    group: ({ id, status, displayName, bidPackages }) =>
      // Allow edit if the status is draft
      status.code === BidPackageStatus.Draft && bidPackages.every((bp) => bp.latestVersion.version === 1) ? (
        <Button
          variant="text"
          onClick={createBidPackagesEditUrl(id, developmentId)}
          label={<span css={Css.baseSb.$}>{displayName}</span>}
        />
      ) : (
        {
          content: displayName,
          css: Css.baseSb.$,
        }
      ),
    package: ({ name, bidPackageGroup, id, status, latestVersion }) => ({
      content:
        status.code !== BidPackageStatus.Draft ? (
          <Button
            variant="text"
            label={<FormattedBidName name={name} latestVersion={latestVersion} />}
            onClick={createBidPackagesDetailUrl(bidPackageGroup!.id, id)}
          />
        ) : (
          <FormattedBidName name={name} latestVersion={latestVersion} />
        ),
      value: name,
      w: 2,
      css: Css.smMd.$,
    }),
    request: ({ tradePartner }) => ({
      content: tradePartner.name,
      w: developmentId ? 2 : 3,
    }),
  }),
  column<Row>({
    header: "Product Offerings",
    group: emptyCell,
    package: ({ latestVersion, developments }) => {
      const rpavCount = latestVersion.readyPlanAggregateVersions.length;
      return {
        content: (
          <div css={Css.df.fdc.$}>
            {latestVersion.readyPlanAggregateVersions.map((rpav, idx) => {
              return (
                <div key={rpav.id} css={Css.df.fdr.gap1.$}>
                  <span>{rpav.readyPlan?.name}</span>
                  {/** TODO: We currently on support one development per Bid.
                   * In the future may need to update this cell to map through all the rpav developments to produce the correct URL
                   */}
                  <span>
                    <Link to={createDevelopmentProductOfferingUrl(developments[0].id, rpav.readyPlan.id, rpav.id)}>
                      {rpav.displayVersion}
                    </Link>
                    {idx < rpavCount - 1 && <span css={Css.mr1.$}>,</span>}
                  </span>
                </div>
              );
            })}
          </div>
        ),
        w: 2,
      };
    },
    request: emptyCell,
  }),
  ...(!developmentId
    ? [
        column<Row>({
          header: "Developments",
          group: ({ developments }) => developments.map((d) => d.name).join(", "),
          package: emptyCell,
          request: emptyCell,
        }),
      ]
    : []),
  column<Row>({
    header: "Status",
    group: ({ status }) => (
      <div css={Css.df.aic.$}>
        <StatusIndicator status={status.code} type="BidPackage" />
        <span css={Css.ml1.$}>{status.name}</span>
      </div>
    ),
    package: ({ status }) => (
      <div css={Css.df.aic.$}>
        <StatusIndicator status={status.code} type="BidPackage" />
        <span css={Css.ml1.$}>{status.name}</span>
      </div>
    ),
    request: emptyCell,
    serverSideSortKey: "status",
  }),
  dateColumn<Row>({
    header: "Last Edited",
    group: ({ updatedAt }) => dateCell(updatedAt),
    package: ({ updatedAt }) => dateCell(updatedAt),
    request: emptyCell,
    serverSideSortKey: "updatedAt",
  }),
];

function createRows(bidPackageGroups: BidPackageGroupDetailFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...bidPackageGroups.map((bpg) => ({
      kind: "group" as const,
      id: bpg.id,
      data: bpg,
      children: bpg.bidPackages.map((bp) => ({
        kind: "package" as const,
        id: bp.id,
        data: bp,
        children: bp.requests.map((r) => ({
          kind: "request" as const,
          id: r.id,
          data: r,
        })),
      })),
    })),
  ];
}

// Format the long bid name and versions labels
// To fit fixed width columns
function FormattedBidName({
  name,
  latestVersion,
}: {
  name: string;
  latestVersion: BidPackageDetailFragment["latestVersion"];
}) {
  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.df.fdc.$}>
        {name.split(",").map((n) => (
          <span key={n}>{n}</span>
        ))}
      </div>
      <span>{latestVersion.displayVersion}</span>
    </div>
  );
}
