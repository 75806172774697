import { TriggerNoticeProps, useSnackbar } from "@homebound/beam";
import { subDays } from "date-fns";
import { useMemo } from "react";
import { formatDate } from "src/components";
import {
  DraftPlanTaskInput,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { BooleanParam, useQueryParams } from "use-query-params";
import { TaskGroupBy } from "../components/DynamicSchedulesGroupBy";
import { GanttView } from "../gantt/DynamicSchedulesGantt";
import { isDisabledDay } from "../utils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

// The Bryntum Gantt library is fairly lacking in terms of TS definitions so we must create our own
type OnBeforeTaskDropFinalizeEvent = {
  context: {
    valid: boolean;
    startDate: Date;
    record: { id: string };
  };
};

type OnBeforeTaskResizeFinalizeEvent = {
  context: {
    async: boolean;
    finalize: (shouldFinalize: boolean) => void;
    endDate: Date;
    resizedRecord: {
      originalData: {
        id: string;
      };
    };
  };
};

type EventHelpers = {
  tasksById: Record<string, ScheduleDraftMode_PlanTaskFragment>;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  triggerNotice: (props: TriggerNoticeProps) => void;
  addDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
};

type DraftScheduleGanttProps = {
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  loading: boolean;
  scheduleParentId: string;
  groupBy: TaskGroupBy;
  isCollapsed: boolean;
};

export function DraftScheduleGantt(props: DraftScheduleGanttProps) {
  const { planTasks, schedulingExclusionDates, loading, scheduleParentId, groupBy, isCollapsed } = props;
  const [{ debug }] = useQueryParams({ debug: BooleanParam });
  const { triggerNotice } = useSnackbar();
  const addDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  const { onBeforeTaskDropFinalize, onBeforeTaskResizeFinalize } = useMemo(() => {
    const tasksById = planTasks.keyBy((t) => t.id);
    const eventHelpers = { tasksById, schedulingExclusionDates, triggerNotice, addDraftTaskChanges };

    return {
      onBeforeTaskDropFinalize: getOnBeforeTaskDropFinalize(eventHelpers),
      onBeforeTaskResizeFinalize: getOnBeforeTaskResizeFinalize(eventHelpers),
    };
  }, [planTasks, schedulingExclusionDates, triggerNotice, addDraftTaskChanges]);

  return (
    <GanttView
      // React hack to force rerendering of this component when the filters change. This is necessary because the gantt chart
      // does not provide a way to redraw the chart zoom when tasks are filtered in or out.
      // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
      key={planTasks.length}
      planTasks={planTasks}
      readOnly={loading}
      eventHandlers={{ onBeforeTaskDropFinalize, onBeforeTaskResizeFinalize }}
      scheduleParentId={scheduleParentId}
      groupBy={groupBy}
      schedulingExclusionDates={schedulingExclusionDates}
      debugMode={!!debug}
      isCollapsed={isCollapsed}
    />
  );
}

function getOnBeforeTaskDropFinalize(eventHelpers: EventHelpers) {
  return function (event: OnBeforeTaskDropFinalizeEvent) {
    const { triggerNotice, addDraftTaskChanges } = eventHelpers;
    const {
      record: { id: taskId },
      startDate: newStartDate,
    } = event.context;

    if (isDisabledEventDay({ date: newStartDate, taskId, eventHelpers })) {
      // The new startDate is not valid working day, setting `context.valid` tells the chart to gracefully back out the change
      // https://bryntum.com/docs/gantt/api/Gantt/feature/TaskDrag#validating-a-drag-drop-operation
      event.context.valid = false;

      return triggerNotice({
        message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
        icon: "error",
      });
    }

    addDraftTaskChanges([{ id: taskId, earliestStartDate: new DateOnly(newStartDate), isManuallyScheduled: true }]);

    return true;
  };
}

function getOnBeforeTaskResizeFinalize(eventHelpers: EventHelpers) {
  return function (event: OnBeforeTaskResizeFinalizeEvent) {
    const { triggerNotice, addDraftTaskChanges } = eventHelpers;
    const { context } = event;
    // Set the event context to `async` to allow us to back out the change via `context.finalize(false)` if a task duration cannot be modified
    context.async = true;

    const taskId = context.resizedRecord.originalData.id;
    // End dates are not inclusive in the gantt chart so we need to subtract a day to get the correct end date
    const newEndDate = new DateOnly(subDays(context.endDate, 1));

    if (isDisabledEventDay({ date: newEndDate, taskId, eventHelpers })) {
      context.finalize(false);

      return triggerNotice({
        message: `End Date of ${formatDate(newEndDate)} is not a working day for this schedule`,
        icon: "error",
      });
    }

    // If the duration can be set for this task, allow the change to stick
    context.finalize(true);

    addDraftTaskChanges([{ id: taskId, desiredEndDate: newEndDate }]);

    return true;
  };
}

function isDisabledEventDay({
  date,
  taskId,
  eventHelpers,
}: {
  date: DateOnly | Date;
  taskId: string;
  eventHelpers: EventHelpers;
}) {
  const { tasksById, schedulingExclusionDates } = eventHelpers;
  const draftTask = tasksById[taskId];
  const scheduleExcludedDates = schedulingExclusionDates.map((sed) => sed.date);

  return isDisabledDay({ date, scheduleExcludedDates, customWorkableDays: draftTask.customWorkableDays });
}
