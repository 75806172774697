import {
  BoundMultiSelectField,
  Button,
  cardStyle,
  CollapseToggle,
  column,
  Css,
  dragHandleColumn,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  insertAtIndex,
  multiFilter,
  recursivelyGetContainingRow,
  SelectToggle,
  Switch,
  Tag,
  useComputed,
  useFilter,
  useGridTableApi,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { Icon, SearchBox } from "src/components";
import {
  CreateOfferingReadyPlanOptionFragment,
  SaveProductOfferingInput,
  SaveProductOfferingOptionInput,
  useLocationsQuery,
  useOptionsStepQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";

import { useReaction, useToggle } from "src/hooks";

import { useParams, useRouteMatch } from "react-router-dom";
import { useStepperContext } from "src/components/stepper";
import {
  ConflictTag,
  PrerequisitesTag,
} from "src/routes/developments/product-offerings/components/ReadOnlyOptionsTable";
import { productOfferingEditPath, ProductOfferingParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { EditProductOfferingStep } from "../utils";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";

type OptionsStepProps = {
  formState: ObjectState<SaveProductOfferingInput>;
};

export function OptionsStep({ formState }: OptionsStepProps) {
  const result = useOptionsStepQuery({
    variables: {
      input: {
        planPackageId: formState.planPackageId.value!,
        designPackageIds: [formState.exteriorDesignPackageId.value!, formState.interiorDesignPackageId.value!],
      },
    },
  });
  return queryResult(result, ({ potentialOptions }) => (
    <OptionsView readyPlanOptions={potentialOptions} formState={formState} />
  ));
}

type OptionsViewProps = {
  /**
   * Create view:
   * - All options available from stepper selected plan package and design package(s) (POF.potentialOptions)
   *
   * Edit view:
   * - All options that are available to be added to the pof (POF.options)
   *  **/
  readyPlanOptions: CreateOfferingReadyPlanOptionFragment[];
  formState: ObjectState<SaveProductOfferingInput>;
};

export function OptionsView({ readyPlanOptions, formState }: OptionsViewProps) {
  const tableApi = useGridTableApi<Row>();
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const optionsTypes = useMemo(() => readyPlanOptions.map((rpo) => rpo.type).unique() ?? [], [readyPlanOptions]);

  const locationsQuery = useLocationsQuery();

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Type",
      options: optionsTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    const location = multiFilter({
      label: "Location",
      options: locationsQuery?.data?.locations ?? [],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option, location };
  }, [locationsQuery?.data?.locations, optionsTypes]);

  const { filter, setFilter } = useFilter({ filterDefs });
  const [showArchived, setShowArchived] = useToggle(false);
  const [collapsedGroups, setCollapsedGroups] = useState(false);

  const tableFormState = useFormState({
    config: tableFormConfig,
    init: {
      onlyOnce: true,
      input: {
        readyPlanOptions: readyPlanOptions.map((o) => ({
          id: o.id,
          optionGroup: o.optionGroup,
          optionDefaultsIfIds: o.optionDefaultsIf.map(({ id }) => id),
          order: o.order,
        })),
      },
    },
  });

  // On initial load either select all options if none have been selected or select the ones that have been selected
  useEffect(() => {
    let activeReadyPlanOptions = readyPlanOptions.filter((rpo) => rpo.active);
    if (formState.options.value.length > 0) {
      const selectedReadyPlanOptions = formState.options.value.map((e) => e.id);
      activeReadyPlanOptions = activeReadyPlanOptions.filter((rpo) => selectedReadyPlanOptions.includes(rpo.id));
    }
    activeReadyPlanOptions
      .filter((rpo) => rpo.optionGroup.isMultiOptionGroup)
      .forEach((rpo) => tableApi.selectRow(rpo.id, true));
    activeReadyPlanOptions
      .filter((rpo) => rpo.optionGroup.isSingleOptionGroup)
      .forEach((rpo) => tableApi.selectRow(rpo.optionGroup.id, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // It would be nice to use a single form state but because we want to persist the defaults of unselected options we need to use a separate form state
  useReaction(
    () => {
      const selectedChildRpos = tableApi.getSelectedRows("child");
      const selectedParentRpogs = tableApi.getSelectedRows("parent");
      const selectedRpoIds = selectedChildRpos
        .map((row) => row.id)
        .concat(selectedParentRpogs.map((row) => row.data.rpo.id));
      return tableFormState.readyPlanOptions.value.filter((rpo) => selectedRpoIds.includes(rpo.id));
    },
    (options) => {
      formState.options.set(options.map(({ id, optionDefaultsIfIds }) => ({ id, optionDefaultsIfIds })));
    },
    [tableApi, tableFormState],
    { fireImmediately: true },
  );

  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$}>Select your options</div>
        <div css={Css.base.pt2.pbPx(60).$}>
          Search and select the various option types to add to the plan. All conflicts and dependencies are already
          attached.
        </div>
      </div>
      <TableActions>
        <div css={Css.df.gap1.jcfe.$}>
          <SearchBox onSearch={setSearchFilter} clearable />
          <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
          <Switch label={"Show Archived"} selected={showArchived} onChange={setShowArchived} />
        </div>
        <div css={Css.df.aic.gap2.$}>
          <Button
            onClick={() => setCollapsedGroups(!collapsedGroups)}
            icon={collapsedGroups ? "expand" : "collapse"}
            label={`${collapsedGroups ? "Expand" : "Collapse"} Groups`}
            variant="secondary"
          />
        </div>
      </TableActions>
      <div css={Css.w100.bgWhite.p4.$}>
        <OptionsTable
          collapsedGroups={collapsedGroups}
          tableFormState={tableFormState}
          formState={formState}
          readyPlanOptions={readyPlanOptions}
          filter={filter}
          searchFilter={searchFilter}
          showArchived={showArchived}
          tableApi={tableApi}
        />
      </div>
      {!productOfferingId && <ProductOfferingStepActions />}
    </>
  );
}

type OptionsTableProps = {
  tableFormState: ObjectState<FormValue>;
  formState: ObjectState<SaveProductOfferingInput>;
  readyPlanOptions: CreateOfferingReadyPlanOptionFragment[];
  searchFilter?: string;
  filter?: OptionTypeFilter | undefined;
  showArchived: boolean;
  collapsedGroups: boolean;
  tableApi: GridTableApi<Row>;
};

export function OptionsTable({
  tableFormState,
  formState,
  readyPlanOptions = [],
  filter,
  searchFilter = "",
  showArchived,
  collapsedGroups,
  tableApi,
}: OptionsTableProps) {
  const isEditPath = !!useRouteMatch([productOfferingEditPath]);
  const rpos = useComputed(() => tableFormState.readyPlanOptions.rows, [tableFormState]);
  const { currentStep } = useStepperContext();
  const readOnly = currentStep.value === EditProductOfferingStep.REVIEW;

  const rows: GridDataRow<Row>[] = useMemo(
    () => createRows(readyPlanOptions, rpos, filter, showArchived),
    [rpos, filter, showArchived, readyPlanOptions],
  );

  useEffect(() => {
    const [collapsed, expanded] = rows.partition((r) => tableApi.isCollapsedRow(r.id));
    const target = collapsedGroups ? expanded : collapsed;
    target.forEach((r) => tableApi.toggleCollapsedRow(r.id));
  }, [tableApi, collapsedGroups, rows]);

  return (
    <GridTable
      api={tableApi}
      rows={rows}
      columns={createColumns(readyPlanOptions, formState, readOnly, isEditPath)}
      filter={searchFilter}
      style={tableStyles}
      rowStyles={rowStyles}
      // TODO: it would be nice to have virtualization and a full page scroll
      // as="virtual"
      onRowDrop={!readOnly ? onRowDropHandler(rows) : undefined}
    />
  );
}

export enum Columns {
  Select,
  Name,
  Type,
  DefaultOf,
  Prerequisites,
  Conflicts,
}

function createColumns(
  rpos: CreateOfferingReadyPlanOptionFragment[],
  formState: ObjectState<SaveProductOfferingInput>,
  readOnly: boolean,
  isEditPath: boolean,
): GridColumn<Row>[] {
  return [
    dragHandleColumn<Row>({}),
    column<Row>({
      id: `${Columns.Name}`,
      header: "Name",
      parent: ({ rpo }, row) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <CollapseToggle row={row.row} />
            <SelectToggle id={row.row.id} disabled={readOnly || !!rpo.latestVersion?.isUpstreamInactive} />
            <div css={Css.df.if(rpo.optionGroup.isSingleOptionGroup).fdc.$}>
              {rpo.optionGroup.isMultiOptionGroup && <Icon icon="archive" xss={Css.mrPx(5).$} />}
              <span css={Css.lgSb.$}>{rpo.optionGroup.name}</span>
              {rpo.optionGroup.isSingleOptionGroup && (
                <div css={Css.df.gap1.aic.$}>
                  <div css={Css.xs.$}>{rpo.globalOption.code}</div>
                  {rpo.latestVersion?.isUpstreamInactive && <Tag text="Archived" type="warning" />}
                  {rpo.location?.name && <Tag text={rpo.location.name} type="neutral" />}
                </div>
              )}
            </div>
          </div>
        ),
        value: `${rpo.optionGroup.name} ${rpo.globalOption.code}`,
        css: Css.sm.if(showHighlights({ rpo, isGroupRow: true, isEditPath })).bgYellow50.$,
      }),
      child: ({ rpo, state }, { row }) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <SelectToggle id={row.id} disabled={readOnly} />
            <div css={Css.df.fdc.$}>
              <span css={Css.baseSb.$}>{rpo.name}</span>
              <div css={Css.df.gap1.aic.$}>
                <span css={Css.xs.$}>{rpo.globalOption.code}</span>
                {rpo.latestVersion?.isUpstreamInactive && <Tag text="Archived" type="warning" />}
                {rpo.location?.name && <Tag text={rpo.location.name} type="neutral" />}
              </div>
            </div>
          </div>
        ),
        value: `${rpo.name} ${rpo.globalOption.code}`,
        css: Css.sm.if(showHighlights({ rpo, isEditPath })).bgYellow50.$,
      }),
      w: "700px",
    }),
    column<Row>({
      id: `${Columns.Type}`,
      header: "Type",
      parent: ({ rpo }) => ({
        content: () => (
          <>
            {rpo.type.name && (
              <Tag
                text={rpo.type.name}
                type={rpo.optionGroup.isSingleOptionGroup ? undefined : "info"}
                xss={rpo.optionGroup.isSingleOptionGroup ? Css.bgPurple200.$ : undefined}
              />
            )}
          </>
        ),
        value: rpo.type.name,
        css: Css.sm.if(showHighlights({ rpo, isGroupRow: true, isEditPath })).bgYellow50.$,
      }),
      child: ({ rpo }) => ({
        content: () => (
          <div css={Css.df.fdc.gap1.$}>
            {rpo.optionGroup.name && (
              <div>
                <Tag text={rpo.optionGroup.name} type="info" />
              </div>
            )}
            {rpo.type.name && (
              <div>
                <Tag text={rpo.type.name} xss={Css.bgPurple200.$} />
              </div>
            )}
          </div>
        ),
        value: rpo.type.name,
        css: Css.sm.if(showHighlights({ rpo, isEditPath })).bgYellow50.$,
      }),
    }),
    column<Row>({
      id: `${Columns.Prerequisites}`,
      header: "Prerequisites",
      parent: ({ rpo }) =>
        // show same prereq select if group only has 1 member
        rpo.optionGroup.isSingleOptionGroup
          ? {
              content: <PrerequisitesTag rpo={rpo} />,
              css: Css.sm.if(showHighlights({ rpo, isGroupRow: true, isEditPath })).bgYellow50.$,
            }
          : emptyCell,
      child: ({ rpo }) => ({
        content: <PrerequisitesTag rpo={rpo} />,
        css: Css.sm.if(showHighlights({ rpo, isEditPath })).bgYellow50.$,
      }),
    }),
    column<Row>({
      id: `${Columns.Conflicts}`,
      header: "Conflicts",
      parent: ({ rpo }) =>
        rpo.optionGroup.isSingleOptionGroup
          ? {
              content: <ConflictTag rpo={rpo} />,
              css: Css.sm.if(showHighlights({ rpo, isGroupRow: true, isEditPath })).bgYellow50.$,
            }
          : emptyCell,
      child: ({ rpo }) => ({
        content: <ConflictTag rpo={rpo} />,
        css: Css.sm.if(showHighlights({ rpo, isEditPath })).bgYellow50.$,
      }),
    }),
    column<Row>({
      id: `${Columns.DefaultOf}`,
      header: "Default of",
      parent: ({ rpo, state }) =>
        // show same default of select if group only has 1 member
        rpo.optionGroup.isSingleOptionGroup
          ? {
              content: () => <DefaultOfSelectField rpo={state} rpos={rpos} formState={formState} readOnly={readOnly} />,
              value: state.optionDefaultsIfIds.value?.toString(),
              css: Css.sm.if(showHighlights({ rpo, isGroupRow: true, isEditPath })).bgYellow50.$,
            }
          : {
              content: rpo.optionGroup.required ? "Required" : undefined,
              alignment: "right",
            },
      child: ({ state, rpo }) => ({
        content: () => <DefaultOfSelectField rpo={state} rpos={rpos} formState={formState} readOnly={readOnly} />,
        value: state.optionDefaultsIfIds.value?.toString(),
        css: Css.sm.if(showHighlights({ rpo, isEditPath })).bgYellow50.$,
      }),
    }),
  ];
}

function createRows(
  rpos: CreateOfferingReadyPlanOptionFragment[],
  rpoObjectStates: readonly ObjectState<FormOptionValue>[],
  filter: OptionTypeFilter | undefined,
  showArchived: boolean,
): GridDataRow<Row>[] {
  // Group by option groups
  const optionGroups = rpos
    .filter((rop) => filter?.location?.includes(rop.location?.id ?? "") ?? true)
    .filter(
      (rpo) =>
        (showArchived || !rpo.latestVersion?.isUpstreamInactive) &&
        (filter?.option?.includes(rpo.type.id ?? "") ?? true),
    )
    .groupBy((o) => o.optionGroup?.id ?? "");
  // sort by group order
  return Object.entries(optionGroups)
    .sort(
      ([optionGroupIdA, rposA], [optionGroupIdB, rposB]) =>
        (rposA[0].optionGroup?.order ?? Number.POSITIVE_INFINITY) -
        (rposB[0].optionGroup?.order ?? Number.POSITIVE_INFINITY),
    )
    .map(([optionGroupId, rpos]) => {
      return {
        kind: "parent" as const,
        data: {
          rpo: rpos[0],
          state: rpoObjectStates.find((rpo) => rpo.id.value === rpos[0].id)!,
        },
        id: optionGroupId,
        draggable: true,
        children: rpos[0].optionGroup.isMultiOptionGroup
          ? rpos
              .flatMap((rpo) => ({
                kind: "child" as const,
                id: rpo.id,
                data: {
                  rpo: rpo,
                  state: rpoObjectStates.find((optionState) => optionState.id.value === rpo.id)!,
                },
                draggable: true,
              }))
              .sortBy((rpo) => rpo.data.state.order.value ?? Number.POSITIVE_INFINITY)
          : undefined,
      };
    });
}

function onRowDropHandler(rows: GridDataRow<Row>[]) {
  return function (draggedRow: GridDataRow<Row>, droppedRow: GridDataRow<Row>, indexOffset: number) {
    // insert dragged row in the index of dropped row + indexOffset
    // this pushes everything else lower, then set order to index for everything from dragged row onwards

    let tempRows = [...rows];
    const foundRowContainer = recursivelyGetContainingRow(draggedRow.id, tempRows)!;
    if (!foundRowContainer) {
      console.error("Could not find row array for row", draggedRow);
      return;
    }
    if (!foundRowContainer.array.some((row) => row.id === droppedRow.id)) {
      console.error("Could not find dropped row in row array", droppedRow);
      return;
    }
    // remove dragged row
    const draggedRowIndex = foundRowContainer.array.findIndex((r) => r.id === draggedRow.id);
    const reorderRow = foundRowContainer.array.splice(draggedRowIndex, 1)[0];

    const droppedRowIndex = foundRowContainer.array.findIndex((r) => r.id === droppedRow.id);

    // we also need the parent row so we can set the newly inserted array
    if (foundRowContainer.parent && foundRowContainer.parent?.children) {
      foundRowContainer.parent.children = [
        ...insertAtIndex(foundRowContainer.parent?.children, reorderRow, droppedRowIndex + indexOffset),
      ];
    } else {
      tempRows = [...insertAtIndex(tempRows, reorderRow, droppedRowIndex + indexOffset)];
    }

    function recursivelySetOrderToIndex(rowArray: GridDataRow<Row>[]) {
      // if it's a child, set the rpo order
      // if it's a group, set the optionGroup order
      for (let i = 0; i < rowArray.length; i++) {
        console.log(rowArray[i]);
        if (rowArray[i].kind === "parent") {
          rowArray[i].data?.state.optionGroup.order.set(i + 1);
          recursivelySetOrderToIndex(rowArray[i].children ?? []);
        }
        if (rowArray[i].kind === "child") {
          rowArray[i].data?.state.order.set(i + 1);
          recursivelySetOrderToIndex(rowArray[i].children ?? []);
        }
      }
    }

    if (foundRowContainer.parent?.children) {
      recursivelySetOrderToIndex(foundRowContainer.parent?.children);
    } else {
      recursivelySetOrderToIndex(tempRows);
    }
  };
}

type RpoSelectFieldProps = {
  rpo: ObjectState<FormOptionValue>;
  rpos: CreateOfferingReadyPlanOptionFragment[];
  formState: ObjectState<SaveProductOfferingInput>;
  readOnly: boolean;
};

function DefaultOfSelectField({ rpo, rpos, formState, readOnly }: RpoSelectFieldProps) {
  // If the formState defaultIds have been set, display those
  const formRpoDefaultIds = formState.options.rows.find((os) => os.value.id === rpo.value.id)?.optionDefaultsIfIds;
  return (
    <BoundMultiSelectField
      field={formRpoDefaultIds?.value?.nonEmpty ? formRpoDefaultIds : rpo.optionDefaultsIfIds}
      options={mapToRpoSelectFieldOptions(rpo, rpos)}
      placeholder="Default of"
      onSelect={(val) => {
        rpo.optionDefaultsIfIds.set(val);
      }}
      readOnly={readOnly}
      compact
    />
  );
}

function mapToRpoSelectFieldOptions(rpo: ObjectState<FormOptionValue>, rpos: CreateOfferingReadyPlanOptionFragment[]) {
  return rpos
    .filter((opt) => opt.optionGroup.id !== rpo.optionGroup.value?.id)
    .sortBy((opt) => opt.name)
    .map((opt) => ({
      id: opt.id,
      name: opt.displayName,
    }));
}

type HeaderRow = { kind: "header"; id: string; data: undefined };

type ParentRow = {
  kind: "parent";
  id: string;
  data: {
    rpo: CreateOfferingReadyPlanOptionFragment;
    state: ObjectState<FormOptionValue>;
  };
};

type ChildRow = {
  kind: "child";
  id: string;
  data: {
    rpo: CreateOfferingReadyPlanOptionFragment;
    state: ObjectState<FormOptionValue>;
  };
};

export type Row = HeaderRow | ParentRow | ChildRow;

const tableStyles = {
  ...cardStyle,
  cellCss: {
    ...cardStyle.cellCss,
    ...Css.aic.pPx(12).$,
  },
  presentationSettings: {
    borderless: false,
  },
};

const rowStyles = { child: { rowCss: Css.bgGray100.$, cellCss: Css.bgGray100.$ } };

type OptionTypeFilter = { option?: string[]; location?: string[] };

type FormOptionValue = SaveProductOfferingOptionInput & {
  optionGroup: { id: string; order: number };
  order: number;
};

type FormValue = {
  readyPlanOptions: FormOptionValue[];
};

export const tableFormConfig: ObjectConfig<FormValue> = {
  readyPlanOptions: {
    type: "list",
    config: {
      id: { type: "value" },

      optionGroup: {
        type: "object",
        config: {
          id: { type: "value" },
          order: { type: "value" },
        },
      },
      optionDefaultsIfIds: { type: "value" },
      order: { type: "value" },
    },
  },
};

/**
 * Highlight option rows (and singleOptionGroup rows) if the option is newly added from upstream
 * And while in the edit path (which is only allowed for the latest version)
 * (This options table is also used in the create path, and for older versions)
 */
function showHighlights({
  rpo,
  isEditPath,
  isGroupRow = false,
}: {
  rpo: CreateOfferingReadyPlanOptionFragment;
  isEditPath: boolean;
  isGroupRow?: boolean;
}) {
  const renderHighlights =
    (!!rpo.draftVersion?.isNewFromUpstream || !!rpo.draftVersion?.isUpstreamInactive) && isEditPath;
  return renderHighlights && (!isGroupRow || !!rpo.optionGroup.isSingleOptionGroup);
}
