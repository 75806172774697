import { currentUser, HomeboundUser } from "@homebound/auth-components";
import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { PdfRoutes } from "src/PdfRoutes";
import { PageLayout } from "src/routes/layout/PageLayout";
import { PdfLayout } from "src/routes/layout/PdfLayout";
import { Routes } from "./Routes";

export function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<HomeboundUser>();

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  async function onLoad() {
    const { data: user } = await currentUser();
    setUser(user);
    setIsAuthenticated(user !== undefined);
    setIsAuthenticating(false);
  }

  const authProps = { isAuthenticated, setIsAuthenticated, user, setUser };
  const routes = <Routes authProps={authProps} />;
  const pdfRoutes = <PdfRoutes />;

  useDeepLink(isAuthenticated);

  if (isAuthenticating) {
    return <div />;
  } else if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/pdf">
          <PdfLayout>{pdfRoutes}</PdfLayout>
        </Route>
        <Route>
          <PageLayout {...{ user, setIsAuthenticated }}>{routes}</PageLayout>
        </Route>
      </Switch>
    );
  } else {
    // PdfRoutes can be accessed while either authed or unauthed-to-amplify
    return (
      <Switch>
        <Route path="/pdf">
          <PdfLayout>{pdfRoutes}</PdfLayout>
        </Route>
        {routes}
      </Switch>
    );
  }
}

/**
 * Save a deep link to redirect to after sign in
 *
 * If the user isn’t authenticated, we check for a path to set as the deepLink.
 * We skip saving the deepLink if the user navigates to "/signIn".
 * Additionally, since we redirect to "/personal-dashboard" when a user logs in or navigates to "/",
 * these paths don’t need to be saved as a deepLink.
 */
function useDeepLink(isAuthenticated: boolean) {
  const location = useLocation();

  useEffect(() => {
    const deepLink = localStorage.getItem("deepLink");
    const skipDeepLink = ["/signIn", "/", "/personal-dashboard"].includes(location.pathname);
    if (!isAuthenticated && !skipDeepLink && !deepLink) {
      const currentPath = location.pathname + location.search;
      localStorage.setItem("deepLink", currentPath);
    }
  }, [isAuthenticated, location]);
}
