import { Css, StaticField, useTestIds } from "@homebound/beam";
import { formatToPrice } from "src/components";
import { Maybe } from "src/generated/graphql-types";

type DiffState = {
  label: string;
  fromOcr: Maybe<string | number>;
  current: Maybe<string | number>;
  isPrice?: boolean;
};

export const BillFileDiffConfirmationContent = (props: { differences: DiffState[] }) => {
  const { differences } = props;
  const testIds = useTestIds({});
  return (
    differences
      .map((diffState) => {
        const { label, fromOcr, current, isPrice } = diffState;
        if (fromOcr && fromOcr !== current) {
          const formatValue = (value: string | number) =>
            isPrice ? formatToPrice({ valueInCents: Number(value) || 0 }) : value;
          return (
            <div css={Css.df.gap2.$} key={label} {...testIds[label]}>
              <StaticField label={<div css={Css.mt2.$}>{label}:</div>}>
                {`From Bill Document: ${formatValue(fromOcr)} | Current: ${formatValue(current!)}`}
              </StaticField>
            </div>
          );
        }
        return null;
      })
      .filter(Boolean) || null
  );
};
