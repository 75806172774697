import { Css, Icon, Palette, ScrollableContent } from "@homebound/beam";
import {
  BryntumGantt,
  BryntumGanttProps,
  BryntumProjectModel,
  DateHelper,
  TaskModel,
} from "@homebound/schedules-v2-gantt";
import "@homebound/schedules-v2-gantt/dist/index.css";
import { addDays, endOfDay, isSameDay, startOfDay, subDays } from "date-fns";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import { formatDate } from "src/components";
import {
  Named,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
} from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { DateOnly, formatMonthDay, formatWithShortYear } from "src/utils/dates";
import { renderToString } from "src/utils/renderToString";
import { DynamicTaskCalendarColorLegend } from "../../schedule-v2/components/TaskColorLegend";
import { TaskGroupBy } from "../components/DynamicSchedulesGroupBy";
import { taskRequiresTradeConfirmation } from "../utils";

type GanttEvent = Pick<TaskModel, "name" | "manuallyScheduled" | "cls" | "draggable" | "resizable" | "expanded"> & {
  id: string;
  startDate: DateOnly;
  endDate: Date;
  internalEndDate: DateOnly;
  internalStartDate: DateOnly;
  internalDuration: number;
  internalAllowedBy: Named[];
  internalConstrainedBy: Named[];
  type: string;
  children?: GanttEvent[];
};

type GanttDependency = {
  id: string;
  from: string;
  to: string;
  cls: string;
  internalPlanTaskName: string;
  internalConstrainTaskName: string;
};

type OnTaskClickEvent = {
  taskRecord: GanttEvent;
};

type GanttViewProps = {
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  readOnly?: boolean;
  eventHandlers?: Pick<BryntumGanttProps, "onBeforeTaskResizeFinalize" | "onBeforeTaskDropFinalize">;
  scheduleParentId: string;
  groupBy: TaskGroupBy;
  debugMode?: boolean;
  isCollapsed: boolean;
};

const footerHeightPx = 50;

export function GanttView(props: GanttViewProps) {
  const {
    planTasks,
    readOnly = false,
    eventHandlers,
    scheduleParentId,
    groupBy,
    schedulingExclusionDates,
    debugMode = false,
    isCollapsed,
  } = props;
  const projectModelRef = useRef<BryntumProjectModel>(null);
  const ganttRef = useRef<BryntumGantt>(null);
  const history = useHistory();

  const taskData = useMemo(
    () => prepareTaskData(planTasks, readOnly, groupBy, isCollapsed),
    [planTasks, readOnly, groupBy, isCollapsed],
  );
  const dependencyData = useMemo(() => prepareDependencies(planTasks), [planTasks]);

  const onTaskClick = useCallback(
    ({ taskRecord }: OnTaskClickEvent) => {
      // Only allow task linking when in the non-draft (read-only) mode
      if (!readOnly) {
        debugMode &&
          // @ts-ignore - This is not the standard way to handle this, but we're only showing these if debugging
          taskRecord.allDependencies.forEach((dep) => {
            if (dep.cls.includes("debug-dependency-darken")) {
              dep.cls = dep.cls.replace(" debug-dependency-darken", "");
              // Not sure exactly why we have to update the class by using it's inner data prop.
              // dep.toEvent.cls is an HTML collection but manipulating it as such with `.add()|.remove()` does not
              // update the task render while this does.
              dep.toEvent.cls = dep.toEvent.data.cls.replace("debug-dependency-task-outline", "");
            } else {
              dep.cls += " debug-dependency-darken";
              dep.toEvent.cls += " debug-dependency-task-outline";
            }
          });
        return;
      }
      history.push(createTaskDetailsPageUrl(scheduleParentId, taskRecord.id));
    },
    [readOnly, history, scheduleParentId, debugMode],
  );

  const scheduleExclusionCalendarIntervals = useMemo(() => {
    return schedulingExclusionDates.map((sed) => ({
      startDate: endOfDay(new Date(sed.date)),
      endDate: endOfDay(addDays(new Date(sed.date), 1)),
      isWorking: false,
      name: sed.name,
      cls: "b-holiday",
      id: sed.id,
    }));
  }, [schedulingExclusionDates]);

  // since we are toggling the expand/collapse local state in a parent component, we only need to call expandAll/collapseAll when our state changes
  useEffect(() => {
    const ganttInstance = ganttRef.current?.instance;
    if (!ganttInstance) return;
    if (isCollapsed) {
      ganttInstance.collapseAll();
    } else {
      ganttInstance.expandAll();
    }
  }, [isCollapsed]);

  return (
    <>
      <BryntumProjectModel
        ref={projectModelRef}
        calendars={[
          {
            id: "business",
            name: "scheduleExclusionDates",
            intervals: scheduleExclusionCalendarIntervals,
          },
        ]}
        hoursPerDay={8}
        calendar="business"
        tasks={taskData}
        dependencies={dependencyData}
      />
      <ScrollableContent omitBottomPadding>
        <div css={{ ...Css.h100.$, ...ganttStyleOverrides }}>
          <div css={Css.h(`calc(100% - ${footerHeightPx}px)`).$}>
            <BryntumGantt
              ref={ganttRef}
              project={projectModelRef}
              // Show calendar icon in standard "weekAndDayLetter" view.
              // Note: This only renders on our standard view. To update others we'll need to map presets from
              //   PresetManager
              viewPreset={{
                base: "weekAndDayLetter",
                headers: [
                  {
                    unit: "week",
                    dateFormat: "ddd DD MMM YYYY",
                  },
                  {
                    unit: "day",
                    renderer: (start: Date) => {
                      if (scheduleExclusionCalendarIntervals.some((sed) => isSameDay(sed.endDate, start))) {
                        return renderToString(<Icon inc={2} icon="calendarX" />);
                      }

                      return DateHelper.format(start, "d1");
                    },
                  },
                ],
              }}
              cellEditFeature={{ disabled: true }}
              readOnly={readOnly}
              columns={[
                { type: "name", width: 300 },
                {
                  text: "Dates",
                  width: 110,
                  field: "id",
                  renderer: ({ record }: { record: GanttEvent }) => {
                    if (record.type === "grouping") return "";
                    return `${formatMonthDay(record.internalStartDate)}-${formatMonthDay(record.internalEndDate)}`;
                  },
                },
                {
                  text: "Duration",
                  width: 70,
                  field: "internalDuration",
                  renderer: ({ record }: { record: GanttEvent }) => {
                    return record.type === "grouping" ? "" : record.internalDuration;
                  },
                },
              ]}
              taskMenuFeature={{ disabled: true }}
              projectLinesFeature={{ disabled: true }}
              taskEditFeature={{ disabled: true }}
              timeRangesFeature={{ showCurrentTimeLine: { name: "Today" } }}
              rowReorderFeature={{ disabled: true }}
              barMargin={0}
              rowHeight={40}
              labelsFeature={{ top: { field: "name" }, disabled: false }}
              // Do not allow zooming in to less than a day time interval
              maxZoomLevel={10}
              taskTooltipFeature={{
                template: ({ taskRecord }: { taskRecord: GanttEvent }) => taskTooltipMarkup({ taskRecord, debugMode }),
              }}
              taskDragFeature={{
                tooltipTemplate({ startDate }: { startDate: Date }) {
                  return `New Start: ${formatDate(new DateOnly(startDate), "weekDayMonthShort")}`;
                },
                showExactDropPosition: true,
              }}
              taskResizeFeature={{
                showExactResizePosition: true,
                tooltipTemplate: ({ endDate }: { endDate: Date }) => {
                  return `New End: ${formatDate(new DateOnly(subDays(endDate, 1)), "weekDayMonthShort")}`;
                },
              }}
              onTaskClick={onTaskClick}
              dependenciesFeature={{
                showTooltip: true,
                highlightDependenciesOnEventHover: true,
                disabled: !debugMode,
                // NOTE: Tooltip not customizable for our current version: https://github.com/bryntum/support/issues/9668
                //  Please prove me wrong!
                tooltip: {
                  allowOver: true,
                },
              }}
              {...eventHandlers}
            />
          </div>
          <div css={Css.hPx(footerHeightPx).pb2.$}>
            <div css={Css.bgWhite.h100.w100.brb4.df.aic.$}>
              <DynamicTaskCalendarColorLegend
                title="Color Legend:"
                items={[
                  { color: Palette.Blue200, label: "Estimated work days" },
                  { color: Palette.Red200, label: "Trade - Not Sent" },
                  { color: Palette.Yellow200, label: "Trade - Sent and Unconfirmed" },
                  { color: Palette.Gray300, label: "Completed" },
                ]}
              />
            </div>
          </div>
        </div>
      </ScrollableContent>
    </>
  );
}

function prepareDependencies(planTasks: ScheduleDraftMode_PlanTaskFragment[]): GanttDependency[] {
  const dependencies: GanttDependency[] = [];

  planTasks.forEach(({ id: planTaskId, globalPlanTask, name: planTaskName, constraintItems }) => {
    const constraintItemIds = constraintItems.map((c) => c.id);

    planTasks.forEach((possibleConstrainingPlanTask) => {
      const { id: possibleConstrainingTaskId, allowanceItems, name } = possibleConstrainingPlanTask;
      if (
        // Ignoring self referencing dependencies for 2 reasons
        // 1. Brytum blows up if it picks up on nested circular dependencies
        // 2. Reduce UI clutter
        possibleConstrainingTaskId !== planTaskId &&
        allowanceItems.some((allowanceItem) => constraintItemIds.includes(allowanceItem.id))
      ) {
        dependencies.push({
          id: `from-${possibleConstrainingTaskId}-to-${planTaskId}`,
          from: possibleConstrainingTaskId,
          to: planTaskId,
          cls: getStyleClass(possibleConstrainingPlanTask),
          internalPlanTaskName: planTaskName,
          internalConstrainTaskName: name,
        });
      }
    });
  });

  return dependencies;
}

function prepareTaskData(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
  readOnly: boolean,
  groupBy: TaskGroupBy,
  isCollapsed: boolean,
) {
  if (groupBy === TaskGroupBy.Stage) {
    return planTasks
      .groupByObject((t) => t.stage)
      .sortBy(([_, tasks]) => tasks.min((t) => t.startDate))
      .map(([stage, tasks]) => ({
        id: stage?.id ?? "no-stage",
        name: stage?.name ?? "No Stage",
        ...toGroupEventWithChildren(tasks, readOnly, isCollapsed),
      }));
  }

  if (groupBy === TaskGroupBy.CostCode) {
    return planTasks
      .groupByObject((t) => t.globalPlanTask.budgetItem?.costCode)
      .sortBy(([_, tasks]) => tasks.min((t) => t.startDate))
      .map(([costCode, tasks]) => ({
        id: costCode?.id ?? "no-cost-code",
        name: costCode?.name ?? "No Cost Code",
        ...toGroupEventWithChildren(tasks, readOnly, isCollapsed),
      }));
  }

  return planTasks.map((t) => planTaskToGanttTask(t, readOnly)).sortByKey("startDate");
}

function toGroupEventWithChildren(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
  readOnly: boolean,
  isCollapsed: boolean,
): Omit<GanttEvent, "id" | "name"> {
  const groupStartDate = planTasks.min((t) => t.startDate);
  const groupEndDate = planTasks.max((t) => t.endDate);
  const allTasksComplete = planTasks.every((t) => t.status.code === TaskStatus.Complete);
  return {
    internalConstrainedBy: [],
    internalAllowedBy: [],
    startDate: groupStartDate,
    endDate: endOfDay(groupEndDate),
    manuallyScheduled: true,
    internalStartDate: groupStartDate,
    internalEndDate: groupEndDate,
    // We are currently not displaying the duration in the sidebar or tooltip for groupings
    // If we do, we'll need to include the schedule exclusion dates to calculate working days correctly
    internalDuration: 0,
    expanded: isCollapsed ? false : true,
    type: "grouping",
    draggable: false,
    resizable: false,
    cls: allTasksComplete ? completedGroupCls : nonCompleteGroupCls,
    children: planTasks.map((task) => planTaskToGanttTask(task, readOnly)).sortByKey("startDate"),
  };
}

function planTaskToGanttTask(planTask: ScheduleDraftMode_PlanTaskFragment, readOnly: boolean): GanttEvent {
  const { id, status, startDate, endDate, name, durationInDays, constraintItems, allowanceItems } = planTask;
  const isIncomplete = status.code !== TaskStatus.Complete;
  return {
    id,
    startDate,
    // Placing the endDate at the very end of the day coupled with the `hoursPerDay` & `calendar=business` settings
    // allow the library to show the task spanning the full day even though 1 day tasks start and end on the same day
    // https://forum.bryntum.com/viewtopic.php?t=17386
    // We do a 1 day offset with a startofday to make sure it displays across the whole day
    endDate: startOfDay(addDays(endDate, 1)),
    name,
    manuallyScheduled: true,
    // The date and duration fields are mutated by the gantt library scheduler so we can pass in
    // custom internal_ fields to get access to known unmodified/correct values
    internalEndDate: endDate,
    internalStartDate: startDate,
    internalDuration: durationInDays,
    internalConstrainedBy: constraintItems,
    internalAllowedBy: allowanceItems,
    draggable: isIncomplete && !readOnly,
    resizable: isIncomplete && !readOnly,
    cls: getStyleClass(planTask),
    expanded: false,
    type: "task",
  };
}

const completedTaskCls = "completed-task";
const tradeConfirmationNotSentCls = "trade-confirmation-not-sent-task";
const tradeConfirmationSentCls = "trade-confirmation-sent-task";
const defaultTaskCls = "default-task";
const nonCompleteGroupCls = "non-complete-group";
const completedGroupCls = "completed-group";
type TaskClasses =
  | typeof completedTaskCls
  | typeof tradeConfirmationNotSentCls
  | typeof tradeConfirmationSentCls
  | typeof defaultTaskCls
  | typeof nonCompleteGroupCls
  | typeof completedGroupCls;

function getStyleClass(planTask: ScheduleDraftMode_PlanTaskFragment): TaskClasses {
  const { status, startDate, tradePartnerStatus, tradePartnerAvailabilityRequests, tradePartner } = planTask;

  if (status.code === TaskStatus.Complete) return completedTaskCls;
  if (taskRequiresTradeConfirmation(status.code, startDate, tradePartnerStatus.code)) {
    const hasSentRequest = tradePartnerAvailabilityRequests?.some((tpar) => tpar.tradePartner.id === tradePartner?.id);
    return hasSentRequest ? tradeConfirmationSentCls : tradeConfirmationNotSentCls;
  }

  return defaultTaskCls;
}

function getTaskBarSelector(taskClass: TaskClasses, isSelected = false) {
  return `.b-gantt-task.${taskClass}${isSelected ? ".b-task-selected" : ""} > .b-gantt-task-content, .b-gantt-task.${taskClass}${isSelected ? ".b-task-selected" : ""}:not(.b-milestone)`;
}

function getTaskLabelSelector(taskClass: TaskClasses) {
  return `${getTaskBarSelector(taskClass)} + label`;
}

const ganttStyleOverrides = {
  ...Css
    // Chart Body
    .addIn(".b-gantt-body-wrap", Css.brt4.$)
    // Grid Header
    .addIn(".b-grid-header-container .b-sch-timeaxiscolumn", Css.bgWhite.$)
    // Column Header
    .addIn(".b-grid-header-container", Css.bgWhite.$)
    // selectedRow
    .addIn(".b-grid-row.b-selected:not(.b-group-row)", Css.bgBlue50.$)
    // Row Hover
    .addIn(
      ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover",
      Css.bgBlue100.$,
    )
    // Row Hover Selected
    .addIn(
      ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover.b-selected",
      Css.bgBlue100.$,
    )
    // Column Data
    .addIn(".b-tree-leaf-cell > .b-tree-cell-inner, .b-grid-cell", Css.xsMd.gray800.$)
    // Grouping Row Titles
    .addIn(".b-tree-parent-cell > .b-tree-cell-inner", Css.smSb.gray900.$)
    // Column Headers
    .addIn(".b-grid-header-text-content", Css.add("textTransform", "none").xsMd.gray800.$)
    // Today Marker Container
    .addIn(".b-grid-header .b-sch-timerange.b-sch-current-time", Css.bgBlue700.ttc.br4.px2.left("-36px").$)
    // Today Marker Label
    .addIn(".b-grid-header .b-sch-timerange label", Css.smMd.$)
    // Today Marker VerticalLine
    .addIn(".b-timeline-subgrid .b-sch-current-time", Css.bcBlue700.$)
    // Task Name Label
    .addIn(".b-sch-label.b-sch-label-top", Css.asb.xsSb.$)
    // Standard Task Bar
    .addIn(".b-gantt-task", Css.br4.$)
    // When a task is draggable, use the grab cursor
    .addIn(".b-gantt-task:not(.b-sch-event-fixed)", Css.cursor("grab").$)
    // Hide (non-collapse) Tree Icons
    .addIn(".b-tree-icon", Css.dn.$)
    // Updating position of the collapse/expand icon and grouping title
    .addIn(
      ".b-tree-expander.b-icon-tree-collapse, .b-tree-expander.b-icon-tree-expand",
      Css.wPx(12).mwPx(12).mlPx(4).mrPx(10).$,
    )
    .addIn(".b-holiday.b-sch-range.b-sch-range", Css.gray700.$)
    .addIn(".b-tree-expander.b-icon-tree-collapse", Css.wPx(12).mwPx(12).mlPx(4).mrPx(10).$)
    // Dependencies
    // NOTE: We're only able to style the arrow for all dep lines, not individually
    //   https://github.com/bryntum/support/issues/4906
    // .addIn(".b-sch-dependency-arrow", Css.$)
    .addIn(".debug-dependency-darken", Css.add("strokeWidth", "2px").add("stroke", Palette.Gray900).important.$)
    .addIn(".b-gantt-task.debug-dependency-task-outline", Css.ba.bsDashed.bw2.bcGray900.$)
    .addIn(`.b-sch-dependency.${completedTaskCls}`, Css.add("stroke", Palette.Gray200).$)
    .addIn(`.b-sch-dependency.${tradeConfirmationNotSentCls}`, Css.add("stroke", Palette.Red200).$)
    .addIn(`.b-sch-dependency.${tradeConfirmationSentCls}`, Css.add("stroke", Palette.Yellow200).$)
    .addIn(`.b-sch-dependency.${defaultTaskCls}`, Css.add("stroke", Palette.Blue200).$)
    // Task Status "Bar" Coloring
    .addIn(getTaskBarSelector(completedTaskCls), Css.bgGray300.$)
    .addIn(getTaskBarSelector(tradeConfirmationNotSentCls), Css.bgRed100.$)
    .addIn(getTaskBarSelector(tradeConfirmationSentCls), Css.bgYellow100.$)
    .addIn(getTaskBarSelector(defaultTaskCls), Css.bgBlue100.$)
    .addIn(getTaskBarSelector(nonCompleteGroupCls), Css.bgBlue300.$)
    .addIn(getTaskBarSelector(completedGroupCls), Css.bgGray400.$)
    // Task Status "Bar" Selected Coloring
    .addIn(getTaskBarSelector(completedTaskCls, true), Css.bgGray500.$)
    .addIn(getTaskBarSelector(tradeConfirmationNotSentCls, true), Css.bgRed300.$)
    .addIn(getTaskBarSelector(tradeConfirmationSentCls, true), Css.bgYellow300.$)
    .addIn(getTaskBarSelector(defaultTaskCls, true), Css.bgBlue300.$)
    .addIn(getTaskBarSelector(nonCompleteGroupCls, true), Css.bgBlue500.$)
    .addIn(getTaskBarSelector(completedGroupCls, true), Css.bgGray600.$)
    // Task Status "Label" Coloring
    .addIn(getTaskLabelSelector(completedTaskCls), Css.gray700.$)
    .addIn(getTaskLabelSelector(tradeConfirmationNotSentCls), Css.red700.$)
    .addIn(getTaskLabelSelector(tradeConfirmationSentCls), Css.yellow700.$)
    .addIn(getTaskLabelSelector(defaultTaskCls), Css.blue700.$)
    .addIn(getTaskLabelSelector(nonCompleteGroupCls), Css.blue700.$)
    .addIn(getTaskLabelSelector(completedGroupCls), Css.gray700.$).$,
};

export function taskTooltipMarkup({ taskRecord, debugMode = false }: { taskRecord: GanttEvent; debugMode?: boolean }) {
  const { internalStartDate, name, internalEndDate, internalDuration, internalConstrainedBy, internalAllowedBy, type } =
    taskRecord;
  if (type === "grouping") return null;

  return renderToString(
    <div css={Css.mwPx(200).$}>
      <div css={Css.smSb.$}>{name}</div>
      <table css={Css.w100.$}>
        <tbody>
          <tr>
            <td>Start:</td>
            <td>{formatWithShortYear(internalStartDate)}</td>
          </tr>
          <tr>
            <td>End:</td>
            <td>{formatWithShortYear(internalEndDate)}</td>
          </tr>
          <tr>
            <td>Duration:</td>
            <td>
              {internalDuration} {pluralize(internalDuration, "day")}
            </td>
          </tr>
        </tbody>
      </table>
      {debugMode && (
        <>
          <div css={Css.smSb.ptPx(4).$}>Constraints:</div>
          {internalConstrainedBy.map(({ name }) => {
            return (
              <div css={Css.pl2.$} key={`Constraints-${name}`}>
                {name}
              </div>
            );
          })}
          <div css={Css.smSb.$}>Allowances:</div>
          {internalAllowedBy.map(({ name }) => {
            return (
              <div css={Css.pl2.$} key={`Allowances-${name}`}>
                {name}
              </div>
            );
          })}
        </>
      )}
    </div>,
  );
}
