import { Css, GridDataRow, simpleHeader } from "@homebound/beam";
import { addDays, addWeeks, isAfter, isBefore, isSameDay, startOfToday, startOfWeek, subDays } from "date-fns";
import { useRouteMatch } from "react-router";
import {
  BillSource,
  Maybe,
  PlanTask,
  PlanTaskBillModal_ProtoBillFragment,
  SaveBillInput,
  SaveBillLineItemInput,
  ScheduleDraftMode_PlanMilestoneFragment,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_TradePartnerContactFragment,
  ScheduleDraftMode_TradePartnerFragment,
  SchedulingExclusionDate,
  TaskDetailsPage_ProjectItemFragment,
  TaskStatus,
  TradeConfirmPublishStep_TradePartnerFragment,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { dynamicSchedulesPath } from "src/routes/routesDef";
import { calcPercent, findIndexFrom, isDefined } from "src/utils";
import { DateOnly, formatShort } from "src/utils/dates";

// Shared type for task detail sections that display project items
export type ProjectItemsSection = {
  projectItems: TaskDetailsPage_ProjectItemFragment[];
  // Don't render border and extra padding when reusing section in side pane
  inSidePane?: boolean;
};

// shared utility function to map market specific contacts to their TradePartnerMarketRoles
export function getMarketsContacts(
  tradePartner: Maybe<ScheduleDraftMode_TradePartnerFragment | TradeConfirmPublishStep_TradePartnerFragment>,
): ScheduleDraftMode_TradePartnerContactFragment[] {
  if (!tradePartner || !tradePartner.contactsForMarket) return [];

  return tradePartner.contactsForMarket.compact();
}

export function MarketContactMenuLabel({ name, email }: ScheduleDraftMode_TradePartnerContactFragment) {
  return (
    <div css={Css.df.fdc.gap1.$}>
      <div>
        {name}, {"<"}
        {email}
        {">"}
      </div>
    </div>
  );
}

export function ScheduleContainer({ children }: { children: React.ReactNode }) {
  return <div css={Css.h100.w100.pr3.ma.$}>{children}</div>;
}

export enum DynamicScheduleView {
  Calendar = "Calendar",
  Gantt = "Gantt",
  List = "List",
  Lookahead = "Lookahead",
  Milestone = "Milestone",
}

export function useScheduleRouteMatch() {
  const isGanttView = !!useRouteMatch(dynamicSchedulesPath.draftGantt);
  const isCalendarView = !!useRouteMatch(dynamicSchedulesPath.draftCalendar);
  const isListView = !!useRouteMatch(dynamicSchedulesPath.draftMode)?.isExact;
  const isMilestoneView = !!useRouteMatch(dynamicSchedulesPath.draftMilestone);
  const isLookaheadView = !!useRouteMatch(dynamicSchedulesPath.draftLookahead);

  if (isGanttView) return DynamicScheduleView.Gantt;
  if (isCalendarView) return DynamicScheduleView.Calendar;
  if (isMilestoneView) return DynamicScheduleView.Milestone;
  if (isListView) return DynamicScheduleView.List;
  if (isLookaheadView) return DynamicScheduleView.Lookahead;

  throw new Error("Unknown schedule view");
}

/**
 * Inserts lookahead divider rows into the task rows when a dynamic schedule list displays a lookahead view.
 */
export function insertLookaheadDividerRows(taskRows: DynamicSchedulesRow[]): DynamicSchedulesRow[] {
  const mondayOfThisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 });

  const lookaheadRows: GridDataRow<LookaheadRow>[] = [
    {
      kind: "lookahead" as const,
      id: "2 Weeks Divider",
      data: { name: "2 Weeks", startDate: mondayOfThisWeek, endDate: subDays(addWeeks(mondayOfThisWeek, 2), 1) },
    },
    {
      kind: "lookahead" as const,
      id: "3 Weeks Divider",
      data: {
        name: "3 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 2),
        endDate: subDays(addWeeks(mondayOfThisWeek, 3), 1),
      },
    },
    {
      kind: "lookahead" as const,
      id: "4 Weeks Divider",
      data: {
        name: "4 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 3),
        endDate: subDays(addWeeks(mondayOfThisWeek, 4), 1),
      },
    },
    {
      kind: "lookahead" as const,
      id: "6 Weeks Divider",
      data: {
        name: "6 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 4),
        endDate: subDays(addWeeks(mondayOfThisWeek, 6), 1),
      },
    },
  ];

  lookaheadRows.forEach((lar) => {
    // Find the first task that starts between the lookahead rows start and end date and insert lar right before it.
    const firstTaskIndex = findIndexFrom(taskRows, (row) => {
      const { kind, data } = row;
      let starter: Date;
      switch (kind) {
        case "task":
        case "lookahead":
          starter = data.startDate;
          break;
        // Milestones can technically not have an estStartDate
        case "milestone":
          starter = data?.estStartDate ?? subDays(mondayOfThisWeek, 1);
          break;
        case "header":
        default:
          starter = subDays(mondayOfThisWeek, 1);
          break;
      }

      return (
        (isSameDay(starter, lar.data.startDate) || isAfter(starter, lar.data.startDate)) &&
        isBefore(starter, lar.data.endDate)
      );
    });
    if (firstTaskIndex !== -1) {
      // @ts-ignore
      taskRows.splice(firstTaskIndex, 0, lar);
    }
  });

  return taskRows;
}

/* Maps our DayOfWeek enum to numeric day */
export enum DayOfWeekDayPicker {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

/** Maps PlanTask.customWorkableDays and PlanSchedule.scheduleExcludedDates to the correct input for `date-fns` business day helpers */
export function getBusinessDaysOptions(
  customWorkableDays: PlanTask["customWorkableDays"],
  scheduleExcludedDates?: Pick<SchedulingExclusionDate, "date">[],
) {
  return {
    businessDays: customWorkableDays?.map((d) => DayOfWeekDayPicker[d]),
    exceptions: scheduleExcludedDates?.keyBy(
      (sed) => formatShort(sed.date),
      () => false,
    ),
  };
}

type IsDisableDayInput = {
  date: DateOnly | Date;
  customWorkableDays: PlanTask["customWorkableDays"];
  scheduleExcludedDates?: DateOnly[];
};

export function isDisabledDay({ date, customWorkableDays, scheduleExcludedDates }: IsDisableDayInput) {
  const totalWorkingDays = customWorkableDays?.map((d) => DayOfWeekDayPicker[d]);

  const isWorkingDayForTask = totalWorkingDays.includes(date.getDay());
  const isHoliday = scheduleExcludedDates?.some((d) => isSameDay(d, date));

  return !isWorkingDayForTask || isHoliday;
}

/** Shared util to check if a task is in the "2 week window" and has not confirmed the trade */
export function taskRequiresTradeConfirmation(
  taskStatus: TaskStatus,
  taskStartDate: DateOnly,
  tradeStatus: TradePartnerTaskStatus,
) {
  const confirmedStatuses = [TradePartnerTaskStatus.Confirmed, TradePartnerTaskStatus.CompletedJob];
  return (
    taskStatus !== TaskStatus.Complete &&
    isBefore(taskStartDate.date, addDays(new Date(), 14)) &&
    !confirmedStatuses.includes(tradeStatus)
  );
}
export type LookaheadRow = { kind: "lookahead"; id: string; data: { name: string; startDate: Date; endDate: Date } };
export type DynamicSchedulesRow =
  | DynamicSchedulesHeaderRow
  | DynamicSchedulesGroupRow
  | LookaheadRow
  | DynamicSchedulesTaskRow
  | DynamicSchedulesMilestoneRow;

type DynamicSchedulesHeaderRow = { kind: "header"; id: string; data: undefined };
type DynamicSchedulesGroupRow = { kind: "group"; id: string; data: { name: string; progress?: number } };
export type DynamicSchedulesTaskRow = {
  kind: "task";
  id: string;
  data: ScheduleDraftMode_PlanTaskFragment;
};

export type DynamicSchedulesMilestoneRow = {
  kind: "milestone";
  id: string;
  data: ScheduleDraftMode_PlanMilestoneFragment;
};

export function createTaskAndMilestoneRows(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
  planMilestones: ScheduleDraftMode_PlanMilestoneFragment[],
  isLookaheadView: boolean,
): GridDataRow<DynamicSchedulesRow>[] {
  const taskRows: GridDataRow<DynamicSchedulesTaskRow>[] = planTasks.map((task) => ({
    kind: "task" as const,
    id: task.id,
    data: task,
  }));

  const planMilestoneRows: GridDataRow<DynamicSchedulesMilestoneRow>[] = (
    isLookaheadView ? filterMilestonesForLookahead(planMilestones) : planMilestones
  ).map((pm) => ({
    id: pm.id,
    kind: "milestone" as const,
    data: pm,
  }));

  const sortedTaskAndMilestones = [...planMilestoneRows, ...taskRows].sort(sortTaskAndMilestoneRows);

  return [
    simpleHeader,
    ...(isLookaheadView ? insertLookaheadDividerRows(sortedTaskAndMilestones) : sortedTaskAndMilestones),
  ];
}

export function createStageRows(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
  allTasks: ScheduleDraftMode_PlanTaskFragment[],
): GridDataRow<DynamicSchedulesRow>[] {
  const stageGroups = planTasks.groupBy((task) => task.stage?.id ?? "no-stage");
  // we need to group all tasks by stage to calculate the progress (regardless of the task filters for "show completed")
  const allTasksStageGroups = allTasks?.groupBy((task) => task.stage?.id ?? "no-stage");
  return [
    simpleHeader,
    ...Object.entries(stageGroups)
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([stageId, tasks]) => [
        {
          kind: "group" as const,
          id: stageId,
          data: {
            name: tasks.first?.stage?.name ?? "No Stage",
            progress: calculateStageGroupProgress(allTasksStageGroups[stageId] ?? []),
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
          // collapse the stage if every task has been completed in that stage
          initCollapsed: tasks.every((t) => t.status.code === TaskStatus.Complete),
        },
      ]),
  ];
}

export function createCostCodeRows(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
): GridDataRow<DynamicSchedulesRow>[] {
  const costCodeGroups = planTasks.groupByObject((task) => task.globalPlanTask.budgetItem.costCode);
  return [
    simpleHeader,
    ...costCodeGroups
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([costCode, tasks]) => [
        {
          kind: "group" as const,
          id: costCode.id,
          data: {
            name: costCode.name,
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
        },
      ]),
  ];
}

export function sortTaskAndMilestoneRows(
  a: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>,
  b: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>,
) {
  const getCompareValues = (row: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>) => ({
    completed:
      row.kind === "task"
        ? row.data.status.code === TaskStatus.Complete
        : (row.data as ScheduleDraftMode_PlanMilestoneFragment).progress === 100,
    startDate:
      row.kind === "task"
        ? row.data.startDate
        : ((row.data as ScheduleDraftMode_PlanMilestoneFragment)?.estStartDate ?? new Date()),
    name: row.data.name.toLowerCase(),
  });

  const aCompare = getCompareValues(a);
  const bCompare = getCompareValues(b);

  // Completed always first
  if (aCompare.completed !== bCompare.completed) return aCompare.completed ? -1 : 1;
  // Then by date start
  if (aCompare.startDate !== bCompare.startDate) return aCompare.startDate < bCompare.startDate ? -1 : 1;

  // Finally by name
  return aCompare.name.localeCompare(bCompare.name);
}

// FIXME: This logic isn't ideal but while we're inbetween combining draft and standard views, and still
//  refining what is "normal" for these tables we will carry this debt
export function filterMilestonesForLookahead<T extends { estStartDate?: DateOnly | null | undefined }>(
  planMilestones: T[],
) {
  const mondayOfThisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 });

  return planMilestones.filter(
    ({ estStartDate }) =>
      !!estStartDate && (isSameDay(estStartDate, mondayOfThisWeek) || isAfter(estStartDate, mondayOfThisWeek)),
  );
}

function calculateStageGroupProgress(tasks: ScheduleDraftMode_PlanTaskFragment[]): number {
  if (tasks.isEmpty) return 0; // Return 0 if no stage groups are provided

  const completedTasksCount = tasks.filter(({ status }) => status.code === TaskStatus.Complete).length;
  return Math.round(calcPercent(completedTasksCount, tasks.length));
}

// ------------ Plan Task billing commons ------------

export const ungroupedTradeLineId = "no-group";

export type DrawRow = {
  kind: "draw";
  data: { drawBasisPoints: number; drawDescription?: string | null; lineItemTotal: number };
};
export type TradeLineRow = {
  kind: "tradeLine";
  data: { displayName: string; lineItemTotal: number };
};
export type OwnerRow = {
  kind: "lineItemOwner";
  data: {
    tradePartnerName: string | undefined;
    accountingNumber: number;
    ownerType: string;
  };
};
export type PlanTaskBillRow = OwnerRow | DrawRow | TradeLineRow;

export function groupPlanTaskProtoBills(
  protoBills: PlanTaskBillModal_ProtoBillFragment[],
  ungroupedTradeLineIdentifier: string = ungroupedTradeLineId,
) {
  // First group the protoBills by Commitment / CommitmentChangeOrder (owner of the line item)
  const lineItemsByOwner = protoBills
    .flatMap((protoBill) => protoBill.lineItems)
    .groupByObject((li) => li.commitmentLineItem.owner);

  return lineItemsByOwner.map(([lineItemOwner, lineItems], idx) => {
    // In the unlikely event that line items are not part of a "draw" and also do not have a "tradeLineItem",
    // then bucket them together into a "Misc" section (using this object identity for the `groupByObject` below).
    const ungroupedTradeLineBucket = {
      id: ungroupedTradeLineIdentifier,
      bidItem: { displayName: "Misc" },
    } as const;

    // Separate out line items associated a "commitmentDraw" then group the non-draw items by trade line
    const [drawLineItems, nonDrawItems] = lineItems.partition((li) => isDefined(li.commitmentDraw));
    const draws = drawLineItems.groupByObject((li) => li.commitmentDraw!);
    const tradeLines = nonDrawItems.groupByObject(
      (li) =>
        li.commitmentLineItem.primaryBidContractLineItem?.prorations?.first?.tradeLineItem ?? ungroupedTradeLineBucket,
    );
    return { lineItemOwner, draws, tradeLines };
  });
}

export function getPlanTaskLineItemRows(
  lineItemsByOwner: ReturnType<typeof groupPlanTaskProtoBills>,
): GridDataRow<PlanTaskBillRow>[] {
  return lineItemsByOwner.map(({ lineItemOwner, draws, tradeLines }) => {
    const drawRows = draws.map(([commitmentDraw, drawLineItems]) => ({
      kind: "draw" as const,
      id: commitmentDraw.id,
      initSelected: true,
      data: {
        drawBasisPoints: commitmentDraw.amountInBasisPoints,
        drawDescription: commitmentDraw.description,
        lineItemTotal: drawLineItems.sum((li) => li.amountInCents),
      },
    }));

    const tradeLineRows = tradeLines.map(([tradeLine, lineItems]) => ({
      kind: "tradeLine" as const,
      id: tradeLine.id,
      initSelected: true,
      data: {
        displayName: tradeLine.bidItem?.displayName ?? "Misc",
        lineItemTotal: lineItems.sum((li) => li.amountInCents),
      },
    }));

    return {
      kind: "lineItemOwner",
      id: lineItemOwner.id,
      data: {
        tradePartnerName: lineItemOwner.tradePartner?.name,
        accountingNumber: lineItemOwner.accountingNumber,
        ownerType: lineItemOwner.__typename === "Commitment" ? "PO" : "CO",
      },
      children: [...drawRows, ...tradeLineRows],
    };
  });
}

type PlanTaskBill_BillLineItemInput = Pick<
  SaveBillLineItemInput,
  "id" | "taskId" | "commitmentLineItemId" | "amountInCents"
>;
type PlanTaskBill_SaveBillInput = Pick<SaveBillInput, "id"> & { lineItems: PlanTaskBill_BillLineItemInput[] };

export function mapPlanTaskToSaveBillInput(
  taskId: string,
  protoBills: PlanTaskBillModal_ProtoBillFragment[],
  deselectedLineItems: PlanTaskBillModal_ProtoBillFragment["lineItems"],
  tradePartnerId: string | undefined,
  internalNote: string | undefined,
): PlanTaskBill_SaveBillInput[] {
  const deselectedClis = deselectedLineItems.map((li) => li.commitmentLineItem.id);

  const groupedBillInputsByTrade = protoBills.groupBy((pb) => pb.tradePartner.id);

  const groupedExistingBillsByTrade = protoBills
    .flatMap((p) => p.existingBills)
    // if in direct pay trade view we filter existing bills by tp id
    .filter((b) => !tradePartnerId || b?.tradePartner?.id === tradePartnerId)
    .groupBy((b) => b.tradePartner.id);

  const saveBillsInput = Object.keys(groupedBillInputsByTrade).reduce((input, key) => {
    const groupedInputBills = groupedBillInputsByTrade[key];
    const groupedExistingBills = groupedExistingBillsByTrade[key] || [];

    // If a trade has 2 bill types open, make sure we add line items to the correct bill type
    // otherwise if no bill found for that trade with a matching type
    // create a new bill for the trade from our input data

    // extract trade partner and project stage id from an existing draft bill or our inputs
    const { tradePartner } = isDefined(groupedExistingBills.first?.id)
      ? groupedExistingBills.first
      : (groupedInputBills.first ?? {});
    const projectStageId =
      groupedExistingBills.first?.projectStage?.id ??
      groupedInputBills.first?.lineItems?.first?.commitmentLineItem.projectItem?.projectStage.id;

    // split existing trade bills by deferred or immediate type
    const [existingDeferredBill, existingImmediateBill] = groupedExistingBills.partition((b) => b.isDeferred);
    // if there's a matching bill already existing for the trade, format the line items
    const existingDeferredItems = (existingDeferredBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentDrawId: li.commitmentDraw?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");
    const existingImmediateItems = (existingImmediateBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentDrawId: li.commitmentDraw?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");

    // format the new line item inputs
    const newLineItems = groupedInputBills.flatMap((b) =>
      b.lineItems.map((li) => ({
        taskId,
        commitmentDrawId: li.commitmentDraw?.id,
        commitmentLineItemId: li.commitmentLineItem.id,
        amountInCents: li.amountInCents,
      })),
    );

    // split the new line item inputs by deferred or immediate type based on items deselected
    // ensure the split occurs only when not in the direct pay trade view
    const [newDeferredItems, newImmediateItems] = newLineItems.partition(
      (bli) => deselectedClis?.includes(bli.commitmentLineItemId) && !isDefined(tradePartnerId),
    );

    const deferredBillInput = [
      ...(existingDeferredBill.first?.tradePartner.id && newDeferredItems.nonEmpty
        ? [
            {
              // If deferred bill already exists update existing bill
              id: existingDeferredBill.first.id,
              lineItems: [...existingDeferredItems, ...newDeferredItems],
              // preserve previous deferred notes & append notes for new items added
              internalNote: [existingDeferredBill.first.internalNote, internalNote].join(",\n"),
            },
          ]
        : // create a new deferred trade bill if deferred bill does not exist
          newDeferredItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: true,
                internalNote,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newDeferredItems,
              },
            ]
          : []),
    ];

    const immediateBillInput = [
      ...(existingImmediateBill.first?.tradePartner.id && newImmediateItems.nonEmpty
        ? [
            {
              // If immediate bill already exists update existing bill
              id: existingImmediateBill.first.id,
              lineItems: [...existingImmediateItems, ...newImmediateItems],
            },
          ]
        : // create a new immediate trade bill if immediate bill does not exist
          newImmediateItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: false,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newImmediateItems,
              },
            ]
          : []),
    ];

    return input.concat([...deferredBillInput, ...immediateBillInput] satisfies PlanTaskBill_SaveBillInput[]);
  }, [] as PlanTaskBill_SaveBillInput[]);

  return saveBillsInput;
}
