import { Accordion, Css } from "@homebound/beam";
import { pascalCase } from "change-case";
import { VersionDetailsCard_VersionFragment } from "src/generated/graphql-types";
import { ConfigChanges } from "src/routes/my-blueprint/activity-feed/events/ProjectReadyPlanConfig/components";

export type ProgramDataChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

export function ProgramDataChanges(props: ProgramDataChangesProps) {
  const { version } = props;
  const { programDataVersions, removedProgramDataVersions } = version;

  const removedByIdentity = removedProgramDataVersions.groupBy(({ identity }) => identity.id);
  const relevantProgramDataVersions = programDataVersions.filter(({ programData, identity }) => {
    const { __typename, id, ...fields } = programData;
    // Because brand new options will get an empty program data object, we want to ignore those or any other that has no actual fields set.
    return Object.keys(fields).filter(
      (key) =>
        (!!(programData as any)[key] || !!(removedByIdentity as any)[identity.id]?.[0]?.programData[key]) &&
        (programData as any)[key] !== (removedByIdentity as any)[identity.id]?.[0]?.programData[key],
    ).nonEmpty;
  });

  if (relevantProgramDataVersions.isEmpty) {
    return <></>;
  }

  return (
    <Accordion
      compact
      xss={Css.py0.$}
      title="Program Data"
      children={
        <>
          {relevantProgramDataVersions.map(({ programData, identity }) => {
            const { __typename, id, ...fields } = programData;
            const fieldsChanged = Object.keys(fields)
              .filter(
                (key) =>
                  (!!(programData as any)[key] || !!(removedByIdentity as any)[identity.id]?.[0]?.programData[key]) &&
                  (programData as any)[key] !== (removedByIdentity as any)[identity.id]?.[0]?.programData[key],
              )
              .map((key) => {
                const newValue = (programData as any)[key];
                const oldValue = (removedByIdentity as any)[identity.id]?.[0]?.programData[key];
                return {
                  attributeName: pascalCase(key).replaceAll(/(?!^)([A-Z])/g, " $1"),
                  newValue:
                    typeof newValue === "object" && newValue?.name
                      ? newValue.name
                      : typeof newValue === "boolean"
                        ? newValue + ""
                        : newValue === null
                          ? "null"
                          : newValue,
                  oldValue:
                    typeof oldValue === "object" && oldValue?.name
                      ? oldValue.name
                      : typeof oldValue === "boolean"
                        ? oldValue + ""
                        : oldValue,
                };
              });

            return (
              <Accordion
                key={id}
                compact
                xss={Css.py0.$}
                defaultExpanded
                title={identity.readyPlanOptions.map((o) => o.displayName).join(", ") || version.readyPlan.name}
                children={<ConfigChanges optionsAdded={{}} optionsRemoved={{}} fieldsChanged={fieldsChanged} />}
              />
            );
          })}
        </>
      }
    />
  );
}
