import { Global } from "@emotion/react";
import { Css, GridColumn, GridTable, px, simpleDataRows, SimpleHeaderAndData } from "@homebound/beam";
import { Fragment } from "react";
import { Price, priceCell } from "src/components";
import {
  CostType,
  DevelopmentCommitmentPdfCommitmentLineItemFragment,
  PandaDocRole,
  ProductAttributeType,
  useDevelopmentCommitmentPdfQuery,
} from "src/generated/graphql-types";
import { Signature } from "src/routes/pdfs/components/PandaDocField";
import { groupBy, queryResult } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";
import { CommitmentAddresses } from "../components/CommitmentAddresses";
import { DefaultSpecsText } from "../components/DefaultSpecsText";
import { Section } from "../components/Section";

export function DevelopmentCommitmentPdf() {
  const [developmentCommitmentId] = useQueryParam("developmentCommitmentId", StringParam);

  const query = useDevelopmentCommitmentPdfQuery({
    variables: { developmentCommitmentId: developmentCommitmentId! },
  });

  return queryResult(query, (data) => {
    const [dc] = data.developmentCommitments;
    const { commitments, homeboundAuthorizedSignatory, areaManager, signatoryContact } = dc;
    const tradePartner = dc.tradePartner!;
    const address = signatoryContact?.address;
    const totalCostInCents = commitments
      .flatMap((c) => c.lineItems)
      .reduce((sum, lineItem) => sum + (lineItem.costChangeInCents ?? 0), 0);

    if (!signatoryContact || !homeboundAuthorizedSignatory || !areaManager || !address) {
      return (
        <div>
          Unable to find valid area manager, homebound authorized signatory, or signatory trade partner contact for{" "}
          {dc.name}
        </div>
      );
    }

    return (
      <Fragment>
        <Global
          styles={{
            "@page": { size: "letter" },
            ul: Css.ml2.add("listStyleType", "disc").$,
          }}
        />

        <img src="/wordmark.svg" alt="Homebound" css={Css.h(px(40)).$} />

        <div css={Css.ttu.lgSb.tac.mt2.$}>Purchase Order</div>

        <div css={Css.df.fdr.bgGray600.white.ttu.mt2.$}>
          <div css={Css.df.f1.p1.$}>Prime Contractor ("Homebound")</div>
          <div css={Css.df.f1.p1.$}>Trade Partner Representative</div>
        </div>

        <CommitmentAddresses tradePartner={tradePartner!} signatoryContact={signatoryContact} />

        <div css={Css.df.fdr.bgGray400.$}>
          <div css={Css.df.f1.p1.$}>Development Addresses</div>
          <div css={Css.df.f1.p1.$}>PO #</div>
        </div>

        {commitments.map((commitment) => {
          const { street1, street2, city, state, postalCode } = commitment.project.buildAddress;
          return (
            <div key={commitment.id} css={Css.df.fdr.$}>
              <div css={Css.f1.p1.$}>
                {street1} {street2} {city}, {state} {postalCode}
              </div>
              <div css={Css.f1.p1.$}>{commitment.accountingNumber}</div>
            </div>
          );
        })}

        <Section title="Line Items">
          {commitments.map((commitment) => {
            const productType = commitment.project.productType?.name;
            // TODO: Restore this with non-ReadyPlan V0 entities
            const readyPlanName = ""; // commitment.project.projectReadyPlanSelection?.readyPlanVariation?.readyPlan.name;
            const exteriorStyle = ""; // commitment.project.projectReadyPlanSelection?.exteriorStyle?.name;
            return (
              <div css={Css.mb2.$} key={commitment.id}>
                <div css={Css.df.bgGray400.$}>
                  <div css={Css.p1.$}>Lot Address: {commitment.project.buildAddress.street1}</div>
                  {productType === "Readyplan" && readyPlanName && exteriorStyle && (
                    <div css={Css.p1.pl0.$}>{`(${readyPlanName}${exteriorStyle})`}</div>
                  )}
                  <div css={Css.p1.$}>PO #: {commitment.accountingNumber}</div>
                </div>
                <GridTable as="table" columns={columns} rows={simpleDataRows(commitment.lineItems)} />
                <div css={Css.df.f1.tar.jcfe.pr3.$}>
                  Total:&nbsp;
                  <Price
                    valueInCents={commitment.lineItems.reduce((acc, curr) => (curr.costChangeInCents ?? 0) + acc, 0)}
                  />
                </div>
              </div>
            );
          })}
          <div css={Css.df.fdr.p1.pr3.$}>
            <div css={Css.df.f1.fwb.tar.jcfe.pt5.$}>
              Total for all lots:&nbsp;
              <Price valueInCents={totalCostInCents} />
            </div>
          </div>
        </Section>

        {dc.contractExhibits && <Section title="Drawings and Exhibits" html={dc.contractExhibits} />}

        <Section title="Project Specifications">
          <DefaultSpecsText />
          <ul>
            {commitments.map((commitment) => {
              const byCostType = groupBy(commitment.lineItems, (li) => li.projectItem.costTypeDetail.code);
              return [CostType.Subcontractor, CostType.Materials, CostType.Labor, CostType.Equipment, CostType.Other]
                .map((ct) => byCostType[ct])
                .filter((lineItems) => lineItems !== undefined)
                .map((li) => {
                  const templateNameGroup = groupBy(
                    li,
                    (li) => li.projectItem.itemTemplateItem?.parent.displayName || "Others",
                  );
                  const { street1, street2, city, state, postalCode } = commitment.project.buildAddress;
                  return (
                    <li key={commitment.id}>
                      {street1} {street2} {city} {state} {postalCode} -{" "}
                      {li[0]?.projectItem?.costTypeDetail?.tradePartnerName}
                      {Object.keys(templateNameGroup).map((templateName) => {
                        return (
                          <ul key={templateName}>
                            {templateName === "Others" ? "" : <span css={Css.fwb.$}>{templateName}</span>}
                            {templateNameGroup[templateName]
                              ?.sort(
                                (a, b) => Number(a.projectItem.item.fullCode) - Number(b.projectItem.item.fullCode),
                              )
                              .filter((li) => !!li.specifications)
                              .map((li) => {
                                const product = li.projectItem.homeownerSelection?.selectedOption?.product;
                                return (
                                  <li key={li.id} css={Css.ml2.$}>
                                    <span>{li.projectItem.displayName}</span>
                                    <ul data-testid="specificationsList">
                                      {product && (
                                        <>
                                          <li>Product: {product.name}</li>
                                          <li>
                                            Finish:{" "}
                                            {product.attributes
                                              .filter((attr) => attr.type === ProductAttributeType.Finish)
                                              .map((attr) => attr.value)
                                              .join(", ")}
                                          </li>
                                          <li>
                                            Brand:{" "}
                                            {
                                              product.attributes.find(
                                                (attr) => attr.type === ProductAttributeType.Brand,
                                              )?.value
                                            }
                                          </li>
                                          <li>Model: {product.sku}</li>
                                        </>
                                      )}
                                      <li>Specifications: {li.specifications}</li>
                                    </ul>
                                  </li>
                                );
                              })}
                          </ul>
                        );
                      })}
                    </li>
                  );
                });
            })}
          </ul>
        </Section>

        {dc.contractSchedule && <Section title="Schedule" html={dc.contractSchedule} />}

        {dc.contractExclusions && <Section title="Exclusions" html={dc.contractExclusions} />}

        {dc.contractTerms && <Section title="Terms and Conditions" html={dc.contractTerms} />}

        <div css={Css.add({ breakInside: "avoid" }).$}>
          <div css={Css.df.fdr.aic.bgGray400.mt2.mb1.base.ttu.tac.p1.$}>
            <div css={Css.df.f1.fdc.aic.$}>Homebound</div>
            <div css={Css.df.f1.fdc.aic.$}>Trade partner</div>
          </div>

          <div css={Css.df.fdr.$}>
            <div css={Css.df.fdc.f1.p1.$}>
              <Signature role={PandaDocRole.HomeboundAuthorizedSignatory} prefix="By: " />
              <div>Name: {homeboundAuthorizedSignatory.name}</div>
              <div>Title: Homebound Authorized Signatory</div>
            </div>
            <div css={Css.df.fdc.f1.p1.$}>
              <Signature role={PandaDocRole.TradePartner} prefix="By: " />
              <div>Name: {signatoryContact.name}</div>
              <div>Title: {signatoryContact.title}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  });
}

type Row = SimpleHeaderAndData<DevelopmentCommitmentPdfCommitmentLineItemFragment>;

// Headers need to be wrapped in a span to avoid default styling applied to simple strings in `maybeAddHeaderStyling`
const h = (header: string) => () => <span>{header}</span>;

const columns: GridColumn<Row>[] = [
  { header: h("Description"), data: ({ projectItem }) => projectItem.name },
  {
    header: h("Item Code"),
    data: ({ projectItem }) => projectItem.item.fullCode,
  },
  {
    header: h("Location"),
    data: ({ projectItem }) => (projectItem.location ? projectItem.location.name : "-"),
  },
  { header: h("Cost Type"), data: ({ projectItem }) => projectItem.costTypeDetail.name },
  { header: h("Qty"), data: ({ quantity }) => quantity },
  { header: h("Units"), data: ({ projectItem }) => projectItem.unitOfMeasure.abbreviation },
  {
    header: h("Unit Price"),
    data: ({ costChangeInCents, quantity }) => {
      const valueInCents = costChangeInCents && quantity ? costChangeInCents / quantity : costChangeInCents;
      return priceCell({ valueInCents });
    },
  },
  { header: h("Subtotal"), data: ({ costChangeInCents }) => priceCell({ valueInCents: costChangeInCents }) },
];
