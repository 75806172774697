import {
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SubmitButton,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import {
  Estimate_MaterialCostPageFragment,
  SaveBidItemEstimateInput,
  TaskCatalogLaborCostsPage_BidItemEstimateFragment,
  useBidEstimateModalOptionsQuery,
  useSaveBidItemEstimateMutation,
} from "src/generated/graphql-types";

type BidEstimateModalProps = {
  materialVariantId?: string;
  bidEstimate?: Estimate_MaterialCostPageFragment | TaskCatalogLaborCostsPage_BidItemEstimateFragment;
  globalPlanTaskId?: string;
};

// this modal is now supporting creating bid item estimates for both materials and labor (via global plan task id for labor)
export function BidEstimateModal({ materialVariantId, bidEstimate, globalPlanTaskId }: BidEstimateModalProps) {
  const { closeModal } = useModal();
  const [saveBidItemEstimate] = useSaveBidItemEstimateMutation();
  const { data } = useBidEstimateModalOptionsQuery();
  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bidEstimate,
      map: (input) => ({
        materialVariantId,
        globalPlanTaskId,
        ...input,
        unitOfMeasureId: input?.bidItemEstimate.unitOfMeasure.id,
        marketIds: input?.markets.map((m) => m.id),
      }),
      ifUndefined: {
        materialVariantId,
        globalPlanTaskId,
      },
    },
  });

  async function saveAndExit() {
    await saveBidItemEstimate({
      variables: {
        input: { ...formState.value, globalPlanTaskId },
      },
      refetchQueries: materialVariantId ? ["MaterialCostPage"] : ["TaskCatalogLaborCostsPage"],
      onCompleted: ({ saveBidItemEstimate }) => {
        triggerNotice({
          message: !bidEstimate
            ? `A new ${globalPlanTaskId ? "labor" : "material"} cost line was created!`
            : `${saveBidItemEstimate.bidItemEstimate.markets.map((m) => m.name).join(", ")} ${globalPlanTaskId ? "labor" : "material"} cost line was updated.`,
        });
      },
    });
    closeModal();
  }
  return (
    <>
      <ModalHeader>
        <span css={Css.lgSb.$}>{bidEstimate ? "Edit Cost" : "Add Cost"}</span>
      </ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap3.$}>
          <BoundMultiSelectField label="Markets" field={formState.marketIds} options={data?.markets ?? []} />
          <div css={Css.df.gap2.mrPx(80).$}>
            <BoundNumberField label="Cost" field={formState.costInMills} compact />
            <BoundSelectField
              label="Unit of Measure"
              field={formState.unitOfMeasureId}
              options={data?.unitsOfMeasure ?? []}
              compact
            />
          </div>
          <BoundTextField label="Source (Link)" field={formState.sourceUrl} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
        <SubmitButton form={formState} label={bidEstimate ? "Save" : "Add"} onClick={saveAndExit} />
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveBidItemEstimateInput> = {
  id: { type: "value" },
  costInMills: { type: "value", rules: [required] },
  materialVariantId: { type: "value" },
  sourceUrl: { type: "value" },
  unitOfMeasureId: { type: "value", rules: [required] },
  marketIds: { type: "value", rules: [required] },
};
