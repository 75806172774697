import { LotConfigStep } from "src/routes/developments/lot-summary/lot-release/enums";
import { PersonalDashboardRoutes } from "src/routes/personal-dashboard/enums";
import { stageCodeToNameMapper, StageUrlSlug } from "src/utils";
import { ReassignPurchaseOrdersStep } from "./developments/reassign-purchase-orders/enums";

export type ProjectParams = { projectId: string };
export type BidItemParams = { bidItemId: string };
export type ProductParams = { productId: string };
export type MaterialParams = { listingId: string; variantId: string };
export type StageParams = { stageSlug: StageUrlSlug };
export type ProjectStageParams = ProjectParams & StageParams;
export type IdOrAddParams = { idOrAdd: string };
export type DevelopmentCommitmentParams = { developmentCommitmentId: string };
export type ChangeEventParams = { changeEventId: string };
export type DevelopmentParams = { developmentId: string };
export type DevelopmentContractParams = { developmentId: string; bidContractRevisionId: string };
export type ItemTemplateItemParams = { itemTemplateId: string; itemTemplateItemId?: string };
export type ScheduleTemplateParams = { scheduleTemplateId: string };
export type LotConfigParams = DevelopmentParams & { projectId?: string; currentStep: LotConfigStep };
export type PlanDetailsParams = DevelopmentParams & { readyPlanId: string };
export type PlanPackageDetailsParams = { planPackageId: string; planPackageVersionId: string };
export type PlanPackageTakeoffParams = PlanPackageDetailsParams & { rpavId: string };
export type DesignCatalogParams = { designPackageId: string; designPackageVersionId: string };
export type ProductOfferingParams = DevelopmentParams & { productOfferingId: string; productOfferingVersionId: string };
export type ProductOfferingScopeParams = ProductOfferingParams & { rpavId: string };
export type ProductOfferingConfigParams = { productOfferingId: string; productOfferingConfigId: string };
export type BidPackageParams = DevelopmentParams & { bidPackageGroupId: string; bidPackageId: string };
export type ReassignPurchaseOrdersParams = DevelopmentParams & { currentStep: ReassignPurchaseOrdersStep };
export type LotConfigDetailParams = DevelopmentParams & {
  projectReadyPlanConfigId: string;
  // It receives the project id from the site condition page when should auto-open the project site condition super drawer
  projectIdFromSiteCondition: string;
};
export type WarrantyParams = { warrantyTicketId: string };
/** I.e. the `/libraries/design-packages/rp:2/detail/tli:3/tli:4. */
export type DesignPackageParams = { designPackageId: string; placeholderTliId: string; productTliId?: string };
export type LaborCostParams = { globalPlanTaskId: string };

export const idOrAdd = ":idOrAdd";
const stageParam = ":stageSlug(planning|pre-con|construction)";

export const invoicesPath = "/invoices";
export const billsPath = "/bills";
export const billDetailsPath = `/bills/:billId(b:\\d*)?`;
export const createBillPath = `/bills/${idOrAdd}(b:\\d*|c:\\d*|cco:\\d*|add)`;
export const purchaseOrdersPath = "/purchase-orders";
export const invoiceTermsPath = "/invoice-terms";
export const invoiceTermPath = `${invoiceTermsPath}/${idOrAdd}(ist:\\d*|add)`;
export const lienWaiversPath = "/lien-waivers";
export const tradePartnersPath = "/trade-partners";
const tradePartnerPath = `${tradePartnersPath}/${idOrAdd}(tp:\\d*|add)`;
export const paymentTermsPath = `${tradePartnersPath}/payment-terms`;
export const tradePartnerPaths = {
  tradePartner: tradePartnerPath,
  details: `${tradePartnerPath}/details`,
  contacts: `${tradePartnerPath}/contacts`,
  contact: `${tradePartnerPath}/contacts/:tpContactId(tpc:\\d*)`,
  reviews: `${tradePartnerPath}/reviews`,
  commitments: `${tradePartnerPath}/commitments`,
  developmentContracts: `${tradePartnerPath}/development-contracts`,
  requirements: `${tradePartnerPath}/requirements`,
};
export const tradeCategoryLeadTimesPath = `${tradePartnersPath}/tradeCategoryLeadTimes`;

export const tradesPath = "/trades";
export const tradesPurchaseOrdersPath = `${tradesPath}/purchase-orders`;
export const tradesTradePartnersPath = `${tradesPath}/trade-partners`;

export const bidPackagesPath = `${tradesPath}/bid-packages`;
export const bidPackagePath = `${bidPackagesPath}/${idOrAdd}(bp:\\d*|add)`;
export const createBidPackagePath = `${bidPackagesPath}/add`;
export const bidPackagesEditPath = `${bidPackagesPath}/edit/:bidPackageGroupId(bpg:\\d*)`;
export const bidPackageDetailsPath = `${bidPackagesPath}/:bidPackageGroupId(bpg:\\d*)/:bidPackageId(bp:\\d*)`;
export const bidPackagePublishPath = `${bidPackagesPath}/:bidPackageGroupId(bpg:\\d*)/:bidPackageId(bp:\\d*)/publish`;

export const documentsPath = "/documents";

export const expensesPath = "/expenses";

const adminPath = "/admin";
export const adminPaths = {
  admin: adminPath,
  projects: `${adminPath}/projects`,
  users: `${adminPath}/users`,
  items: `${adminPath}/items`,
  costCodes: `${adminPath}/costCodes`,
  featureFlags: `${adminPath}/feature-flags`,
  userGroups: `${adminPath}/user-groups`,
  locations: `${adminPath}/locations`,
};

export const projectsPath = "/projects";

const projectPath = `${projectsPath}/:projectId(p:\\d*)`;
export const developmentsPath = "/developments";
export const developmentPath = `${developmentsPath}/:developmentId(dev:\\d*)`;

export const developmentPaths = {
  development: developmentPath,
  legacyScheduleRoute: `${developmentPath}/schedule`,
  lotSchedules: `${developmentPath}/lot-schedules`,
  developmentSchedule: `${developmentPath}/development-schedule`,
  jobLogs: `${developmentPath}/job-logs`,
  jobLogsGallery: `${developmentPath}/job-logs/gallery`,
  jobLogNoteDrawer: `${developmentPath}/job-logs/:jobLogId/:jobLogNoteId`,
  detail: `${developmentPath}/detail`,
  documents: `${developmentPath}/documents`,
  team: `${developmentPath}/team`,
  drops: `${developmentPath}/drops`,
  allLots: `${developmentPath}/cohorts`,
  bidPackages: `${developmentPath}/bid-packages`,
  bidPackage: `${developmentPath}/bid-packages/:bidPackageId(bp:\\d*)`,
  contract: `${developmentPath}/procurement/:bidContractRevisionId(bcr:\\d*)`,
  contractLineItems: `${developmentPath}/procurement/:bidContractRevisionId(bcr:\\d*)/line-items`,
  unitPricing: `${developmentPath}/procurement/:bidContractRevisionId(bcr:\\d*)/unit-pricing`,
  contractOverview: `${developmentPath}/procurement/:bidContractRevisionId(bcr:\\d*)/overview`,
  contractPurchaseOrders: `${developmentPath}/procurement/:bidContractRevisionId(bcr:\\d*)/purchase-orders`,
  lotConfig: `${developmentPath}/lot-config`,
  lotSummary: `${developmentPath}/lot-summary`,
  lotRelease: `${developmentPath}/lot-release`,
  lotSequenceSheet: `${developmentPath}/lot-summary/sequence-sheet`,
  lotDetails: `${developmentPath}/lot-summary/sequence-sheet/:projectReadyPlanConfigId(prpc:\\d*)`,
  lotSiteConditionDetails: `${developmentPath}/lot-summary/sequence-sheet/:projectIdFromSiteCondition(p:\\d*)`,
  lotSummaryRelease: `${developmentPath}/lot-summary/release`,
  procurement: `${developmentPath}/procurement`,
  purchaseOrders: `${developmentPath}/purchase-orders`,
  reassignPurchaseOrders: `${developmentPath}/reassign-purchase-orders`,
  scopeTemplate: `${developmentPath}/templates/:itemTemplateId(it:\\d*)`,
  scopeTemplateItem: `${developmentPath}/templates/:itemTemplateId(it:\\d*)/:itemTemplateItemId(itiv:\\d*)`,
  scopeTemplateCostReport: `${developmentPath}/templates/cost/:itemTemplateId(it:\\d*)`,
  scopeTemplates: `${developmentPath}/templates`,
  reviewTemplates: `${developmentPath}/templates/review`,
  reviewTemplateCosts: `${developmentPath}/templates/costs`,
  planAndOptions: `${developmentPath}/plan-and-options`,
  productOfferings: `${developmentPath}/product-offerings`,
  productOffering: `${developmentPath}/product-offerings/:productOfferingId(rp:\\d*)/:productOfferingVersionId(rpav:\\d*|latest)?`,
  productOfferingApplyToProject: `${developmentPath}/product-offerings/:productOfferingId(rp:\\d*)/:productOfferingVersionId(rpav:\\d*)/apply`,
  productOfferingVersionHistory: `${developmentPath}/product-offerings/:productOfferingId(rp:\\d*)/history`,
  productOfferingSpecificConfigs: `${developmentPath}/product-offerings/:productOfferingId(rp:\\d*)/product-offering-configs`,
  productOfferingConfigs: `${developmentPath}/product-offering-configs`,
  productOfferingSpecificConfigsCompare: `${developmentPath}/product-offering-configs/:productOfferingId(rp:\\d*)/compare`,
  productOfferingConfigsCompare: `${developmentPath}/product-offering-configs/compare`,
  planDetails: `${developmentPath}/plan-and-options/:readyPlanId(rp:\\d*)`,
  planData: `${developmentPath}/plan-and-options/:readyPlanId(rp:\\d*)/planData`,
  optionData: `${developmentPath}/plan-and-options/:readyPlanId(rp:\\d*)/optionData`,
  activity: `${developmentPath}/plan-and-options/:readyPlanId(rp:\\d*)/activity`,
  editOptions: `${developmentPath}/plan-and-options/:readyPlanId(rp:\\d*)/edit-options`,
  taskLookaheadReport: `${developmentPath}/reports/task-lookahead`,
  settings: `${developmentPath}/settings`,

  featureFlagTest: `${developmentPath}/feature-flag-test`,

  changeLog: `${developmentPath}/change-log`,
  changeLogDetails: `${developmentPath}/change-log/:changeRequestId(changeRequest:\\d*)`,
};

export const costMappingPath = "/schedule-cost-mapping/:developmentId(dev:\\d*)";

/** Full route of the Development Cost Map wizard , currentStep */

export const librariesPath = `/libraries`;
export const globalOptionsPath = `${librariesPath}/global-options`;
export const materialsCatalogPath = `${librariesPath}/material-catalog`;
export const materialCreatePath = `${materialsCatalogPath}/add`;
export const materialDetailsPath = `${materialsCatalogPath}/:variantId(mv:\\d*)`;
export const materialCostPath = `${materialDetailsPath}/cost`;
export const installTasksPath = `${materialsCatalogPath}/install-tasks`;
export const productCatalogPath = `${librariesPath}/product-catalog`;
export const productCreatePath = `${productCatalogPath}/add`;
export const productDetailsPath = `${productCatalogPath}/:productId(pr:\\d*)`;
export const itemCatalogPath = `${librariesPath}/item-catalog`;
export const bidItemCreatePath = `${itemCatalogPath}/add`;
export const bidItemDetailsPath = `${itemCatalogPath}/:bidItemId(bi:\\d*)`;
export const templatesPath = `/templates`;
export const itemTemplatesPath = `${librariesPath}${templatesPath}/items`;
export const itemTemplatePath = `${itemTemplatesPath}/${idOrAdd}(it:\\d*|add)`;
export const itemTemplateItemPath = `${itemTemplatesPath}/:itemTemplateId(it:\\d*)/:itemTemplateItemId(itiv:\\d*)`;
export const scheduleTemplatesPath = `${librariesPath}${templatesPath}/schedule-templates`;
export const scheduleTemplatePath = {
  scheduleTemplate: `${scheduleTemplatesPath}/:scheduleTemplateId(st:\\d*)`,
};

export const catalogTasksPath = `${librariesPath}/task-catalog`;
export const catalogTaskPath = `${catalogTasksPath}/${idOrAdd}(gpt:\\d*|add)`;
export const constraintItemsCatalogPath = `${catalogTasksPath}/constraint-and-allowance-catalog`;
export const catalogTaskFolderPath = `${catalogTasksPath}/:folderId(globalPlanTag:\\d*|\\w*)`;
export const catalogTaskLaborCosts = `${catalogTasksPath}/:globalPlanTaskId(gpt:\\d*)/labor-costs`;

export const milestonesCatalogPath = `${librariesPath}/milestone-catalog`;
export const milestoneCatalogPath = `${milestonesCatalogPath}/${idOrAdd}(gpm:\\d*|add)`;

export const designCatalogsPath = `${librariesPath}/design-packages`;

// designCatalog inherits from ReadyPlan so its ID per joist-config is `rp:123` not the typical `dc:123`
export const designCatalogPath = `${designCatalogsPath}/:designPackageId(rp:\\d*)/:designPackageVersionId(rpav:\\d*|latest)?`;
export const designPackageNewSlotPath = `${designCatalogPath}/new`;
export const designPackageFinishSchedulePath = `${designCatalogPath}/finish-schedule`;
export const designPackageCompareModePath = `${designCatalogPath}/compare-mode`;
export const designPackageSlotDetailPath = `${designCatalogPath}/detail/:placeholderTliId(tli:\\d*)/:productTliId(tli:\\d*)?`;
export const designPackageProductSearchPath = `${designCatalogPath}/search/:placeholderTliId(tli:\\d*)/:productTliId(tli:\\d*)?`;
export const designPackageAddProductPath = `${designCatalogPath}/add-product/:placeholderTliId(tli:\\d*)?`;
export const designPackageVersionHistoryPath = `${designCatalogPath}/history`;

export const planPackagesPath = `${librariesPath}/plan`;
export const planPackagePath = `${planPackagesPath}/:planPackageId(rp:\\d*)/:planPackageVersionId(rpav:\\d*|latest)?`;
export const planPackageDetailsOverview = `${planPackagePath}/overview`;
export const planPackageDetailsOptionData = `${planPackagePath}/option-data`;
export const planPackageDetailsActivity = `${planPackagePath}/activity`;
export const planPackageEditPath = `${planPackagesPath}/edit/:planPackageId(rp:\\d*)?/:planPackageVersionId(rpav:\\d*|latest)?`;
export const planPackageTakeoffsPath = `${planPackagePath}/takeoffs/:rpavId(rpav:\\d*)`;
export const planPackageVersionHistoryPath = `${planPackagePath}/history`;

export const globalProductOfferingsPath = `${librariesPath}/product-offerings`;
export const productOfferingCreatePath = `${globalProductOfferingsPath}/add`;
export const productOfferingPath = `${globalProductOfferingsPath}/${idOrAdd}(rp:\\d*|add)`;
export const productOfferingEditPath = `${globalProductOfferingsPath}/edit/:productOfferingId(rp:\\d*)`;
export const productOfferingScopePath = `${globalProductOfferingsPath}/:productOfferingId(rp:\\d*)/scope/:rpavId(rpav:\\d*)`;

export const globalProductOfferingConfigsPath = `${librariesPath}/product-offering-configs`;
export const productOfferingConfigCreatePath = `${globalProductOfferingConfigsPath}/add`;
export const productOfferingConfigPath = `${globalProductOfferingConfigsPath}/:productOfferingId(rp:\\d*)/${idOrAdd}(poc:\\d*|add)`;
export const productOfferingConfigEditPath = `${globalProductOfferingConfigsPath}/:productOfferingId(rp:\\d*)/edit/:productOfferingConfigId(poc:\\d*)`;
export const productOfferingConfigsComparePath = `${globalProductOfferingConfigsPath}/compare`;

export const projectItemScopeOfWorkPath = `/pdf/projectItem`;
export const tradeScopeOfWorkPath = `/pdf/changeEventLineItem`;
export const scheduleOfValuesPath = `/pdf/schedule-of-values`;
export const homeownerChangeOrderPath = `/pdf/homeowner-change-order`;
export const estimateExportPath = `/pdf/estimate`;
export const scheduleExportPath = `/pdf/schedule/:scheduleId(schedule:\\d*)`;
export const tradeSpecificAgreementPath = `/pdf/trade-specific-agreement`;
export const developmentCommitmentPdfPath = `/pdf/development-commitment`;
export const commitmentPdfPath = `/pdf/commitment`;
export const invoicePandaDocPdfPath = `/pdf/invoicePandaDoc`;
export const invoicePdfPath = `/pdf/invoice`;
export const lienWaiverPdfPath = `/pdf/lien-waivers`;
export const toDoPdfPath = `/pdf/to-dos`;
export const commitmentChangeOrderPdfPath = `/pdf/commitment-change-order`;
export const developmentContractPdfPath = `/pdf/development-contract`;
export const bidCommitmentPdfPath = `/pdf/bid-commitment`;
export const bidCommitmentChangeOrderPdfPath = `/pdf/bid-commitment-change-order`;
export const lotSequenceSheetPdfPath = `/pdf/lot-sequence-sheet`;
export const lotSummaryDetailsPdfPath = `/pdf/lot-summary-details`;
export const billPdfPath = `/pdf/bill`;
export const finishSchedulePdfPath = `/pdf/finish-schedule`;
export const finishScheduleVisualModePdfPath = `/pdf/finish-schedule-visual-mode`;
export const projectFinishScheduleVisualModePdfPath = `/pdf/project-finish-schedule-visual-mode`;
export const projectFinishSchedulePdfPath = `/pdf/project-finish-schedule`;
export const tradesPunchlistPdfPath = `/pdf/trades-punchlist`;

export const developmentCommitmentsPath = "/development-commitments";
export const developmentCommitmentsPaths = {
  developmentCommitments: developmentCommitmentsPath,
  developmentCommitment: `${developmentCommitmentsPath}/:developmentCommitmentId(dc:\\d*)`,
};

export const personalDashboardPaths: Record<string, string> = {
  base: "/personal-dashboard",
  dashboard: "/personal-dashboard/:section?",
  actions: `/personal-dashboard/${PersonalDashboardRoutes.ACTIONS}`,
  upcoming: `/personal-dashboard/${PersonalDashboardRoutes.UPCOMING}`,
  toDos: `/personal-dashboard/${PersonalDashboardRoutes.TO_DOS}`,
  toDoModal: `/personal-dashboard/${PersonalDashboardRoutes.TO_DOS}/:toDoId`,
  approvals: `/personal-dashboard/${PersonalDashboardRoutes.APPROVALS}`,
  activity: `/personal-dashboard/${PersonalDashboardRoutes.ACTIVITY}`,
};

export const inboxPaths: Record<string, string> = {
  base: "/inbox",
};

export const developmentLotConfigProjectPath = `${developmentPaths.lotRelease}/:projectId(p:\\d*)`;
export const developmentLotConfigPaths: Record<LotConfigStep, string> = {
  [LotConfigStep.CONFIRM]: `${developmentLotConfigProjectPath}/${LotConfigStep.CONFIRM}`,
  [LotConfigStep.REVIEW]: `${developmentLotConfigProjectPath}/${LotConfigStep.REVIEW}`,
};

export const reassignPurchaseOrdersPath = `${developmentPaths.reassignPurchaseOrders}/:currentStep`;
export const reassignPurchaseOrdersPaths: Record<ReassignPurchaseOrdersStep, string> = {
  [ReassignPurchaseOrdersStep.SELECT]: `${developmentPaths.reassignPurchaseOrders}/${ReassignPurchaseOrdersStep.SELECT}`,
  [ReassignPurchaseOrdersStep.RELEASE]: `${developmentPaths.reassignPurchaseOrders}/${ReassignPurchaseOrdersStep.RELEASE}`,
};

export const projectPaths = {
  project: projectPath,
  dashboard: `${projectPath}/dashboard`,
  team: `${projectPath}/team`,
  settings: `${projectPath}/settings`,
  items: `${projectPath}/items`,
  featureFlagTest: `${projectPath}/feature-flag-test`,
  bill: `${projectPath}/bills/:billId(b:\\d*)?`,

  budgetBase: `${projectPath}/budget`,
  budget: `${projectPath}/budget/${stageParam}`,
  budgetReallocation: `${projectPath}/budget-reallocations/${stageParam}/${idOrAdd}(br:\\d*|add)`,

  scheduleBase: `${projectPath}/schedule`,
  schedule: `${projectPath}/schedule/${stageParam}`,
  dynamicSchedules: `${projectPath}/dynamicSchedule`,

  contracts: `${projectPath}/homeowner-contracts`,
  contract: `${projectPath}/homeowner-contracts/:contractId(hc:\\d*)`,
  contractChangeOrder: `${projectPath}/homeowner-contracts/change-order/:changeOrderId(hcco:\\d*)?`,
  contractChangeOrderDraws: `${projectPath}/homeowner-contracts/change-order/:changeOrderId(hcco:\\d*)\\??tab=draws`,
  contractDraws: `${projectPath}/homeowner-contracts/:contractId(hc:\\d*)\\?tab=draws`,
  contractInvoiceSchedule: `${projectPath}/homeowner-contracts/:contractId(hc:\\d*)\\?tab=invoice`,

  commitments: `${projectPath}/commitments`,
  commitment: `${projectPath}/commitments/${idOrAdd}(c:\\d*|add)`,
  commitmentChangeOrder: `${projectPath}/commitments/:commitmentId/change-order/${idOrAdd}(cco:\\d*|add)`,

  homeownerNotes: `${projectPath}/homeowner-notes`,

  invoices: `${projectPath}/invoices`,
  invoice: `${projectPath}/invoices/${idOrAdd}(inv:\\d*|add)`,
  invoiceOld: `${projectPath}/invoices/old/${idOrAdd}(inv:\\d*|add)`,

  estimates: `${projectPath}/estimates`,
  estimate: `${projectPath}/estimates/:estimateId(e:\\d*)`,

  expenses: `${projectPath}/expenses`,

  finishSchedule: `${projectPath}/finish-schedule`,
  /*
    Individual redirect paths from:
    - /selections/:stage
    - /selections/:stage/:projectItemId
    to:
    - /pre-con-services
    - /pre-con-services/:projectItemId
    - /specs-and-selection
    - /specs-and-selection/:projectItemId

    @deprecated use either preConServices or specsAndSelections paths
  */
  selectionsBase: `${projectPath}/selections`,
  selectionsPreCon: `${projectPath}/selections/${stageCodeToNameMapper.PRE_CON_EXECUTION}`,
  selectionsPreConDetails: `${projectPath}/selections/${stageCodeToNameMapper.PRE_CON_EXECUTION}/:projectItemId(pi:\\d*)`,
  selectionsConstruction: `${projectPath}/selections/${stageCodeToNameMapper.CONSTRUCTION}`,
  selectionsConstructionDetails: `${projectPath}/selections/${stageCodeToNameMapper.CONSTRUCTION}/:projectItemId(pi:\\d*)`,

  preConServices: `${projectPath}/pre-con-services`,
  preConServicesDetail: `${projectPath}/pre-con-services/:projectItemId(pi:\\d*)`,

  lotSummary: `${projectPath}/lot-summary`,

  specsAndSelections: `${projectPath}/specs-and-selections`,
  specsAndSelectionsDetail: `${projectPath}/specs-and-selections/:projectItemId(pi:\\d*)`,

  toDos: `${projectPath}/to-dos`,
  toDoModal: `${projectPath}/to-dos/:toDoId`,

  jobLogs: `${projectPath}/job-logs`,
  jobLogsGallery: `${projectPath}/job-logs/gallery`,
  jobLogNoteDrawer: `${projectPath}/job-logs/:jobLogId/:jobLogNoteId`,

  documents: `${projectPath}/documents`,

  changeEvents: `${projectPath}/change-events`,
  changeEvent: `${projectPath}/change-events/:changeEventId(ce:\\d*)`,
  changeEventLineItems: `${projectPath}/change-events/:changeEventId(ce:\\d*)/line-items`,
  changeEventOverview: `${projectPath}/change-events/:changeEventId(ce:\\d*)/overview`,
  changeEventEstimates: `${projectPath}/change-events/:changeEventId(ce:\\d*)/estimates`,
  changeEventCommitments: `${projectPath}/change-events/:changeEventId(ce:\\d*)/commitments`,
  changeEventHistory: `${projectPath}/change-events/:changeEventId(ce:\\d*)/history`,
  changeEventLineItem: `${projectPath}/change-events/:changeEventId(ce:\\d*)/line-items/:celiId(celi:\\d*)`,
  changeEventLineItemHistory: `${projectPath}/change-events/:changeEventId(ce:\\d*)/line-items/:celiId(celi:\\d*)/history`,
  changeEventLineItemSelection: `${projectPath}/change-events/:changeEventId(ce:\\d*)/line-items/:celiId(celi:\\d*)/selection`,
  changeLog: `${projectPath}/change-log`,
  changeLogDetails: `${projectPath}/change-log/:changeRequestId(changeRequest:\\d*)`,
};

const changeLogPath = "/change-log";
export const changeLogPaths = {
  base: changeLogPath,
  details: `${changeLogPath}/:changeRequestId(changeRequest:\\d*)`,
  changeTypes: `${changeLogPath}/change-types`,
  businessFunctions: `${changeLogPath}/functions`,
  businessFunctionDetails: `${changeLogPath}/functions/:businessFunctionId(bf:\\d*)`,
};

// Using this to filter out projects paths in the `suggestablePaths` array below rather than listing out all suggestable project paths.
const nonSuggestableProjectPaths = [projectPaths.items];

export const suggestablePaths = [
  billsPath,
  developmentCommitmentsPath,
  documentsPath,
  invoicesPath,
  expensesPath,
  personalDashboardPaths.dashboard,
  personalDashboardPaths.toDos,
  projectsPath,
  ...Object.values(projectPaths).reduce(
    (acc, path) => (nonSuggestableProjectPaths.includes(path) ? acc : acc.concat(path)),
    [] as string[],
  ),
  itemTemplatesPath,
  itemTemplatePath,
  tradePartnersPath,
];

const warrantyPath = "/warranty";
export const warrantyPaths = {
  base: warrantyPath,
  details: `${warrantyPath}/:warrantyTicketId(wt:\\d*)`,
};

export const dynamicSchedulesPath = {
  // Draft routes using shared draft schedule store
  draftMode: `${projectPaths.dynamicSchedules}/draft`,
  draftNewTask: `${projectPaths.dynamicSchedules}/draft/new`,
  draftPublish: `${projectPaths.dynamicSchedules}/draft/publish`,
  draftCalendar: `${projectPaths.dynamicSchedules}/draft/calendar`,
  draftGantt: `${projectPaths.dynamicSchedules}/draft/gantt`,
  draftLookahead: `${projectPaths.dynamicSchedules}/draft/lookahead`,
  draftMilestone: `${projectPaths.dynamicSchedules}/draft/milestones`,
  // Detail routes that doo not rely on store
  details: `${projectPaths.dynamicSchedules}/:planTaskId(t:\\d*)`,
  materials: `${projectPaths.dynamicSchedules}/:planTaskId(t:\\d*)/materials`,
  materialsDetails: `${projectPaths.dynamicSchedules}/:planTaskId(t:\\d*)/materials/:variantId(mv:\\d*)`,
  milestoneDetails: `${projectPaths.dynamicSchedules}/milestones/:planMilestoneId(pm:\\d*)`,
};

export const financesPath = {
  bill: `${billsPath}/:billId(b:\\d*)?`,
};
