import { Css } from "@homebound/beam";
import { Section } from "./Section";

export function BidCommitmentTerms() {
  return (
    <Section title="Terms and Conditions">
      <div css={Css.mb2.$}>
        <ol>
          <li>
            INCORPORATION. This Purchase Order (“PO”) is issued pursuant to and hereby made a part of the Master Service
            Agreement (“Agreement”), including the Trade Partner Price Agreement between Homebound Technologies, Inc
            (“Homebound”) and entity or individual (“Trade Partner”). This PO incorporates herein by reference all the
            definitions, terms, and conditions of the Agreement (including the terms and conditions and pricing schedule
            as set forth in the Trade Partner Price Agreement) as though set forth in full herein.
          </li>
          <li>
            CONTRACT DOCUMENTS. The Contract Documents for this PO are enumerated below and incorporated herein by
            reference.
            <ol type="a">
              <li>This PO;</li>
              <li>The Agreement, including all amendments thereto;</li>
              <li>The Project Specific Agreement ("PSA") if applicable</li>
              <li>The Trade Partner Price Agreement;</li>
              <li>Any applicable Plans and Specifications as listed within this PO</li>
            </ol>
          </li>
          <li>
            PAYMENT. Trade Partner must reference this Purchase Order number on its application for payment and submit
            application for payment in the means and manner requested by Homebound. No payment made hereunder shall be
            considered an acceptance of the Work in whole or in part, or a waiver of any rights or remedies to which
            Homebound is entitled.
          </li>
        </ol>
      </div>
    </Section>
  );
}
