import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  BoundToggleChipGroupField,
  Button,
  Css,
  FieldGroup,
  FormDivider,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { reaction } from "mobx";
import { Observer } from "mobx-react";
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  ContactsTabTradePartnerContactFragment,
  SaveTradePartnerContactInput,
  TradeConfirmPublishStepDocument,
  TradePartnerContactsDocument,
  useDeleteTradePartnerContactMutation,
  useSaveTradePartnerContactMutation,
} from "src/generated/graphql-types";
import { USStates as states } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { HasIdAndName } from "src/utils/types";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { emailFormatRule } from "./utils";

export type TradePartnerContactModalProps = {
  tradePartnerId: string;
  contact: ContactsTabTradePartnerContactFragment | undefined;
  markets: HasIdAndName[];
  onTradeConfirmPublishStep?: boolean;
};

export function TradePartnerContactModal(props: TradePartnerContactModalProps) {
  const { contact, markets, tradePartnerId, onTradeConfirmPublishStep } = props;
  const { closeModal } = useModal();
  const [saveContact] = useSaveTradePartnerContactMutation({
    refetchQueries: [onTradeConfirmPublishStep ? TradeConfirmPublishStepDocument : TradePartnerContactsDocument],
  });
  const tid = useTestIds(props);
  const { triggerNotice } = useSnackbar();

  const form = useFormState({
    config: formConfig,
    // if !contact then this is the "Create" form so enable. Otherwise, disable if there -is- a contact but it's deactivated
    readOnly: contact && !contact?.isActive,
    init: {
      input: contact,
      map: (contact) => ({
        ...contact,
        marketIds: onTradeConfirmPublishStep ? markets.map((m) => m.id) : contact.markets.map((m) => m.id),
      }),
    },
  });

  const addressFields = useMemo(
    () => [form.address.street1, form.address.city, form.address.state, form.address.postalCode, form.address.country],
    [form],
  );

  // Conditionally add/remove `required` to the address fields. Using `reaction` instead of `autorun`
  // so that we can be very explicit about only re-running when the billing signatory changes.
  useMemo(() => {
    return reaction(
      () => form.maybeBillingSignatory.value,
      () => {
        const maybeBillingSignatory = form.maybeBillingSignatory.value;
        // We can support non-signatory addresses if we want
        // const anyAddressFieldSet = addressFields.some((f) => f.value !== undefined && f.value !== null);
        addressFields.forEach((f) => {
          if (maybeBillingSignatory && !f.required) {
            f.rules.push(required);
          } else if (!maybeBillingSignatory && f.required) {
            f.rules.splice(0, 1);
            f.set(undefined);
          }
        });
        if (maybeBillingSignatory) {
          form.address.country.set("US");
        }
      },
      { fireImmediately: true },
    );
  }, [form, addressFields]);

  // automatically select markets when a user opens this modal from the trade confirm step
  useEffect(() => {
    const maybeMarketId = form.marketIds.value;
    if (!maybeMarketId && onTradeConfirmPublishStep) {
      form.marketIds.set(markets.map((m) => m.id));
    }
  }, [form, onTradeConfirmPublishStep, markets]);

  // Because we conditionally show the address fields, but still want a flat list
  // of fields past to FormLines, we combine them like this.
  const formLines = useComputed(() => {
    const contactLines = [
      <FieldGroup>
        <BoundTextField field={form.name} />
        <BoundTextField field={form.title} />
      </FieldGroup>,
      <BoundTextField field={form.email} />,
      <FieldGroup>
        <BoundTextField field={form.officePhone} label="Office Phone" />
        <BoundTextField field={form.mobilePhone} label="Mobile Phone" />
      </FieldGroup>,
      <BoundToggleChipGroupField
        field={form.marketIds}
        onChange={(values) => form.marketIds.set(values)}
        label="Markets"
        options={markets.map((m) => ({ label: m.name, value: m.id }))}
      />,
      <>
        <div css={Css.baseSb.mt3.$}>Roles</div>
        <div css={Css.xs.gray900.mt1.mb2.$}>
          Turn on user role abilities below. Assign roles for each market in the “Role by Market” section.
        </div>
        <BoundSwitchField field={form.maybeBillingSignatory} label="Contact and PO Signatory" labelStyle="filter" />
      </>,
    ];

    const addressLines = [
      <FormDivider />,
      <Copy>For billing signatories, please enter their business address.</Copy>,
      <BoundTextField field={form.address.street1} label="Street" />,
      ...(form.address.country.value === "US"
        ? [
            <FieldGroup widths={[1, 1]}>
              <BoundTextField field={form.address.city} />
              <BoundSelectField
                field={form.address.state}
                options={states}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.name}
              />
            </FieldGroup>,
          ]
        : [
            <FieldGroup widths={[1, 1]}>
              <BoundTextField field={form.address.city} />
              <BoundTextField field={form.address.state} />
            </FieldGroup>,
          ]),
      <FieldGroup widths={[1, 1]}>
        <BoundTextField field={form.address.postalCode} />
        <BoundSelectField
          field={form.address.country}
          options={countries}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.name}
        />
      </FieldGroup>,
    ];
    return [...contactLines, ...(form.maybeBillingSignatory.value === true ? addressLines : [])];
  }, [form]);

  // - how do we want to do address?
  return (
    <Fragment>
      <ModalHeader>{contact ? "Edit Contact" : "Create Contact"}</ModalHeader>
      <ModalBody>
        <FormLines width="lg" labelSuffix={{ required: "*" }}>
          {formLines.map((line, i) => (
            <Fragment key={i}>{line}</Fragment>
          ))}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <ContactDeleteOrDeactivateButton contact={contact} />
        <Observer>
          {() => (
            <Button
              label="Save"
              disabled={!form.valid}
              onClick={async () => {
                if (form.canSave()) {
                  await saveContact({
                    variables: {
                      input: {
                        ...form.changedValue,
                        tradePartnerId,
                        marketIds: onTradeConfirmPublishStep ? markets.map((m) => m.id) : form.marketIds.value,
                      },
                    },
                    onCompleted: ({ saveTradePartnerContact }) =>
                      triggerNotice({
                        message: `${saveTradePartnerContact.tradePartnerContact.name} has been added to the Trade Index.`,
                      }),
                  });
                }
                closeModal();
              }}
              {...tid.save}
            />
          )}
        </Observer>
      </ModalFooter>
    </Fragment>
  );
}

type FormValue = SaveTradePartnerContactInput;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  email: { type: "value", rules: [required, emailFormatRule] },
  title: { type: "value" },
  officePhone: { type: "value" },
  mobilePhone: { type: "value" },
  maybeBillingSignatory: { type: "value" },
  marketIds: { type: "value" },
  address: {
    type: "object",
    config: {
      street1: { type: "value" },
      city: { type: "value" },
      state: { type: "value" },
      postalCode: { type: "value" },
      country: { type: "value" },
    },
  },
};

const countries = [{ name: "US" }, { name: "Bahamas" }];

function Copy(props: { children: string | ReactNode }) {
  return (
    <div
      css={{
        ...Css.sm.gray700.mt2.mb3.wPx(480).$,
        "& > p": Css.my2.$,
      }}
    >
      {props.children}
    </div>
  );
}

function ContactDeleteOrDeactivateButton({ contact }: { contact: ContactsTabTradePartnerContactFragment | undefined }) {
  const { openModal } = useModal();
  const [deleteTpcMutation] = useDeleteTradePartnerContactMutation({ variables: { input: { id: contact?.id ?? "" } } });
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();

  const onClick = useCallback(async () => {
    await deleteTpcMutation();
    triggerNotice({
      icon: "success",
      message: `Trade partner contact ${contact?.canDelete.allowed ? "deleted" : "deactivated"} successfully`,
    });
    closeModal();
  }, [closeModal, contact?.canDelete.allowed, deleteTpcMutation, triggerNotice]);

  if (!contact) return null;
  if (contact.canDelete.allowed)
    return (
      <Button
        label="Delete Contact"
        variant="danger"
        onClick={() =>
          openModal({
            content: (
              <ConfirmationModal
                title={`Delete contact ${contact?.name}`}
                confirmationMessage="This will delete the trade partner contact and cannot be undone"
                label="Delete"
                danger
                onConfirmAction={onClick}
              />
            ),
          })
        }
      />
    );
  // else assume Deactivate workflow
  return (
    <Button
      label="Deactivate Contact"
      variant="danger"
      disabled={
        !contact.canDeactivate.allowed ? contact.canDeactivate.disabledReasons.map((r) => r.message).join() : undefined
      }
      onClick={onClick}
    />
  );
}
