import {
  cardStyle,
  Chips,
  column,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  multiFilter,
  MultiSelectField,
  RowStyles,
  selectColumn,
  simpleHeader,
  singleFilter,
  Tag,
  useComputed,
  useFilter,
  useSnackbar,
} from "@homebound/beam";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { SearchBox, useNavigationCheck } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { useGlobalOptionTagsFilter } from "src/components/autoPopulateFilters/useGlobalOptionTagsFilter";
import { useGlobalOptionTypes } from "src/components/autoPopulateSelects/useGlobalOptionTypes";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  ChooseOptions_ReadyPlanOptionFragment,
  ChooseOptionsStepQuery,
  GlobalOptionsFilter,
  GlobalOptionStatus,
  GlobalOptionTypeStatus,
  LocationsQuery,
  LocationsSelectField_LocationFragment,
  LocationType,
  NamedFragment,
  useChooseOptionsStepQuery,
  useLocationsQuery,
  useSavePlanPackageDetailsMutation,
  useSaveReadyPlanChosenOptionsMutation,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanPackageNextStepButton } from "src/routes/libraries/plan-package/stepper/components/PlanPackageNextStepButton";
import { queryResult } from "src/utils";
import { disableBasedOnPotentialOperation } from "../PotentialOperationsUtils";
import { ChooseOptionsRow, GlobalOptionRow } from "./utils";

type ChooseOptionsStepBodyProps = {
  planId: string;
  versionId?: string;
  returnUrl?: string;
  /** Function to set the step as dirty or not dirty */
  setStepDirty: (dirty: boolean) => void;
  /** If true, elevation options will be included in the list of options to choose from.
   * This property is only necessary for the ReadyPlan workflow*/
  withElevations?: boolean;
  tableApi: GridTableApi<ChooseOptionsRow>;
  useV2Locations?: boolean;
};

export function ChooseOptionsStepBody(props: ChooseOptionsStepBodyProps) {
  const { planId, versionId, setStepDirty, withElevations = false, returnUrl, tableApi, useV2Locations } = props;
  const { triggerNotice } = useSnackbar();

  // Get the global option types to use in our filter
  const gots = useGlobalOptionTypes();
  // This sublist of option types is used to limit the filter options, and the global options to query for when no filter is set
  const optionsTypes = useMemo(
    () =>
      gots?.filter((got) => {
        const initialFilter =
          got.globalOptionsCount > 0 && got.status !== GlobalOptionTypeStatus.Archived && !got.isPlanPackage;
        // Optionally exclude Elevation options
        return withElevations ? initialFilter : initialFilter && !got.isElevation;
      }) ?? [],
    [gots, withElevations],
  );
  const { data: locationsData } = useLocationsQuery({
    fetchPolicy: "cache-and-network",
    ...(useV2Locations
      ? {
          variables: {
            filter: {
              version: [2],
              type: [LocationType.Group, LocationType.Level, LocationType.Room, LocationType.Feature],
            },
          },
        }
      : {}),
  });
  const tagsFilter = useGlobalOptionTagsFilter();

  // Build the filter
  const filterDefs: FilterDefs<ChooseOptionsFilter> = useMemo(
    () => ({
      type: multiFilter({
        label: "Type",
        options: optionsTypes,
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
      }),
      locations: multiFilter({
        label: "Location",
        options: locationsData?.locations ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
      }),
      tags: tagsFilter,
      sortBy: singleFilter({
        label: "Sort By",
        options: [
          { name: "Recently Edited", value: "updatedAt" as const },
          { name: "Recently Created", value: "createdAt" as const },
          { name: "Option Code", value: "code" as const },
        ],
        nothingSelectedText: "Recently Used First",
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ value }) => value,
      }),
    }),
    [optionsTypes, locationsData, tagsFilter],
  );
  const { filter, setFilter } = useFilter({ filterDefs });

  const query = useChooseOptionsStepQuery({
    variables: {
      readyPlanId: planId,
      readyPlanVersionId: versionId,
      filter: {
        ...mapToFilter(filter, optionsTypes),
        status: [GlobalOptionStatus.Active],
      },
    },
  });
  const [savePP] = useSavePlanPackageDetailsMutation();
  const [saveRpos] = useSaveReadyPlanChosenOptionsMutation();
  const disabled = disableBasedOnPotentialOperation(query.data?.readyPlanLike2.canEdit);

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("globalOption"), [tableApi]);

  // get from query, but also set locally
  const [rows, setRows] = useState<GridDataRow<ChooseOptionsRow>[]>([]);
  useEffect(() => {
    if (query.data) setRows(createRows(query.data, filter.sortBy ?? "lastUsedAt"));
  }, [query.data, filter.sortBy]);

  const existingRposByKey = useMemo(
    () =>
      new Map<string, ChooseOptions_ReadyPlanOptionFragment>(
        query.data?.readyPlanLike2.options.map((rpo) => [keyRpo(rpo), rpo]),
      ),
    [query.data],
  );

  const mapSelectionToSaveRpoInputs = useCallback(() => {
    const desiredRposByKey = new Map<string, { globalOptionId: string; locationId: string | undefined }>(
      selectedRows.flatMap((row) =>
        row.data.locations.map((loc) => [keyRpo(row.data.id, loc), { globalOptionId: row.data.id, locationId: loc }]),
      ),
    );

    // create RPOs that are not in the existing RPOs
    const toCreate = [...desiredRposByKey.entries()]
      .filter(([key]) => !existingRposByKey.has(key))
      .map(([_, value]) => ({ readyPlanId: planId, ...value }));

    // enable/disable RPOs that are in the existing RPOs but not in the desired RPOs
    const toEnableDisable =
      [...existingRposByKey.values()]
        // we first filter only for the relevant option types, to prevent disabling options not displayed on this step
        .filter((rpo) => optionsTypes.some((goType) => goType.id === rpo.type.id))
        // then we filter for the ones where there desired status does not match there current active state
        .filter((rpo) => desiredRposByKey.has(keyRpo(rpo)) !== rpo.active)
        // and toggle active for the existing options
        .map((rpo) => ({ id: rpo.id, active: !rpo.active })) ?? [];

    return [...toCreate, ...toEnableDisable];
  }, [existingRposByKey, optionsTypes, planId, selectedRows]);

  const onSave = useCallback(async () => {
    const saveRpoInputs = mapSelectionToSaveRpoInputs();
    if (saveRpoInputs.nonEmpty) {
      if (versionId) {
        const response = await savePP({ variables: { input: { id: planId, options: saveRpoInputs } } });
        return response;
      } else {
        await saveRpos({ variables: { input: { readyPlanOptions: saveRpoInputs } } });
      }
      triggerNotice({ message: `Your Options have been updated!` });
    }
  }, [mapSelectionToSaveRpoInputs, planId, savePP, saveRpos, triggerNotice, versionId]);

  const onClickSave = useCallback(async () => {
    await onSave();
  }, [onSave]);

  const saveRpoInputs = mapSelectionToSaveRpoInputs();
  const changeCount = saveRpoInputs.length;
  useEffect(() => {
    setStepDirty(changeCount > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount]);

  // if there are no active locations when user selects a row
  // activate the 'None' location
  useEffect(() => {
    selectedRows.forEach((row) => {
      if (row.data.locations.isEmpty) {
        row.data.locations.push(undefined);
        setRows([...rows]);
      }
    });
  }, [selectedRows, rows]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => changeCount > 0, [changeCount]);

  return (
    <>
      {queryResult(query, () => (
        <div css={Css.pt3.pb0.bgGray100.df.fdc.fg1.$}>
          <TableActions>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
            </div>
            <SearchBox onSearch={setSearchFilter} clearable updateQueryString={false} />
          </TableActions>

          <div css={Css.fg1.pb1.$}>
            <GridTable
              api={tableApi}
              rows={rows}
              columns={createColumns(locationsData, rows, setRows, tableApi, existingRposByKey, disabled)}
              filter={searchFilter}
              style={tableStyles}
              rowStyles={rowStyles}
              as="virtual"
            />
          </div>
        </div>
      ))}
      {versionId ? (
        <PlanPackageNextStepButton planId={planId} versionId={versionId} onSave={onSave} />
      ) : (
        <NextStepButton
          label="Continue"
          onClick={onClickSave}
          onCloseReturnUrl={returnUrl}
          exitButton={{ variant: "secondary", label: "Save & Exit", onClick: onClickSave }}
        />
      )}
    </>
  );
}

type ChooseOptionsFilter = GlobalOptionsFilter & {
  sortBy: undefined | "lastUsedAt" | "createdAt" | "updatedAt" | "code";
};

function createColumns(
  locationsData: LocationsQuery | undefined,
  rows: GridDataRow<ChooseOptionsRow>[],
  setRows: React.Dispatch<React.SetStateAction<GridDataRow<ChooseOptionsRow>[]>>,
  api: GridTableApi<ChooseOptionsRow>,
  existingRposByKey: Map<string, ChooseOptions_ReadyPlanOptionFragment>,
  disabled?: ReactNode,
): GridColumn<ChooseOptionsRow>[] {
  return [
    ...(disabled
      ? [
          column<ChooseOptionsRow>({
            header: "",
            globalOption: ({ id, locations }) => ({
              content: (
                <input
                  type="checkbox"
                  disabled
                  checked={locations.some(
                    (loc) =>
                      !existingRposByKey.has(keyRpo(id, loc)) ||
                      existingRposByKey.get(keyRpo(id, loc))?.active !== false,
                  )}
                />
              ),
              value: id,
            }),
            w: "45px",
          }),
        ]
      : [selectColumn<ChooseOptionsRow>()]),
    column<ChooseOptionsRow>({
      header: "Option Code",
      globalOption: ({ code }) => ({ content: () => code, css: Css.xs.$ }),
      w: "150px",
    }),
    column<ChooseOptionsRow>({
      header: "Name",
      globalOption: ({ name }) => ({ content: name, css: Css.sm.$ }),
      w: 3,
    }),
    column<ChooseOptionsRow>({
      header: "Type",
      globalOption: ({ type }) => ({ content: <Tag text={type.name} />, css: Css.sm.$, value: type.name }),
    }),
    column<ChooseOptionsRow>({
      header: "Location",
      globalOption: (data, opts) => (
        <MultiSelectField<LocationsSelectField_LocationFragment, string | undefined>
          disabled={
            disabled || (!data.type.allowsLocations && `Options of type ${data.type.name} do not allow locations`)
          }
          compact
          label="Locations"
          options={[{ name: "None", id: undefined } as any, ...(locationsData?.locations ?? [])]}
          values={data.locations}
          getOptionMenuLabel={(loc) => {
            if (!loc.id) return loc.name;

            return (
              <ArchivedTag
                active={
                  !existingRposByKey.has(keyRpo(data.id, loc.id)) ||
                  existingRposByKey.get(keyRpo(data.id, loc.id))?.active !== false
                }
              >
                <span>
                  <Tag xss={Css.m0.$} type={"info"} text={loc.type.name} /> {loc.name}{" "}
                  {loc.name !== loc.displayLocationPath && <span css={Css.gray500.$}>{loc.displayLocationPath}</span>}
                </span>
              </ArchivedTag>
            );
          }} // add archived tag if rpo exists for this location but is inactive
          getOptionValue={(loc) => loc.id}
          onSelect={(selectedLocations) => {
            // set locations in the row
            const locations = selectedLocations.map((id) => id);

            // get parent and child that matches this row
            // so we can set data in the rows state
            const rowIndex = rows.findIndex((r) => r.id === opts.row.id);
            if (rowIndex !== undefined && rows[rowIndex]) {
              // need to keep existing, but archived, locations
              rows[rowIndex].data = { ...data, locations };
              setRows([...rows]);

              // set selected if there are locations selected (including "None" option)
              // deselected if zero are selected
              api.selectRow(opts.row.id, selectedLocations.length > 0);
            }
          }}
        />
      ),
    }),
    column<ChooseOptionsRow>({
      header: "Tag",
      globalOption: (data) => ({
        content: () => (data.tags.length ? <Chips values={data.tags.map((t) => t.name)} wrap compact /> : undefined),
        value: data.tags.map((t) => `${t.id} ${t.name}`).join(" "),
      }),
    }),
  ];
}

/** Create rows of "GO + Location" pairs. */
function createRows(
  data: ChooseOptionsStepQuery,
  sortBy: "lastUsedAt" | "createdAt" | "updatedAt" | "code",
): GridDataRow<ChooseOptionsRow>[] {
  // Create a set of the ReadyPlan's already-selected
  // In theory we could `keyBy` instead of `groupBy`, but there is legacy RPOs that are duplicated
  const existingRpos = data.readyPlanLike2.options.groupBy((rpo) => rpo.globalOption.id) ?? {};
  // Group by option type
  const [initiallySelected, others] = data.globalOptions
    .map(
      (go) =>
        ({
          kind: "globalOption" as const,
          id: go.id,
          data: {
            ...go,
            readyPlanOptions: existingRpos[go.id],
            locations: existingRpos[go.id]?.filter((rpo) => rpo.active).map((rpo) => rpo.location?.id) ?? [],
          },
          initSelected: !!existingRpos[go.id]?.some((rpo) => rpo.active),
        }) as GridDataRow<GlobalOptionRow>,
    )
    .partition((c) => c.initSelected);

  function sortOptions(options: GridDataRow<GlobalOptionRow>[]): GridDataRow<GlobalOptionRow>[] {
    // For lastUsedAt, which may be null, use a date before HB was founded so that they sort to the end
    const gosSorted = options.sortBy((c) => c.data[sortBy] ?? new Date(2000, 0, 1));
    if (sortBy === "code") return gosSorted;
    // For the dates, we want the earliest first, which is the reverse of the standard sort
    return gosSorted.reverse();
  }

  return [simpleHeader, ...sortOptions(initiallySelected), ...sortOptions(others)];
}

function mapToFilter(filterWithSort: ChooseOptionsFilter, globalOptionTypes: NamedFragment[]): GlobalOptionsFilter {
  const { sortBy, ...filter } = filterWithSort;
  // If `type` filter is empty, return only the globalOptionTypes that we want to show (i.e. Elevation may or may not be included)
  const type = filter.type && filter.type.length > 0 ? filter.type.compact() : globalOptionTypes.map((got) => got.id);
  return { ...filter, type };
}

const tableStyles = {
  ...cardStyle,
  cellCss: {
    ...cardStyle.cellCss,
    ...Css.aic.pPx(12).$,
  },
  presentationSettings: {
    borderless: false,
    typeScale: "xs" as const,
    wrap: false,
  },
};

const rowStyles: RowStyles<ChooseOptionsRow> = {
  header: {
    rowCss: Css.bgGray100.bn.important.$,
    cellCss: Css.bgGray100.bn.important.$,
  },
  globalOption: {
    rowCss: Css.bgWhite.bn.boxShadow("0px 4px 8px 0px #35353514").mt1.borderRadius("8px").hPx(44).$,
    cellCss: Css.bgWhite.$,
  },
};

// Helper method for comparing desired and existing RPOs
function keyRpo(globalOptionId: string, locationId: string | undefined): string;
function keyRpo(rpo: ChooseOptions_ReadyPlanOptionFragment): string;
function keyRpo(
  rpoOrGlobalOptionId: string | ChooseOptions_ReadyPlanOptionFragment,
  locationId?: string | undefined,
): string {
  if (typeof rpoOrGlobalOptionId === "string") return `${rpoOrGlobalOptionId}-${locationId}`;
  return `${rpoOrGlobalOptionId.globalOption.id}-${rpoOrGlobalOptionId.location?.id}`;
}
