import { FetchResult } from "@apollo/client";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useStepperContext } from "src/components/stepper";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { PlanPackage_SaveReadyPlanOptionsMutation, SavePlanPackageDetailsMutation } from "src/generated/graphql-types";
import { createPlanPackageEditUrl, createPlanPackageUrl } from "src/RouteUrls";

type PlanPackageNextStepButtonProps = {
  planId: string | undefined;
  versionId: string | undefined;
  disabled?: boolean;
  lastStep?: boolean;
  onSave: () => Promise<
    FetchResult<SavePlanPackageDetailsMutation | PlanPackage_SaveReadyPlanOptionsMutation> | undefined
  >;
};

export function PlanPackageNextStepButton({
  planId,
  versionId,
  disabled,
  lastStep,
  onSave,
}: PlanPackageNextStepButtonProps) {
  const history = useHistory();
  const { nextStepEvenIfDisabled } = useStepperContext();

  const onContinue = useCallback(async () => {
    const response = await onSave();
    if (response?.data && versionId !== response.data.savePlanPackage.planPackage.version.id) {
      const pp = response.data.savePlanPackage.planPackage;
      history.replace(createPlanPackageEditUrl(pp.id, pp.version.id));
      nextStepEvenIfDisabled();
    }
  }, [onSave, versionId, history, nextStepEvenIfDisabled]);

  const onSaveAndExit = useCallback(async () => {
    const response = await onSave();
    const pp = response?.data?.savePlanPackage.planPackage;
    if (pp) {
      history.push(createPlanPackageUrl(pp.id, pp.version.id));
    } else if (planId && versionId) {
      history.push(createPlanPackageUrl(planId, versionId));
    }
  }, [history, onSave, planId, versionId]);

  return (
    <NextStepButton
      label={lastStep ? "Finish" : "Continue"}
      disabled={disabled}
      onClick={lastStep ? onSaveAndExit : onContinue}
      exitButton={{
        variant: "secondary",
        label: "Save & Exit",
        onClick: onSaveAndExit,
      }}
    />
  );
}
