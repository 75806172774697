import {
  Button,
  ButtonMenu,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalProps,
  ScrollableContent,
  actionColumn,
  column,
  emptyCell,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { dateCell, priceCell } from "src/components";
import {
  TaskCatalogLaborCostsPage_BidItemEstimateFragment,
  useTaskCatalogLaborCostsPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { LaborCostParams, catalogTasksPath } from "src/routes/routesDef";
import { createTaskCatalogFormUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { BidEstimateModal } from "../material-catalog/BidEstimateModal";

export function TaskCatalogLaborCostsPage() {
  const { globalPlanTaskId } = useParams<LaborCostParams>();

  const query = useTaskCatalogLaborCostsPageQuery({
    variables: {
      filter: { parent: [globalPlanTaskId] },
      page: { offset: 0, limit: 100 },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.bidItemEstimates.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data?.bidItemEstimates.entities.length,
            limit: 100,
          },
        },
      });
    }
  }, [query]);

  return queryResult(query, ({ bidItemEstimates }) => (
    <TaskCatalogTablePageView
      bidItemEstimates={bidItemEstimates.entities}
      maybeFetchMore={maybeFetchMore}
      globalPlanTaskId={globalPlanTaskId}
    />
  ));
}

function TaskCatalogTablePageView({
  bidItemEstimates,
  maybeFetchMore,
  globalPlanTaskId,
}: {
  bidItemEstimates: TaskCatalogLaborCostsPage_BidItemEstimateFragment[];
  maybeFetchMore: () => void;
  globalPlanTaskId: string;
}) {
  const { openModal } = useModal();
  const columns = useMemo(() => createColumns(openModal, globalPlanTaskId), [openModal, globalPlanTaskId]);
  const rows = useMemo(() => createRows(bidItemEstimates), [bidItemEstimates]);
  return (
    <>
      <PageHeader
        title="Labor Costs"
        breadcrumb={[
          { href: catalogTasksPath, label: "Task Catalog" },
          { label: "Edit Task", href: createTaskCatalogFormUrl(globalPlanTaskId) },
        ]}
        right={
          <Button
            label="Add Labor Cost"
            onClick={() =>
              openModal({
                content: <BidEstimateModal globalPlanTaskId={globalPlanTaskId} />,
              })
            }
          />
        }
      />
      <ScrollableContent virtualized>
        <GridTable columns={columns} rows={rows} as="virtual" infiniteScroll={{ onEndReached: maybeFetchMore }} />
      </ScrollableContent>
    </>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: TaskCatalogLaborCostsPage_BidItemEstimateFragment };

export type Row = HeaderRow | DataRow;

function createColumns(openModal: (props: ModalProps) => void, globalPlanTaskId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Date Added",
      data: ({ createdAt }) => dateCell(createdAt),
    }),
    column<Row>({
      header: "Contributor",
      data: ({ createdBy }) => createdBy.name,
      w: "150px",
    }),
    column<Row>({
      header: "Market",
      data: ({ markets }) => markets.map((m) => m.name).join(", "),
    }),
    column<Row>({
      header: "Source",
      data: ({ sourceUrl }) =>
        sourceUrl && (
          <Link to={{ pathname: sourceUrl }} target="_blank">
            {sourceUrl}
          </Link>
        ),
    }),
    column<Row>({
      header: "UoM",
      data: ({ bidItemEstimate }) => bidItemEstimate?.unitOfMeasure.abbreviation,
    }),
    column<Row>({
      header: "Cost",
      data: ({ costInMills }) =>
        priceCell({
          valueInCents: costInMills / 10,
          minDecimalPlaces: 3,
          maxDecimalPlaces: 3,
          trim: true,
          alignment: "left",
        }),
    }),
    actionColumn<Row>({
      header: emptyCell,
      w: "50px",
      data: (row) => (
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          items={[
            {
              label: "Edit",
              onClick: () =>
                openModal({ content: <BidEstimateModal globalPlanTaskId={globalPlanTaskId} bidEstimate={row} /> }),
            },
          ]}
        />
      ),
    }),
  ];
}

function createRows(data: TaskCatalogLaborCostsPage_BidItemEstimateFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...data?.map((bie) => ({
      kind: "data" as const,
      id: bie.id,
      data: bie,
    })),
  ];
}
