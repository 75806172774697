import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import { MaterialVariantSelectField } from "src/components/autoPopulateSelects/MaterialVariantSelectField";
import {
  AddEditItemModal_MvFragment,
  MaterialType,
  useAddEditItemModal_CreateLocationMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { TakeoffsStoreState } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { StoreApi } from "zustand";
import { AddEditItemLocationsField } from "./AddEditItemLocationsField";
import {
  AddEditItemModalDataViewProps,
  AddEditItemObjectState,
  CommonFields,
  PublishedTakeoffBanner,
  SelectedTask,
  TakeoffTaskBoundSelectField,
  TliData,
  saveAddEditItem,
  useAddEditItemModalFormState,
  withGetTliQuery,
} from "./utils";

export const AddEditMaterialItemModal = withGetTliQuery(AddEditMaterialItemModalDataView);

function AddEditMaterialItemModalDataView(props: AddEditItemModalDataViewProps) {
  const { planPackageId, initialTliData, takeoffsManagerStore } = props;
  const [saveLocation] = useAddEditItemModal_CreateLocationMutation();

  const { saveTli, planPackage } = takeoffsManagerStore.getState();
  const { closeModal, addCanClose } = useModal();
  const isEditing = !!initialTliData?.id;
  const readOnly = !planPackage.canEditLineItems.allowed;

  const formState = useAddEditItemModalFormState("material", readOnly, initialTliData);

  addCanClose(() => {
    // eslint-disable-next-line no-restricted-globals
    return formState.dirty ? confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>{readOnly ? "Material Details" : isEditing ? "Edit Material" : "Add Material"}</span>
          </ModalHeader>
          <ModalBody>
            {readOnly && <PublishedTakeoffBanner />}
            <div css={Css.df.fdc.gap2.pb3.$}>
              <MaterialFields
                store={takeoffsManagerStore}
                planPackageId={planPackageId}
                initialTliData={initialTliData}
                readOnly={readOnly}
                formState={formState}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {readOnly ? (
              <Button label="Close" onClick={closeModal} />
            ) : (
              <>
                <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
                <Button
                  label="Save"
                  data-testid="saveBtn"
                  disabled={!formState.valid}
                  onClick={async () => {
                    await saveAddEditItem({
                      formState,
                      planPackageId,
                      saveTli: saveTli,
                      saveLocation,
                    });
                    closeModal();
                  }}
                  size="lg"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type MaterialFieldsProps = {
  store: StoreApi<TakeoffsStoreState>;
  formState: ObjectState<AddEditItemObjectState>;
  planPackageId: string;
  initialTliData?: TliData;
  readOnly: boolean;
};

function MaterialFields({ store, planPackageId, initialTliData, readOnly, formState }: MaterialFieldsProps) {
  const [selectedTask, setSelectedTask] = useState<SelectedTask | undefined | null>(
    initialTliData?.task ? { id: initialTliData?.task?.id, name: initialTliData?.task?.name } : undefined,
  );
  const setDefaults = useCallback(
    (selectedMaterialVariant: AddEditItemModal_MvFragment | undefined) => {
      // Default the item UOM, even if a value had been specified
      if (selectedMaterialVariant?.listing?.item.unitOfMeasure?.id) {
        formState.unitOfMeasureId.set(selectedMaterialVariant?.listing?.item.unitOfMeasure?.id);
        formState.unitOfMeasure.set(selectedMaterialVariant?.listing?.item.unitOfMeasure);
      }
      // Default the item
      if (!formState.itemId.value) {
        formState.itemId.set(selectedMaterialVariant?.listing?.item.id);
      }
      // Default the item task
      if (
        formState.locationId.value &&
        formState.materialVariantId.value &&
        !formState.taskId.value &&
        selectedMaterialVariant?.listing?.item?.planTaskMappings?.nonEmpty
      ) {
        const taskMappings = selectedMaterialVariant?.listing?.item?.planTaskMappings;
        const exactTaskMatch = taskMappings.find((ptm) => ptm.location?.id === formState.locationId.value);
        const wildCardLocationMatch = taskMappings.find((ptm) => !ptm.location);
        const defaultTask = exactTaskMatch ?? wildCardLocationMatch;

        if (defaultTask) {
          formState.taskId.set(defaultTask?.task.id);
          setSelectedTask(defaultTask.task);
        }
      }
    },
    [formState],
  );
  // When a material cost type is selected then...
  const onMaterialSelect = useCallback(
    (id: string | undefined, selectedMaterialVariant: AddEditItemModal_MvFragment | undefined) => {
      // Set the material variant on the line
      formState.materialVariantId.set(id);
      formState.materialVariant.set(selectedMaterialVariant);

      // We set the defaults
      setDefaults(selectedMaterialVariant);
    },
    [formState, setDefaults],
  );

  return (
    <>
      <MaterialVariantSelectField
        label="Material*"
        field={formState.materialVariantId}
        nothingSelectedText="Please select a material"
        filter={{
          type: [MaterialType.Construction],
          itemId: !formState.isNewEntity && formState.itemId.value ? [formState.itemId.value] : undefined,
          includeArchived: false,
        }}
        // // FIXME: Lets clean up this interaction, if we can, post pilot
        // // Only allow reselection of the material if its `Add New`
        initialOptions={
          !formState.isNewEntity && formState.materialVariant.value?.displayName
            ? [formState.materialVariant.value].compact()
            : []
        }
        getOptionLabel={(o) => `${o.code} - ${o.displayName}`}
        getOptionValue={(o) => o.id}
        placeholder="Select material"
        onSelect={onMaterialSelect}
        disabled={disableBasedOnPotentialOperation(initialTliData?.canEditMaterial)}
      />
      <AddEditItemLocationsField
        planPackageId={planPackageId}
        rpavId={store.getState().planPackage.version.id}
        newLocationNameField={formState.newLocationName}
        field={formState.locationId}
        onSelect={(locationId: string | undefined, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
          formState.materialVariant.value && setDefaults(formState.materialVariant.value);
        }}
        disabled={disableBasedOnPotentialOperation(initialTliData?.canEditLocation)}
      />
      <TakeoffTaskBoundSelectField
        allowNone={true}
        selectedTask={selectedTask}
        readOnly={readOnly}
        formState={formState}
      />
      <CommonFields
        store={store}
        planPackageId={planPackageId}
        readOnly={readOnly}
        formState={formState}
        disabled={{
          options: disableBasedOnPotentialOperation(initialTliData?.canEditOptions),
          quantity: disableBasedOnPotentialOperation(initialTliData?.canEditQuantity),
        }}
      />
    </>
  );
}
